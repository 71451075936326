import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import { LogIn, User, Bell, Minimize, Search, X, ArrowLeftCircle, LogOut } from 'react-feather';
import Bookmark from "../../layout/bookmark"
import { useNotification } from '../../hooks/notification/useNotification';
import { useSignalR } from '../../hooks/signalR/useSignalR'

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

import { Notification, Admin, Account } from '../../constant'

import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { useHistory } from 'react-router-dom';
import { ConfigServer } from '../../data/config';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();


const Rightbar = (props) => {
  const [searchresponsive, setSearchresponsive] = useState(true)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("en")
  const [notificationDropDown, setNotificationDropDown] = useState(false)
  const history = useHistory();

  // //console.log(moonlight);

  // localStorage.setItem('gato', moonlight);

  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true)
      setTimeout(() => {
        document.body.className = "dark-only";
      }, 100);
    }
  }, []);







  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }


  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }
  //       localStorage.setItem('gato', light);
  //Mode Moonligth
  const MoonlightToggle = (light) => {
    // localStorage.setItem('gato', light);
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  const cerrarSesion = () => {
    let url = localStorage.getItem("url_helen");
    localStorage.clear();

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(url + "/logout", requestOptions)
      .then(response => response.text())
      .then(result => {
        setTimeout(() => {
          window.location.href = url;
        }, 500);
      })
      .catch(error => console.log('error', error));
  }

  const backToHELEN = () => {
    let username = localStorage.getItem("username");
    let base64 = btoa(username);
    let url = localStorage.getItem("url_helen");
    window.location.href = url + "/validacionHtds/" + base64;
  }

  const [nameUser, setNameUser] = useState("User");
  const [roleUser, setRoleUser] = useState("Normal")

  useEffect(() => {
    localStorage.getItem("nameUser") !== null && setNameUser(localStorage.getItem("nameUser").toUpperCase());
    localStorage.getItem("roleUser") !== null && setRoleUser(localStorage.getItem("roleUser").toUpperCase());
  }, []);


  const { notifications, deleteNotification, updateMessage, play } = useNotification();


  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0 text-white">
        <ul className="nav-menus">
          <li><span className="header-search text-white"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li>
          <Bookmark />
          <li>
            <div className="mode text-white" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          <li>
            <div onClick={backToHELEN} className="mode text-white"><ArrowLeftCircle className='text-white' /></div>
          </li>
          {/* <li className="maximize-Icono-Maximizar text-white"><a className="text-white" href="#Datos" onClick={goFull}><Minimize /></a></li> */}
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media" >  <br /> <br />
              <img className="b-r-10  "
                src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                style={{ width: "30%" }}
                alt="" />
              <div className="media-body p-2 text-white"><span>{nameUser} <i className="middle fa fa-angle-down"></i> <br />  </span>
                {/* <p className="mb-0 font-roboto text-white">{roleUser} <i className="middle fa fa-angle-down"></i></p> */}
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li ><User /><span>{Account}  </span> </li>
              <li onClick={cerrarSesion}><LogIn /><span>Cerrar sesión</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}
export default translate(Rightbar);