import React, { useEffect, useState, useRef } from 'react';
import { Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import './styleDashboard.css';
import { Col, Row, Card, Table } from 'reactstrap';
import DeniReactTreeView from "deni-react-treeview"





export const ModalTrazabilidadSampleDashboard = (
    { treeviewRef, close, namePatient, dataSection, dataID
        , idGender, dataInformation, dataSample, dataSendProcess2, dataSendProcess3, dataTrackingRecepetion, itemsTree, ...props }) => {





    const onRenderItem = (item) => {
        return (
            <div className="treeview-item-example">
                {
                    item.refused_check_specimen === true ?
                        <div>
                            {
                                item.isLeaf === false ?
                                    <span className='text-primary '>
                                        <i className="icofont icofont-doctor-alt f-18 "></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                                    :
                                    <span className=''>
                                        <i className="icofont icofont-doctor-alt f-18 "></i><label className='f-12' >{item.text}</label> &nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                            }
                        </div>
                        :
                        <div>
                            {
                                item.isLeaf === false ?
                                    <span className='text-danger '>
                                        <i className="icofont icofont-laboratory  f-14"></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                                    :
                                    <span className=''>

                                        <i className="icofont icofont-laboratory  f-14"></i><label className='f-12' >{item.text}</label>&nbsp;&nbsp;&nbsp;&nbsp;

                                        {
                                            item.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                        {
                                            item.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : <i className='fa fa-times text-danger f-16' ></i>
                                        }&nbsp;
                                    </span>
                            }
                        </div>
                }
            </div >
        )
    }

    const themes = ['green']



    return (

        <Modal {...props} padding={false} closeButton={false}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div
                    style={{
                        display: "flex",
                        padding: "10px 10px",
                    }}
                >
                    <Col sm="12" >
                        <Row>
                            <Col sm="5" xl="4" style={{ textAlign: "center" }}>
                                <b className="txt-secondary f-18">Trazabilidad de la muestra </b>
                            </Col>
                        </Row>
                    </Col>
                    <button className="butto-full-modal" onClick={close}>
                        <label className='f-20'>X</label>
                    </button>
                </div>

                <Col sm="12">
                    <Row>
                        <Col sm="5" xl="4" className='p-l-0' >
                            <Card style={{ backgroundColor: "#4493d11f" }} >
                                <Row>
                                    <Col sm="4">
                                    </Col>
                                    <Col sm="8">
                                        <div className='userTrazabilidad imagen ' >
                                            <img style={{ width: "70%" }} src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                            <br />
                                        </div>
                                    </Col>
                                </Row>

                                <br />
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    <label className='f-w-600 f-14 txt-secondary '>{namePatient}</label>
                                </Col>

                                <Col sm="12" className='p-l-0' >
                                    <div className='table-responsiv'>
                                        <Table size='sm' striped bordered hover>
                                            <thead>
                                                <tr className='text-center'>
                                                    <th>Envio</th>
                                                    <th>Preparación</th>
                                                    <th>Recepción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-times text-danger f-14' ></i> <i className='fa fa-times text-danger f-14' ></i>  </td>
                                                    <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-check txt-success f-14'></i>  <i className='fa fa-times text-danger f-14' ></i> </td>
                                                    <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-check txt-success f-14'></i>  <i className='fa fa-check txt-success f-14'></i>  </td>
                                                </tr>

                                            </tbody>

                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="12">
                                    <label className='f-w-600 f-14 txt-secondary '>Estudios a Realizarse:</label>
                                    <br />
                                    <div className='study-flebotomia' >
                                        <DeniReactTreeView
                                            className="deni-react-treeview-container5"
                                            ref={treeviewRef}
                                            showCheckbox={false}
                                            showIcon={false}
                                            items={itemsTree}
                                            onRenderItem={onRenderItem}
                                            // theme={themes}
                                            selectRow={false}
                                        />
                                    </div>
                                </Col>
                                <br /> <br />
                            </Card>
                        </Col>

                        <Col sm="7" xl="8" className='p-l-0 p-r-0' >

                            <Row>
                                <br />
                                <Col sm="12" style={{ textAlign: "center" }}>
                                    {
                                        dataSample.length === 0 ?
                                            <div className='disabled disableLabel'>
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0' >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Toma de Muestra</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE TOMA</label>

                                                        </Col>
                                                    </Row>
                                                </Col> <br /> <br />
                                                <hr />
                                            </div>
                                            :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Toma de Muestra</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {/* <br /> */}
                                                </Col>
                                                {
                                                    dataSample.length >= 0 && dataSample.map((_obj) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    _obj.status === true ?
                                                                        <div>
                                                                            <label className='txt-danger f-14'>TOMA CANCELADA</label> <br />
                                                                            <b className='txt-danger' >fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                            <br />
                                                                            <b className='txt-danger'>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                            <br />
                                                                            <b className='txt-danger'>Cancelaciones en toma:</b>  <label>
                                                                                {
                                                                                    _obj.cancellations
                                                                                }
                                                                            </label>
                                                                            <hr className='txt-danger' />
                                                                        </div>
                                                                        :
                                                                        <div className='cuadrado-trazabilidad' >
                                                                            <br />
                                                                            <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                            <br />
                                                                            <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                            <br />
                                                                            <b>Cancelaciones en toma:</b>  <label>
                                                                                {
                                                                                    _obj.cancellations
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                }


                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    }
                                </Col>
                            </Row>




                            <Row>
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    {
                                        dataSendProcess2.length === 0 ?
                                            <div className='disabled disableLabel' >
                                                <br /> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Envio a Sucursal</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE ENVIO DE MUESTRA A SUCURSAL</label>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <br /> <br />
                                                <hr />
                                            </div> :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Envio a Sucursal</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {/* <br /> */}
                                                </Col>
                                                <div className='cuadrado-trazabilidad' >
                                                    {
                                                        dataSendProcess2.length >= 0 && dataSendProcess2.map((_obj) => {
                                                            return (
                                                                <div>
                                                                    <br />
                                                                    <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                    <br />
                                                                    <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                    <br />
                                                                    <b>Cancelaciones en envio de muestras</b>  <label>
                                                                        {
                                                                            _obj.cancellations
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>

                                            </div>
                                    }
                                </Col>
                            </Row>


                            <Row>
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    {
                                        dataSendProcess3.length === 0 ?
                                            <div className='disabled disableLabel'>
                                                <br /> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Preparación</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE PREPARACIÓN</label>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <br /> <br />
                                                <hr />
                                            </div>
                                            :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Preparación</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {/* <br /> */}
                                                </Col>
                                                <div className='cuadrado-trazabilidad' >
                                                    {
                                                        dataSendProcess3.length >= 0 && dataSendProcess3.map((_obj) => {
                                                            return (
                                                                <div>
                                                                    <br />
                                                                    <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                    <br />
                                                                    <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                    <br />
                                                                    <b>Cancelaciones en envio de muestras</b>  <label>
                                                                        {
                                                                            _obj.cancellations
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                    }
                                </Col>
                            </Row>


                            <Row>
                                <Col sm="12" style={{ textAlign: "center" }} >
                                    {
                                        dataID.length === 0 ?
                                            <div className='disabled disableLabel'>
                                                <br /> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="1">
                                                            <div style={{ filter: "grayscale(1)" }} className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad' ></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b style={{ filter: "grayscale(1)" }} className='label-trazabilidad f-18'>Analitica(recepción) </b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div style={{ filter: "grayscale(1)" }} className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                            <br /> <br />
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE ANALITICA </label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <br /> <br />
                                                <hr />
                                            </div> :
                                            <div>
                                                <Col sm="12"  >
                                                    <Row>
                                                        <Col sm="1">
                                                            <div className="circulo  m-l-0">  <br />
                                                                <img style={{ width: "50%" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} /> <br />
                                                            </div>
                                                        </Col>
                                                        <Col sm="11">
                                                            <Row>
                                                                <Col sm="4" className='p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                                <Col sm="3" className='p-l-0 p-r-0'  >  <br />
                                                                    <b className='label-trazabilidad f-18'>Análitica(recepción)</b>
                                                                </Col>
                                                                <Col sm="4" className='p-l-0 p-r-0' > <br /> <br />
                                                                    <div className='rectangulo-trazabilidad'></div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </Col>
                                                <Row>
                                                    {
                                                        dataID.length > 0 && dataID.sort() && dataID.map((e, k) => {
                                                            return (
                                                                <Col sm="4" key={k} className >
                                                                    <Col sm="12" className="p-l-0 p-r-0" >
                                                                        <div className='Card-nim-Component-Header'>
                                                                            <Col sm="12">
                                                                                <label className="label-trazabilidad-l f-14" ><b>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; {e}</b> </label>
                                                                            </Col>

                                                                        </div>
                                                                        <div className='Card-nim-Component-Body'>
                                                                            {
                                                                                dataSection[e].secciones != undefined && dataSection[e].secciones.map((l, keyy) => {
                                                                                    return (
                                                                                        <Col sm="12" key={keyy} style={{ textAlign: "initial" }} >
                                                                                            <label className="f-12"> <i className="fa fa-circle statusIcon  f-14"  ></i> {l.nombre}</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }

                                                                        </div>




                                                                    </Col>  <br /> <br /> <br /> <br />
                                                                </Col>

                                                            )
                                                        })
                                                    }

                                                </Row>




                                            </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <br />
            </div>
        </Modal >

    )
}