import React, { Fragment } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Input, Label, Row, Table } from 'reactstrap';
import { BtnSaveWorkOrder,BtnCancelWorkOrder, CheckTextEmail, CheckTextPrint, CheckTextWhats, LabelPrinterPoint, PlaceHolderOption } from '../../../constant';
import { TableWorkOrderUpdate } from '../../../components/elements/TableWorkOrderUpdate';
import { ModalDevolution } from '../../../components/workOrders/ModalDevolution';
import { useEditWorkOrder } from '../../../hooks/workOrder/useEditWorkOrder';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import Select from 'react-select';
import 'animate.css';
import { ModalViewExams } from '../../../components/workOrders/ModalViewExams';
import { ModalCommonDiscounts } from '../../../components/workOrders/ModalCommonDiscounts';
import {ModalAuthorize} from '../../../components/workOrders/ModalAuthorize';
import {ModalLiquidacion} from '../../../components/workOrders/ModalLiquidacion';

export const IndexEditWorkOrder = () => {
	let enable_whatsapp = localStorage.getItem('enable_whatsapp');
	const enable_tracking_toma = localStorage.getItem('enable_tracking_toma');
	const {
		//WORK ORDER 
			// WORK ORDER MODEL
			id_company,
			id_printer_point, observations, observations_sample,observations_general,
			print_results, send_whatsapp, send_doctor, results_partial, 
			send_email, check_invoice, name_company, listTest, 
			listIndicationsExams, listIndicationsProfile, listPayMethods,
			firstLoadingWorkOrder, searchExamProfileState, devolutionsState,
			totalW, subTotalW, totalDiscountW, totalTaxesW, totalDevolutionsW,totalDevolutionsWOC,
			showMessageW, modalViewExamsP, viewExamsProfile, enableBtnSaveWorkorder,enableBtnCancelWorkorder,
			validationsWorkOrder, loading,valid_commont,
			//LIST PRELOADED VALUES
			listPrinterPoint, listPayMethod, listReturnReason,
			times,
			//WORK ORDER METHODS
			handleInputChangeWorkOrder, handleSelectValuesWorkOrder,
			handleSelectExamChange, removeExamProfile, handleCheckSpecimen,
			handleCheckUrgent, changeSelectSamplings, changeValueTimeSampling,
			handleAddPayMethodInputs, handleChangePayMethod, handleGiveChange,
			handleDeletePayMethod, handleViewExamsProfile, toggleModalViewExams,
			saveWorkOrder,handleModalCancelWorkOrder,handleCheckForwarded,handleBack,
			//DATOS WORK ORDER AUTORIZE
			user_autorize,handleInputChangeAuthorize,password_autorize,validationsAutorize,
			id_return_reason_woc,percentage_return,showMessageWOC,percentagereturn,
			//COMENTARIOS
			checkComments, ShowClassNameC,enableComments,
			//METHODS WORK ORDER AUTORIZE
			modalAutorizeWO, toggleAutorizeWO,saveAutorized,validatemont,validate_mont,
			//LIQUIDAR WORK ORDER
			handleInputChangeLiquided,modalLiquidedWO,toggleLiquidedWO,handleModalLiquided,id_method_liquided,validatesaveLiquided,
			amount_liquided,validationsliquided,saveLiquided,id_method_pay,amount_liq,
		//PATIENT
			//PATIENT MODEL
			informationPatient,

		//DOCTOR
			//DOCTOR MODEL
			doctorState, selectListDoctors, 
			listDoctors,

			//DOCTOR METHODS
			handleChangeDoctor,
		
		//RETURN REASON
			//RETURN REASON METHODS
			handleCreateDevolution, handleChangeReturnReason, 
			saveReturnReason, toggleDevolutions, modalDevolutions,
			validateDevolutions, modalTitle,
		
		//COMMON DISCOUNTS
			//VALUES 
			arrayNameDiscount, arrayCommonDiscounts,checkDiscount,clearDiscount,

			//METHODS
			handleModalListCommonDiscounts,modalCommonDiscount, toggleCommonDiscount,
		
		//ALTERNATIVE METHODS
		number_format,
		//VALIDA SI ESTA TOTALMENTE PAGADA LA ORDEN
		checkpaid,canceldevolution
	} = useEditWorkOrder();

	const {
		id_printer_point_valid,
		listTest_valid, 
		listPayMethods_valid
	} = validationsWorkOrder;	

	return (
		<Fragment>
			<Container fluid={true}>
				<Row className={firstLoadingWorkOrder ? 'm-b-15 m-t-15' : 'd-none'}>
					<Col sm="12" md="12" lg="12" xl="12" className='text-center'>
						<div className="loader-box primary" style={{ width: "150px", height: "150px", marginLeft: "45%", marginRight: "50%" }}>
							<div className="loader-9" color='primary' style={{ height: "150px", width: "150px" }}></div>
						</div>
						<br />
						<p><b>Cargando admisión...</b></p>
					</Col>
				</Row>
				<Row className={firstLoadingWorkOrder ? 'd-none match-height' : 'match-height animate__animated animate__fadeInDown m-b-15 m-t-15'}>
					<Col sm="12" md="6">
						<Card className='shadow'>
							<CardHeader className='bg-primary p-2'>
								<h6 className='m-t-5 m-b-0 m-l-5'>
									<i className='fa fa-user f-15 m-r-5'></i>
									<Label className='f-10'>Datos del paciente</Label>
								</h6>
							</CardHeader>
							<CardBody className='p-1'>
								<Row>
									<Col sm="3" md="3" lg="3" xl="3" className='p-r-0 p-l-0' style={{textAlign: "-webkit-center" }}>
										{
											informationPatient.gender === "Masculino"
											? <div className='profile-vector'><img className='img-fluid' src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "100px" }} alt="" /></div>
											: <div className='profile-vector'><img className='img-fluid' src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "100px" }} alt="" /></div>
										}
									</Col>
									<Col sm="9" md="9" lg="9" xl="9" className='text-center p-t-20' style={{ verticalAlign: "middle"}}>
										<label><b>{ informationPatient.name_patient }</b></label>
										<br />
										<label className='text-muted'>{ informationPatient.birthday } / {informationPatient.age}</label>
									</Col>
								</Row>
								<Row className='m-t-5'>									
									<Col sm="12" md="12" lg="12" xl="12">
										<Table className='table table-xs p-0'>
											<tbody className='f-10'>
												<tr className='p-0'>
													<td className='p-t-1 p-b-0'>
														<i className='fa fa-circle iconResults'></i><b className='f-12'> NIM:</b>
													</td>
													<td className='p-t-1 p-b-0'>
														<label className='f-12'><b>{ informationPatient.nim }</b></label>
													</td>
												</tr>
												<tr className={`${informationPatient.curp === null ? 'd-none' : "p-0 m-0"}`}>
													<td className='p-t-1 p-b-0'>
														<i className='fa fa-circle iconResults'></i><b className='f-12'> Curp:</b>
													</td>
													<td className='p-t-1 p-b-0'>
														<label className='f-12'>{ informationPatient.curp }</label>
													</td>
												</tr>
												<tr className="p-0 m-0">
													<td className='p-t-1 p-b-0'>
														<i className='fa fa-circle iconResults'></i><b className='f-12'> Sexo:</b>
													</td>
													<td className='p-t-1 p-b-0'>
														<label className='f-12'>{ informationPatient.gender }</label>
													</td>
												</tr>
												<tr className='p-0 m-0'>
													<td className='p-t-1 p-b-0'>
														<i className='fa fa-circle iconResults'></i><b className='f-12'> Contacto:</b>
													</td>
													<td className='p-t-1 p-b-0'>
														<label className='f-12'>{ informationPatient.phone }</label>
														<br />
														<label className='f-12'>{ informationPatient.email }</label>
													</td>
												</tr>
												<tr className={`${informationPatient.membership === null ? 'd-none' : 'p-0 m-0'}`}>
													<td className='p-t-1 p-b-0'>
														<i className='fa fa-circle iconResults'></i><b className='f-12'> Membresia:</b>
													</td>
													<td className='p-t-1 p-b-0'>
														<label className='f-12'>{ informationPatient.membership }</label>
													</td>
												</tr>
												<tr className={`${name_company === "" ? 'd-none' : "p-0 m-0"}`}>
													<td className='p-t-1 p-b-0'>
														<i className='fa fa-circle iconResults'></i><b className='f-12'> Empresa</b>
													</td>
													<td className='p-t-1 p-b-0'>
														<label className='f-12'>{ name_company }</label>
													</td>
												</tr>
											</tbody>
										</Table>
									</Col>
									<Col sm="4" md="4" lg="4" xl="4">

									</Col>
									<Col sm="4" md="4" lg="4" xl="4">

									</Col>
									<Col sm="4" md="4" lg="4" xl="4">
										<Button className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
											outline
											color='danger-2x'
											disabled={enableBtnCancelWorkorder}		
											onClick={handleModalCancelWorkOrder}>
											{BtnCancelWorkOrder}
										</Button>	
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col sm="12" md="6">
						<Card className='shadow' style={{maxWidth: '100%', height: '300px'}}>
							<CardHeader className='bg-primary p-2'>
								<h6 className='m-t-5 m-b-0 m-l-5'>
									<i className='fa fa-user-md f-15 m-r-5'></i>
									<Label className='f-10'>Médico solicitante</Label>
								</h6>
							</CardHeader>
							<CardBody className='p-0'>
								<Row className='p-3'>
									<Col sm="12" md="12" lg="12" xl="12" className='p-r- p-l-1'>
										<SearchAsync
											name="selectListDoctors"
											method={handleChangeDoctor}
											loincState={doctorState}
											url='Doctors/SearchDoctor'
											maxLenght={3}
											mMultiple={true}
										/>
									</Col>
								</Row>
								<Row className='p-1'>
									<Col sm="12" md="12" lg="12" xl="12">
										<Table className='table table-xs'>
											<tbody>
												{
													(listDoctors !== null && listDoctors.length > 0) && listDoctors.map(d => {
														return (
															<tr>
																<td>
																	<p className='f-12'>
																		<i className='fa fa-circle iconResults f-12'></i>&nbsp;
																		{d.name_doctor+" - "+d.specialty+" - "+(d.email === null ? "" : d.email)}
																	</p>
																</td>
															</tr>
														)
													})
												}
											</tbody>											
										</Table>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row className={firstLoadingWorkOrder ? 'd-none match-height' : 'match-height animate__animated animate__fadeInDown'}>
					<Col sm="6" md="6" lg="6" xl="6">
						<Card className='shadow'>
							<CardHeader className='bg-primary p-3'>
								<i className='fa fa-cogs f-20'></i> &nbsp;&nbsp;&nbsp;&nbsp;
								<label className=''>Acciones</label>
							</CardHeader>
							<CardBody className='p-l-2 p-t-0 p-b-10'>
								<Row className='m-t-5'>
									<Col sm="12">
										<Label>{LabelPrinterPoint} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
										<FormGroup>
											<Select
												classNamePrefix="select"
												name="id_printer_point"
												value={id_printer_point}
												options={listPrinterPoint}
												onChange={(e) => handleSelectValuesWorkOrder(e, "id_printer_point")}
												placeholder={PlaceHolderOption}
												isClearable={true}
											/>
											
											<FormText color='danger' hidden={!id_printer_point_valid}>Debes de seleccionar una opción</FormText>
										</FormGroup>
									</Col>
								</Row>
								<Row className='m-t-5'>
									<Col xs="6" sm="6" md="6" lg="4" xl="4">
										<Label>{CheckTextPrint}</Label>
										<br />
										<Label className='switch'>
											<Input type='checkbox' name="print_results" onChange={handleInputChangeWorkOrder} checked={print_results} />
											<span className='slider round'></span>
										</Label>
									</Col>
									<Col xs="6" sm="6" md="6" lg="4" xl="4">
                                        <Label className="labelCheckPrint">Resultados parciales</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="results_partial" onChange={handleInputChangeWorkOrder} checked={results_partial} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
									<Col xs="6" sm="6" md="6" lg="4" xl="4">
                                        <Label className="labelCheckPrint">{CheckTextEmail}</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="send_email" onChange={handleInputChangeWorkOrder} checked={send_email} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
								</Row>
								<Row className='m-t-5'>
									{
										 enable_whatsapp === "True" 
										 ?
											<Col xs="6" sm="6" md="6" lg="4" xl="4" >
												<Label className="labelCheckPrint">{CheckTextWhats}</Label> <br />
												<Label className="switch">
													<Input type="checkbox" name="send_whatsapp" onChange={handleInputChangeWorkOrder} checked={send_whatsapp} />
													<span className="slider round"></span>
												</Label>
											</Col>
										 : ""
									}									
									{
										listDoctors.length > 0 &&
										<Col xs="6" sm="6" md="6" lg="4" xl="4">
                                            <Label className="labelCheckPrint">Envio a médico</Label> <br />
                                            <Label className="switch">
                                                <Input type="checkbox" name="send_doctor" onChange={handleInputChangeWorkOrder} checked={send_doctor} />
                                                <span className="slider round"></span>
                                            </Label>
                                        </Col>
									}
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col sm="6" md="6" lg="6" xl="6">
						<Card className=''>
							<CardHeader className='bg-primary p-3'>
								<i className='fa fa-comments-0 f-20'></i> &nbsp;&nbsp;&nbsp;&nbsp;
								<label>Observaciones</label>
							</CardHeader>
							<CardBody className='p-l-5 p-t-10 p-b-15'>
								<Row className='match-height m-t-10'>
									<Col sm="12" md="12" lg="12" xl="12">
										<Label className='f-w-600 f-12 badge badge-light-primary'>Hoja de Trabajo</Label> 
										<br />
										<textarea name='observations' className='form-control form-control-sm input-air-primary'
											rows={2} onChange={handleInputChangeWorkOrder} value={observations}>
										</textarea>
									</Col>
								</Row>
								<Row className='m-t-20 m-b-20 match-height'>
									<Col sm="12" md="12" lg="12" xl="12">
										<Label className='f-w-600 f-12 badge badge-light-primary'>Hoja de flebotomista</Label> 
										<br />
										<textarea
											name='observations_sample'
											className='form-control form-control-sm input-air-primary'
											rows={2}
											onChange={handleInputChangeWorkOrder}
											value={observations_sample}
										>
										</textarea>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div className={firstLoadingWorkOrder ? 'd-none match-height' : 'match-height animate__animated animate__fadeInDown' }>
					<Row className='m-b-5'>
						<Col sm="12" md="12" lg="12" xl="12">
							<Card className='shadow'>
								<CardHeader className={checkpaid==true?'p-3 bg-primary':'d-none'}>
									<i className="icofont icofont-laboratory f-20"></i>&nbsp; &nbsp;
									<label>Búsqueda de examen/perfil</label>
								</CardHeader>
								<CardBody className='p-3'>
									<Row>
										<Col sm="12" md="12" className={checkpaid==true?'':'d-none'}>
											<FormGroup>
												<SearchAsync
													loincState={searchExamProfileState}
													name="id_ProfileExam"
													url="Profiles/SearchProfileExam"
													maxLenght={3}
													method={handleSelectExamChange}
												/>
												<FormText color='danger' hidden={!listTest_valid}>Debes de seleccionar una opción</FormText>
											</FormGroup>
										</Col>
									</Row>
									<Row className='m-t-5'>
										<Col sm="12" md="12">
											{
												listTest.length > 0 &&
												<TableWorkOrderUpdate
													key={'tableOfExamsUpdate'}
													items={listTest}
													{
														...{
															number_format, id_company, removeExamProfile, handleCheckSpecimen, handleCheckUrgent,
															changeSelectSamplings, timesCurves: times, changeValueTimeSampling, handleViewExamsProfile,
															handleCheckForwarded,enable_tracking_toma,checkDiscount,valid_commont
														}
													}
												/>
											}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className='m-b-15'>
						<Col sm="12" md="4" lg="4" xl="4">
							<Card className='shadow'>
								<CardHeader className='bg-primary p-1'>
									<br />
									&nbsp; &nbsp; <i className="icofont icofont-prescription f-20"></i> &nbsp; &nbsp; &nbsp; &nbsp;
									<label className='labelIndication'>Indicaciones del Exámen / Perfil</label>
								</CardHeader>
								<CardBody className='p-l-1 p-t-1' style={{ overflowY: 'scroll', scrollbarColor: 'rgba(0,0,0,0.5)', scrollbarWidth: 'thin', height: '400px' }}>
									{
										(listIndicationsExams !== null && listIndicationsExams.length > 0 ) && listIndicationsExams.map( (indication, indexIndication) => {
											return (
												<Row key={`indicationExam-${indexIndication}`} className="m-b-5">
													<Col sm="12" md="12" lg="12" xl="12">
														<span className="m-b-5">
															<i className="icofont icofont-laboratory f-16 icon-Exam"></i>
															&nbsp;&nbsp;
															<b>{indication.code+" "+indication.name_exam}</b>
														</span>
														<br /><br />
														<Row>
															{
																(indication.list_indications.length > 0) 
																?
																	indication.list_indications.map( (textIndication, t) => {
																		return (
																			<Col sm="12" md="12" lg="12" key={'indication-'+indexIndication+'-'+t}>
																				<label className='text-secondary p-l-15'>- {textIndication.indication}</label>
																			</Col>
																		)
																	})
																:
																	<Col sm="12" md="12" lg="12" xl="12">
																		<label className='text-secondary p-l-15'>- SIN INDICACIONES PREVIAS</label>
																	</Col>
															}																	
														</Row>
													</Col>
												</Row>
											)
										})
									}	
									{
										(listIndicationsProfile !== null && listIndicationsProfile.length > 0) && listIndicationsProfile.map((profile, indexProfile) => {
											return (
												<Row key={`indicationProfile-${indexProfile}`}>
													<Col sm="12" md="12" lg="12" xl="12">
														<span className="m-b-5">
															<i className="icofont icofont-laboratory f-16 icon-Exam"></i>
															&nbsp;&nbsp;
															<b>{ profile.code+" "+profile.name }</b>
														</span>
														<br /><br />
														<Row className='m-b-5'>
															{
																profile.list_indications.length > 0 && profile.list_indications.map((indication, indexIndication) => {
																	return(
																		<Col sm="12" md="12" lg="12" xl="12" key={`indicationProfileExam-${indexIndication}`}>
																			<span className='f-w-400 p-l-15'>{indication.code+" "+indication.name_exam}</span>
																			<br /><br />
																			<Row className='m-b-5'>
																				{
																					profile.list_indications.length > 0 && profile.list_indications.map((indication, indexIndication) => {
																						return (
																							<Col sm="12" md="12" lg="12" xl="12" key={`indicationProfileExam-${indexIndication}`}>
																								<span className='f-w-400 p-l-15'>{ indication.code+" "+indication.name_exam }</span>
																								<br /><br />
																								<Row>
																									{
																										(indication.list_indications.length > 0)
																										?
																											indication.list_indications.map((textIndication, t) => {
																												return (
																													<Col sm="12" md="12" lg="12" key={'indication-'+indexIndication+'-'+t}>
																														<label className='text-secondary p-l-20'>- { textIndication.indication }</label>
																													</Col>
																												)
																											})
																										:
																											<Col sm="12" md="12" lg="12" xl="12">
																												<label className='text-secondary p-l-20'>- SIN INDICACIONES PREVIAS </label>
																											</Col>
																									}
																								</Row>
																							</Col>
																						)
																					})
																				}
																			</Row>
																		</Col>
																	)
																})
															}
														</Row>
													</Col>
												</Row>
											)
										})
									}
								</CardBody>
							</Card>
						</Col>
						<Col sm="12" md="8" lg="8" xl="8">
							<Card className='shadow'>
								<CardHeader className='bg-primary p-1'>
									<br />
									&nbsp; &nbsp; <i className="fa fa-usd f-20"></i>&nbsp; &nbsp;
									<label className='labelDateGenerales'>Información de pago</label>
								</CardHeader>
								<CardBody className='p-l-2 p-t-0'>
									<Row className='m-t-10 p-1'>
									<Col sm="12" md="12" lg="12" xl="12" className={ShowClassNameC}>
                                            <Label className='f-w-600 f-12 badge badge-light-primary'>Observaciones Generales</Label>
                                            <textarea name="observations_general" className='form-control form-control-sm input-air-primary'
                                                rows="2" onChange={handleInputChangeWorkOrder}
                                                value={observations_general}>
                                            </textarea>
                                        </Col>
										<Col sm="12" md="6" lg="6" xl="6" className='p-l-0 mt-2'>
											<Row>
												<Col xs="6" sm="5" md="5" lg="5" xl="5" className='p-l-3'>
													<Label className="labelcheckComment ml-2">{"Comentarios"}</Label> <br />
                                                    <Label className="switch">
                                                        <Input type="checkbox" name="checkComment" onChange={enableComments} checked={checkComments} />
                                                        <span className="slider round"></span>
                                                    </Label>
												</Col>
												<Col xs="6" sm="7" md="7" lg="7" xl="7" >
													<Button type="button" className='float-right btn-air-primary' disabled={checkpaid==true?false:true} outline color='primary' size='sm' onClick={handleAddPayMethodInputs}>
														<i className='fa fa-plus'></i> Método de pago
													</Button>
												</Col>
											</Row>
										</Col>
										{/* <Col sm="12" md="6" lg="6" xl="6" className='p-l-0'>
											<Button type="button" className={devolutionsState.listTestDevolution.length > 0 ? `float-right text-center btn-air-primary animate__animated animate__flipInX` : 'd-none' } size="xs" color='warning' onClick={handleCreateDevolution}>
												Ingresar nueva devolución
											</Button>
										</Col> */}
									</Row>
									<Row className='m-t-5'>
										<Col sm="12" md="6" lg="6" xl="6">
											<Table>
												<thead>
													<tr className='text-center border-bottom-info'>
														<th><b>Método de pago</b></th>
														<th><b>Monto</b></th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{
														listPayMethods.map((p, keyP) => {
															if(p.name!="CREDITO" || p.fully_paid==false){
																return (
																	<tr key={'paymethod'+keyP}>
																		<td>
																			<Input
																				type='select'
																				required={true}
																				className="form-control form-control-sm input-air-primary p-1" 
																				value={p.id_pay_method}
																				onChange={(e) => handleChangePayMethod(e, keyP)}
																				disabled={p.id_work_order_pay !== null ? true : false}
																			>
																				<option value={0}>Selecciona una opción</option>
																				
																				{
																					listPayMethod.length > 0 && listPayMethod.map(pm => {
																						if (id_company !== 0 && id_company !== null) {
																							
																								if (pm.name == 'CREDITO') {
																									return (
																										<option key={'method-'+pm.id_pay_method} value={pm.id_pay_method}>{`${pm.abbreviation} - ${pm.name}`}</option>
																									)
																								}
																							
																							
																						}
																						else {
																							// if (pm.name !== 'CREDITO') {
																								return (
																									<option key={'method-'+pm.id_pay_method} value={pm.id_pay_method}>{`${pm.abbreviation} - ${pm.name}`}</option>
																								)
																							// }
																						}
																					})
																				}
																			</Input>
																		</td>
																		<td>
																			<Input
																				type="number" name='amount' data-id={keyP} value={p.amount}
																				placeholder="$ 0.0" step="any" className='form-control input-air-primary form-control-sm' 
																				onChange={(e) => handleGiveChange(e, "amount", "listPayMethods")}
																				disabled={p.id_work_order_pay !== null ? true : false}
																			/>
																		</td>
																		<td style={{verticalAlign: "middle"}}>
																			{p.fully_paid==false?
																				<Button type='button' className='btn-air-success' outline color='success' size='xs'
																				onClick={() => handleModalLiquided(p.id_work_order_pay,p.amount)}>
																					<i className="fa fa-money"></i>
																				</Button>:
																				<Button type='button' className='btn-air-danger' outline color='danger' size='xs'
																				onClick={() => handleDeletePayMethod(keyP)} disabled={p.id_work_order_pay !== null ? true : false} >
																					<i className='icofont icofont-ui-delete'></i>
																				</Button>
																			}
																			
																		</td>
																	</tr>
																)
															}
														
															
														})
													}
												</tbody>
												<tfoot>
													<tr>
														<td colSpan={3} className="text-xs-center">
															<FormText color='danger' hidden={!listPayMethods_valid}>Selecciona un metodo de pago o ingresa la cantidad correcta</FormText>
														</td>
													</tr>
													<tr>
														<td className='text-right'>
															<b className='labelChange'>{showMessageW.labelMoney}</b>
														</td>
														<td className='text-right'>
															<b className='labelChange'>$ {`${number_format(showMessageW.amount, 2)}`}</b>
														</td>
													</tr>
												</tfoot>
											</Table>
										</Col>
										<Col sm="12" md="6" lg="6" xl="6">
											<div className='table-respomsive'>
												<Table className='table-xs'>
													<thead>
														<tr>
															<th className='p-1 f-12 text-center' colSpan={2}>
																<b className='labelResumen'>Resúmen</b>
															</th>
														</tr>
													</thead>
													<tbody>
														<tr className='border-bottom-info'>
															<td className='f-14'><label className='labelPrices'>Subtotal</label></td>
															<td className='text-right f-14'><label className='labelPrices'>$ { number_format(subTotalW, 2) }</label></td>
														</tr>
														<tr style={{ cursor: "pointer" }} className='border-bottom-info'>
															<td className='f-14'><label className='labelPrices'>Descuento</label></td>
															<td className='text-right text-danger f-14'>
																<label className='labelPrices'>(-) $ { number_format(totalDiscountW, 2) }</label>
																<a onClick={handleModalListCommonDiscounts} class="f-w-600 f-12 badge badge-light-primary ml-2"><i class="fa fa-plus-circle"></i></a>
															</td>
														</tr>
														<tr>
															<td className='f-14'> <label className='f-14'>I.V.A</label></td>
															<td className="text-right f-14"><label className='labelPrices'>$ { number_format(totalTaxesW, 2) }</label></td>
														</tr>
														<tr>
															<td className='f-14'> <b className='f-14'>Total</b></td>
															<td className="text-right f-14"><b className='f-14'>$ { number_format(totalW, 2) }</b></td>
														</tr>
														<tr style={{ cursor: "pointer" }} className='border-bottom-info'>
															<td className='f-14'><label className='labelPrices'>Devoluciones</label></td>
															<td className='text-right text-primary f-14'><label className='labelPrices'>$ { number_format(totalDevolutionsW, 2) }</label></td>
														</tr>
													</tbody>
												</Table>
											</div>
											<Row className='m-t-15'>
												<Col sm="6">
													{
														listTest.length > 0 && 
														<Button
															className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
															outline
															color='primary-2x'
															disabled={enableBtnSaveWorkorder}		
															onClick={saveWorkOrder}													
														>
															{BtnSaveWorkOrder}
														</Button>
													}
												</Col>
												<Col sm="6">
													<Button className="progress-bar-animated btn-air-danger"
														outline
														color='danger-2x'	
														onClick={handleBack}>
														Regresar
													</Button>
												</Col>
											</Row>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>						
					</Row>
				</div>
			</Container>
			<ModalDevolution
				{
					...{
						toggleDevolutions, modalTitle, 
						devolutionsState, modalDevolutions, listTest,
						number_format, listReturnReason, handleChangeReturnReason, validateDevolutions,
						saveReturnReason,canceldevolution,showMessageW,loading 
					}
				}
			/>

			<ModalViewExams
				{
					...{
						modalViewExamsP, toggleModalViewExams, viewExamsProfile
					}
				}
			/>

			{/* <ModalCommonDiscounts
				{
					...{
						modal: modalCommonDiscount, toggle: toggleCommonDiscount,valid_commont,
						arrayCommonDiscounts, arrayNameDiscounts: arrayNameDiscount,checkDiscount,clearDiscount,
					}
				}
			/> */}
			<ModalAuthorize
			{
					...{
						
						modal: modalAutorizeWO, toggle: toggleAutorizeWO,user_autorize,handleInputChangeAuthorize,password_autorize,saveAutorized,validate_mont,validationsAutorize,
						listReturnReason, handleChangeReturnReason,id_return_reason_woc,percentage_return,totalDevolutionsWOC,showMessageWOC,percentagereturn,loading
					}
				}
			/>
			<ModalLiquidacion
			{
				...{
					
					modal: modalLiquidedWO, toggle: toggleLiquidedWO,listPayMethod,saveLiquided,
					validatesaveLiquided,handleModalLiquided,id_method_liquided,amount_liquided,validationsliquided,
					handleInputChangeLiquided,loading,id_method_pay,amount_liq
				}
			}
			/>
		</Fragment>
	)
}
