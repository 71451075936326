import React, { Fragment } from 'react'
import { Container, Col, Row, Button, Card, CardBody, Input } from 'reactstrap';
import { ModalAntibiotic } from '../../../components/micro/antibiotic/ModalAntibiotic';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useAntibiotic } from '../../../hooks/micro/useAntibiotic';

export const IndexAntibiotic = () => {

    const {
        antibiotics, method, validaciones,
        antibiotic,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch
    } = useAntibiotic();

    //console.log(antibiotics.length);

    return (
        <Fragment>
            <Container fluid={true} className="p-4 p-l-0 p-r-0">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de antibioticos
                                </div>
                                <div className="text-right" style={{ margin: 5, padding: 0 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                                    </div>
                                </div>
                                <div className="table-responsive"> <br />
                                    <TableStriped
                                        notMaped={["visibleTable", "id_mic_antibiotic_group"]}
                                        methodsActions={true}
                                        cabeceras={["Antibioticos"]}
                                        items={antibiotics}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <ModalAntibiotic
                            {...{
                                modal, modalTitle, toggle, method,
                                validaciones,
                                antibiotic,
                                handleInputChange, loading,
                                saveCreate, saveUpdate, saveDelete
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
