import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';
import DeniReactTreeView from "deni-react-treeview"
import './styleDashboard.css';


export const ModalCheckSpecimen2 = (props) => {

    const {
        modalCheckSpecimen: modal, toggleCheckSpecimen: toggle,
        loadingSpecimen, treeviewRef, itemsTree, changeTree, savePendingMuestra, enable_tracking_toma
    } = props;


    const onRenderItem = (item, treeview) => {
        return (
            <div className="treeview-item-example">
                {
                    item.refused_check_specimen === true ?
                        <div>
                            {
                                item.isLeaf === false ?
                                    <span className='text-primary '>
                                        <i className="icofont icofont-doctor-alt f-16 "></i><label className='f-14' >{item.text}</label>

                                    </span>
                                    :
                                    <span className=''>
                                        <i className="icofont icofont-doctor-alt f-16 "></i><label className='f-14' >{item.text}</label>
                                    </span>
                            }
                        </div>
                        :
                        <div>
                            {
                                item.isLeaf === false ?
                                    <span className='text-danger '>
                                        <i className="icofont icofont-laboratory  f-16"></i><label className='f-14' >{item.text}</label>
                                    </span>
                                    :
                                    <span className=''>
                                        <i className="icofont icofont-laboratory  f-16"></i><label className='f-14' >{item.text}</label>
                                    </span>
                            }
                        </div>
                }
            </div>
        )
    }



    return (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false} centered={true}  >
            <ModalHeader toggle={toggle} className="bg-primary">
              
                        <label className=''>Actualizar Muestras Remitidas</label>
               
            </ModalHeader>
            <ModalBody>
                <Col sm="12">
                    <Row>
                        <Col sm="1">
                        </Col>
                        <Col sm="11"> <br />
                            <DeniReactTreeView
                                className="deni-react-treeview-container5"
                                ref={treeviewRef}
                                style={{ marginRight: '10px', marginBottom: '10px', height: "200px", textAlign: "center" }}
                                showCheckbox={true}
                                showIcon={false}
                                items={itemsTree}
                                onCheckItem={(e) => changeTree(e)}
                                onRenderItem={onRenderItem}
                                selectRow={false}
                            />
                        </Col>
                    </Row>
                </Col>
            </ModalBody>
            <ModalFooter>
               
                        <Button size="sm" outline color="primary" type="button" onClick={() => savePendingMuestra()} className={loadingSpecimen ? "disabled progress-bar-animated progress-bar-striped" : ""}  >
                            Actualizar muestras
                        </Button>
               
                <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}