import React, { Fragment, useState } from 'react'
import '../../../../assets/css/pantalla1.css'
import '../../../../assets/css/analiticaCards.css'
import { ComponentUser } from './ComponentUser';
//import { ComponentCardNim } from './ComponentCardNim';
import { ListCardToma } from './ListCardToma';
import { Container, Row, Col, Label, Button, FormGroup, Nav, NavLink, NavItem, TabContent, TabPane, Card, CardHeader, CardBody } from 'reactstrap';
import { useTracking } from '../../../../hooks/tomaMuestra/useTracking';
import { ModalTracking } from './ModalTracking';
import { TimerCard } from './TimerCard';
import { ModalTrackingUser } from './ModalTrackingUser';
import { ModalRack } from './ModalRack';
import 'animate.css';
import { ModalExams } from './ModalExams';
//import { useLogin } from '../../../../hooks/tomaMuestra/useLogin';


export const IndexTracking = () => {

    let filter = ["nim"];

    const {
        headers, users, nimCards, nim,
        headerSelected, userSelected,
        handleModalNim, getExamsFromNim, quitFocus, handleFocusInputNim,
        modal, toggle, loading, handleInputChange, typeModal, cerrarModalNim,
        exams, handleInputCheckboxChange, initTracking, changeUserSelected, changeHeaderSelected,
        scanUserValue, userAutoFocus, onChangeScanUser, onKeyPressScanUser, focusInUser,
        scanNimValue, nimAutoFocus, onChangeScanNim, onKeyPressScanNim, focusInNim,
        addOther,
        handleModalUser, toggle2, modal2, typeModalLogin,
        handleDepartamento, show, handleShow, show2,
        departamento, sections, userName, password,
        process: proccesArray,
        id_area, id_departament, handleInputChangeT, iniciarSesionCard
        , handleSelectValuesTFC, card_code, id_section, LoginTrankingFormCard
        , handleInputChangeTFC,
        deleteUser, deleteSectionprocess, endTracking, reload, cerrarModalUsuario,
        racks, rackInfo, arrayNims, modalRack, toggleRack, initTrackingFromRack, rackExist, initChangeRack, changeRackStatus, rackPrevious, rackNext, changeRack,
        removeRackNim, handleModalListNim, modal3, typeModalList, toggle3, llavecard, arrayExams, cerrarModalNimExam, labelNim,
        card_codes, cardView, handleViewCard, handleCloseCard,
        focusCardInput, onKeyPressCard, cardAutoFocus, setFocusCardInput, handleSelectValuesT,
        showText, resultNim,
        enableNim, handleViewNim, handleCloseViewNim,
        typeModalRefused, modalRefused, toggleRefused, refused, reason, id_return_reason, handleSelectValuesR,
        refusedNIm, handleRefusedPatient, handleInputChangeR, refusedPatien,
        inputAndCard, handleInputAndCard,
        focusrefused, refusedAutoFocus, onKeyPressRefused, setFocusrefused, refusedPatient,
        handlerefusedManual, show10, closedModalRefused,
        typeModalInfo, modalInfo, toggleInfoPatient, handlePatientInfo, closedModalInfo, dataPatient, dataStudy, loadingPatient,
        namepatien, age, curp, phone, gender, datanim, loadingRefused,
        //cancell
        typeModalCan, modalCan, toggleCancell, closedModalCancell, handlePatientCancell,
        refusedPatientSection




    } = useTracking();



    //#region sizeDiv

    let ancho = 10;

    let sizeDiv = (ancho / headers.length);

    let ancho2 = 10;
    let sizeDiv2 = (ancho2 / headers.length);

    const [activeTab, setActiveTab] = useState("1")


    const tabToma = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }



    return (
        <Fragment>
            <Container fluid={true} style={{ backgroundColor: "#ffffff" }} className='bg-barra1  p-0'>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" >
                    <Row>
                        <Col sm="12" className='p-l-1'> <br />
                            <div>
                                {
                                    show2 === true ?
                                        (
                                            <Row>
                                                {
                                                    headers.map((x, key) => {
                                                        return <Col sm={sizeDiv}
                                                            className='pointer '
                                                            key={key}
                                                            onClick={() => changeHeaderSelected(x.id_section, x.id_process)}
                                                            style={
                                                                {
                                                                    fontSize: 15,
                                                                    height: 52,
                                                                    textAlign: "-webkit-center",
                                                                    fontFamily: "'Montserrat', sans-serif",
                                                                    fontWeight: "bold",
                                                                    padding: "2px 4px",
                                                                    borderRadius: "14px", borderBottomRightRadius: "0px",
                                                                    border: "0px",
                                                                    color: (headerSelected.id_section === x.id_section && headerSelected.id_process === x.id_process) ? "#ffffff" : "#0079C7",
                                                                    backgroundColor: (headerSelected.id_section === x.id_section && headerSelected.id_process === x.id_process) ? "#0079C7" : "#ffffff",
                                                                }
                                                            }>
                                                            {x.seccion} <br /> {x.proceso}
                                                        </Col>
                                                    })
                                                }
                                                <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0 p-r-0 p-t-0 p-b-0 pruebaTraking'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-1'>
                                                        <img className="pointer " src={require("../../../../assetsToma/imagenes/AgregarArea.png")} style={{ width: "18%" }} alt="" onClick={handleDepartamento} />
                                                    </Col>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <Row>
                                                {
                                                    headers.map((x, key) => {
                                                        return <Col sm={sizeDiv2}
                                                            className='pointer '
                                                            key={key}
                                                            onClick={() => changeHeaderSelected(x.id_section, x.id_process)}
                                                            style={
                                                                {
                                                                    fontSize: 15,
                                                                    height: 52,
                                                                    textAlign: "-webkit-center",
                                                                    fontFamily: "'Montserrat', sans-serif",
                                                                    fontWeight: "bold",
                                                                    padding: "2px 4px",
                                                                    borderRadius: "14px", borderBottomRightRadius: "0px",
                                                                    border: "0px",
                                                                    color: (headerSelected.id_section === x.id_section && headerSelected.id_process === x.id_process) ? "#ffffff" : "#0079C7",
                                                                    backgroundColor: (headerSelected.id_section === x.id_section && headerSelected.id_process === x.id_process) ? "#0079C7" : "#ffffff",
                                                                }
                                                            }>
                                                            {x.seccion} <br /> {x.proceso}
                                                        </Col>
                                                    })
                                                }
                                                <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0 p-r-0 p-t-0 p-b-0 pruebaTraking'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-1'>
                                                        <img className="pointer " src={require("../../../../assetsToma/imagenes/AgregarArea.png")} style={{ width: "16%" }} alt="" onClick={handleDepartamento} />
                                                        {
                                                            show2 ? ("") : (<label className='Label-FromCard'>Nueva Área</label>)
                                                        }
                                                    </Col>
                                                </Col>
                                            </Row>
                                        )
                                }

                            </div>
                        </Col>
                    </Row>
                </Col >

                <div>
                    {
                        show2 ?
                            (
                                ""
                            )
                            :
                            (
                                <div className='Nueva-Area' > <br />
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0' >
                                        <FormGroup>
                                            <select
                                                className="Input-From-Section-Card"
                                                name="id_area"
                                                onChange={handleInputChangeT}
                                                value={id_area}
                                            >
                                                <option value={0}>{"Selecciona una opción"} </option>
                                                {
                                                    sections.length >= 1 && sections.map((obj, index) => {
                                                        return <option value={obj.id_section} key={index} >{obj.name}</option>
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                        <FormGroup>
                                            <select
                                                className="Input-From-Section-Card"
                                                name="id_departament"
                                                onChange={handleInputChangeT}
                                                value={id_departament}
                                            >
                                                <option value={-1}>{"Selecciona una opción"} </option>
                                                {
                                                    proccesArray.length >= 1 && proccesArray.map((obj, index) => {
                                                        return <option value={obj.id_process} key={index} >{obj.name}[{obj.code}]</option>
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <FormGroup className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `}>
                                        <Col sm="12">
                                            <Row>
                                                <Col sm="1">
                                                </Col>
                                                <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: "center" }} >
                                                    <input
                                                        className="Input-FromCard text-center "
                                                        placeholder="codigo"
                                                        type='text'
                                                        value={card_codes}
                                                        name={`card_codes`}
                                                        onChange={handleInputChangeT}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <br />  <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }} >
                                            <button className='Button-FromCard' onClick={iniciarSesionCard}>Ingresar</button>

                                        </Col>
                                    </FormGroup>
                                    <Row className={`${!cardView ? 'm-t-10 animate__animated animate__flipInX' : 'animate__animated animate__flipOutX'}`} hidden={cardView}>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }} >
                                            <div style={{ position: 'absolute', left: "-1000", top: "-1000", zIndex: 3 }} className={'ocultar-input'}	>
                                                <input
                                                    id='inputFocusCardL'
                                                    autoFocus={setFocusCardInput}
                                                    value={card_codes}
                                                    onChange={(e) => handleSelectValuesT(e.target.value, "card_codes")}
                                                    onKeyDown={onKeyPressCard}
                                                    onBlur={cardAutoFocus}
                                                />
                                            </div>
                                            <img className="" src={require("../../../../assets/images/loginHelenLabs/TomaDeMuestra/Barra1.gif")} alt="LogoDNA" style={{ width: '150px', height: '150px', filter: "invert(100%) sepia(100%) saturate(1000%) hue-rotate(170deg)" }} />   <br />
                                            <br />
                                            <p className={`txt-danger ${!showText && "d-none"}`}><i className="fa fa-exclamation-circle txt-danger" aria-hidden="true"></i> Selecciona tu area de trabajo</p>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }} >
                                                <Button className='' outline color='primary' onClick={() => handleViewCard()}>Ingresa Manualmente</Button>

                                            </Col>

                                        </Col>
                                    </Row>
                                    <Row className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `} >

                                    </Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                        <br />
                                        <Button outline color='primary' className={`${cardView ? 'animate__animated animate__flipInX  ' : 'd-none'} `} onClick={() => handleCloseCard()}>Regresar</Button>
                                    </Col>
                                </div>
                            )
                    }
                </div>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{}} >
                    <Row>
                        <Col xs="2" sm="2" md="2" lg="3" xl="2" className='p-r-0'> <br />
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ float: "left", zIndex: 5 }} className="p-l-0 p-r-0" >
                                <div>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className="box-col-12">
                                        <div className="inbox custom-scrollbar">
                                        </div>
                                    </Col>
                                </div>
                                {
                                    show ?
                                        (
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0' style={{ textAlign: "center" }} >
                                                    {
                                                        users !== undefined && users.length >= 1 && users.map((o, index) => {
                                                            return (
                                                                <Row>
                                                                    <ComponentUser
                                                                        obj={o}
                                                                        key={`user-${index}`}
                                                                        changeUser={changeUserSelected}
                                                                        userSelected={userSelected}
                                                                        objj={resultNim}
                                                                        {
                                                                        ...{
                                                                            enableNim, handleViewNim, handleCloseViewNim, typeModalRefused, modalRefused, toggleRefused, refused, reason, id_return_reason, handleSelectValuesR, headerSelected,
                                                                            refusedNIm, handleRefusedPatient, handleInputChangeR, refusedPatien,
                                                                            inputAndCard, handleInputAndCard, focusrefused, refusedAutoFocus, onKeyPressRefused, setFocusrefused, refusedPatient,
                                                                            handlerefusedManual, show10, closedModalRefused,
                                                                            //info patient
                                                                            typeModalInfo, modalInfo, toggleInfoPatient, handlePatientInfo, closedModalInfo,
                                                                            dataPatient, dataStudy, loadingPatient, namepatien, age, curp, phone, gender, datanim, loadingRefused,
                                                                            //cancell
                                                                            typeModalCan, modalCan, toggleCancell, closedModalCancell, handlePatientCancell,
                                                                            refusedPatientSection



                                                                        }
                                                                        }
                                                                    />



                                                                </Row>

                                                            )
                                                        })
                                                    }
                                                </Col>


                                            </Row>

                                        )
                                        :
                                        (
                                            <Col xs="12" sm="12" md="12" lg="12" xl="8" className='p-l-0 p-r-0' >
                                                <div className=' username-Active-traking pointer card-active' onClick={reload}>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                                                                <img className=" " src={require("../../../../assetsToma/imagenes/Recargar.png")} style={{ width: "45%" }} alt="" />
                                                            </Col>
                                                            <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 p-3' style={{ textAlign: "-webkit-auto" }}>
                                                                <label className='label-div-menu p-0 pointer'>{"Recargar"}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div> <br />
                                                <div className='username-Active-traking pointer' onClick={handleModalUser}>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="8">
                                                        <Row>
                                                            <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 p-2 ' style={{ textAlign: "-webkit-center" }}>
                                                                <img className=" " src={require("../../../../assetsToma/imagenes/AgregarUsuario.png")} style={{ width: "90%" }} alt="" />
                                                            </Col>
                                                            <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 p-3' style={{ textAlign: "-webkit-auto" }} >
                                                                <label className=' label-div-menu  p-0 pointer'>{"Añadir Usuarios"}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div> <br />
                                                <div className=' username-Active-traking pointer' onClick={deleteUser}>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="8">
                                                        <Row>
                                                            <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 p-2' style={{ textAlign: "-webkit-center" }}>
                                                                <img className=" " src={require("../../../../assetsToma/imagenes/CerrarSesion.png")} style={{ width: "90%" }} alt="" />
                                                            </Col>
                                                            <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 p-3' style={{ textAlign: "-webkit-auto" }} >
                                                                <label className='label-div-menu  p-0 pointer'>{"Cerrar Sesión"}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div> <br />
                                                <div className='username-Active-traking pointer' onClick={deleteSectionprocess}>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="8">
                                                        <Row>
                                                            <Col xs="3" sm="3" md="3" lg="2" xl="3" className='p-l-0 p-r-0 p-t-2 p-3' style={{ textAlign: "-webkit-center" }}>
                                                                <img className=" " src={require("../../../../assetsToma/imagenes/CerrArrea.png")} style={{ width: "100%" }} alt="" />
                                                            </Col>
                                                            <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 p-3' style={{ textAlign: "-webkit-auto" }} >
                                                                <label className='label-div-menu  p-0 pointer'>{"Cerrar Área"}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div> <br />
                                                <div>
                                                </div>

                                            </Col>
                                        )

                                }

                            </Col>
                        </Col>

                        <Col xs="10" sm="10" md="10" lg="9" xl="9" style={{ textAlign: "-webkit-right" }} >
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }}>
                                    <div style={{ position: 'absolute', left: "-1000", top: "-1000", zIndex: 3 }} className="ocultar-input"  >  <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
                                        <input
                                            autoFocus={focusInUser}
                                            placeholder='Start Scanning user'
                                            value={scanUserValue}
                                            onChange={onChangeScanUser}
                                            id='inputFocusUser'
                                            className='SearchInput'
                                            onKeyDown={onKeyPressScanUser}
                                            onBlur={userAutoFocus}
                                        />
                                        {scanUserValue}
                                        <input
                                            autoFocus={focusInNim}
                                            placeholder='Start Scanning nim'
                                            value={scanNimValue}
                                            onChange={onChangeScanNim}
                                            id='inputFocusNim'
                                            className='SearchInput'
                                            onKeyDown={onKeyPressScanNim}
                                            onBlur={nimAutoFocus}
                                        />
                                        {scanNimValue}
                                    </div>

                                    <div> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <Nav className="nav-fill flex-column flex-md-row round" tabs>
                                                <NavItem >
                                                    <NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active  ' : ''} onClick={() => tabToma("1")}>  Toma Única</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="#javascript" role="tab" className={(headerSelected !== null) && headerSelected.enable_racks ? (activeTab === "2" ? 'active' : "") : " d-none"} onClick={() => tabToma("2")}>Gradilla</NavLink>
                                                    {/* <NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => tabToma("2")}>Gradilla</NavLink> en caso que falle la validacion de arriba de las gradillas entonces colocar esta linea de codigo en su lugar  */}
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </div>
                                    {
                                        (headerSelected !== null) &&
                                        <TabContent activeTab={activeTab} >
                                            {
                                                headerSelected.enable_racks ?
                                                    <TabPane className="fade show  " tabId="2">
                                                        <div className={racks.length >= 1 ? "d-none" : ""}>
                                                            <div style={{ textAlign: "-webkit-center" }} className="Div-scaner">
                                                                <img className="imgbarTraking" src={require("../../../../assetsToma/imagenes/QR.gif")} /> <br />
                                                            </div>
                                                            <>
                                                                <Label className='rE'>Escanea el <label className="negritaLabel">Código QR</label><br />de la gradilla <br />o</Label> <br />
                                                                <button className='buttonTraking' onClick={handleModalNim}>Ingrésalo manualmente </button>
                                                            </>
                                                        </div>
                                                        <div>
                                                            <Row>
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                    {
                                                                        (headerSelected !== null) && headerSelected.enable_racks ?
                                                                            <>
                                                                                <Row>
                                                                                    {
                                                                                        racks.map((data, key) => {
                                                                                            return (
                                                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" key={`rack-${+ new Date()}`}>
                                                                                                    <div className='card nim'>
                                                                                                        <div className='Card-gradilla-Component-Header'>
                                                                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                                                                <Row>
                                                                                                                    <Col xs="10" sm="10" md="10" lg="10" xl="10">
                                                                                                                        <Row>
                                                                                                                            <Col xs="3" sm="3" md="3" lg="3" xl="3" className='label-Title-gradilla p-r-0' style={{ textAlign: "-webkit-left" }} >
                                                                                                                                <label >{data.rackInfo.name + "[" + data.rackInfo.code + "]"}</label>
                                                                                                                            </Col>
                                                                                                                            <Col xs="9" sm="9" md="9" lg="9" xl="9">
                                                                                                                                <label className='label-Title-gradilla'>{data.items[0].name}</label>
                                                                                                                            </Col>
                                                                                                                            <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                                                                                                                <TimerCard
                                                                                                                                    time={new Date(data.items[0].init_time)}
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </Col>
                                                                                                        </div>
                                                                                                        <div className='Card-gradilla-Component-Body'>
                                                                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                                                                <Row>
                                                                                                                    {
                                                                                                                        data.items.map((x, llave) => {
                                                                                                                            return (
                                                                                                                                <Col xs="3" sm="3" md="3" lg="3" xl="3" key={"keyNim-" + x.nim}>
                                                                                                                                    <div className='line-gradilla p-2'>
                                                                                                                                        <label htmlFor="">{x.nim}</label>
                                                                                                                                    </div>
                                                                                                                                </Col>

                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </Row>
                                                                                                            </Col>
                                                                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                                                                <Row>
                                                                                                                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                                                                                                    </Col>
                                                                                                                    <Col xs="8" sm="8" md="8" lg="8" xl="2">
                                                                                                                        <button className='Button-Finish-Body'>Finalizar</button>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </Col>

                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Col>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Row>

                                                                            </>
                                                                            :
                                                                            ""
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <br /> <br />
                                                    </TabPane>
                                                    :
                                                    <TabPane className="fade show" tabId="1">
                                                        <div className={nimCards.length >= 1 ? "d-none" : ""}>
                                                            <div style={{ textAlign: "-webkit-center" }} className="Div-scaner">
                                                                <img className="imgbarTraking" src={require("../../../../assetsToma/imagenes/Barras.gif")} /> <br />
                                                            </div>
                                                            <>
                                                                <Label className='rE'>Escanea el <label className="negritaLabel">Código de Barras</label><br />de las muestras <br />o</Label> <br />
                                                                <button className='buttonTraking' onClick={handleModalNim}>Ingrésalo manualmente </button>
                                                            </>
                                                            {/* <div className='tap-top' style={{display: "block"}} onClick={() => { handleShow(); }}><i className= "icon-angle-double-up f-24"></i>
                                                    </div> */}
                                                        </div>
                                                        <div>
                                                            <Row className='p-1'>
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                    {
                                                                        (headerSelected !== null) && headerSelected.enable_racks ?
                                                                            ""
                                                                            :
                                                                            <>
                                                                                <div className={nimCards.length >= 1 ? "" : " d-none"}>
                                                                                </div>
                                                                                <Row>
                                                                                    {
                                                                                        nimCards.map((data, key) => {
                                                                                            return (
                                                                                                <Col xs="4" sm="4" md="4" lg="6" xl="4" key={`tarjeta-${data.nim}`}>
                                                                                                    <div className='Card-nim-Component-Header'>
                                                                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                                                            <Row>
                                                                                                                <Col xs="7" sm="7" md="7" lg="7" xl="7" className='p-1' >
                                                                                                                    <label className='Label-Card-Header nim'>{data.nim}</label>
                                                                                                                </Col>
                                                                                                                <Col xs="5" sm="5" md="5" lg="5" xl="5" className='p-1' >
                                                                                                                    <TimerCard time={data.time} />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </Col>
                                                                                                    </div>
                                                                                                    <div className='Card-nim-Component-Body'>
                                                                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                                                            <Row>
                                                                                                                <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-l-0'> <br />
                                                                                                                    <div className='circle-studyTracking'> <br />
                                                                                                                        <img src={require("../../../../assetsToma/imagenes/Frascos.gif")} style={{ width: "55%" }} alt="" />
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                <Col xs="8" sm="8" md="8" lg="8" xl="8" className='' style={{ textAlign: "-webkit-left" }}>  <br />
                                                                                                                    <Col style={{ textAlign: "-webkit-right" }} >
                                                                                                                        {
                                                                                                                            data.exams.length > 3 ?
                                                                                                                                <i className="icofont icofont-wheel f-20 statusIcon pointer" onClick={() => handleModalListNim(key, data.exams, data.nim)}></i>
                                                                                                                                : ""
                                                                                                                        }
                                                                                                                    </Col>
                                                                                                                    <div className='Div-nameExam-Body'>
                                                                                                                        {
                                                                                                                            data.exams.map((x, llave) => {
                                                                                                                                return (
                                                                                                                                    llave < 3
                                                                                                                                        ?
                                                                                                                                        <label key={"llave-" + llave} className='f-12 txt-secondary '>- {x.name_exam}</label> : ""
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-right" }} >
                                                                                                                    <Row>
                                                                                                                        <Col xs="7" sm="7" md="7" lg="7" xl="7">
                                                                                                                        </Col>
                                                                                                                        <Col xs="5" sm="5" md="5" lg="5" xl="5">
                                                                                                                            <button onClick={() => initTracking("", data.nim)} className='Button-Finish-Body'>Finalizar</button>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </Col>

                                                                                                            </Row>
                                                                                                        </Col>
                                                                                                    </div>
                                                                                                    <br /> <br />








                                                                                                </Col>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    <div className={nimCards.length >= 1 ? "tap-top-code-Tracking" : " d-none"} style={{ display: "block" }} onClick={handleModalNim} >
                                                                                        <img src={require("../../../../assetsToma/imagenes/AgregarBarras.png")} style={{ width: "37%" }} className='pointer zoom' onClick={handleModalNim} />
                                                                                    </div> <br />

                                                                                </Row>
                                                                            </>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                            }
                                        </TabContent>
                                    }
                                </Col>
                            </Row>
                            <div className='tap-top-menu-Tracking zoom' style={{ display: "block" }} onClick={() => { handleShow(); }}><i className="icon-angle-double-up f-24 zoom "></i>
                            </div>


                        </Col>
                    </Row>
                </Col>



                <ModalTracking
                    {...{
                        nim, getExamsFromNim,
                        modal, toggle, loading, handleInputChange, typeModal, cerrarModalNim,
                        exams, handleInputCheckboxChange, initTracking
                    }}
                />
                <ModalTrackingUser
                    {
                    ...{
                        toggle2, modal2, typeModalLogin, handleSelectValuesTFC, card_code, id_section, LoginTrankingFormCard
                        , handleInputChangeTFC, cerrarModalUsuario
                    }
                    }
                />
                <ModalRack
                    {
                    ...{
                        rackInfo, arrayNims, modalRack, toggleRack, initTrackingFromRack, rackExist, initChangeRack, changeRackStatus, rackPrevious, rackNext, changeRack, loading, removeRackNim
                    }
                    }
                />
                <ModalExams
                    {
                    ...{
                        modal3, typeModalList, nimCards, toggle3, llavecard, arrayExams, cerrarModalNimExam, labelNim

                    }}

                />

            </Container >
        </Fragment >
    )
}
