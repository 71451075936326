import React from 'react'
import { Card, Col, Row, CardHeader, CardBody, Button,  } from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';
import { useGlobalReferenceValue } from '../../../hooks/exams/useGlobalReferenceValue';

export const GlobalReferenceValue = (props) => {

    const {
        examId
    } = props;

    const {
        globalReferenceValue, handleChangeGlobalReference, saveGlobalReferenceValue, configCkEditor
    } = useGlobalReferenceValue(examId);

    
    return (
        <Row className="pt-1 position-relative">
            <Col sm="12" md="12" lg="12" xl="12">
                <Card>
                    <CardHeader className="p-3 bg-secondary">
                        <h6 className="mb-0">
                            <i className="fa fa-align-justify"></i>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Valor de referencia global
                        </h6>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="12">
                                <p><span className='text-info'>*</span> Si deseas agregar una imagen, solo selecciona tu imagen de tu galeria <code>(ctrl + c)</code>, posteriomente pega la imagen en el editor <code>( ctrl + v )</code> </p>
                            </Col> 
                        </Row>
                        <Row className='m-t-2'>
                            <Col sm="12" md="12" lg="12" xl="12">
                                {
                                    globalReferenceValue !== '' && <CKEditor
                                        initData={globalReferenceValue}
                                        // data={globalReferenceValue}
                                        type="classic"
                                        onChange={handleChangeGlobalReference}
                                        config={configCkEditor}
                                        editorUrl={'//cdn.ckeditor.com/4.20.0/full/ckeditor.js'}
                                    />
                                }
                                    {/* <CKEditors
                                        activeclassName="p10"
                                        config={config}
                                        content={globalReferenceValue}
                                        events={
                                            {
                                                "change": onchange
                                            }
                                        }
                                    /> */}
                                {/* <CKEditor

                                    editor={ ClassicEditor }
                                    data={globalReferenceValue}
                                    onChange={
                                        (event, editor) => {
                                            setGlobalReferenceValue(editor.getData());
                                        }
                                    }
                                /> */}
                            </Col>
                        </Row>
                        <Row className='m-t-15'>
                            <Col sm="4" md="4" lg="4" xl="4"></Col>
                            <Col sm="4" md="4" lg="4" xl="4">
                                <Button
                                    outline
                                    color='primary'
                                    type='button'  
                                    onClick={() => saveGlobalReferenceValue()}                                  
                                >
                                    Guardar
                                </Button>
                            </Col>
                            <Col sm="4" md="4" lg="4" xl="4"></Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
