import React, { useState } from 'react';
import { useForm } from '../forms/useForm';
import { useEffect } from 'react';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { Code500 } from '../../constant/globalMessage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';

import radialimage from '../../assets/images/dashboard-2/radial-graph.png';
import ConfigDB from '../../data/customizer/config';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;;
//<mport { usePagination } from '../pagination/usePagination';



export const useReportWorkOrderInvoice = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [createSweet] = useSweetAlert();

    const [viewCompany, setViewCompany] = useState(true);
    const [reportCompany, setReportCompany] = useState(null);
    const [reportPatient, setReportPatient] = useState(null);
    const [reportGeneralPublic, setReportGeneralPublic] = useState(null);
    const numberItems = 5;


    //FORM QUERIES
    const [formQuery, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        start_date: `${new Date().getFullYear()}-${((new Date().getMonth() + 1) <= 9) ? '0'+ new Date().getMonth() : (new Date().getMonth() + 1)}-${new Date().getDate <= 9 ? '0'+new Date().getDate() : new Date().getDate()}`,
        end_date: `${new Date().getFullYear()}-${((new Date().getMonth() + 1) <= 9) ? '0'+ new Date().getMonth() : (new Date().getMonth() + 1)}-${new Date().getDate <= 9 ? '0'+new Date().getDate() : new Date().getDate()}`,
        id_branch: 0
    });

    const {
        start_date, end_date, id_branch
    } = formQuery;

    useEffect(async () => {
        await GetReportInvoice();
    }, []);

    //METHODS
    const GetReportInvoice = async () => {

        let method = `Reports/GetReportInvoices/${start_date}/${end_date}`;

        if (id_branch !== 0 && id_branch.value !== undefined) {
            method = method + `&id_branch=${id_branch.value}`;
        }

        const request = await _handleRequest("GET", method, "Reporte global");

        if (request !== null) {

            setReportCompany(request.reportCompany);
            setReportPatient(request.reportPatient);
            setReportGeneralPublic(request.reportInvoiceGeneralPublic);
        }
    }

    const handleChangeView = () => {
        setViewCompany(!viewCompany);
    }

    const RiskBarCompany = {
        series: [reportCompany?.total_requiered_invoice, reportCompany?.total_not_requiered_invoice],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                offsetY: -10,
            },

            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    inverseOrder: true,
                    hollow: {
                        margin: 5,
                        size: '60%',
                        image: radialimage,
                        imageWidth: 140,
                        imageHeight: 140,
                        imageClipped: false,
                    },
                    track: {
                        opacity: 0.4,
                        colors: secondary
                    },
                    dataLabels: {
                        show: true,
                        enabled: true,
                        // enabledOnSeries: undefined,
                        value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                                return val + ' '
                            }
                        },
                        textAnchor: 'middle',
                        distributed: false,
                        offsetX: 0,
                        offsetY: 0,

                        style: {
                            fontSize: '14px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fill: ['#2b2b2b'],

                        },
                        total: {
                            show: true,
                            label: "Facturas solicitadas",
                            color: primary,
                            // formatter: function (w) {
                            //   return w.globals.seriesTotals.reduce((a, b) => {
                            //     return a + b
                            //   }, 0)
                            // }
                            formatter: function (w) {
                                return reportCompany?.total_requiered_invoice;
                            }
                        }
                    },
                }
            },

            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, reportCompany?.total_work_orders],
                    gradientToColors: ['#a927f9'],
                    type: 'horizontal'
                },
            },
            stroke: {
                dashArray: 15,
                strokecolor: ['#ffffff']
            },

            labels: ['Solicitadas', 'Pendientes'],
            colors: [secondary, primary],
        }
    }

    const RiskBarPatient = {
        series: [reportPatient?.total_requiered_invoice, reportPatient?.total_not_requiered_invoice],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                offsetY: -10,
            },

            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    inverseOrder: true,
                    hollow: {
                        margin: 5,
                        size: '60%',
                        image: radialimage,
                        imageWidth: 140,
                        imageHeight: 140,
                        imageClipped: false,
                    },
                    track: {
                        opacity: 0.4,
                        colors: secondary
                    },
                    dataLabels: {
                        show: true,
                        enabled: true,
                        // enabledOnSeries: undefined,
                        value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                                return val + ' '
                            }
                        },
                        textAnchor: 'middle',
                        distributed: false,
                        offsetX: 0,
                        offsetY: 0,

                        style: {
                            fontSize: '14px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fill: ['#2b2b2b'],

                        },
                        total: {
                            show: true,
                            label: (reportPatient?.total_requiered_invoice === 0 ? "Facturas pendientes" :  "Facturas solicitadas"),
                            color: primary,
                            // formatter: function (w) {
                            //   return w.globals.seriesTotals.reduce((a, b) => {
                            //     return a + b
                            //   }, 0)
                            // }
                            formatter: function (w) {
                                return (reportPatient?.total_requiered_invoice === 0 ? reportPatient?.total_not_requiered_invoice :  reportPatient?.total_requiered_invoice )
                            }
                        }
                    },
                }
            },

            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, reportPatient?.total_work_orders],
                    gradientToColors: ['#a927f9'],
                    type: 'horizontal'
                },
            },
            stroke: {
                dashArray: 15,
                strokecolor: ['#ffffff']
            },

            labels: ['Solicitadas', 'Pendientes'],
            colors: [secondary, primary],
        }
    }

    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, Code500);
        }
        else {
            createSweet("warning", "warning", nameMethod, response?.data?.msg);
        }

        return null;
    }

  

   









    return {
        loading,

        start_date, end_date, id_branch,

        viewCompany,

        handleInputChange, handleChangeView,

        reportCompany, reportPatient, reportGeneralPublic,
        
        GetReportInvoice,

        RiskBarCompany, RiskBarPatient
    }

}
