import { useState, useEffect, useRef } from 'react';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
 

export const useCommonDiscounts = () => {
  
    //GLOBAL VARIABLES
    const history = useHistory();

    //#region SHOW DISPLAY MESSAGES
        const [createSweet] = useSweetAlert();
    //#endregion
    
    //#region MODALS
        const [loading, setLoading] = useState(false);

        const [modal, setModal] = useState(false);

        const [modalTitle, setModalTitle] = useState("");

        const [method, setMethod] = useState("");

        const toggleModal = () => {
            setModal(!modal);            
        }
    //#endregion

    //#region COMMON DISCOUNTS
        //#region MODELS
            //LIST
            const [listCommonDiscounts, setListCommonDiscounts] = useState([]);
            const [listSections, setListSections] = useState([]);
            const [listProfiles, setListProfiles] = useState([])
            const [listBranchesTree, setListBranchesTree] = useState([]);

            //LIST FILTERED
            const [listExamsFiltered, setListExamsFiltered] = useState([]);            

            //MODELS
            const [idCommonDiscount, setIdCommonDiscount] = useState(0);

            //REFS
            const treeViewRef = useRef(null);

            //FORMVALUES
            const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
                id_gender: 0,
                name: "",
                code: "",
                valid_from: "",
                valid_to: "",
                days_of_week: [],
                percentage: "",
                auto_redeem: false,
                accumulative: false,
                percentage_global: false,
                day_from: 0,
                day_to: 0,
                list_exams_includes: [],
                list_exams_excludes: [],
                list_profiles_includes: []
            });

            const {
                id_gender,
                name,
                code,
                valid_from,
                valid_to,
                days_of_week,
                percentage,
                auto_redeem,
                accumulative,
                percentage_global,
                day_from,
                day_to,
                list_exams_includes,
                list_exams_excludes,
                list_profiles_includes,
            } = formValues;

        //#endregion

        //#region INPUTS VALIDATIONS
            const [validationsCommonDiscount, setValidationsCommonDiscount] = useState({
                name_valid: false,
                code_valid: false,
                valid_from_valid: false,
                valid_to_valid: false,
                days_of_week_valid: false,
                percentage_valid: false,
                day_from_valid: false,
                day_to_valid: false,     
                branch_tree_valid: false           
            })
        //#endregion
            
        //#region  USE EFFECTS
            useEffect(() => {
                getProfiles();
                getCommonDiscounts();
                getSections();
                getBranches();                
            }, []);

            useEffect(() => {
                if (!modal) {
                    handleResetValues();
                }
            }, [modal])
            
            
        //#endregion

        //#region METHODS
            //#region GET METHODS
                const getCommonDiscounts = async () => {
                    const _list = await _handleRequest("GET", "CommonDiscount/List", "Campañas");

                    if (_list !== null && _list.length > 0) {                    

                        setListCommonDiscounts(_list);
                    }
                }
                
                const getSections = async () => {
                    const _list = await _handleRequest("GET", "Section/List/false");

                    if (_list !== null && _list.length > 0) {
                        let _list_sections = [];

                        _list.forEach(_section => {
                            _list_sections.push({
                                id_section: _section.id_section,
                                name: _section.name,
                                check: false
                            });
                        });

                        setListSections(_list_sections);
                    }
                }

                const getBranches = async () => {
                    const _list = await _handleRequest("GET", "CommercialLine/ListCommercialLineChildrenBranch", "Sucursales")

                    if (_list !== null && _list.length > 0) {
                        setListBranchesTree(_list);
                    }
                }

                const getProfiles = async () => {
                    const _list = await _handleRequest("GET", "Profiles", "Perfiles");

                    if (_list !== null && _list.length > 0) {
                        let _list_profiles = [];

                        _list.forEach(_profile => {
                            _list_profiles.push({
                                label: _profile.code + " " + _profile.name,
                                value: _profile.id_profile,
                                is_curve: _profile.is_curve,
                                list_curves: _profile.curveProfileViewModel
                            });
                        });

                        setListProfiles(_list_profiles);
                    }
                }

                const saveAuthorized = async (e, typeAuthorization) => {
                    e.preventDefault();

                    setLoading(true);

                    const _list = _handleRequest("GET", `CommonDiscount/AuthorizedCommonDiscount/${idCommonDiscount}/${typeAuthorization === "autorizar" ? false : true}`, "Autorizar campaña");

                    if (_list !== null) {
                        setLoading(false);
                        createSweet("success", "success", "Se autorizo com exito" );
                        getCommonDiscounts();   
                        toggleModal();
                    }

                    setLoading(false);
                }
            //#endregion

            //#region HANDLE METHODS
                const handleCreate = () => {
                    handleUpdateValues({
                        id_gender: 0,
                        name: "",
                        code: "",
                        valid_from: "",
                        valid_to: "",
                        days_of_week: [],
                        percentage: "",
                        auto_redeem: false,
                        accumulative: false,
                        percentage_global: false,
                        day_from: 0,
                        day_to: 0,
                        list_exams_includes: [],
                        list_exams_excludes: [],
                        list_profiles_includes: []
                    });

                    let _list_branches = listBranchesTree;

                    _list_branches.map(_commercial => {
                        _commercial.children.map(_branch => {
                            _branch.state = 2;
                            return _branch;
                        });

                        _commercial.state = false;
                        return _commercial;
                    });
                    setListBranchesTree(_list_branches);

                    let _list_sections = listSections;

                    _list_sections.map(_section => {
                        _section.check = false;
                        return _section;
                    });

                    setListSections(_list_sections);

                    setMethod("create");
                    setModalTitle("Nueva campaña");
                    toggleModal();
                }

                const handleChangeSections = async (e, _id_section) => {
                    let _checked = e.target.checked;

                    let _list_sections = listSections;                  
                    let _list_exams_filtered = listExamsFiltered;                  

                    _list_sections.map(_section => {
                        if (_section.id_section === _id_section) {
                            _section.check = _checked;
                        }

                        return _section;
                    }); 

                    setListSections([..._list_sections]);

                    if (!_checked) {
                        let _list_exams_not_filtered = _list_exams_filtered.filter(x => x.id_section !== _id_section);

                        setListExamsFiltered([..._list_exams_not_filtered]);
                    }
                    else {
                        const _list = await _handleRequest("GET", "Exam/GetExamSections/"+_id_section , "Exámenes");

                        if (_list !== null && _list.length > 0) {

                            let _list_exams_filter = listExamsFiltered;

                            _list.forEach(_exam => {

                                let _valid_exist_exam = list_exams_includes.find(x => x.id_exam === _exam.id_exam);
                                let _valid_exist_in_excludes = list_exams_excludes.find(x => x.id_exam === _exam.id_exam);

                                if (_valid_exist_exam === undefined && _valid_exist_in_excludes === undefined) {
                                    _list_exams_filter.push({
                                        label: _exam.code + " " + _exam.name,
                                        value: _exam.id_exam,
                                        id_section: _exam.id_section
                                    });
                                }
                            });

                            setListExamsFiltered([..._list_exams_filter]);
                        }
                    }                                    
                }

                const handleSelectExamsIncludes = (e, is_included = true) => {
                    if (e.value !== undefined) {
                        
                        let _value = e.value;

                        let _find_test = listExamsFiltered.find(x => x.value === _value);

                        if (_find_test !== undefined) {
                            
                            let _list_exams_includes_form = (is_included) ? list_exams_includes : list_exams_excludes;
                            
                            let _list_exams_filtered = listExamsFiltered;  
                                                                                    
                            _list_exams_includes_form.push({
                                id_exam: _value,
                                name: _find_test.label,
                                id_section: _find_test.id_section,
                                percentage: 0
                            });
    
                            let _list_filter = _list_exams_filtered.filter(_exam => {
                                if (_exam.value === _value) {
                                    return false;
                                }

                                return true;
                            });
    
                            setListExamsFiltered([..._list_filter]);

                            if (is_included) {
                                handleUpdateValues({
                                    ...formValues,
                                    list_exams_includes: [..._list_exams_includes_form]
                                });
                            } 
                            else {
                                handleUpdateValues({
                                    ...formValues,
                                    list_exams_excludes: [..._list_exams_includes_form]
                                });
                            }                                                       
                        }
                    }
                }

                const handleSelectProfilesIncludes = (e) => {
                    if (e.value !== undefined) {
                        
                        let _value = e.value;

                        let _list_selected_profiles = list_profiles_includes;
                        let _find_profile = listProfiles.find(x => x.value === _value);
                        let _find_selected_profile = _list_selected_profiles.find(x => x.id_profile === _value);

                        if (_find_profile !== undefined && _find_selected_profile === undefined) {
                            
                            if (_find_profile.is_curve) {
                                _find_profile.list_curves.map(_curve => {
                                    _curve.percentage = 0;

                                    return _curve;
                                });
                            }

                            _list_selected_profiles.push({
                                id_profile: _find_profile.value,
                                name: _find_profile.label,
                                percentage: 0,
                                is_curve: _find_profile.is_curve,
                                list_curves: _find_profile.list_curves
                            });

                            handleUpdateValues({
                                ...formValues,
                                list_profiles_includes: [..._list_selected_profiles]
                            });
                        }
                    }
                }  

                const handleChangePercentage = (e, _id_exam = null, _id_profile = null, _id_curve = null) => {
                    
                    let _value = e.target.value;

                    if (_id_exam !== null) {
                        let _list_exams_includes = list_exams_includes;

                        _list_exams_includes.map(_exam => {
                            if (_exam.id_exam === _id_exam) {
                                
                                _exam.percentage = _value;
                            }

                            return _exam;
                        });                    
                        handleUpdateValues({
                            ...formValues,
                            list_exams_includes: [..._list_exams_includes]
                        });
                    }
                    else if (_id_profile !== null) {
                        let _list_profiles_includes = list_profiles_includes.map(_profile => {
                            if (_profile.id_profile === _id_profile) {
                                
                                if (_profile.is_curve) {
                                    _profile.list_curves[_id_curve].percentage = _value;
                                }
                                else {
                                    _profile.percentage = _value;
                                }
                            }

                            return _profile;
                        });

                        handleUpdateValues({
                            ...formValues,
                            list_profiles_includes: _list_profiles_includes
                        });
                    }
                }

                const handleRemoveTest = (is_included = true, _id_exam = null, _id_profile = null) => {
                    if (_id_exam !== null) {
                        let _list_exams_filter = listExamsFiltered;
                        
                        //REMOVE SELECTED EXAM FROM LIST "list_exam_includes"
                        let _list_exams_includes = [];
                        let _data_exam = null;

                        if (is_included) {
                            _list_exams_includes = list_exams_includes.filter(_exam => {
                                if (_exam.id_exam === _id_exam) {
                                    _data_exam = _exam;
                                    return false;
                                }
                                return true;
                            });
                        }
                        else {
                            _list_exams_includes = list_exams_excludes.filter(_exam => {
                                if (_exam.id_exam === _id_exam) {
                                    _data_exam = _exam;
                                    return false;
                                }
                                return true;
                            });
                        }

                        //ADD EXAM IN LIST "ListExamsFiltered", becouse is deleted from list
                        if (_data_exam !== null) {
                            _list_exams_filter.push({
                                label: _data_exam.name,
                                value: _data_exam.id_exam,
                                id_section: _data_exam.id_section
                            });
                        }
                        
                        //SET THE DIFFERENT VALUES
                        setListExamsFiltered([..._list_exams_filter]);

                        if (is_included) {
                            handleUpdateValues({
                                ...formValues,
                                list_exams_includes: [..._list_exams_includes]
                            });
                        }
                        else {
                            handleUpdateValues({
                                ...formValues,
                                list_exams_excludes: [..._list_exams_includes]
                            });
                        }
                    }
                    else if (_id_profile !== null) {
                        let _list_profile_includes = list_profiles_includes.filter(_profile => {
                            if (_profile.id_profile === _id_profile) {
                                return false;
                            }

                            return true;
                        });

                        handleUpdateValues({
                            ...formValues,
                            list_profiles_includes: [..._list_profile_includes]
                        });
                    }
                }
                           
                const handleCheckDaysOfWeek = (_value) => {
                    let _list_days = formValues.days_of_week;
                    
                    let _find_day = _list_days.find(x => x === _value);

                    if (_find_day === undefined) {
                        _list_days.push(_value);
                    }
                    else {
                        _list_days = _list_days.filter(_day => {
                            if (_day !== _value) {
                                return true;
                            }
                        });
                    }

                    handleUpdateValues({
                        ...formValues,
                        days_of_week: [..._list_days]
                    });
                }

                const handleUpdate = (_id_common_discount, type = "edit") => {                    

                    setIdCommonDiscount(0);
                    setListExamsFiltered([]);
                    setListSections([]);
                    setListExamsFiltered([]);

                    handleUpdateValuesFromUpdate(_id_common_discount, type);

                }

                const handleUpdateValuesFromUpdate = (_id_common_discount, type) => {

                    let _find_commonDiscount = listCommonDiscounts.find(x => x.id_common_discount === _id_common_discount);

                    if (_find_commonDiscount !== undefined) {

                        let _list_branches_tree = [];
                        let _list_sections = [];

                        let _list_exams_excluded = [];
                        let _list_exams_included = [];
                        let _list_profiles_included = [];                        

                        let _list_exams_filtered = [];

                        let _list_profiles = listProfiles;
                        
                        _find_commonDiscount.examSections.forEach(_exam => {

                            _list_exams_filtered.push({
                                label: _exam.code + " " + _exam.name,
                                value: _exam.id_exam,
                                id_section: _exam.id_section
                            });
                        });
                        
                        if (_find_commonDiscount.branches.length > 0) {

                            _list_branches_tree = listBranchesTree.map(_commercialLine => {
                                
                                _commercialLine.children.map(_branchTree => {
                                    let _find_branch = _find_commonDiscount.branches.find(x => x === _branchTree.id);

                                    if (_find_branch !== undefined) {
                                        _branchTree.state = 1;   
                                        _commercialLine.expanded = true;                                     
                                    }
                                    else {
                                        _branchTree.state = 2;
                                    }

                                    return _branchTree;
                                });

                                let _find_commercial_line = _find_commonDiscount.commercialLines.find(x => x === _commercialLine.id);

                                if (_find_commercial_line !== undefined) {
                                    _commercialLine.state = 1;
                                    _commercialLine.expanded = true;
                                }
                                else {
                                    _commercialLine.state = 2;
                                }

                                return _commercialLine;
                            });
                        }
                        else {
                            _list_branches_tree = listBranchesTree;
                        }
                        
                        if (_find_commonDiscount.sections.length > 0) {
                            _list_sections = listSections.map(_sectionS => {
                                let _find_section = _find_commonDiscount.sections.find(x => x === _sectionS.id_section);

                                if (_find_section !== undefined) {
                                    _sectionS.check = true;
                                }
                                else{
                                    _sectionS.check = false;
                                }

                                return _sectionS;
                            });                     
                        }
                        else {
                            _list_sections = listSections;
                        }
                        
                        _find_commonDiscount.examExcludes.forEach(_examE => {
                            let _find_excluded_exam = _list_exams_filtered.find(x => x.value === _examE);

                            if (_find_excluded_exam !== undefined) {
                                _list_exams_excluded.push({
                                    id_exam: _find_excluded_exam.value,
                                    name: _find_excluded_exam.label,
                                    id_section: _find_excluded_exam.id_section,
                                    percentage: 0
                                });

                                _list_exams_filtered = _list_exams_filtered.filter(x => x.value !== _examE);
                            }
                        });

                        _find_commonDiscount.discountTest.forEach(_testI => {
                            if (_testI.id_profile !== null) {
                                let _find_included_profile = _list_profiles.find(x => x.value === _testI.id_profile);

                                if (_find_included_profile !== undefined) {
                                    _list_profiles_included.push({
                                        id_profile: _find_included_profile.value,
                                        name: _find_included_profile.label,
                                        percentage: _testI.percentege,
                                        is_curve: _testI.is_curve,
                                        list_curves: _testI.list_curves
                                    });

                                    _list_profiles = _list_profiles.filter(x => x.value !== _testI.id_profile);
                                }
                            }
                            else if (_testI.id_exam !== null) {
                                let _find_included_exam = _list_exams_filtered.find(x => x.value === _testI.id_exam);

                                if (_find_included_exam !== undefined) {
                                    _list_exams_included.push({
                                        id_exam: _find_included_exam.value,
                                        name: _find_included_exam.label,
                                        id_section: _find_included_exam.id_section,
                                        percentage: _testI.percentege
                                    });

                                    _list_exams_filtered = _list_exams_filtered.filter(x => x.value !== _testI.id_exam);
                                }
                            }
                        });

                        let _days_of_week = _find_commonDiscount.days_of_week;
                        
                        let _array_days_of_week = _days_of_week !== "" ? _days_of_week.split(',') : [];

                        _array_days_of_week = _array_days_of_week.map(_day => {
                            
                            _day = parseInt(_day);

                            return _day;
                        });

                        let _id_gender = 0;
                        let _days_from = 0;
                        let _days_to = 0;

                        if (_find_commonDiscount.discountCondition !== null) {
                            _id_gender = _find_commonDiscount.discountCondition.id_gender === null ? 0 : _find_commonDiscount.discountCondition.id_gender;
                            _days_from = _find_commonDiscount.discountCondition.day_from / 365;
                            _days_to = _find_commonDiscount.discountCondition.day_to / 365;
                        }

                        handleUpdateValues({
                            id_gender: _id_gender,
                            name: _find_commonDiscount.name,
                            code: _find_commonDiscount.code,
                            valid_from: _find_commonDiscount.valid_from,
                            valid_to: _find_commonDiscount.valid_to,
                            days_of_week: _array_days_of_week,
                            percentage: (_find_commonDiscount.percentage !== "" && _find_commonDiscount.percentage !== null) ? _find_commonDiscount.percentage : 0,
                            auto_redeem: _find_commonDiscount.auto_redeem,
                            accumulative: false,
                            percentage_global: (_find_commonDiscount.percentage !== "" && _find_commonDiscount.percentage !== null) ? false : true,
                            day_from: _days_from,
                            day_to: _days_to,
                            list_exams_includes: _list_exams_included,
                            list_exams_excludes: _list_exams_excluded,
                            list_profiles_includes: _list_profiles_included
                        });

                        setIdCommonDiscount(_find_commonDiscount.id_common_discount);
                        setListSections([..._list_sections]);
                        setListBranchesTree([..._list_branches_tree]);
                        setListProfiles([..._list_profiles]);
                        setListExamsFiltered([..._list_exams_filtered]);

                        setMethod(type === "edit" ? "update" : "view");
                        setModalTitle(type === "edit" ? "Editar campaña" : "Visualizar campaña");
                        toggleModal();
                    }
                }

                const handleResetValues = () => {
                    if (listBranchesTree.length > 0) {
                        let _list_valid_tree = listBranchesTree.map(_commercialLine => {

                            _commercialLine.children.map(_branch => {
                                _branch.state = 2;
                            });

                            _commercialLine.state = 2;
                            _commercialLine.expanded = true;

                            return _commercialLine;
                        });

                        setListBranchesTree([..._list_valid_tree]);
                    }

                    if (listSections.length > 0) {
                        let _list_section_tmp = listSections.map(_section => {
                            _section.check = false;

                            return _section;
                        });

                        setListSections([..._list_section_tmp]);
                    }

                    if (list_profiles_includes.length > 0) {
                        let _list_profiles = listProfiles;

                        list_profiles_includes.forEach(_profile => {
                            _list_profiles.push({
                                value: _profile.id_profile,
                                label: _profile.name,
                                is_curve: _profile.is_curve,
                                list_curves: _profile.list_curves
                            });
                        });

                        setListProfiles([..._list_profiles]);
                        handleUpdateValues({
                            ...formValues,
                            list_profiles_includes: []
                        });
                    }
                }

                const handleDelete = (_id_common_discount) => {
                    let _find_commonDiscount = listCommonDiscounts.find(x => x.id_common_discount === _id_common_discount);

                    if (_find_commonDiscount !== undefined) {
                        handleUpdateValues({
                            ...formValues,
                            name: _find_commonDiscount.name,
                            code: _find_commonDiscount.code
                        });

                        setIdCommonDiscount(_id_common_discount);
                        setModalTitle("Eliminar campaña");                 
                        setMethod("delete");
                        toggleModal();                        
                    }                    
                }

                const handleAuthorized = (_id_common_discount) => {
                    let _find_commonDiscount = listCommonDiscounts.find(x => x.id_common_discount === _id_common_discount);

                    if (_find_commonDiscount !== undefined) {
                        handleUpdateValues({
                            ...formValues,
                            name: _find_commonDiscount.name,
                            code: _find_commonDiscount.code
                        });

                        setIdCommonDiscount(_id_common_discount);
                        setModalTitle("Autorizar campaña");                 
                        setMethod("auth");
                        toggleModal();      
                    }                    
                }

                const handleDesAuthorized = (_id_common_discount) => {

                    let _find_commonDiscount = listCommonDiscounts.find(x => x.id_common_discount === _id_common_discount);

                    if (_find_commonDiscount !== undefined) {
                        handleUpdateValues({
                            ...formValues,
                            name: _find_commonDiscount.name,
                            code: _find_commonDiscount.code
                        });

                        setIdCommonDiscount(_id_common_discount);
                        setModalTitle("Desautorizar campaña");                 
                        setMethod("unAuth");
                        toggleModal();      
                    }   
                }
            //#endregion

            //#region METHODS BY POST
                const saveCommonDiscount = async (_type) => {
                    setLoading(true);

                    if (validationCommonDiscountForm()) {
                        setLoading(false);
                        return;
                    }

                    let _string_days_of_week = days_of_week.join(",");

                    let _list_test_includes = [];

                    if (list_exams_includes.length > 0) {
                        list_exams_includes.forEach(_exam => {
                            _list_test_includes.push({
                                id_profile: null,
                                id_exam: _exam.id_exam,
                                percentege: _exam.percentage,
                                is_curve: false,
                                list_curves: null
                            });
                        });
                    }

                    if (list_profiles_includes.length > 0) {
                        list_profiles_includes.forEach(_profile => {
                            
                            _list_test_includes.push({
                                id_profile: _profile.id_profile,
                                id_exam: null,
                                percentege: _profile.percentage,
                                is_curve: _profile.is_curve,
                                list_curves: _profile.list_curves
                            })
                        });
                    }

                    let _list_selected_sections = [];
                    
                    if (listSections.length > 0) {
                        listSections.forEach(_section => {
                            if (_section.check) {
                                _list_selected_sections.push(_section.id_section);            
                            }
                        })
                    }

                    let _list_exams_excluded = [];

                    if (list_exams_excludes.length > 0) {
                        list_exams_excludes.forEach(_excluded => {
                            _list_exams_excluded.push(_excluded.id_exam);
                        });
                    }

                    const _api = treeViewRef.current.api;
                    const _selected_branch = _api.getItems();

                    _selected_branch.map(_branch => {
                        if (_branch.state === false) {
                            _branch.state = 2
                        };

                        return _branch;
                    });

                    let body = {
                        id_common_discount: idCommonDiscount,
                        name: formValues.name,
                        code: formValues.code,
                        valid_from: formValues.valid_from,
                        valid_to: formValues.valid_to,
                        days_of_week: _string_days_of_week,
                        percentage: formValues.percentage_global ? null : formValues.percentage,
                        auto_redeem: formValues.auto_redeem,
                        accumulative: formValues.accumulative,
                        Conditions: {
                            id_gender: formValues.id_gender,
                            day_from: day_from * 365,
                            day_to: formValues.day_to * 365
                        },
                        Tests: _list_test_includes,
                        Sections: _list_selected_sections,
                        ExamExcludes: _list_exams_excluded,
                        comercialLine_branch: _selected_branch
                    };

                    let _route_request = "";
                    let _type_method = "";
                    let _message = "";

                    if (_type === "create") {
                        _route_request = "CommonDiscount/Create";
                        _type_method = "POST";
                        _message = "Crear Campaña";
                    }
                    else {
                        _route_request = "CommonDiscount/Update";
                        _type_method = "PUT";
                        _message = "Actualizar campaña";
                    }
                    
                    const _response = await _handleRequest(_type_method, _route_request, _message, body);

                    if (_response !== null ) {
                        
                        setLoading(false);
                        createSweet("success", "success", "Se creo correctamente");
                        getCommonDiscounts();
                        toggleModal();
                    }

                    setLoading(false);
                }
            //#endregion

            //#region METHODS BY DELETE
                const saveDelete = async (e) => {
                    e.preventDefault();
                    setLoading(true);

                    const _list = await _handleRequest("DELETE", `CommonDiscount/${idCommonDiscount}`, "Eliminar campaña");

                    if (_list !== null) {
                        setLoading(false);
                        createSweet("success", "success", "Se elimino correctamente" );
                        getCommonDiscounts();   
                        toggleModal();
                    }

                    setLoading(false);
                }
            //#endregion

            //#region VALIDATIONS
                const validationCommonDiscountForm = () => {
                    let _status_validation = false;
                    let newValidations = {};

                    if (formValues.name.length < 3) {
                        newValidations = {
                            ...newValidations,
                            name_valid: true
                        };

                        _status_validation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            name_valid: false
                        };
                    }

                    if (formValues.code.length < 3) {
                        newValidations = {
                            ...newValidations,
                            code_valid: true
                        };

                        _status_validation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            code_valid: false
                        };
                    }

                    if (formValues.valid_from === "") {
                        newValidations = {
                            ...newValidations,
                            valid_from_valid: true
                        };

                        _status_validation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            valid_from_valid: false
                        };
                    }

                    if (formValues.valid_to === "") {
                        newValidations = {
                            ...newValidations,
                            valid_to_valid: true
                        };

                        _status_validation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            valid_to_valid: false
                        };
                    }

                    if (formValues.days_of_week.length === 0) {
                        newValidations = {
                            ...newValidations,
                            days_of_week_valid: true
                        };

                        _status_validation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            days_of_week_valid: false
                        };
                    }

                    if (formValues.day_from === "" || (formValues.day_from !== "" && parseInt(formValues.day_from) < 0)) {
                        newValidations = {
                            ...newValidations,
                            day_from_valid: true
                        };

                        _status_validation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            day_from_valid: false
                        };
                    }
                    
                    if (formValues.day_to === "" || formValues.day_to === "0" || (formValues.day_to !== "" && parseInt(formValues.day_to) <= 0)) {
                        newValidations = {
                            ...newValidations,
                            day_to_valid: true
                        };

                        _status_validation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            day_to_valid: false
                        };
                    }

                    if (!formValues.percentage_global) {
                        if (formValues.percentage === "" || formValues.percentage === "0" || (formValues.percentage !== "" && parseFloat(formValues.percentage) < 0)) {
                            newValidations = {
                                ...newValidations,
                                percentage_valid: true
                            };

                            _status_validation = true;
                        }
                        else {
                            newValidations = {
                                ...newValidations,
                                percentage_valid: false
                            };
                        }
                    }

                    if (listBranchesTree.length > 0) {
                        listBranchesTree.forEach(_commercial => {
                            if (_commercial.children.length > 0) {
                                let _valid_branch = _commercial.children.find(x => x.state === 1);
                            
                                if (_valid_branch === undefined) {
                                    newValidations = {
                                        ...newValidations,
                                        branch_tree_valid: true
                                    };
                                    
                                    _status_validation = true;
                                }
                            }
                        })
                    }

                    setValidationsCommonDiscount(newValidations);

                    return _status_validation;
                }
            //#endregion 

        //#endregion
        
    //#endregion

    const _handleRequest = async(method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        //#region MODALS
        modal, toggleModal, loading, method, modalTitle,
        //#endregion

        //#region COMMON DISCOUNTS LIST
            listCommonDiscounts, listBranchesTree, listSections,
            listExamsFiltered, listProfiles,     
        //#endregion

        //#region COMMON DISCOUNTS MODELS

            //FORM
            id_gender,  name, code, valid_from, valid_to, days_of_week,
            percentage, auto_redeem, accumulative, percentage_global, day_from,
            day_to, list_exams_includes, list_exams_excludes, list_profiles_includes,

            //FORM METHODS
            handleInputChange,

            //REFS
            treeViewRef,
        //#endregion

        //#region VALDATIONS
            validationsCommonDiscount,
        //#endregion

        //#region COMMON DISCOUNTS METHODS
            handleCreate, handleChangeSections, handleSelectExamsIncludes, handleChangePercentage,
            handleRemoveTest, handleSelectProfilesIncludes, handleCheckDaysOfWeek, handleDesAuthorized,
            saveCommonDiscount, handleUpdate, handleDelete, saveDelete, handleAuthorized, saveAuthorized,
        //#endregion
    }
}
