import React from 'react';
import { Modal, ModalBody, Col, Row, Button, FormGroup, Card, ModalFooter } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import { ListCardsCredit } from './ListCardsCredit';


export const ModalPreviewCredt = (props) => {

    const {
        toggleCredit, modalCredit, typeModalCredit = "ViewCredit",
        totalPageCount, currentPage, nextPage, previousPage, goToPage, dataCredit
    } = props;


    return (
        <Modal isOpen={modalCredit} toggle={toggleCredit} backdrop='static' keyboard={false} centered={true} size='xl' scrollable={true} >
            <ModalBody className="border-3 b-primary "  >
                {
                    typeModalCredit === "ViewCredit" ?
                        <div>
                            <b className='txt-secondary f-16'>Pacientes Pendientes de cobro</b>
                            <hr />
                            <ListCardsCredit
                                methodsActions={true}
                                items={dataCredit}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                totalPageCount={totalPageCount}
                                currentPage={currentPage}
                                goToPage={goToPage}
                            />
                        </div>
                        :
                        ""
                }
            </ModalBody>
            {
                <ModalFooter className="border-1 b-primary ">
                    <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleCredit}>Cerrar</Button>
                </ModalFooter>
            }
        </Modal>
    )
}
