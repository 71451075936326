import React from 'react';
import { Modal, Col, Row, Label, Button, Input, Media, ModalBody, Table } from 'reactstrap';
import 'animate.css';
import '../../../../assets/css/analiticaCards.css'



export const ModalInfoPatient = (props) => {

    const {


        typeModalInfo = "info", modalInfo, toggleInfoPatient, closedModalInfo,
        dataPatient, dataStudy, loadingPatient, namepatien, age, curp, phone, gender, datanim,
        loadingRefused


    } = props;

    //console.log(loadingRefused);






    return (
        <Modal isOpen={modalInfo} toggle={closedModalInfo} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeModalInfo === "info" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Información del paciente"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "25%" }} alt="" onClick={closedModalInfo} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" style={{ textAlign: "center" }} className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >
                                {
                                    loadingPatient === false ?
                                        <>
                                            <Row className='mt-2'>
                                                <Col sm="12" md="12" lg="12" className='text-center'>
                                                    <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                <Col sm="3" md="3" lg="3" className='text-left'>
                                                    {
                                                        loadingRefused === true ?
                                                            <p className='f-12 text-danger'><b>Estudios Rechazados</b></p>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        loadingRefused === false ?
                                                            <p className='f-24 text-primary'><b>Cargando</b></p>
                                                            :
                                                            ""
                                                    }
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            </Row>
                                        </>
                                        :
                                        <Col sm="12" className='text-center'>

                                            <div className="user-image">
                                                <div className="avatar">
                                                    {
                                                        gender === 2 ?
                                                            <Media body style={{ width: "15%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserMen.svg")} />
                                                            :
                                                            <Media body style={{ width: "15%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} />
                                                    }
                                                </div>
                                                <div className="icon-wrapper" data-intro="Change Profile image here">
                                                </div>
                                            </div>
                                            <div className="info">
                                                <Row>
                                                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                                                        <Row >
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Sexo"}</a></div>
                                                                    <div className="desc  detailPatienModal f-14">
                                                                        {
                                                                            gender === 2 ? "Masculino" : "Femenino"
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Edad"}</a></div>
                                                                    <div className="desc detailPatienModal f-14">
                                                                        {age}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                                                        <div className="user-designation">
                                                            <div className="title"><b target="_blank" >{"Nombre"}</b></div>
                                                            <div className="desc mt-2 detailPatienModal">{namepatien}</div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                                                        <Row>
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Curp"}</a></div>
                                                                    <div className="desc detailPatienModal f-12">
                                                                        {curp}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Telefono"}</a></div>
                                                                    <div className="desc detailPatienModal f-12">
                                                                        {phone}
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />

                                            </div>
                                            <div>
                                                <Col sm="12">
                                                    <b className='txt-secondary'>Estudios por Sección:</b>
                                                </Col>
                                                <Col sm="12">
                                                    {
                                                        dataStudy.map((_obj) => {
                                                            return (
                                                                <Row>
                                                                    <Col sm="6" style={{ textAlign: "initial" }} >
                                                                        <Input id={`checkbox-${_obj.id_exam}`} value={_obj.id_exam} checked={_obj.check_specimen}
                                                                            disabled={_obj.check_specimen === true ? true : false}
                                                                            type="checkbox"
                                                                            className='checkbox_animated'
                                                                        />
                                                                        <Label className='f-w-600  modal-label-modal badge badge-light-secondary' for={`checkbox-${_obj.id_exam}`}>{_obj.name}</Label>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </Col>

                                            </div>

                                        </Col>
                                }
                            </ModalBody>
                        </Col>
                    </>
                    : ""
            }


        </Modal >
    )
}