import { SearchAsync } from '../../../components/elements/SearchAsync';
import React from 'react';
import { Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export const DataAnalytesForm = (props) => {

    const {
        activeTab, analytes, methodActive, id_analyte_group, name_analyte_group, groupAnalytes, handleInputChange, vIdAnalyteGroup, vNameAnalyteGroup, loinc_num, loincState, isSimple
    } = props;
    // //console.log(analytes);
    return (
        <>
            {
                isSimple === false &&
                <FormGroup className="mb-3 col-12">
                    <Label className="text-sm">Grupo de analito</Label>
                    <Input
                        bsSize="sm"
                        type="select"
                        name="id_analyte_group"
                        value={id_analyte_group}
                        onChange={handleInputChange}
                        required={true}
                    >
                        <option value={-1}>Selecciona una opción</option>
                        <option value={0}>Nuevo grupo de analitos</option>
                        {
                            groupAnalytes.length > 0 &&
                            groupAnalytes.map((u, key) => {
                                return <option
                                    key={key}
                                    value={u.id_analyte_group}
                                >
                                    {u.name}
                                </option>
                            })
                        }
                    </Input>
                    <Input type="hidden" invalid={vIdAnalyteGroup} />
                    <FormFeedback>Debes seleccionar una opción</FormFeedback>
                </FormGroup>
            }
            <FormGroup className={`mb-3 col-12 ${id_analyte_group === "0" ? "" : "d-none"}`}>
                <Label className="text-sm">Nombre del grupo de analitos</Label>
                <Input
                    readOnly={activeTab === 2}
                    bsSize="sm"
                    placeholder="Grupo de analitos"
                    type="text"
                    name="name_analyte_group"
                    value={name_analyte_group}
                    autoComplete="off"
                    onChange={handleInputChange}
                    invalid={vNameAnalyteGroup}
                />
                <FormFeedback>El nombre del grupo debe tener más de 3 caracteres</FormFeedback>
            </FormGroup> <br /> <br /> <br /> 
            <FormGroup className={`mb-3 col-12`}>
                <Label className="text-sm">Código internacional</Label>
                <SearchAsync
                    value={loinc_num}
                    name="loinc_num"
                    method={handleInputChange}
                    loincState={loincState}
                    url="Loincs/Search"
                    maxLenght={3}
                    mDisabled={activeTab === 2}
                />
            </FormGroup> 
            {
                analytes !== undefined ?
                    analytes.length === 0 ?
                        <FormGroup className="mb-3 col-12"> <br /> <br /> <br />
                            <Button
                                //color="primary"
                                outline color="primary"
                                type="submit"
                                className={"btn-block"}
                            >
                                Confirmar analito
                            </Button>
                        </FormGroup>
                        :
                        <FormGroup className="mb-3 col-12"> <br />
                            <Button
                                //color="primary"
                                outline color="primary"
                                type="submit"
                                className={"btn-block"}
                            >
                                Actualizar analito
                            </Button>
                        </FormGroup>
                    :
                    <FormGroup className="mb-3 col-12">
                        <Button
                            color="primary"
                            type="submit"
                            className={"btn-block"}
                        >
                            {
                                methodActive === "create" ?
                                    "Agregar analito" :
                                    "Editar analito"
                            }
                        </Button>
                    </FormGroup>
            }
        </>
    )
}
