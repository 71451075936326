import React, { Fragment } from 'react';
import { Button ,Col, Container, Row, Card, CardBody } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { RibbonRate } from '../../../constant';
import { useRates } from '../../../hooks/rates/useRates';
import { ListCards } from '../../../components/cards/ListCards';
import { ModalRates } from '../../../components/rates/ModalRates';

export const IndexRates = () => {   
    
    let filter = ["nameBrowser"];

    const {
        rates, branches, commercialLines, typeRate, method, validacionesRates,
        name, abbreviation, id_commercial_line, id_branch, id_type_rate, default_rate,
        handleInputChange, handleSelectValues, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        navigateConfigPrices,
        toggleDivCommercialLine,
        viewDivBranches,
        navigatePriceCost,
        downloadExcel
    } = useRates();

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Tarifario fat"  /> */}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">

                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {RibbonRate}
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate} ><span className="fa fa-plus-circle"></span></Button>
                                </div>
                            </CardBody>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <ListCards
                            filterProps={filter}
                            type="list"
                            inputFiter={true}
                            items={rates}
                            {...{handleUpdate, handleDelete}}
                            methodsAbsolutes = {
                                [
                                    {
                                        type: 'linkId',
                                        method: navigateConfigPrices,
                                        icon: 'icofont icofont-cur-dollar f-14',
                                        colorBtn: 'primary',
                                        titleToolTip: "Agregar precios"                                      
                                    },
                                    {
                                        type: 'linkId',
                                        method: navigatePriceCost,
                                        icon: 'fa fa-money f-14',
                                        colorBtn: 'success',
                                        titleToolTip: ""                                    
                                    },
                                    {
                                        type: 'linkId',
                                        method: downloadExcel,
                                        icon: 'fa fa-file-excel-o',
                                        colorBtn: 'primary',
                                        titleToolTip: "Descargar Excel"
                                    }
                                ]
                            }
                        />                        
                    </Col>
                </Row>
            </Container>
            <ModalRates
                {
                    ...{
                        branches, commercialLines, typeRate, modal, toggle, modalTitle, method, loading,
                        handleInputChange, handleSelectValues, validacionesRates,
                        name, abbreviation, id_commercial_line, id_branch, id_type_rate, default_rate,
                        saveCreate, saveUpdate, saveDelete,
                        toggleDivCommercialLine,
                        viewDivBranches
                    }
                }
            />
        </Fragment>
    )
}
