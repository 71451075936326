import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Form, FormFeedback, FormGroup, Input, ModalBody, ModalFooter, Row, Col } from 'reactstrap'
import { ComponenteBoard } from './ComponenteBoard';

export const FormTypePrecodedText = (props) => {

    const { toggle, loading, name, handleInputChange, validaciones, methodAction, button, data, method, data2, changeOrder, arrayPrecod, changeArrayPrecod } = props;
    const { name: validacionName } = validaciones;

    const [info, setInfo] = useState(data)
    //console.log(info);
    // const [pruebasDatos, setPruebasDatos] = useState([]);

    const grid = 8;

    let layoutVersion = localStorage.getItem('layout_version');

    const getListStyle = isDraggingOver => ({
        background:  isDraggingOver ? ( layoutVersion === "dark-only" ? "#0079C7" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "1px solid #0079C7" ,
        padding: grid,
        width: "100%",
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: `${grid * 1}px ${grid * 2}px`,
        margin: `0 0 ${grid}px 0`,
        background: isDragging ? ( layoutVersion === "dark-only" ? "linear-gradient(270deg, #0079C7 0%, #002940 85.94%)" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        color: layoutVersion === "dark-only" ? "#fff" :  "#000" ,
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "1px solid #0079C7" ,
        boxShadow: isDragging ? `0 0 10px` : "",
        // border: "1px solid #cecece",
        // width: "150px",
        // height: "100px",
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        let list = info;
        const items = reorder(list, sourceIndex, destIndex);
        setInfo(items);
        changeArrayPrecod([...items]);
    };

    const reorder = (list, startIndex, endIndex) => {
        let result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    return (
        <Form role="form" onSubmit={methodAction}>
            <ModalBody>
                <Col sm="12">
                    <FormGroup className="mb-3 col-12">
                        <Input
                            placeholder="Nombre del tipo de texto preocodificado"
                            type="text"
                            name="name"
                            value={name}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validacionName}
                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                </Col> <br />

             

                <Col sm="12" style={{ textAlign: "-webkit-center" }}>
                    {
                        method === "update" ?
                        <Col sm={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" type="droppableItem" direction='vertical'>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {info.map((item, index) => (
                                            <Draggable
                                                key={"proceso-" + item.id_precoded_text}
                                                draggableId={"proceso-" + item.id_precoded_text}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div>
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <span><i className="float-right fa fa-bars"></i></span>
                                                            {item.title}
                                                        </div>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
                            :
                            ""
                    }

                </Col>





            </ModalBody>
            <ModalFooter>
                <Button
                outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
