import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "../forms/useForm";
import { sendRequest } from "../requests/useRequest";

export const useHistoryPatient = () => {

    const { pacienteId } = useParams()
    const [dataPatient, setDataPatient] = useState({});
    const [workOrders, setWorkOrders] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [workOrderId, setWorkOrderId] = useState(0);
    const [dataModal, setDataModal] = useState({});
    const [errorValidacion, setErrorValidacion] = useState("");

    const [formValues, handleInputChange] = useForm({
        password: ""
    })

    const { password } = formValues;

    const getPatient = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Patient/FindPatientId/" + pacienteId);

        if (respuesta.code === 200) {
            setDataPatient(respuesta.data)
        }
    }

    const getWorkOrders = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "WorkOrder/SearchWorkOrderFromPatient/" + pacienteId);

        if (respuesta.code === 200) {
            setWorkOrders(respuesta.data);
        }
    }



    const getInformation = async () => {

        let body = {
            username: localStorage.getItem("nameUser"),
            password: password,
            id_work_order: workOrderId
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };
        const respuesta = await sendRequest(requestOptions, "WorkOrder/GetInformationWorkOrder");

        if (respuesta.code === 200) {
            let wos = workOrders;
            wos = wos.map(element => {
                if (element.id_work_order === workOrderId) {
                    //console.log(respuesta.data.listExam);
                    element.listExam = respuesta.data.listExam;
                    element.listProfile = respuesta.data.listProfile;
                    element.listSections = respuesta.data.listSections;
                    element.send_results_pacient = true;
                }
                return element;
            })
            setWorkOrders(wos);
            toggle();
        } else {
            setErrorValidacion("Error de validación, no se puede mostrar la información.");
        }
    }

    const showInformationModal = (id_work_order) => {
        let wo = workOrders.find(x => x.id_work_order === id_work_order);
        setDataModal({
            nim: wo.nim
        })
        setWorkOrderId(id_work_order);
        toggle();
    }

    useEffect(() => {
        getPatient();
        getWorkOrders();
    }, [])

    return {
        pacienteId, dataPatient, workOrders, getInformation, showInformationModal,
        modal, toggle, dataModal, password, handleInputChange, errorValidacion
    };
}
