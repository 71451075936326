import React, { Fragment } from 'react'
import { Badge, Button, ButtonGroup, Card, CardBody, Col, Container, FormGroup, Input, Label, Pagination, Row, Table, Tag } from 'reactstrap'
import { useViewInvoice } from '../../hooks/invoice/useViewInvoice';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { ModalCancelInvoice } from '../../components/Invoice/ModalCancelInvoice';
import { StatusCancellationInvoice, StatusPreInvoice, StatusStampedInvoice } from '../../constant/billing';

export const IndexViewInvoice = () => {

    const {
        listbranch,
        //#region RETURN GLOBAL VARIABLES
        loadingTableInvoice, loading, method,
        //#endregion

        //#region MODALS
            modalTitle, modalCancellation, toggleModalCancellation,
        //#endregion

        //#region RETURN VIEW INVOICE
            // RETURN MODELS
            informationInvoice, ListInvoices, ListTypeCancellations,

            id_invoice, code_type_cancellation, code_reference_value, is_general_public, 

            handleInputChange, 

            //RETURN FILTERS
            iSerie, iFolio, iRfc, iTypeDocument, iTypePayMethod, iDateIni, iDateEnd, iBranch,

            handleInputChangeFilters,

            //RETURN HANDLE METHODS
            handleCancelInvoice, SaveCancellationInvoice, handleSearch,
            handleFilters, DownloadInvoice, DownloadXml, DownloadSupport,
            handleEditInvoice,

            //RETURN PAGINATION
            goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
            prevPage, nextPage, totalRecords, resultCount,isgeneral_public,downdloadExcel
        //#endregion 

    } = useViewInvoice();
   

    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0' >
                <Row className='mt-2 mb-3'>
                    <Col sm="12" md="12" lg="12">
                        <Row>
                            <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0' >
                                <Card>
                                <CardBody className='p-3'>
                                    <Col sm="12">
                                     
                                        <Row>
                                            <Col sm="2">
                                            <FormGroup>
                                                <Label type="text" className='form-col-label txt-secondary'>Fecha de inicio</Label>
                                                <Input type='date' value={iDateIni} name='iDateIni' onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                            <FormGroup>
                                                <Label className='form-col-label txt-secondary'>Fecha de cierre</Label>
                                                <Input type='date' value={iDateEnd} name="iDateEnd" onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                            <FormGroup>
                                                <Label className='txt-secondary'>Método de pago</Label>
                                                <Input type='select' value={iTypePayMethod} name='iTypePayMethod' onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='Método de pago'>
                                                    <option value="0">Selecciona una opción</option>
                                                    <option value="PUE">PUE</option>
                                                    <option value="PPD">PPD</option>
                                                </Input>
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                            <FormGroup>
                                                <Label className='txt-secondary'>Tipo de comprobante</Label>
                                                <Input type="select" value={iTypeDocument} name='iTypeDocument' onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='Tipo de comprobante'>
                                                    <option value="0">Selecciona una opción</option>
                                                    <option value="I">Ingreso (Factura)</option>
                                                    <option value="E">Egreso (Nota de crédito)</option>
                                                    <option value="P">Pago (Complemento de pago)</option>
                                                </Input>
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                            <FormGroup>
                                                <Label className='txt-secondary'>Sucursal</Label>
                                                <Input type='select' value={iBranch} name='iBranch' onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='Sucursal'>
                                                    <option value="0">Selecciona una opción</option>
                                                    {
                                                        listbranch.length > 0 && listbranch.map((s, key) => {
                                                            return  <option key={key} value={s.id_branch}>
                                                                        {s.label}
                                                                    </option>
                                                        })
                                                    }
                                                </Input>
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                            <FormGroup>
                                                <Label className='txt-secondary'>Publico General</Label>
                                                <Input type="select" value={isgeneral_public} name='isgeneral_public' onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='Tipo de comprobante'>
                                                    <option value="0">Selecciona una opción</option>
                                                    <option value="true">Factura Global</option>
                                                   
                                                </Input>
                                            </FormGroup>
                                            </Col>
                                           
                                        </Row>
                                    </Col>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                               
                                                                <td>
                                                                    <Pagination aria-label='...' style={{ justifyContent: "space-between" }}>
                                                                        <ul className='pagination pagination-primary'>
                                                                            {
                                                                                actualPage === 1 ?
                                                                                    <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                                                    : <li style={{ cursor: "pointer" }} className='page-item' onClick={goPreviousPage} ><span className='page-link'>Anterior</span></li>
                                                                            }
                                                                            {
                                                                                actualPage > 1 &&
                                                                                <>
                                                                                    <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => goPreviousPage(1)} ><span className='page-link'>1</span></li>
                                                                                    {
                                                                                        actualPage > 2 && 
                                                                                        <>
                                                                                            <li className="page-item"><span className='page-link'>...</span></li>
                                                                                            <li style={{ cursor: "pointer" }} onClick={() => goToPage(actualPage -1)} className='page-item'><span className='page-link'>{ actualPage - 1 }</span></li>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{actualPage}</span></li>
                                                                            {
                                                                                actualPage < totalPages && 
                                                                                <>
                                                                                    {
                                                                                        actualPage < (totalPages - 1) && 
                                                                                        <>
                                                                                            <li style={{ cursor:'pointer' }} onClick={() => goToPage(actualPage + 1)} className='page-item'><span className='page-link'>{ actualPage + 1 }</span></li>
                                                                                            <li className='page-item'><span className='page-link'>...</span></li>
                                                                                        </>
                                                                                    }
                                                                                    <li style={{ cursor: 'pointer' }} onClick={() => goToPage(totalPages)} className='page-item'><span className='page-link'>{ totalPages }</span></li>
                                                                                </>
                                                                            }
                                                                            {
                                                                                totalPages === actualPage ?
                                                                                    <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                                                    :<li style={{ cursor: "pointer" }} className='page-item primary' onClick={goNextPage}><span className='page-link primary'>Siguiente</span></li>
                                                                            }
                                                                        </ul>
                                                                    </Pagination>
                                                                </td>
                                                    <td style={{ width: "245px" }}>
                                                        <Input type='text' value={iRfc} name="iRfc" onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='RFC - Razón social' />
                                                    </td>

                                                    <td style={{ width: "245px" }}>
                                                        <Input type='text' value={iFolio} name='iFolio' onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='Folio' />
                                                    </td>

                                                    <td style={{ width: "245px" }}></td>
                                                    <td style={{ width: "300px" }}>
                                                        <label >Mostrando { actualPage } a { resultCount } de { totalRecords } resultados</label>
                                                    </td>

                                                    <td style={{ width: "90px" }}>
                                                        <Button type="button" size="xs" outline color="success" onClick={() => downdloadExcel()}   >
                                                            <i className="icofont icofont-file-excel" /> Excel
                                                        </Button>
                                                    </td>
                                                    <td style={{ width: "70px" }}>
                                                        <Button type="button" size="xs" outline color="primary"  onClick={() => handleFilters()}>Buscar</Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0' >
                                                <div className='table-responsive'> 
                                                    <Table size='sm' striped  hover >
                                                        <thead className="theadInvoice" >
                                                            <tr className='text-center' >
                                                                <th className='labelThead'>Serie - Folio</th>
                                                                <th className='labelThead'>Receptor</th>
                                                                <th className='labelThead'>Folio fiscal (UUID)</th>
                                                                <th className='labelThead'>Fecha</th>
                                                                <th className='labelThead'>Moneda</th>
                                                                <th className='labelThead'>Monto</th>
                                                                <th className='labelThead'>Tipo</th>
                                                                <th className='labelThead'><i className="icofont icofont-send-mail f-24 text-warning"></i></th>
                                                                <th className='labelThead'><i className="icofont icofont-coins f-18 text-success"></i></th>
                                                                <th className='labelThead'><i className="icofont icofont-envelope-open f-18 text-info"></i></th>
                                                                <th className='labelThead'><i className="icofont icofont-file-pdf f-18 text-info"></i></th>
                                                                <th className='labelThead'><i className="fa fa-file-code-o f-18 text-info"></i></th>
                                                                <th className='labelThead'><i className="fa fa-file-text f-18" style={{ color:"#f73d6d" }}></i></th>
                                                                <th className='labelThead'>Estado</th>
                                                                <th className='labelThead'> Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{textAlign : "center"}} >
                                                            {
                                                             !ListInvoices.length > 0 && <tr>
                                                                <td colSpan={16} className=' text-center f-16'>
                                                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}  style={{ width: "30%" }} alt="" />   <br /> 
                                                                <label className='txt-secondary'>Sin ningún resultado</label>                                        
                                                                </td>
                                                            </tr>
                                                             }
                                                            {
                                                                ListInvoices.length > 0 && ListInvoices.map(_invoice => {
                                                                    return (
                                                                        <tr key={_invoice.id_tmp} hidden={!_invoice.visibleTable} className={_invoice.statuaInvoice == StatusPreInvoice ? 'text-info' : ''}>
                                                                            <td style={{verticalAlign: "middle"}}>
                                                                                <Badge className={_invoice.isGeneralPublic === true ? "f-12 badge-public-general pointerCursor"
                                                                                 :
                                                                                 _invoice.statuaInvoice === "Prefactura"   ? "f-12 badge-prefactura pointerCursor" : "f-12 primary pointerCursor"
                                                                                 
            
                                                                                }
                                                                                 >
                                                                                    { _invoice.serieFolio }
                                                                                </Badge>
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle"}}>
                                                                                <b>{ _invoice.rfcReceptor }</b>
                                                                                <br />
                                                                                <span>{_invoice.commercialName }</span>
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle"}}>{ _invoice.uuid }</td>
                                                                            <td style={{verticalAlign: "middle"}}>{ _invoice.dateCreated }</td>
                                                                            <td style={{verticalAlign: "middle"}}>{ _invoice.codeCoin }</td>
                                                                            <td style={{verticalAlign: "middle"}}>{ _invoice.total }</td>
                                                                            <td style={{verticalAlign: "middle"}}> 
                                                                                <Badge color={ _invoice.typeDocument === "I" ? "success" : (_invoice.typeDocument === "P" ? "info" : "danger") } className='rounded-pill f-12'>{ _invoice.typeDocument }</Badge> 
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle", cursor: "pointer"}} id={`tooltip-isEnvelope-${_invoice.id_tmp}`} >{ _invoice.isEnvelope 
                                                                                ? <i className="icofont icofont-check-circled f-16 text-success f-w-700"></i> 
                                                                                : <i className="icofont icofont-close-circled f-16 text-danger f-w-700"></i> }
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-isEnvelope-${_invoice.id_tmp}`}
                                                                                    dataText={_invoice.isEnvelope ? "Factura enviada" : "Factura no enviada"}
                                                                                />
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle", cursor: "pointer"}} id={`tooltip-isPayed-${_invoice.id_tmp}`} >{ _invoice.isPayed 
                                                                                ? <i className="icofont icofont-check-circled f-16 text-success f-w-700"></i> 
                                                                                : <i className="icofont icofont-close-circled f-16 text-danger f-w-700"></i> }
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-isPayed-${_invoice.id_tmp}`}
                                                                                    dataText={_invoice.isPayed ? "Factura pagada" : "Factura sin pagar"}
                                                                                />
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle", cursor: "pointer"}} id={`tooltip-isEnvelopeOpen-${_invoice.id_tmp}`} >
                                                                                {
                                                                                    _invoice.isGeneralPublic === true ? 
                                                                                    <div>
                                                                                        {
                                                                                            _invoice.id_pay_method === 1 ? <label className='f-w-600 f-12 badge badge-light-secondary'>Efectivo</label>
                                                                                            :
                                                                                            (_invoice.id_pay_method === 2 ) ? <label className='f-w-600 f-12 badge badge-light-secondary'>T.C</label>
                                                                                            :
                                                                                            (_invoice.id_pay_method === 4 ) ? <label className='f-w-600 f-12 badge badge-light-secondary'>T.D</label>
                                                                                            :
                                                                                            <label className='f-w-600 f-12 badge badge-light-secondary'>Otro</label>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                         { _invoice.isEnvelopeOpen ? <i className="icofont icofont-search f-16 text-success f-w-700"></i> 
                                                                                            : <i className="icofont icofont-restaurant-search f-16 text-danger f-w-700"></i> } 
                                                                                    </div>
                                                                                }   
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-isEnvelopeOpen-${_invoice.id_tmp}`}
                                                                                    dataText={_invoice.isEnvelopeOpen ? "Leido por el cliente" : "Sin leer"}
                                                                                />                                                                          
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle", cursor: "pointer"}} id={`tooltip-downloadI-${_invoice.id_tmp}`}  onClick={() => DownloadInvoice(( _invoice.statuaInvoice === StatusPreInvoice ? _invoice.id_invoice : _invoice.uuid ), (_invoice.statuaInvoice === StatusPreInvoice ? true : false) )}>  
                                                                                <i className="icofont icofont-download-alt f-16 text-success f-w-700"></i>    
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-downloadI-${_invoice.id_tmp}`}
                                                                                    dataText={"Descargar PDF"}
                                                                                />                                                                          
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle", cursor: "pointer"}} id={`tooltip-downloadXml-${_invoice.id_tmp}`}  onClick={ _invoice.statuaInvoice !== StatusPreInvoice ? () => DownloadXml(_invoice.id_invoice, _invoice.uuid) : null }>  
                                                                                {
                                                                                    _invoice.statuaInvoice == StatusPreInvoice ? <i className='text-info f-w-700'>---</i> : <>
                                                                                        <i className="icofont icofont-download-alt f-16 text-info f-w-700"></i>    
                                                                                        <ToolTips
                                                                                            placement="top"
                                                                                            dataTarget={`tooltip-downloadXml-${_invoice.id_tmp}`}
                                                                                            dataText={"Descargar XML"}
                                                                                        /> 
                                                                                    </>
                                                                                }                                                                         
                                                                            </td>
                                                                            <td  style={{verticalAlign: "middle"}} id={`tooltip-downloadSupport-${_invoice.id_tmp}`}  onClick={ () => DownloadSupport(_invoice.id_invoice) }>  
                                                                          {
                                                                            (_invoice.typeDocument === "P" ) ? ""
                                                                            :
                                                                            <div>
                                                                                  <i className="icofont icofont-download-alt f-16 text-info f-w-700"></i>    
                                                                            <ToolTips
                                                                                placement="top"
                                                                                dataTarget={`tooltip-downloadSupport-${_invoice.id_tmp}`}
                                                                                dataText={"Descargar Soporte"}
                                                                            />  
                                                                            </div>
                                                                          }
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle"}}>
                                                                                <Badge color={ _invoice.statuaInvoice === StatusPreInvoice 
                                                                                    ? "warning"
                                                                                    : (_invoice.statuaInvoice === StatusStampedInvoice
                                                                                    ? "success"
                                                                                    : "danger" ) }
                                                                                    >{ _invoice.statuaInvoice }</Badge>
                                                                            </td>
                                                                            <td style={{verticalAlign: "middle"}}>
                                                                                {
                                                                                    _invoice.statuaInvoice !== StatusCancellationInvoice && _invoice.statuaInvoice !== StatusPreInvoice 
                                                                                    ?
                                                                                        <>
                                                                                            <Button size='xs' id={`tooltip-btnCancell-${_invoice.id_tmp}`} color='danger' onClick={() => handleCancelInvoice(_invoice.id_invoice)}> <i className="icofont icofont-close-circled"></i></Button>
                                                                                            <ToolTips
                                                                                                placement="top"
                                                                                                dataTarget={`tooltip-btnCancell-${_invoice.id_tmp}`}
                                                                                                dataText={"Cancelar factura"}
                                                                                            />   
                                                                                        </>
                                                                                    : ""
                                                                                } 
                                                                                {
                                                                                    _invoice.statuaInvoice === StatusPreInvoice
                                                                                    ?
                                                                                        <>
                                                                                            <ButtonGroup>
                                                                                                <Button size='xs' id={`tooltip-btnEdit-${_invoice.id_tmp}`} color='warning' onClick={() => handleEditInvoice(_invoice.id_invoice)} >
                                                                                                    <i className="icofont icofont-edit"></i>
                                                                                                </Button>
                                                                                                <ToolTips
                                                                                                    placement="top"
                                                                                                    dataTarget={`tooltip-btnEdit-${_invoice.id_tmp}`}
                                                                                                    dataText={"Editar factura"}
                                                                                                /> 
                                                                                               
                                                                                            </ButtonGroup>
                                                                                        </>
                                                                                    : ""
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                  
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>

                                </Card>
                                  
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ModalCancelInvoice
                {
                    ...{
                        modalTitle, modalCancellation, toggleModalCancellation,
                        loading, method,

                        informationInvoice, ListTypeCancellations, 
                        id_invoice, code_type_cancellation, code_reference_value, is_general_public,
                        handleInputChange, SaveCancellationInvoice
                    }
                }
            />
        </Fragment>
    )
}
