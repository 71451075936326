import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row, Collapse, Input, Table } from 'reactstrap';
import { CImagen } from './analyteCurvas/CImagen';


export const ComponentImagenology = ({item,key}) => {

  //console.log(item)
  // <p dangerouslySetInnerHTML={{ __html: description }} />



  return (
    <div key={key} >
        <Col sm="12" className='p-l-0 p-r-0' >
        <label className='f-w-600 f-12 badge badge-light-primary' >{item.name}</label>
        <br />
        <label className=''>Capturado</label>
        {
          <CImagen
          item={item.examImagenology}
          key={key}
          />
        }
        </Col> <br /> <br />
    </div> 
  )
}
