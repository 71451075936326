import React, { useState } from 'react';
import { CardBody, Form, FormGroup, Label, Row, Input, FormFeedback, ButtonGroup, Button, Tooltip, TabContent, TabPane, Nav, NavItem, NavLink, Col } from 'reactstrap';
import { DataAnalytesForm } from './FormsTypeAnalytes/DataAnalytesForm';
import { IntegerAndRealNumbersForm } from './FormsTypeAnalytes/IntegerAndRealNumbersForm';
import { NumberRealForm } from './FormsTypeAnalytes/NumberRealForm';
import { RangeForm } from './FormsTypeAnalytes/RangeForm';
import { TextComputedForm } from './FormsTypeAnalytes/TextComputedForm';
import { TextPrecodedForm } from './FormsTypeAnalytes/TextPrecodedForm';
import Select from 'react-select';
import classnames from 'classnames';



export const FormAnalytes = (props) => {
    const {
        analytes, methodActive, addAnalyte, editAnalyte, changeUnit, validarFormula, resetValidarFormula,
        name, app_code, code, id_analyte_group, name_analyte_group, id_type_analyte, id_info_analyte, print, required, id_type_precoded_text, integers, decimals, id_unit, id_unit_to, compute, check_compute, check_complex, replace,
        groupAnalytes, typeAnalytes, typePrecodedTexts, units, unitsTo,
        handleInputChange, validaciones, validateFormula, handleModalTest,
        handleFullModal, modalFullRef, loincState,
        loinc_num,
        handleValueBold, handleValueItalics, handleValueUnderline, handleValueFormatTransform, valueBold, valueItalics, valueUnderline, valueFormatTransform,
        selectedInfoAnalytes, infoAnalytes, handleValueInfoAnalyte,
        selectedInfoCharacteristics, infoCharacteristics, handleValueInfoCharacteristics,
        isSimple = false,
        selectedExamSimple, simpleExams, handleValueExamSimple,
        selectedAnalyteImport, analytesFromExam,
        //handleChangeTextP,data,
        onChange, data, handleChangeTextP, selected,
        tabTypeAnalyte: activeTab, toggleTypeAnalyte: toggle
    } = props;

    const {
        name: vName,
        app_code: vAppCode,
        // code: vCode,
        id_analyte_group: vIdAnalyteGroup,
        name_analyte_group: vNameAnalyteGroup,
        id_type_analyte: vIdTypeAnalyte,
        id_type_precoded_text: vIdTypePrecodedText,
        integers: vIntegers,
        decimals: vDecimals,
        compute: vCompute
    } = validaciones;

    const [tooltipBold, setTooltipBold] = useState(false);
    const [tooltipItalics, setTooltipItalics] = useState(false);
    const [tooltipUnderline, setTooltipUnderline] = useState(false);
    const [tooltipFormat, setTooltipFormat] = useState(false);
    const [tooltipUppercase, setToolTooltipUppercase] = useState(false);
    const [tooltipLowercase, setTooltipLowercase] = useState(false);

    const toggleTooltipBold = () => setTooltipBold(!tooltipBold);
    const toggleTooltipItalics = () => setTooltipItalics(!tooltipItalics);
    const toggleTooltipUnderline = () => setTooltipUnderline(!tooltipUnderline);
    const toggleTooltipFormat = () => setTooltipFormat(!tooltipFormat);
    const toggleTooltipUppercase = () => setToolTooltipUppercase(!tooltipUppercase);
    const toggleTooltipLowercase = () => setTooltipLowercase(!tooltipLowercase);
    // //console.log(infoAnalytes);

    // const [activeTab, setActiveTab] = useState(1);
    // const toggle = tab => {
    //     if (activeTab !== tab) setActiveTab(tab);
    // }

    // //console.log("------");
    // //console.log(selectedExamSimple);
    // //console.log(selectedAnalyteImport);
    // //console.log("*******");


    return (
        <Form onSubmit={methodActive === "create" ? addAnalyte : editAnalyte}>
            {
                !isSimple &&
                <Row>
                    {
                        methodActive === "create" &&
                        <Col className="12">
                            <Nav
                                className="nav-fill flex-column flex-md-row mt-3"
                                pills
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        aria-selected={activeTab === 1}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: activeTab === 1
                                        })}
                                        onClick={() => { toggle(1); }}
                                        href="#"
                                        role="tab"
                                    >
                                        Nuevo <br /> analito
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-selected={activeTab === 2}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: activeTab === 2
                                        })}
                                        onClick={() => { toggle(2); }}
                                        href="#"
                                        role="tab"
                                    >
                                        Importar <br /> analito
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-selected={activeTab === 3}
                                        className={classnames("mb-sm-3 mb-md-0", {
                                            active: activeTab === 3
                                        })}
                                        onClick={() => { toggle(3); }}
                                        href="#"
                                        role="tab"
                                    >
                                        Nueva <br /> caracteristica
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    }
                </Row>
            }
            <CardBody>
                <TabContent activeTab={activeTab}>
                    <Row>
                        {
                            activeTab === 2 &&
                            <FormGroup className={`mb-3 col-12`}>
                                <Label className="text-sm">Busqueda de examen</Label>
                                <Select
                                    // className="form-control form-control-plaintext form-control-sm"
                                    classNamePrefix="select"
                                    options={simpleExams}
                                    name="id_exam_simple"
                                    isClearable={false}
                                    value={selectedExamSimple}
                                    placeholder={`Busqueda de examen`}
                                    onChange={(e) => handleValueExamSimple(e)}
                                />
                                <FormFeedback>El nombre debe tener más de 3 caracteres </FormFeedback>
                            </FormGroup>
                        }
                        <FormGroup className={`mb-3 ${isSimple ? "d-none" : "col-12"}`}>
                            <Label className="text-sm">Busqueda del analito</Label>
                            {
                                activeTab === 1 ?

                                    <Select
                                        //className="form-control form-control-plaintext form-control-sm"
                                        classNamePrefix="select"
                                        options={infoAnalytes}
                                        name="id_info_analyte"
                                        isClearable={false}
                                        value={selectedInfoAnalytes}
                                        placeholder={`Busqueda de analito`}
                                        onChange={(e) => handleValueInfoAnalyte(e, "nuevo")}
                                    />

                                    :
                                    activeTab === 2 ?
                                        <Select
                                            // className="form-control form-control-plaintext form-control-sm"
                                            classNamePrefix="select"
                                            options={analytesFromExam}
                                            name="id_analyte_import"
                                            isClearable={false}
                                            value={selectedAnalyteImport}
                                            placeholder={`Busqueda de analito`}
                                            onChange={(e) => handleValueInfoAnalyte(e, "importado")}
                                        />
                                        :
                                        // selectedInfoCharacteristics, infoCharacteristics, handleValueInfoCharacteristics,
                                        <Select
                                            // className="form-control form-control-plaintext form-control-sm"
                                            classNamePrefix="select"
                                            options={infoCharacteristics}
                                            name="id_info_analyte"
                                            isClearable={false}
                                            value={selectedInfoCharacteristics}
                                            placeholder={`Busqueda de caracteristica`}
                                            onChange={(e) => handleValueInfoCharacteristics(e)}
                                        />
                            }
                            <FormFeedback>El nombre debe tener más de 3 caracteres </FormFeedback>
                        </FormGroup>
                        <FormGroup className={`mb-3 ${isSimple ? "d-none" : "col-12"}`}>
                            <Label className="text-sm">Analito</Label>
                            <Input
                                bsSize="sm"
                                placeholder="Analito"
                                type="text"
                                name="name"
                                value={name}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={vName}
                                readOnly={true}
                            />
                            <FormFeedback>El nombre debe tener más de 3 caracteres </FormFeedback>
                        </FormGroup>
                        <FormGroup className={`mb-3 ${isSimple ? "d-none" : "col-12"}`}>
                            <Label className="text-sm">AppCode</Label>
                            <Input
                                bsSize="sm"
                                placeholder="AppCode"
                                type="text"
                                name="app_code"
                                value={app_code}
                                required={false}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={vAppCode}
                                readOnly={true}
                            />
                            <FormFeedback>El código debe tener más de un caracter </FormFeedback>
                        </FormGroup>
                        <FormGroup className={`mb-3 ${isSimple ? "d-none" : "col-12"}`}>
                            <Label className="text-sm">Código</Label>
                            <Input
                                bsSize="sm"
                                placeholder="Código"
                                type="text"
                                name="code"
                                value={code}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={vAppCode}
                                readOnly={true}
                            />
                            <FormFeedback>El código debe tener más de un caracter </FormFeedback>
                        </FormGroup>
                        <FormGroup className={`mb-3 ${isSimple ? "col-3" : "col-4"}`}>
                            <Label className="text-sm">Impresión</Label>
                            <Input
                                bsSize="sm"
                                type="select"
                                name="print"
                                value={print}
                                onChange={handleInputChange}
                                required={true}
                                className="form-control form-control-sm input-air-primary"

                            >
                                <option value={false}>No</option>
                                <option value={true}>Si</option>
                            </Input>
                        </FormGroup>
                        <FormGroup className={`mb-3 ${isSimple ? "col-3" : "col-12"} ${print ? "" : "d-none"}`}>
                            <Label className="text-sm">Formato de impresión</Label>
                            <br />
                            <ButtonGroup size="sm">
                                <Button color="default" id="tooltipBold" onClick={handleValueBold} className={valueBold ? "btn-secondary" : "btn-outline-secondary"} type="button"><i className="fa fa-bold"></i></Button>
                                <Tooltip placement="top" isOpen={tooltipBold} target="tooltipBold" toggle={toggleTooltipBold}>
                                    Texto en negrita
                                </Tooltip>
                                <Button color="default" id="tooltipItalics" onClick={handleValueItalics} className={valueItalics ? "btn-secondary" : "btn-outline-secondary"} type="button"><i className="fa fa-italic"></i></Button>
                                <Tooltip placement="top" isOpen={tooltipItalics} target="tooltipItalics" toggle={toggleTooltipItalics}>
                                    Texto en cursivo
                                </Tooltip>
                                <Button color="default" id="tooltipUnderline" onClick={handleValueUnderline} className={valueUnderline ? "btn-secondary" : "btn-outline-secondary"} type="button"><i className="fa fa-underline"></i></Button>
                                <Tooltip placement="top" isOpen={tooltipUnderline} target="tooltipUnderline" toggle={toggleTooltipUnderline}>
                                    Texto subrayado
                                </Tooltip>
                                <Button color="default" id="tooltipFormat" onClick={() => handleValueFormatTransform(0)} className={valueFormatTransform === 0 ? "btn-secondary" : "btn-outline-secondary"} type="button">Ab</Button>
                                <Tooltip placement="top" isOpen={tooltipFormat} target="tooltipFormat" toggle={toggleTooltipFormat}>
                                    Texto normal
                                </Tooltip>
                                <Button color="default" id="tooltipUppercase" onClick={() => handleValueFormatTransform(1)} className={valueFormatTransform === 1 ? "btn-secondary" : "btn-outline-secondary"} type="button">AB</Button>
                                <Tooltip placement="top" isOpen={tooltipUppercase} target="tooltipUppercase" toggle={toggleTooltipUppercase}>
                                    Texto mayúsculas
                                </Tooltip>
                                <Button color="default" id="tooltipLowercase" onClick={() => handleValueFormatTransform(2)} className={valueFormatTransform === 2 ? "btn-secondary" : "btn-outline-secondary"} type="button">ab</Button>
                                <Tooltip placement="top" isOpen={tooltipLowercase} target="tooltipLowercase" toggle={toggleTooltipLowercase}>
                                    Texto minúsculas
                                </Tooltip>
                            </ButtonGroup>
                        </FormGroup>
                        <FormGroup className={`mb-3 ${isSimple ? "col-3" : "col-12"}`}>
                            <Label className="text-sm">Tipo de captura</Label>
                            <Input
                                disabled={activeTab === 2}
                                bsSize="sm"
                                type="select"
                                name="required"
                                value={required}
                                onChange={handleInputChange}
                                required={true}
                                className="form-control form-control-sm input-air-primary"
                            >
                                <option value={false}>No riguroso</option>
                                <option value={true}>Riguroso</option>
                            </Input>
                        </FormGroup>
                        <FormGroup className={`mb-3 ${isSimple ? "col-3" : "col-12"}`}>
                            <Label className="text-sm">Tipo de analito</Label>
                            <Input
                                disabled={activeTab === 2}
                                bsSize="sm"
                                type="select"
                                name="id_type_analyte"
                                value={id_type_analyte}
                                onChange={handleInputChange}
                                required={true}
                                className="form-control form-control-sm input-air-primary"

                            >
                                <option value={0}>Selecciona una opción</option>
                                {
                                    typeAnalytes.length > 0 &&


                                    typeAnalytes.map((u, key) => {


                                        return <option
                                            key={key}
                                            value={u.id_type_analyte}
                                        >
                                            {u.name}
                                        </option>
                                    })
                                }
                            </Input>
                            <Input type="hidden" invalid={vIdTypeAnalyte} />
                            <FormFeedback>Debes seleccionar una opción</FormFeedback>
                        </FormGroup>
                        {/* TEXTO PRECODIFICADO */}
                        <TextPrecodedForm
                            {...{
                                activeTab, isSimple, id_type_analyte, id_type_precoded_text, typePrecodedTexts, handleInputChange, vIdTypePrecodedText,
                                //handleChangeTextP
                                onChange, data, handleChangeTextP, selected
                            }}
                        />
                        {/* NUMEROS REALES */}
                        <NumberRealForm
                            {...{
                                activeTab, isSimple, id_type_analyte, integers, decimals, handleInputChange, vIntegers, vDecimals
                            }}
                        />
                        {/* NUMEROS ENTEROS Y REALES */}
                        <IntegerAndRealNumbersForm
                            {...{
                                activeTab, isSimple, changeUnit,
                                id_type_analyte, id_unit, id_unit_to,
                                units, unitsTo,
                                handleInputChange
                            }}
                        />
                        {/* RANGO PRECODIFICADO Y TEXTO CONDICIONADO */}
                        {/* TEXTOS COMPUTADOS */}
                        {
                            (id_type_analyte === "1" || id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5" || id_type_analyte === "6") ?
                                <RangeForm
                                    {...{
                                        activeTab, isSimple, id_type_analyte, replace, handleInputChange, handleFullModal, modalFullRef
                                    }}
                                /> : ""
                        }
                        {
                            (id_type_analyte === "7") ?
                                <>
                                    <RangeForm
                                        {...{
                                            activeTab, isSimple, id_type_analyte, replace, handleInputChange, handleFullModal, modalFullRef
                                        }}
                                    />
                                    <TextComputedForm
                                        {...{
                                            activeTab, isSimple, validarFormula, resetValidarFormula,
                                            id_type_analyte, compute, check_compute, check_complex,
                                            handleInputChange, validateFormula, handleModalTest, vCompute, handleFullModal, modalFullRef
                                        }}
                                    />
                                </>
                                : ""
                        }
                        {/* DATA ANALITOS */}
                        <DataAnalytesForm
                            {...{
                                activeTab, analytes, methodActive, id_analyte_group, name_analyte_group, groupAnalytes, handleInputChange, vIdAnalyteGroup, vNameAnalyteGroup, loinc_num, loincState, isSimple
                            }}
                        />
                    </Row>
                </TabContent>
            </CardBody>
        </Form >
    )
}

