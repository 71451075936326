import { useEffect, useState } from 'react';
import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useHistory } from 'react-router';
import { usePagination } from '../pagination/usePagination';

export const useBranches = () => {

    const history = useHistory();

    useEffect(() => {

        getBranch();
    }, [history.location]);

    const [branches, setBranches] = useState([]);
    const [branchId, setBranchId] = useState(0);

    const [states, setStates] = useState({});
    const [municipalities, setMunicipalities] = useState({});
    const [commercialLines, setCommercialLines] = useState([]);

    const [previewLogo, setPreviewLogo] = useState("");
    const [previewFirm, setPreviewFirm] = useState("");
    const [previewBackground, setPreviewBackground] = useState("");

    const [imageLogo, setImageLogo] = useState("");
    const [imageFirm, setImageFirm] = useState("");
    const [imageBackground, setImageBackground] = useState("");

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const toggle = () => {
        setModal(!modal);
    }

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        id_commercial_line: 0,
        code: 0,
        name: "",
        number_phone: "",
        email: "",
        id_state: 0,
        id_municipality: 0,
        address: "",
        colony: "",
        cp: "",
        url: "",
        logo: "",
        name_responsible: "",
        certificate: "",
        image_firm: "",
        toma: 0,
        background_image: "",
        roleUser_responsible:""
    });

    const {
        id_commercial_line, code, name, number_phone, email, id_state, id_municipality, address,
        colony, cp, url, logo, name_responsible, certificate, image_firm, toma, background_image,roleUser_responsible
    } = formValues;

    const [validaciones, setValidaciones] = useState({
        id_commercial_line_valid: false,
        code_valid: false,
        name_valid: false,
        rfc_valid: false,
        number_phone: false,
        email_valid: false,
        id_state_valid: false,
        id_municipality_valid: false,
        address_valid: false,
        colony_valid: false,
        cp_valid: false,
        name_responsible_valid: false,
        certificate_valid: false,
        roleUser_responsible_valid:false
    });

    const getState = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "State/List");

        if (respuesta.code === 200) {
            setStates(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getState);

        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Reportes", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Reportes", respuesta.data.msg);

        }
        // else {
        //     validarSesion(history, respuesta.code, getState);
        // }
    }

    const getMunicipality = async (id_state) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Municipality/List/${id_state}`);

        if (respuesta.code === 200) {
            setMunicipalities(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMunicipality);
            setMunicipalities([]);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Reportes", "Ocurrio un problema en el servidor");
            setMunicipalities([]);
        }
        else{
            createSweet("error", "warning", "Reportes", respuesta.data.msg);
            setMunicipalities([]);
        }
    }

    const getCommercialLines = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "CommercialLine/List");

        if (respuesta.code === 200) {
            let newCommercialLine = [];
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((item) => {
                    newCommercialLine.push({
                        id_commercial_line: item.id_commercial_line,
                        long_name: item.long_name,
                        short_name: item.short_name,
                        rfc: item.rfc
                    });
                });

                setCommercialLines(newCommercialLine);
            }
            else {
                setCommercialLines([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCommercialLines);        
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Sucursales", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Sucursales", respuesta.data.msg);
        }
    }

    const getBranch = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Branch/List");
        //console.log(respuesta);

        if (respuesta.code === 200) {
            let newBranch = [];

            let startItems = 0;
            let endItems = numberItems;

            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            let index = 0;

            respuesta.data.forEach(({
                id_commercial_line, address, background_image, certificate, code, colony, cp, email, id_branch, id_municipality, id_state,
                image_firm, imgBackground, imgFirma, imgLogo, name, name_responsible, number_phone, toma, url, name_short_commercial_line, name_long_commercial_line, rfc, status,
                roleUser_responsible
            }) => {

                let posicion = index + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                newBranch.push({
                    id_branch,
                    id_state,
                    id_municipality,
                    id_commercial_line,
                    name,
                    name_short_commercial_line,
                    name_long_commercial_line,
                    code,
                    rfc,
                    number_phone,
                    email,
                    address,
                    colony,
                    cp,
                    url,
                    name_responsible,
                    certificate,
                    image_firm,
                    toma,
                    background_image,
                    imgLogo,
                    imgFirma,
                    imgBackground,
                    roleUser_responsible,
                    status,
                    visibleTable,
                    
                });

                index++;
            });

            setBranches(newBranch);
            setBranchData(newBranch);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getBranch);

        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Sucursales", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Sucursales", respuesta.data.msg);

        }
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(branches.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listBranchs = [];
        branches.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listBranchs.push({
                ...data,
                //  typeExam: data.is_simple ? 
                //datos mostrados de la misma liena
                visibleTable: visibleTable
            });
        });

        setBranches(listBranchs);

    }, [totalPageCount, currentPage])

    const handleCreate = () => {
        //RESETEAS LOS VALORES DEL FORM

        handleUpdateValues({
            id_commercial_line: 0,
            code: 0,
            name: "",
            number_phone: "",
            email: "",
            id_state: 0,
            id_municipality: 0,
            address: "",
            colony: "",
            cp: "",
            url: "",
            logo: "",
            name_responsible: "",
            certificate: "",
            image_firm: "",
            toma: 0,
            background_image: "",
            roleUser_responsible:""
        })

        setValidaciones({
            id_commercial_line_valid: false,
            code_valid: false,
            name_valid: false,
            rfc_valid: false,
            number_phone: false,
            email_valid: false,
            id_state_valid: false,
            id_municipality_valid: false,
            address_valid: false,
            colony_valid: false,
            cp_valid: false,
            name_responsible_valid: false,
            certificate_valid: false,
            roleUser_responsible_valid:false
        });

        setBranchId(0);
        setPreviewFirm("");
        setPreviewLogo("");
        setPreviewBackground("");

        setImageLogo("");
        setImageFirm("");
        setImageBackground("");
        //Crear nueva sucursal
        setMethod("create");
        setModalTitle("Crear nuevo laboratorio");
        getState();
        getCommercialLines();
        toggle();
    }

    const saveCreate = async (e) => {

        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        var formData = new FormData();

        formData.append('id_commercial_line', formValues.id_commercial_line);
        formData.append('code', formValues.code);
        formData.append('name', formValues.name);
        formData.append('number_phone', formValues.number_phone);
        formData.append('email', formValues.email);
        formData.append('id_state', formValues.id_state);
        formData.append('id_municipality', formValues.id_municipality);
        formData.append('address', formValues.address);
        formData.append('colony', formValues.colony);
        formData.append('cp', formValues.cp);
        formData.append('url', formValues.url);
        formData.append('logo', formValues.logo);
        formData.append('name_responsible', formValues.name_responsible);
        formData.append('certificate', formValues.certificate);
        formData.append('image_firm', formValues.image_firm);
        formData.append('toma', formValues.toma ? 1 : 0);
        formData.append('background_image', formValues.background_image);
        formData.append('roleUser_responsible', formValues.roleUser_responsible);
        

        let requestOptions = {
            method: 'POST',
            body: formData
        };

        const respuesta = await sendRequest(requestOptions, "Branch/Create", 'multipart/form-data');

        if (respuesta.code === 200) {
            getBranch();
            reset();
            createSweet("create", "success");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            setLoading(false);

            createSweet("error", "error", "Sucursales", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);

            createSweet("error", "warning", "Sucursales", respuesta.data.msg);
        }
    }

    const handleChangeState = (e) => {
        handleInputChange(e);

        let mValue = e.target.value;

        getMunicipality(mValue);
    }

    const imageHandler = (e) => {
        handleInputChange(e);

        let name_input = e.target.name;
        const reader = new FileReader();

        if (name_input === "logo") {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setPreviewLogo(reader.result);
                }
            }
        }
        else if (name_input === "image_firm") {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setPreviewFirm(reader.result);
                }
            }
        }
        else if (name_input === "background_image") {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setPreviewBackground(reader.result);
                }
            }
        }

        if (e.target.files.length >= 1) {
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleUpdate = (id_branch) => {
        let branch = branches.find(b => b.id_branch === parseInt(id_branch));
      

        handleUpdateValues({
            id_commercial_line: branch.id_commercial_line,
            code: branch.code === null ? "" : branch.code,
            name: branch.name,
            number_phone: branch.number_phone === null ? '' : branch.number_phone,
            email: branch.email === null ? '' : branch.email,
            id_state: branch.id_state,
            id_municipality: branch.id_municipality === null ? 0 : branch.id_municipality,
            address: branch.address,
            colony: branch.colony === null ? "" : branch.colony,
            cp: branch.cp === null ? '' : branch.cp,
            url: branch.url === null ? '' : branch.url,
            name_responsible: branch.name_responsible,
            certificate: branch.certificate === null ? '' : branch.certificate,
            toma: branch.toma,
            roleUser_responsible:branch.roleUser_responsible
        });

        setImageLogo(branch.imgLogo);
        setImageFirm(branch.imgFirma);
        setImageBackground(branch.imgBackground)

        setValidaciones({
            id_commercial_line_valid: false,
            code_valid: false,
            name_valid: false,
            rfc_valid: false,
            number_phone: false,
            email_valid: false,
            id_state_valid: false,
            id_municipality_valid: false,
            address_valid: false,
            colony_valid: false,
            cp_valid: false,
            name_responsible_valid: false,
            certificate_valid: false,
            roleUser_responsible_valid:false
        });

        getState();
        getMunicipality(branch.id_state);
        getCommercialLines();
        setBranchId(id_branch);
        setMethod("update");
        setModalTitle("Modificar sucursal");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        var formData = new FormData();

        formData.append('id_branch', branchId);
        formData.append('id_commercial_line', formValues.id_commercial_line);
        formData.append('code', formValues.code);
        formData.append('name', formValues.name);
        formData.append('number_phone', formValues.number_phone);
        formData.append('email', formValues.email);
        formData.append('id_state', formValues.id_state);
        formData.append('id_municipality', formValues.id_municipality);
        formData.append('address', formValues.address);
        formData.append('colony', formValues.colony);
        formData.append('cp', formValues.cp);
        formData.append('url', formValues.url);
        formData.append('logo', formValues.logo);
        formData.append('name_responsible', formValues.name_responsible);
        formData.append('certificate', formValues.certificate);
        formData.append('image_firm', formValues.image_firm);
        formData.append('toma', formValues.toma);
        formData.append('background_image', formValues.background_image);
        formData.append('roleUser_responsible', formValues.roleUser_responsible);

        let requestOptions = {
            method: 'PUT',
            body: formData
        };

        const respuesta = await sendRequest(requestOptions, "Branch/Update", 'multipart/form-data');

        if (respuesta.code === 200) {
            getBranch();
            reset();
            createSweet("update", "success");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            setLoading(false);

            createSweet("error", "error", "Reportes", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);

            createSweet("error", "warning", "Reportes", respuesta.data.msg);
        }
    }

    const handleDelete = (id_branch) => {
        let branch = branches.find(b => b.id_branch === parseInt(id_branch));

        handleUpdateValues({
            name: branch.name,
            code: branch.code === null ? '' : branch.code
        });

        setBranchId(id_branch);
        setMethod("delete");
        setModalTitle("Eliminar Sucursal");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Branch/${branchId}`);

        if (respuesta.code === 200) {
            getBranch();
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false);

            createSweet("error", "error", "Sucursales", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);

            createSweet("error", "warning", "Sucursales", respuesta.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        const validacion = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

        if (formValues.name.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            }
        }

        if (parseInt(formValues.code) < 2) {
            newValidaciones = {
                ...newValidaciones,
                code_valid: false
            }
            statusValidacion = false;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                code_valid: false
            }
        }

        if (formValues.number_phone.length < 9) {
            newValidaciones = {
                ...newValidaciones,
                number_phone_valid: false
            }
            statusValidacion = false;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                number_phone_valid: false
            }
        }

        if (formValues.email.length < 2) {
            if (validacion.test(formValues.email)) {
                newValidaciones = {
                    ...newValidaciones,
                    email_valid: false
                }
            }
            else {
                newValidaciones = {
                    ...newValidaciones,
                    email_valid: true
                }
                statusValidacion = true;
            }
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                email_valid: false
            }
        }

        if (formValues.id_state === "") {
            newValidaciones = {
                ...newValidaciones,
                id_state_valid: true
            }

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_state_valid: false
            }
        }

        if (formValues.id_municipality === "") {
            newValidaciones = {
                ...newValidaciones,
                id_municipality_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_municipality_valid: false
            };
        }

        if (formValues.address.length < 5) {
            newValidaciones = {
                ...newValidaciones,
                address_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                address_valid: false
            };
        }

        if (formValues.colony.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                colony_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                colony_valid: true
            }
        }

        if (parseInt(formValues.cp) <= 0) {
            newValidaciones = {
                ...newValidaciones,
                cp_valid: false
            };
            statusValidacion = false;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                cp_valid: false
            };
        }

        if (formValues.name_responsible.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name_responsible_valid: true
            }
            statusValidacion = true;
            createSweet("Oops", "warning", "Sucursales", "Verifica los datos en la pestaña 'Responsable de resultados/Nombre del responsable'");
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_responsible_valid: false
            }
        }

        if (formValues.certificate.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                certificate_valid: true
            };
            statusValidacion = true;
            createSweet("Oops", "warning", "Sucursales", "Verifica los datos en la pestaña 'Responsable de resultados/Cédula profesional'");
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                certificate_valid: false
            };
        }
        if (formValues.roleUser_responsible.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                roleUser_responsible_valid: true
            };
            statusValidacion = true;
            createSweet("Oops", "warning", "Sucursales", "Verifica los datos en la pestaña 'Responsable de resultados/Cédula profesional'");
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                roleUser_responsible_valid: false
            };
        }

        if (formValues.id_commercial_line === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_commercial_line_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_commercial_line_valid: false
            };
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }

    const handleDataTable = () => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listBranchs = [];
        branches.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listBranchs.push({
                ...data,
                //  typeExam: data.is_simple ? 
                //datos mostrados de la misma liena
                visibleTable: visibleTable
            });
        });

        setBranches(listBranchs);
    }

    const [branchData, setBranchData] = useState([]);

    const handleSearchBranch = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        branchData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setBranches(newMethod);
    }

    const navigateExamsMaquila = (id_branch) => {
        history.push("../maquilaInterna/"+id_branch);
    }

    const navigateSettingsInvoice = (id_branch) => {
        history.push('../configuraciones/razon-social/configuracion-folios/'+id_branch);
    } 

    return {
        branches, states, municipalities, commercialLines,
        method, handleInputChange, validaciones,
        id_commercial_line, code, name, number_phone, email, id_state, id_municipality,
        address, colony, cp, url, logo, name_responsible, certificate, image_firm, toma,
        imageLogo, imageFirm, imageBackground,roleUser_responsible,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleChangeState,
        previewLogo, previewFirm, previewBackground, imageHandler,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleSearchBranch,
        navigateExamsMaquila, navigateSettingsInvoice
    }
}

