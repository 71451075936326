import React from 'react';
import { Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { CompleteInput, CompleteSelect, PlaceHolderOption, CompleteEmail } from '../../constant';
import Select from 'react-select';

export const InformationAgreement = (props) => {

    const {
        //FORM
        contract_number, validity, is_global, payment_periodicity, id_pay_method, id_financing_methods,
        credit_limit, percentage_credit_limit, id_use_cfdi,
        sales_name, sales_phone, sales_email, payment_name, payment_phone, payment_email, id_seller,
        observations, swift_code,

        //METHODS
        handleInputChangeA, handleSelectValuesA,

        //VALIDATIONS
        contract_number_valid, validity_valid, payment_periodicity_valid, id_pay_method_valid, id_financing_methods_valid,
        credit_limit_valid, percentage_credit_limit_valid, id_use_cfdi_valid, sales_name_valid,
        sales_phone_valid, sales_email_valid, payment_name_valid, payment_phone_valid, payment_email_valid, id_seller_valid,

        //INFORMATION COMPANY AND DOCTORS
        informationCompany,

        //LISTS
        listPayForms, listFinancingMethods, listUseCfdi, listSellers,

        //VALID VISIBILITY INPUTS
        visibility_inputs,

        //MICROSANITARY
        sample_periodicity, is_micro,

        on_check_sanitary, is_pay_for_compamy, required_invoice_company,
    } = props;

    const {
        view_contact_number,
        view_validity,
        view_is_global,
        view_payment_periodicity,
        view_id_pay_method,
        view_id_financing_methods,
        view_credit_limit,
        view_percentage_credit_limit,
        view_id_use_cfdi,
        view_checkSpecimenQuantity,
        view_quantity_sample,
        view_sales_div,
        view_payment_div,
        view_id_seller,
        view_observations,
        view_swift_code,
    } = visibility_inputs;

    return (
        <>            
                <Col sm="12" md="7" lg="7">
                    <Card className='ribbon-wrapper shadow p-t-0 p-b-0'>
                        <CardHeader className='bg-primary p-3'>
                            <h6>Datos del convenio</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row className='mt-1'>
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Información del convenio</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>No.Contrato <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type='text' className='form-control form-control-sm input-air-primary b-r-9' name="contract_number" value={contract_number} 
                                            onChange={handleInputChangeA} autoComplete='off' invalid={contract_number_valid} readOnly={!view_contact_number}
                                        />
                                        <FormFeedback>{ CompleteInput }</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Fecha de expiración <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type='date' className='form-control form-control-sm input-air-primary b-r-9' name="validity" value={validity} 
                                            onChange={handleInputChangeA} autoComplete='off' invalid={validity_valid} readOnly={!view_validity}
                                        />
                                        <FormFeedback>Ingresa una fecha valida</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup className='p-checkbox-icon'>
                                        <Label className='col-form-label'>¿Fijar como convenio principal? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type='select' className='form-control form-control-sm input-air-primary b-r-9 ' name="is_global" value={is_global} 
                                            onChange={handleInputChangeA} disabled={informationCompany.agreement_global} readOnly={!view_is_global}
                                        >
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </Input>
                                        <FormFeedback>{CompleteInput}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="4" lg="4" className="text-left">
                                    <Label className='col-form-label'>Convenio para micro sanitaria</Label>
                                    <br />
                                    <Label className='switch'>
                                        <Input type="checkbox" name="is_micro" checked={is_micro} onChange={on_check_sanitary}/>
                                        <span className='slider round'></span>
                                    </Label>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos financieros</span>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Periodicidad de corte <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="select" className='form-control form-control-sm input-air-primary b-r-9' name='payment_periodicity' value={payment_periodicity} 
                                            onChange={handleInputChangeA} invalid={payment_periodicity_valid} readOnly={!view_payment_periodicity}
                                        >
                                            <option value={7}>7 días</option>
                                            <option value={14}>14 días</option>
                                            <option value={21}>21 días</option>
                                            <option value={30}>30 días</option>
                                            <option value={60}>60 días</option>
                                        </Input>
                                        <FormFeedback>{CompleteSelect}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Forma de pago <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="select" className='form-control form-control-sm input-air-primary b-r-9' name='id_pay_method' value={id_pay_method} onChange={handleInputChangeA} invalid={id_pay_method_valid}>
                                            <option value={0}>{PlaceHolderOption}</option>
                                            {
                                                listPayForms.length > 0 && listPayForms.map(_payform => {
                                                    return <option value={_payform.id_pay_method} key={`payForm-${_payform.id_pay_method}`}>{_payform.abbreviation + " " + _payform.name}</option>
                                                })
                                            }
                                        </Input>
                                        <FormFeedback>{CompleteSelect}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Método financiero <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="select" className='form-control form-control-sm input-air-primary b-r-9' name='id_financing_methods' value={id_financing_methods} 
                                            onChange={handleInputChangeA} invalid={id_financing_methods_valid} readOnly={!view_id_financing_methods}
                                        >
                                            <option value={0}>{PlaceHolderOption}</option>
                                            {
                                                listFinancingMethods.length > 0 && listFinancingMethods.map(_financing => {
                                                    return <option value={_financing.id_financing_methods} key={`financing-${_financing.id_financing_methods}`}>
                                                        {_financing.name}
                                                    </option>
                                                })
                                            }
                                        </Input>
                                        <FormFeedback>{CompleteSelect}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    parseInt(id_financing_methods) === 3 && <Col sm="12" md="4" lg="4">
                                        <FormGroup>
                                            <Label className='col-form-label'>Monto de la linea de crédito</Label>
                                            <Input type="number" className='form-control form-control-sm input-air-primary b-r-9' name='credit_limit' value={credit_limit} onChange={handleInputChangeA} invalid={credit_limit_valid} readOnly={!view_credit_limit} />
                                            <FormFeedback>{CompleteInput}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    informationCompany.category === 2 && <Col sm="12" md="4" lg="4">
                                        <FormGroup>
                                            <Label className='col-form-label'>Porcentaje asignado</Label>
                                            <Input type="number" className='form-control form-control-sm input-air-primary b-r-9' name='percentage_credit_limit' value={percentage_credit_limit} 
                                                onChange={handleInputChangeA} invalid={percentage_credit_limit_valid} readOnly={!view_percentage_credit_limit}
                                            />
                                            <FormFeedback>{CompleteInput}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                }
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Uso de CFDI<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="select" className='form-control form-control-sm input-air-primary b-r-9' name='id_use_cfdi' value={id_use_cfdi} onChange={handleInputChangeA} invalid={id_use_cfdi_valid} readOnly={!view_id_use_cfdi}>
                                            <option value={0}>{PlaceHolderOption}</option>
                                            {
                                                listUseCfdi.length > 0 && listUseCfdi.map(_use => {
                                                    return <option value={_use.id_use_cfdi} key={`useCfdi-${_use.id_use_cfdi}`}>
                                                        {_use.code + " " + _use.description}
                                                    </option>
                                                })
                                            }
                                        </Input>
                                        <FormFeedback>{CompleteSelect}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                {   is_micro ?
                                        <FormGroup>
                                            <Label className='col-form-label'>Periodicidad de muestreo</Label>
                                            <Input type="select" className='form-control form-control-sm input-air-primary b-r-9' name='sample_periodicity' value={sample_periodicity} 
                                                onChange={handleInputChangeA}
                                            >
                                                <option value="semanal">Semanal</option>
                                                <option value="quincenal">Quincenal</option>
                                                <option value="mensual">Mensual</option>
                                                <option value="anual">Anual</option>
                                            </Input>
                                            <FormFeedback>{CompleteSelect}</FormFeedback>
                                        </FormGroup>                                    
                                    : ""
                                }
                                </Col>
                            </Row>
                            <Row className='mt-1' hidden={!view_sales_div}>
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Metodología de pagos y facturación</span>
                                </Col>
                            </Row>
                            <Row className='mt-1' hidden={!view_sales_div}>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup className='p-checkbox-icon'>
                                        <Label className='col-form-label'>¿Quien genera el pago? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type='select' className='form-control form-control-sm input-air-primary b-r-9 ' name="is_pay_for_compamy" value={is_pay_for_compamy} 
                                            onChange={handleInputChangeA}
                                        >
                                            <option value={true}>Empresa</option>
                                            <option value={false}>Paciente</option>
                                        </Input>
                                        <FormFeedback>{CompleteInput}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup className='p-checkbox-icon'>
                                        <Label className='col-form-label'>¿Recepción de factura? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type='select' className='form-control form-control-sm input-air-primary b-r-9 ' name="required_invoice_company" value={required_invoice_company} 
                                            onChange={handleInputChangeA}
                                        >
                                            <option value={true}>Empresa</option>
                                            <option value={false}>Paciente</option>
                                        </Input>
                                        <FormFeedback>{CompleteInput}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-1' hidden={!view_sales_div}>
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de contacto (Empresa)</span>
                                </Col>
                            </Row>
                            <Row className='mt-1' hidden={!view_sales_div}>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='sales_name' value={sales_name} autoComplete='off' onChange={handleInputChangeA} invalid={sales_name_valid} />
                                        <FormFeedback>Escribe mínimo 4 caracteres.</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Teléfono <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='sales_phone' value={sales_phone} autoComplete='off' onChange={handleInputChangeA} invalid={sales_phone_valid} />
                                        <FormFeedback>Teléfono a 10 dígitos</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Correo <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="email" className='form-control form-control-sm input-air-primary b-r-9' name='sales_email' value={sales_email} autoComplete='off' onChange={handleInputChangeA} invalid={sales_email_valid} />
                                        <FormFeedback>{CompleteEmail}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mt-1' hidden={!view_payment_div}>
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de contacto de pagos</span>
                                </Col>
                            </Row>
                            <Row className='mt-1' hidden={!view_payment_div}>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='payment_name' value={payment_name} autoComplete='off' onChange={handleInputChangeA} invalid={payment_name_valid} />
                                        <FormFeedback>Escribe mínimo 4 caracteres.</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Teléfono <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='payment_phone' value={payment_phone} autoComplete='off' onChange={handleInputChangeA} invalid={payment_phone_valid} />
                                        <FormFeedback>{CompleteInput}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Correo <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type="email" className='form-control form-control-sm input-air-primary b-r-9' name='payment_email' value={payment_email} autoComplete='off' onChange={handleInputChangeA} invalid={payment_email_valid} />
                                        <FormFeedback>{CompleteEmail}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="5" lg="5">
                                    <FormGroup>
                                        <Label className='col-form-label'>Vendedor <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Select
                                            name="id_seller"
                                            defaultValue={id_seller}
                                            value={id_seller}
                                            isClearable={true}
                                            placeholder="Selecciona una opción"
                                            options={listSellers}
                                            className='input-air-primary b-r-9 border-danger border-1'
                                            onChange={(e) => handleSelectValuesA(e, 'id_seller')}
                                            isDisabled={!view_id_seller}
                                        />
                                        <FormText color='danger' hidden={!id_seller_valid}>Selecciona un vendedor</FormText>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="7" lg="7">
                                    <FormGroup>
                                        <Label className='col-form-label'>Observaciones</Label>
                                        <Input type="textarea" className='form-control form-control-sm input-air-primary b-r-9' name='observations' value={observations} autoComplete='off' onChange={handleInputChangeA} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row hidden={!view_swift_code}>
                                <Col sm="12" md="5" lg="5">
                                    <FormGroup>
                                        <Label className='col-form-label'>Código Swift</Label>
                                        <Input type="text" className='form-control form-control-sm input-air-primary b-r-9' name='swift_code' value={swift_code} autoComplete='off' onChange={handleInputChangeA} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
        </>
    )
}
