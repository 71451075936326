import React from 'react';
import { ModalButtonCancel, LabelBranch, LabelCommercialLine, PlaceHolderOption } from '../../constant';
import { Form, Label, ModalBody, Row, Input, FormFeedback, Col, ModalFooter, Button, FormGroup } from 'reactstrap';
import '../../assets/css/toggleSwitch.css';

export const FormRates = (props) => {

    const {
        branches, commercialLines, typeRate, toggle, loading, handleInputChange, validacionesRates, methodAction, name, abbreviation, id_commercial_line, id_branch, id_type_rate, default_rate, toggleDivCommercialLine, viewDivBranches, button
    } = props;

    const {
        name_valid, abbreviation_valid, 
        id_commercial_line_valid,
        id_branch_valid,
        id_type_rate_valid
    } = validacionesRates;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Nombre <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="text"
                                name="name"
                                value={ name }
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={name_valid}
                            />
                            <FormFeedback>Nombre invalido</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Abreviatura <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="text"
                                name="abbreviation"
                                value={abbreviation}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={abbreviation_valid}
                            />
                            <FormFeedback>Debes completar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="9">
                            <Label className="col-form-label">Tarifario, predefinido para la razón social</Label> <b></b>
                        <FormGroup>
                            <Label></Label>
                            <Label className="switch">
                                <Input type="checkbox" onChange={ (e) => toggleDivCommercialLine(e) } name="default_rate" checked={default_rate}/>
                                <span className="slider round"></span>                            
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">{LabelCommercialLine}  <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input  
                                className="form-control form-control-sm input-air-primary" 
                                type="select" 
                                name="id_commercial_line" 
                                value={id_commercial_line} 
                                onChange={handleInputChange} 
                                invalid={id_commercial_line_valid}>
                                <option value={0}>{PlaceHolderOption}</option>
                                {
                                    commercialLines.length >= 1 && commercialLines.map((obj, key) => {
                                        return (
                                            <option key={key} value={obj.id_commercial_line}>{ obj.long_name }</option>
                                        )
                                    })
                                }
                            </Input>
                            <FormFeedback>Selecciona un elemento de la lista</FormFeedback>
                        </FormGroup>  
                    </Col>
                </Row>
                <Row className={ viewDivBranches ? "d-none" : "" }>
                    <Col md="7">
                        <FormGroup>
                            <Label className="col-form-label">{LabelBranch}</Label>
                            <Input 
                            className="form-control form-control-sm input-air-primary" 
                            type="select" 
                            name="id_branch" 
                            value={id_branch} 
                            onChange={handleInputChange} 
                            invalid={id_branch_valid}>
                                <option value={0}>{PlaceHolderOption}</option>
                                {
                                    branches.length >= 1 && branches.map((obj, key) => {
                                        return (
                                            <option key={key} value={obj.id_branch}>{ obj.code+" "+obj.name }</option>
                                        )
                                    })
                                }                      
                            </Input>
                            <FormFeedback>Selecciona un elemento de la lista</FormFeedback>
                        </FormGroup>                        
                    </Col>
                    <Col md="5">
                        <FormGroup>
                            <Label className="col-form-label">Tipo de tarifario</Label>
                            <Input 
                            className="form-control form-control-sm input-air-primary" 
                            type="select" 
                            name="id_type_rate" 
                            value={id_type_rate} 
                            onChange={handleInputChange} 
                            invalid={id_type_rate_valid}>
                                <option value={0}>{PlaceHolderOption}</option>
                                {
                                    typeRate.length >= 1 && typeRate.map((obj, key) => {
                                        return (
                                            <option key={key} value={obj.id_type_rate}>{ obj.name }</option>
                                        )
                                    })
                                }
                            </Input>
                            <FormFeedback>Selecciona un elemento de la lista</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" outline color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>{ ModalButtonCancel }</Button>
            </ModalFooter>
        </Form>
    )
}
