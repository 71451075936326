import React, { Fragment, useRef, useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import esLocale from '@fullcalendar/core/locales/es';
import { validarSesion } from '../../../hooks/tokens/useToken'
import { sendRequest } from '../../../hooks/requests/useRequest'
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert'
import { useHistory } from 'react-router-dom'

export const IndexMicDiary = () => {
    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const calendario = useRef(null);
    const [info, setInfo] = useState(null)
    const [samplings, setSamplings] = useState([])

    useEffect(() => {
        setTimeout(() => {
            calendario.current.getApi().updateSize()
        }, 1000);
        return true
    }, [])

    const prueba = (info) => {
        let quoter = samplings.find(x => x.id === info.event.id);
        setInfo({
            ...quoter
        })
    }

    const navegarMuestreo = () => {
        // //console.log("Este es el id", info);
        history.push("../sanitaria/muestreo/" + info.id);
    }

    const cancelarMuestreo = () => {
        setInfo(null);
    }

    const getCotizaciones = async (info) => {

        let date1 = info.startStr.slice(0, 10);
        let date2 = info.endStr.slice(0, 10);

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `QuoterDeterminations/ListQuoter/${date1}/${date2}`);

        if (respuesta.code === 200) {
            let lista = [];
            respuesta.data.forEach(element => {
                let nuevo = {
                    title: element.code,
                    date: element.date_sampling,
                    id: element.code,
                    ...element
                };
                lista.push(nuevo);
            });
            //console.log(lista);
            setSamplings([...lista])
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCotizaciones);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Agenda", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Agenda", respuesta.data.msg);
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <br /><br />
                <Row>
                    {/* <Col sm="12"> */}
                    <Col sm="12" md="12" lg="8" xl="9">
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            initialView="dayGridMonth"
                            nowIndicator={true}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay' // user can switch between the two
                            }}
                            locale={esLocale}
                            events={samplings}
                            aspectRatio={2}
                            ref={calendario}
                            timeZone={'UTC'}
                            eventClick={prueba}
                            datesSet={(e) => getCotizaciones(e)}
                        />
                    </Col>
                    {/* <Col > */}
                    <Col sm="12" md="12" lg="4" xl="3"  >
                        <Card>
                            <CardHeader className='bg-secondary p-3'>
                                <h5 className="mb-0">
                                    <label className='labelNim'>Información de muestreo</label>
                                </h5>
                            </CardHeader>
                            {
                                info === null ?
                                    <CardBody className="p-3">
                                        <p>DATOS GENERALES</p>
                                        <ul className='list-group'>
                                            <li className='list-group-item'><i className="fa fa-building"></i> Compañia: </li>
                                            <li className='list-group-item'><i className="fa fa-phone"></i> Telefono: </li>
                                            <li className='list-group-item'><i className="fa fa-map-marker"></i> Dirección: </li>
                                        </ul>
                                        <hr />
                                        <p>DATOS DEL MUESTREO</p>
                                        <ul className='list-group'>
                                            <li className='list-group-item'><i className="fa fa-rub"></i> No. Muestras: </li>
                                        </ul>
                                    </CardBody>
                                    :
                                    <CardBody className="p-3">
                                        <p>DATOS GENERALES</p>
                                        <ul className='list-group'>
                                            <li className='list-group-item'><i className="fa fa-building"></i> Compañia: {info.name_company} </li>
                                            <li className='list-group-item'><i className="fa fa-phone"></i> Telefono: {info.phone}</li>
                                            <li className='list-group-item'><i className="fa fa-map-marker"></i> Dirección: {info.address + "," + info.colony + "," + info.cp}</li>
                                        </ul>
                                        <hr />
                                        <p>DATOS DEL MUESTREO</p>
                                        <ul className='list-group'>
                                            <li className='list-group-item'><i className="fa fa-rub"></i> No. Muestras: {info.no_samples}</li>
                                        </ul>
                                        <hr />
                                        <button type='button' onClick={navegarMuestreo} className='btn btn-block btn-primary'>INICIAR MUESTREO</button>
                                        <button type='button' onClick={cancelarMuestreo} className='btn btn-block btn-secondary'>CANCELAR</button>
                                    </CardBody>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    )
}
