import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { usePagination } from '../pagination/usePagination';

export const useAgreement = () => {

    //#region GLOBAL VARIABLES
        const history = useHistory();
        let id_commercial_line_l = localStorage.getItem('commercialLineId');
        let id_branch = localStorage.getItem('branchId');
    //#endregion
    
    //#region MODALS
        const [loading, setLoading] = useState(false);

        const [modal, setModal] = useState(false);
        const [modal2, setModal2] = useState(false);
        const [modal3, setModal3] = useState(false);

        const [modalTitle, setModalTitle] = useState("");
        const [modalTitle1, setModalTitle1] = useState("");
        
        const [method, setMethod] = useState("");

        const toggle2 = () => {
            setModal2(!modal2)
        }
    
        const toggle3 = () => {
            setModal3(!modal3)
        }

        const toggle = () => {
            setModal(!modal);
        }

    //#endregion

    //#region DISPLAY MESSAGES
        const [createSweet] = useSweetAlert();
    //#endregion    

    //#region AGREEMENTS 

        //#region MODELS
            //LIST
                const [agreements, setAgreements] = useState([]);
                const [listAgreementsSearch, setListAgreementsSearch] = useState([]);
                const [infoData, setInfoData] = useState([])

            //MODEL
                const [agreementId, setAgreementId] = useState(0);
                const [numberItems, setNumberItems] = useState(8);
                
            //FORM
                const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
                    name_company: "",
                    contract_number: "",
                    validity: "",
                    status: false,
                    percentage_credit_limit:0,
                    credit_limit:0
                });
        
                const { name_company, contract_number, validity, status,percentage_credit_limit ,credit_limit} = formValues;
                
        //#endregion

        //#region USE EFFECTS
            useEffect(() => {

                getAgreements();
            
            }, []);
        //#endregion

        //#region METHODS

            //#region NAVIGATEMETHODS

                const navigateNewAgreement = () => {
                    history.push("../convenios/nuevo-convenio");
                }

                const navigateUpdate = (id_agreement) => {
                    history.push(`../convenios/${id_agreement}/editar-convenio`);
                }
            //#endregion

            //#region GET METHODS
                const getAgreements = async () => {

                    let requestOptions = {
                        method: 'GET'
                    };

                    let newAgreements = [];

                    const respuesta = await sendRequest(requestOptions, "Agreement/List");

                    if (respuesta.code === 200) {

                        if (respuesta.data.length >= 1) {

                            let _startItems = 0;
                            let _endItems = numberItems;
                            let _aux = numberItems * currentPage;

                            _endItems = _aux;
                            _startItems = _endItems - numberItems;

                            respuesta.data.forEach((obj, _keyAgreement) => {

                                let _position = _keyAgreement + 1;
                                let _visibleTable = false;

                                if (_position > _startItems && _position <= _endItems) {
                                    _visibleTable = true;
                                }

                                newAgreements.push({
                                    id_agreement: obj.id_agreement,
                                    id_commercial_line: obj.id_commercial_line,
                                    id_company: obj.id_company,
                                    id_doctor: obj.id_doctor,                                                                  
                                    name_company: obj.name_company,
                                    name_doctor: obj.name_doctor,
                                    contract_number: obj.contract_number,
                                    validity: obj.validity,
                                    validityAgreement: obj.validAgreement,                                    
                                    status: obj.status,
                                    name_pay_method: obj.name_pay_method,
                                    financing_method_name: obj.financing_method_name,
                                    category: obj.category,
                                    percentage_credit_limit: obj.percentage_credit_limit,
                                    credit_limit: obj.credit_limit,
                                    visibleTable: _visibleTable                     
                                });
                            });

                            setAgreements(newAgreements);
                            setListAgreementsSearch(newAgreements);
                        }
                        else {
                            setAgreements([]);
                            setListAgreementsSearch([]);
                        }
                    }
                    else if (respuesta.code === 401) {
                        validarSesion(history, respuesta.code, getAgreements);
                    }
                    else if(respuesta.code === 500){
                        createSweet("error", "error", "Convenios", "Ocurrio un error en el servidor");
                    }
                    else{
                        createSweet("error", "warning", "Convenios", respuesta.data.msg);
                    }
                }
            //#endregion

            //#region HANDLE METHDOS
                const handleDelete = (id_agreement) => {
                    let agreement = agreements.find(a => a.id_agreement === id_agreement);

                    handleUpdateValues({
                        name_company: agreement.name_company,
                        contract_number: agreement.contract_number,
                        validity: agreement.validity
                    });

                    setAgreementId(id_agreement);
                    setMethod("delete");
                    setModalTitle("Eliminar Convenio");
                    toggle();
                }

                const handleListProfile = async (id_agreement) => {

                    let requestOptions = {
                        method: 'GET'
                    };

                    const respuesta = await sendRequest(requestOptions, `Agreement/GetAgreementId/${id_agreement}/${id_branch}/${id_commercial_line_l}`);
                    if (respuesta.code === 200) {
                    setInfoData(respuesta.data)
                    }
                
                    setMethod("list");
                    setModalTitle("Examenes");
                    toggle2();
                }

                const handleUpdateCategory = (id_agreement) => {

                    let agreement = listAgreementsSearch.find(a => a.id_agreement === id_agreement);

                    handleUpdateValues({
                        percentage_credit_limit: agreement.percentage_credit_limit,
                        credit_limit: agreement.credit_limit
                    });

                    setAgreementId(id_agreement);

                    setMethod("list");
                    setModalTitle1("Limite fijo con tolerancia");
                    toggle3();
                }

                const handleUpdateStatus = (id_agreement) => {
                    let agreement = listAgreementsSearch.find(a => a.id_agreement === parseInt(id_agreement));
            
                    handleUpdateValues({
                        name_company: agreement.name_company,
                        contract_number: agreement.contract_number,
                        validity: agreement.validity,
                        status: agreement.status
                    });
            
                    setAgreementId(id_agreement);
                    setMethod("updateStatus");
                    setModalTitle("Cambiar estado del convenio");
                    toggle();
                }

                const handleSearchAgreement = (e) => {
                    let _search = e.target.value;
                    let _newList = [];

                    listAgreementsSearch.forEach(_agreement => {
                        let _findNameCopany = _agreement.id_company !== null ?  _agreement.name_company.toUpperCase() : "";
                        let _findNameDoctor = _agreement.id_doctor !== null ? _agreement.name_doctor.toUpperCase() : "";

                        if ((_findNameCopany.indexOf(_search.toUpperCase()) > -1) || (_findNameDoctor.indexOf(_search.toUpperCase()) > -1) ) {
                            _agreement.visibleTable = true;
                            _newList.push(_agreement);
                        }
                    });

                    setAgreements(_newList);
                }
            //#endregion

            //#region METHODS POST & PUT

            const updateCategoryLimit = async (e) =>{

                e.preventDefault();

                let agreement = listAgreementsSearch.find(a => a.id_agreement === agreementId);

                let body = {

                    id_company: agreement.id_company,
                    category: agreement.category,
                    id_agreement: agreementId,
                    percentage_credit_limit: formValues.percentage_credit_limit,
                    credit_limit: formValues.credit_limit
                }

                let requestOptions = {
                    method: 'PUT',
                    body: JSON.stringify(body)
                };

                const respuesta = await sendRequest(requestOptions, "Agreement/UpdateCategoryLimitCreditAgreement");
                
                if (respuesta.code == 200) {

                    createSweet("success", "success", "Se actualizo correctamente");
                    toggle3();

                    agreement.percentage_credit_limit = formValues.percentage_credit_limit;
                    agreement.credit_limit = formValues.credit_limit;

                    setAgreements(
                        agreements,
                        {...agreement}
                    );
                }
                else if (respuesta.code === 401) {
                    validarSesion(history, respuesta.code, updateCategoryLimit);
                }
                else if(respuesta.code === 500){
                    createSweet("error", "error", "Convenios", "Ocurrio un error en el servidor");
                }
                else{
                    createSweet("error", "warning", "Convenios", respuesta.data.msg);
                }
            }

            const saveDelete = async (e) => {
                e.preventDefault();

                let requestOptions = {
                    method: 'DELETE'
                };

                setLoading(true);

                const respuesta = await sendRequest(requestOptions, `Agreement/${agreementId}`);

                if (respuesta.code === 200) {
                    getAgreements();
                    setLoading(false);
                    createSweet("create", "info", "Convenio eliminado");
                    toggle();
                }
                else if (respuesta.code === 401) {
                    validarSesion(history, respuesta.code, saveDelete);
                    setLoading(false);
                }
                else if(respuesta.code === 500){
                    createSweet("error", "error", "Convenios", "Ocurrio un error en el servidor");
                    setLoading(false);
                }
                else{
                    createSweet("error", "warning", "Convenios", respuesta.data.msg);
                    setLoading(false)
                }
            }

            const saveUpdateStatus = async (e) => {

                e.preventDefault();

                setLoading(true);

                let data = {
                    id_agreement: agreementId,
                    status: !formValues.status
                };

                let mValues = JSON.stringify(data);

                let requestOptions = {
                    method: 'PUT',
                    body: mValues
                };

                const respuesta = await sendRequest(requestOptions, `Agreement/UpdateStatus`);

                if (respuesta.code === 200) {
                    createSweet("success", "success", "Se cambio el status del convenio");
                    toggle();
                    setLoading(false);
                    getAgreements();
                }
                else if (respuesta.code === 401) {
                    validarSesion(history, respuesta.code, saveUpdateStatus);
                    setLoading(false);
                }
                else if(respuesta.code === 500){
                    createSweet("error", "error", "Convenios", "Ocurrio un error en el servidor");
                    setLoading(false);
                }
                else{
                    createSweet("error", "warning", "Convenios", respuesta.data.msg);
                    setLoading(false)
                }
            }
        //#endregion
            
            //#region PAGINATION
                const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(agreements.length, numberItems);

                useEffect(() => {
                    let _startItems = 0;
                    let _endItems = numberItems;

                    let _aux = numberItems * currentPage;

                    _endItems = _aux;

                    _startItems = _endItems - numberItems;

                    let _list_tmp = [];

                    agreements.forEach((_agreement, _keyAgreement) => {
                        let _position = _keyAgreement + 1;
                        let _visibleTable = false;

                        if (_position > _startItems && _position <= _endItems) {
                            _visibleTable = true;
                        }

                        _list_tmp.push({
                            ..._agreement,
                            visibleTable: _visibleTable
                        });
                    });

                    setAgreements(_list_tmp);

                }, [totalPageCount, currentPage]);
                
            //#endregion
        //#endregion

    //#endregion

    return {
        modal, modalTitle, toggle, method, loading,
        name_company, contract_number, validity, status,
        navigateNewAgreement, navigateUpdate, agreements,
        handleDelete, saveDelete,
        handleUpdateStatus, saveUpdateStatus,handleListProfile,
        toggle2,modal2,infoData,
        toggle3,modal3,updateCategoryLimit,modalTitle1,
        percentage_credit_limit,handleInputChange,handleUpdateCategory,credit_limit,

        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearchAgreement
    }
}
