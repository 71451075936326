import React, { Fragment } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Input, Label, Progress, Row, Table } from 'reactstrap'
import Chart from 'react-apexcharts'
import { useReportCxc } from '../../../hooks/reports/useReportCxc'
import ReactApexChart from 'react-apexcharts'
import './styleReport.css';



export const IndexReportCxc = () => {

    const {
        loadingTotalIngress, loadingOutsideBalance, loadingIngressInvoiced,
        loadingIngressPending, loadingDiscount, loadingClients,
        loadingExams, loadingProfiles, loadingTableClients, loadingExpiredWallet,

        lblTotalIngress, lblOutSideBalance, lblIngreessInvoice, lblIngressPendiing,
        lblDiscount, lblTotalClients, lblTotalExams, lblTotalProfiles, 
        lblCurrentTotalInvoices, lblCurrentPaymentMonth, lblTotalInvoicesNextMonth,
        lblCurrentPaidInvoices, lblPercentageCompliance,
        lblCurrentTotalBilling, lblCurrentPaymentBilling, lblCurrentPendingPaymentBilling,
        lblBeforeTotalBilling, lblBeforePaymentBilling, lblBeforePendingPaymentBilling,
        lblAfterTotalBilling, lblAfterPaymentBilling, lblAfterPendingPaymentBilling,

        viewBeforeMonth, viewCurrentMonth, viewAfterMonth,

        detailTransactionsByClients, listDetailTransactionsByClients, listIndicatorsDetail,

        lblPercentageInvoiced, lblPendingPercentageInvoiced, lblTotalOrders,

        dateFilter, setDateFilter,

        //CHARTS
        ChartAgePayable, ChartPaymentConditions, ChartPaymentConditionsTable,

        handleFilterByClient, handleFilterDate, number_format,
        handleCleanFilterByClient, handleViewTransactions,
        handleFilterTransactions,

        MakeExcelTransactionMontly,

        // * FILTERS
        filterCredit, setFilterCredit,
        filterAccount, setFilterAccount,
        filterPayment, setFilterPayment,
        filterPendingPayment, setFilterPendingPayment,
        filterInvoiced, setFilterInvoiced,
        filterPendigInvoiced, setFilterPendigInvoiced,
        filterPreInvoice, setFilterPreInvoice,
        filterPpd, setFilterPpd,
        filterPue, setFilterPue,
        filterOutValidity, 
        filterCurrent, 
        filterPaid, 
        filterViewAll, 
    } = useReportCxc();

    return (
        <Fragment>
            {
                // * HEADER FILTERS
            }
            <Row>
                <Col sm="12" md="6" lg="12">
                    <Card>
                        <CardBody className='p-3'>
                            <Row>
                                <Col sm="6" md="6" lg="5" className='text-center f-s-italic text-info p-2'>
                                 
                                    <h5 className='f-14 f-w-700'>Reporte cuentas por cobrar - Empresas </h5>
                                </Col>
                                <Col sm="3" md="3" lg="3" className='d-flex'>
                                    <Input type='month' value={dateFilter} className='border-0 mr-2' onChange={(e) => {
                                        setDateFilter(e.target.value)
                                    }} />
                                </Col>
                                <Col sm="3" md="3" lg="2" className='d-flex'>
                                    <Button color='info' outline onClick={handleFilterDate}>Filtrar</Button>
                                </Col>
                                <Col sm="3" md="3" lg="2" className='d-flex'>
                                    <Button color='danger' outline onClick={handleCleanFilterByClient}>Limpiar</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                // * END HEADER FILTERS
            }
            {
                // * HEADER CARDS
            }
            <Row>
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Ingresos Mensuales (Admisiones)</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingTotalIngress && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingTotalIngress && <h4 className='text-success'><i className="fa fa-usd"></i> {lblTotalIngress}</h4>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Saldo Pendiente Mensual (Admisiones)</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingOutsideBalance && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingOutsideBalance && <h4 className='text-warning'><i className="icofont icofont-cur-dollar-minus"></i> {lblOutSideBalance}</h4>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                {
                /* <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Porcentage Facturado</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center'>
                                {
                                    loadingIngressInvoiced && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingIngressInvoiced && <h4 className={lblPercentageInvoiced < 40 ? "text-danger" : lblPercentageInvoiced > 40 && lblPercentageInvoiced < 80 ? "text-warning" : "text-success"}>
                                        {lblPercentageInvoiced}<i className="fa fa-percent"></i>
                                    </h4>
                                }
                                {
                                    !loadingOutsideBalance && <small>Correspondiente a: <b>{lblIngreessInvoice}</b> de <b>{lblTotalOrders}</b></small>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Pendiente por facturar</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center'>
                                {
                                    loadingIngressPending && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingIngressInvoiced && <h4 className='text-warning'>{lblPendingPercentageInvoiced} <i className="fa fa-percent"></i></h4>
                                }
                                {
                                    !loadingOutsideBalance && <small>Correspondiente a: <b>{lblIngressPendiing}</b> de <b>{lblTotalOrders}</b></small>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col> */
                }
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'># Clientes</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingClients && <h4 className='text-info'><i className="fa fa-building-o"></i> {lblTotalClients}</h4>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Facturas para el siguiente mes</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center widgets-i widgets-ind'>
                                {
                                    loadingDiscount &&   <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    // !loadingDiscount && <h4 className='text-danger'><i className="fa fa-long-arrow-down"></i> <i className="fa fa-usd"></i> {number_format(lblDiscount, 2)}</h4>
                                    !loadingDiscount && <h4 className='text-danger'><i className="fa fa-angle-double-right"></i> {lblTotalInvoicesNextMonth}</h4>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>                                
            </Row>
            <Row className='mt-2'>                
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Facturas emitidas</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingIngressInvoiced && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingIngressInvoiced && <h4>
                                       <i className="fa fa-file-pdf-o text-success"></i> {lblCurrentTotalInvoices}
                                    </h4>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Facturas para cobrar este mes</h5>
                        </CardHeader>
                        <CardBody>
                  

                            <Col sm="12" className='text-center widgets-ind' style={{textAlign: "center"}}>
                                {
                                    loadingProfiles &&  <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    // !loadingProfiles && <h4 className='text-primary'><i className="icofont icofont-paperclip"></i> {lblTotalProfiles}</h4>
                                    !loadingProfiles && <h4 className='text-primary'><i className="icofont icofont-money-bag"></i> {lblCurrentPaymentMonth}</h4>
                                }
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            {/* <h5 className='card-title f-14'>Facturas que se debian de pagar en este mes y se pagaron</h5> */}
                            <h5 className='card-title f-14'>Facturas actualmente pagadas</h5>

                        </CardHeader>
                        <CardBody>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingExams && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    // !loadingExams && <h4 className='text-info'><i className="fa fa-flask"></i> {lblTotalExams}</h4>
                                    !loadingExams && <h4 className='text-info'><i className="icofont icofont-coins"></i> {lblCurrentPaidInvoices}</h4>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>                
                <Col sm="12" md="6" lg="3">
                    <Card className='border-1'>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Porcentaje de cumplimiento</h5>
                        </CardHeader>
                        <CardBody>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingIngressPending && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    // !loadingIngressInvoiced && <h4 className='text-warning'>{lblPendingPercentageInvoiced} <i className="fa fa-percent"></i></h4>
                                    !loadingIngressInvoiced && <h4 className='text-warning'>{lblPercentageCompliance} <i className="fa fa-percent"></i></h4>
                                }
                                {
                                    //  !loadingOutsideBalance && <small>Correspondiente a: <b>{lblIngressPendiing}</b> de <b>{lblTotalOrders}</b></small>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                // * END CARDS
            }
            <Row className='mt-2'>
                <Col sm="12" md="12" lg="12">
                    <Card>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'> Indicadores de Facturación</h5>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col sm="12" md="12" lg="12" id='chart'>
                    <Card>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Antiguedad de saldos</h5>
                        </CardHeader>
                        <CardBody className='p-1 text-center '>
                            {
                                loadingExpiredWallet && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                            }
                            {
                                !loadingExpiredWallet && <center>
                                    <Chart options={ChartAgePayable?.options} series={ChartAgePayable?.series} type="bar" height={100} width={"100%"} />
                                </center>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                // * AMOUNT LABEL'S
            }
            <Row className='mt-2'>
                <Col sm="12" md="6" lg="6">
                    <Card>
                        <CardHeader className='text-center p-1 bg-info'>
                            <h6 className='pt-1'>Facturado Mes anterior</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Facturado mes anterior</b>
                                    <h5>${ number_format(lblBeforeTotalBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pagado mes anterior</b>
                                    <h5>${ number_format(lblBeforePaymentBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='b-r-1 text-center'>
                                    <b>Saldo pendiente mes anterior</b>
                                    <h5>${ number_format(lblBeforePendingPaymentBilling, 2)}</h5>
                                </Col>                            
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="6">
                    <Card>
                        <CardHeader className='text-center p-1 bg-success'>
                            <h6 className='pt-1'>Facturado Mes actual</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Facturado</b>
                                    <h5>${ number_format(lblCurrentTotalBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pagado</b>
                                    <h5>${ number_format(lblCurrentPaymentBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pendiente</b>
                                    <h5>${ number_format(lblCurrentPendingPaymentBilling, 2)}</h5>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col >
            </Row>
            {
                // * END AMOUNT LABEL'S
            }
            <Row className='mt-2'>
                <Col sm="12" md="12" lg="12">
                    <Card className='border-1'>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <Row className='mt-2'>
                                        <Col sm="6" md="6" lg="6">
                                            <ButtonGroup className='p-2'>
                                                <Button outline={!viewBeforeMonth} onClick={() => handleViewTransactions(true, false, false)}><i className="fa fa-angle-double-left"></i> Anterior Més</Button>
                                                <Button outline={!viewCurrentMonth} onClick={() => handleViewTransactions(false, true, false)}>Més actual</Button>
                                                <Button outline={!viewAfterMonth} onClick={() => handleViewTransactions(false, false, true)}>Siguiente Més <i className="fa fa-angle-double-right"></i></Button>
                                            </ButtonGroup>
                                        </Col>
                                        <Col sm="6" md="6" lg="6" className='text-right'>
                                            <Label type="text" className={`f-w-600 f-12 badge badge${filterViewAll ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(true, false, false, false) }} >Todo</Label>
                                            <Label type="text" className={`f-w-600 f-12 badge badge${filterOutValidity ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, true, false, false) }} >Vencida</Label>
                                            <Label type="text" className={`f-w-600 f-12 badge badge${filterPaid ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, false, false, true) }} >Pagada</Label>
                                            <Label type="text" className={`f-w-600 f-12 badge badge${filterCurrent ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, false, true, false) }} >Vigente</Label>
                                            <Label type="text" className={`f-w-600 f-12 badge badge-success`} style={{ cursor: "pointer" }} onClick={() => { MakeExcelTransactionMontly(viewBeforeMonth, viewCurrentMonth, filterViewAll, filterOutValidity, filterPaid, filterCurrent) }} >Descargar excel</Label>
                                        </Col>
                                    </Row>
                                    <Card className='p-2' hidden={!viewBeforeMonth}>                                        
                                        <CardBody className='p-2' style={{ height: '440px', overflowY: "scroll"}}>
                                            <Table className='b-r-5 table-striped table-hover table-xs' >
                                                <thead className='b-r-5'>
                                                    <tr style={{ backgroundColor: "#033d5b" }}>
                                                        <th className='labeltheadInvoice'>#</th>
                                                        <th className='labeltheadInvoice'>Fecha emisión</th>
                                                        <th className='labeltheadInvoice'>N° factura</th>
                                                        <th className='labeltheadInvoice'>Cliente</th>
                                                        <th className='labeltheadInvoice'>Monto facturado</th>
                                                        <th className='labeltheadInvoice'>Días de crédito</th>
                                                        <th className='labeltheadInvoice'>F. de Vencimiento</th>
                                                        <th className='labeltheadInvoice'>Estatus</th>
                                                        <th className='labeltheadInvoice'>F. de Pago</th>
                                                        <th className='labeltheadInvoice'>Complemento</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        loadingTableClients && <tr>
                                                            <td colSpan={13} className='text-center'>
                                                                <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {
                                                        listIndicatorsDetail?.length > 0 &&
                                                        listIndicatorsDetail?.filter(_invoice => {
                                                            if (_invoice?.beforeMonth) {
                                                                if (filterOutValidity && _invoice?.status === "VENCIDA") {
                                                                    return _invoice;
                                                                }
                                                                else if (filterPaid && _invoice?.status === "PAGADA") {
                                                                    return _invoice;
                                                                }
                                                                else if (filterCurrent && _invoice?.status === "VIGENTE") {
                                                                    return _invoice;
                                                                }
                                                                else if (filterViewAll) {
                                                                    return _invoice;
                                                                }
                                                            }
                                                        })
                                                            ?.map((_invoice, _index) => {
                                                                return <tr key={_invoice?.idInvoice} className={ _invoice?.isStamp ? "f-w-700" : "f-w-500" }>
                                                                    <td>{_index + 1}</td>
                                                                    <td>{_invoice?.dateStampInvoice}</td>
                                                                    <td>{_invoice?.serie ?? ""} {_invoice?.folio ?? ""}</td>
                                                                    <td>{_invoice?.nameClient}</td>
                                                                    <td>$ {number_format(_invoice?.totalInvoice, 2)}</td>
                                                                    <td>{_invoice?.creditDays}</td>
                                                                    <td>{_invoice?.validityDate}</td>
                                                                    <td>
                                                                        <Label type="text" className={`f-w-600 f-12 badge badge-${_invoice?.status === "PAGADA" ? 'success' : _invoice?.status === "VIGENTE" ? "secondary" : "danger"}`}>
                                                                            {_invoice?.status}
                                                                        </Label>
                                                                    </td>
                                                                    <td>{_invoice?.paymentDate ?? ""}</td>
                                                                    <td>{_invoice?.idComplement ?? "---"}</td>
                                                                </tr>
                                                            })
                                                    }
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                    <Card className='p-2' hidden={!viewCurrentMonth}>
                                        <CardBody className='p-2' style={{ height: '440px', overflowY: "scroll"}}>
                                            <Table className='b-r-5 table-striped table-hover table-xs'>
                                                <thead className='b-r-5'>
                                                    <tr style={{ backgroundColor: "#033d5b" }}>
                                                        <th className='labeltheadInvoice'>#</th>
                                                        <th className='labeltheadInvoice'>Fecha emisión</th>
                                                        <th className='labeltheadInvoice'>N° factura</th>
                                                        <th className='labeltheadInvoice'>Cliente</th>
                                                        <th className='labeltheadInvoice'>Monto facturado</th>
                                                        <th className='labeltheadInvoice'>Días de crédito</th>
                                                        <th className='labeltheadInvoice'>F. de Vencimiento</th>
                                                        <th className='labeltheadInvoice'>Estatus</th>
                                                        <th className='labeltheadInvoice'>F. de Pago</th>
                                                        <th className='labeltheadInvoice'>Complemento</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        loadingTableClients && <tr>
                                                            <td colSpan={13} className='text-center'>
                                                                <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {
                                                        listIndicatorsDetail?.length > 0 &&
                                                        listIndicatorsDetail?.filter(_invoice => {
                                                            if (_invoice?.currentMonth) {
                                                                if (filterOutValidity && _invoice?.status == "VENCIDA") {
                                                                    return _invoice;
                                                                }
                                                                else if (filterPaid && _invoice?.status == "PAGADA") {
                                                                    return _invoice;
                                                                }
                                                                else if (filterCurrent && _invoice?.status == "VIGENTE") {
                                                                    return _invoice;
                                                                }
                                                                else if (filterViewAll) {
                                                                    return _invoice;
                                                                }
                                                            }
                                                        })
                                                            ?.map((_invoice, _index) => {
                                                                return <tr key={_invoice?.idInvoice} className={ _invoice?.isStamp ? "f-w-700" : "f-w-500" }>
                                                                    <td>{_index + 1}</td>
                                                                    <td>{_invoice?.dateStampInvoice}</td>
                                                                    <td>{_invoice?.serie ?? ""} {_invoice?.folio ?? ""}</td>
                                                                    <td>{_invoice?.nameClient}</td>
                                                                    <td>$ {number_format(_invoice?.totalInvoice, 2)}</td>
                                                                    <td>{_invoice?.creditDays}</td>
                                                                    <td>{_invoice?.validityDate}</td>
                                                                    <td>
                                                                        <Label type="text" className={`f-w-600 f-12 badge badge-${_invoice?.status === "PAGADA" ? 'success' : _invoice?.status === "VIGENTE" ? "secondary" : "danger"}`}>
                                                                            {_invoice?.status}
                                                                        </Label>
                                                                    </td>
                                                                    <td>{_invoice?.paymentDate ?? ""}</td>
                                                                    <td>{_invoice?.idComplement ?? "---"}</td>
                                                                </tr>
                                                            })
                                                    }
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col sm="12" md="6" lg="4" id='chart'>
                    <Card>
                        <CardBody>
                            <ReactApexChart options={ChartPaymentConditions?.options} series={ChartPaymentConditions?.series} type='donut' height={380} width={450} />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="8">
                    <Card className='border-1' style={{ height: '440px', overflowY: "scroll", scrollBehavior: "smooth" }}>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Reporte de condiciones de pago</h5>
                        </CardHeader>
                        <CardBody className='p-2'>                            
                            <Table className='b-r-5 table-striped table-hover'>
                                <thead className='b-r-5'>
                                    <tr style={{ backgroundColor: "#033d5b" }}>
                                        <th className='labeltheadInvoice'>#</th>
                                        <th className='labeltheadInvoice'>Cliente</th>
                                        <th className='labeltheadInvoice'>Crédito</th>
                                        <th className='labeltheadInvoice'>Contado</th>
                                        <th className='labeltheadInvoice'>Descuento aplicado</th>
                                        <th className='labeltheadInvoice'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loadingTableClients && <tr>
                                            <td colSpan={7} className='text-center'>
                                                <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        detailTransactionsByClients?.length > 0 && detailTransactionsByClients?.map((_orders, _indexOrder) => (
                                            <tr key={_orders?.id_client} style={{ cursor: "pointer" }} onClick={() => handleFilterByClient(_orders?.id_client)}>
                                                <td>
                                                    <b>{_indexOrder + 1}</b>
                                                </td>
                                                <td>
                                                    <b><i className="icofont icofont-building-alt"></i> {_orders?.name_client ?? ""}</b>
                                                </td>
                                                <td className='text-warning'>
                                                    <b>${number_format((_orders?.totalCredit ?? 0), 2)}</b>
                                                </td>
                                                <td className='text-success'>
                                                    <b>${number_format((_orders?.totalCash ?? 0), 2)}</b>
                                                </td>
                                                <td>
                                                    <b>${number_format((_orders?.totalDiscount ?? 0), 2)}</b>
                                                </td>
                                                <td className='text-center'>
                                                    <ReactApexChart options={ChartPaymentConditionsTable?.options} series={[
                                                        (_orders?.totalCredit ?? 0),
                                                        (_orders?.totalCash ?? 0),
                                                        (_orders?.totalDiscount ?? 0)
                                                    ]} type='donut' height={45} width={45} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>            
            <Row className='mt-2'>
                <Col sm="12" md="12" lg="12">
                    <Card>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Cargos pendientes - saldados</h5>
                        </CardHeader>
                        <CardBody className='p-2' >
                            <div className='text-left'>
                                <p>Mostrando - {listDetailTransactionsByClients?.length} resultados</p>
                            </div>
                            <div className='text-right'>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterCredit ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterCredit(!filterCredit) }} >Crédito</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAccount ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterAccount(!filterAccount) }} >Contado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPayment(!filterPayment) }} >Pagado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPendingPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendingPayment(!filterPendingPayment) }} >Pendiente pagado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterInvoiced(!filterInvoiced) }} >Facturado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPendigInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendigInvoiced(!filterPendigInvoiced) }} >Pendiente de facturar</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPreInvoice ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPreInvoice(!filterPreInvoice) }} >Prefactura</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPpd ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPpd(!filterPpd) }} >PPD</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPue ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPue(!filterPue) }} >PUE</Label>
                            </div>
                            <div style={{ height: '440px', overflowY: "scroll", scrollBehavior: "smooth" }}>
                                <Table className='b-r-5 table-striped  -hover'>
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#033d5b" }}>
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>Cliente</th>
                                            <th className='labeltheadInvoice'>NIM</th>
                                            <th className='labeltheadInvoice'>Paciente</th>
                                            <th className='labeltheadInvoice'>Condición de pago</th>
                                            <th className='labeltheadInvoice'>Estado del pago</th>
                                            <th className='labeltheadInvoice'>Estatus facturación</th>
                                            <th className='labeltheadInvoice'>Importe</th>
                                            <th className='labeltheadInvoice'>Descuento</th>
                                            <th className='labeltheadInvoice'>Impuesto (IVA 16%)</th>
                                            <th className='labeltheadInvoice'>Serie</th>
                                            <th className='labeltheadInvoice'>Folio</th>
                                            <th className='labeltheadInvoice'>Método Pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loadingTableClients && <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            listDetailTransactionsByClients?.length > 0 &&
                                            listDetailTransactionsByClients?.filter(_transaction => {
                                                if (filterCredit) {
                                                    return _transaction?.paymentCondition == "Crédito";
                                                }

                                                if (filterAccount) {
                                                    return _transaction?.paymentCondition == "Pago de contado";
                                                }

                                                if (filterPayment) {
                                                    return _transaction?.paymentStatus == "Pagado";
                                                }

                                                if (filterPendingPayment) {
                                                    return _transaction?.paymentStatus == "Pendiente";
                                                }

                                                if (filterInvoiced) {
                                                    return _transaction?.financialStatus == "Facturado";
                                                }

                                                if (filterPendigInvoiced) {
                                                    return _transaction?.financialStatus == "Pendiente";
                                                }

                                                if (filterPreInvoice) {
                                                    return _transaction?.financialStatus == "Prefactura";
                                                }

                                                if (filterPpd) {
                                                    return _transaction?.payMethod == "PPD";
                                                }

                                                if (filterPue) {
                                                    return _transaction?.payMethod == "PUE";
                                                }

                                                if (!filterCredit && !filterAccount && !filterPayment && !filterPendingPayment && !filterInvoiced && !filterPendigInvoiced && !filterPreInvoice && !filterPpd && !filterPue) {
                                                    return _transaction;
                                                }
                                            })
                                                ?.map((_order, _index) => {
                                                    return <tr key={_order?.idWorkOrder} className={_order?.financialStatus == "S/Factura" ? "bg-danger text-white" : ""}>
                                                        <td>{_index + 1}</td>
                                                        <td>{_order?.nameClient}</td>
                                                        <td>{_order?.nim}</td>
                                                        <td>{_order?.namePatient}</td>
                                                        <td><Label type="text" className={`f-w-600 f-12 badge badge-${_order?.paymentCondition === "Crédito" ? 'warning' : "secondary"}`}>{_order?.paymentCondition}</Label></td>
                                                        <td><Label type="text" className={`f-w-600 f-12 badge badge-light-${_order?.paymentStatus === "Pendiente" ? 'warning' : "success"}`}>{_order?.paymentStatus}</Label></td>
                                                        <td><Label type="text" className={`f-w-600 f-12 badge badge-light-${_order?.financialStatus === "Pendiente" ? 'danger' : _order?.financialStatus == "Prefactura" ? "warning" : "success"}`}>{_order?.financialStatus}</Label></td>
                                                        <td className='f-w-700'>$ {number_format(_order?.totalWorkOrder, 2)}</td>
                                                        <td className='font-danger f-w-700'>$ {number_format(_order?.totalDiscount, 2)}</td>
                                                        <td className='f-w-700'>$ {number_format(_order?.totalTax, 2)}</td>
                                                        <td>{_order?.serie}</td>
                                                        <td>{_order?.folio}</td>
                                                        <td className='f-w-700'>{_order?.payMethod}</td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className='mb-5'></div>
        </Fragment>
    )
}
