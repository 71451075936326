import React from 'react'
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button, } from 'reactstrap';

export const FormMicSiteArea = (props) => {

    const {
        toggle, loading, handleInputChange, 
        validaciones, methodAction, 
        id_mic_site, name, button, sites, 
    } = props;
    const {
        id_mic_site_valid, name_valid,
    }=validaciones;

    //const txtsection = localStorage.getItem('TextAS');
    ////console.log(sites,"UwU");
    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Sitio <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="select"
                                name="id_mic_site"
                                value={id_mic_site}
                                onChange={handleInputChange}
                                options={sites}
                                invalid={id_mic_site_valid}
                                //noOptionsMessage={()=>"Sin opciones"}
                            >
                                <option defaultValue={0}>Selecciona una opción</option>
                                {
                                    sites.map(element=>{
                                        return(
                                            <option key={element.id_mic_site} value={element.id_mic_site}>{element.name}</option>
                                        )
                                    })
                                }
                            </Input>
                        {/* <FormText color='danger' hidden={!areas_valid}>Debes seleccionar almenos una opción</FormText> */}
                            <FormFeedback>Selecciona una opción</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label className="col-form-label">Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                className="form-control form-control-sm input-air-primary" 
                                name="name"
                                onChange={handleInputChange}
                                value={name}
                                autoComplete="off"
                                invalid={name_valid}
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} disabled={loading}> 
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
