import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

export const ModalInformation = (props) => {

    const {
        modalInformation, toggleInformation, validCode, password, handleInputChange, getInformationSpecialDiscount
    } = props;

    return (
        <Modal isOpen={modalInformation} centered={true} toggle={toggleInformation}>
            <ModalHeader className="bg-primary" toggle={toggleInformation}>Validación</ModalHeader>
            <ModalBody>
                <p>Estas apunto de mostrar información protegida de este descuento especial: {validCode}</p>
                <span>Ingresa tu contraseña</span>
                <Input placeholder="Contraseña..." type="password" name="password" autoComplete="new-password" value={password} onChange={handleInputChange} />
                {/* <span className="text-danger">{errorValidacion}</span> */}
                <hr />
                <h6>¿Estás seguro de hacerlo?</h6>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={getInformationSpecialDiscount}>Mostrar información</Button>
                <Button color="secondary" onClick={toggleInformation}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}
