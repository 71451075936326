import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useUser = () => {

    //GLOBAL VARIABLES
    const history = useHistory();
    const numberItems = 10;
    //SHOW DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //END SHOW DISPLAT MESSAGE

    //#region MODALS
    const [modalTitle, setModalTitle] = useState(false);
    const [modalUser, setModalUser] = useState(false);
    const [methodUser, setMethodUser] = useState("");

    const toggleUser = () => {
        setModalUser(!modalUser);
    }

    const [modalTitleSection, setModalTitleSection] = useState(false)
    const [modalSection, setModalSection] = useState(false);
    const [typeModal, setTypeModal] = useState("typeSection")


    const toggleSection = () => {
        setModalSection(!modalSection)

    }





    //#endregion MODALS

    //#region USERS
    //#region USERS MODEL
    const [listUsers, setListUsers] = useState([]);
    const [listUsersSearch, setListUsersSearch] = useState([]);
    const [userId, setUserId] = useState(0);
    const [listRol, setListRol] = useState([]); 

    const [loading, setLoading] = useState(false);

    const [formUser, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        name_user: "",
        listRoles: [],
        listSection: []
        
    });

    const { name_user, listRoles, listSection } = formUser;
    //#endregion USERS MODEL

    //#region USERS VALIDATIONS
    const [validationsUser, setValidationsUser] = useState({
        listRoles_valid: false
    });
    //#endregion USERS VALIDATIONS        

    //#region USERS METHOD

    const handleGetUsersRoles = useCallback(async () => {
        let listTmp = await handleRequest("GET", "Users", "Usuarios");
        ////console.log(listTmp);

        if (listTmp !== null && listTmp.length > 0) {

            let list = [];

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            // let name_roles;
            // let array_roles = [];


            listTmp.forEach((obj, index) => {
                let posicion = index + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }


                let array_roles = [];
                let name_roles;

                if (obj.roles !== undefined && obj.roles!==null) {
                    name_roles = [];

                    obj.roles.forEach(r => {

                        name_roles.push(
                            [r]
                        );
                        array_roles.push(r);
                    })
                }
                else {
                    name_roles = "Sin roles asignados"
                }

                let array_IDroles = [];
                let id_roles;

                if (obj.ids_roles !== undefined && obj.ids_roles!==null) {
                    id_roles = [];

                    obj.ids_roles.forEach(element => {

                        id_roles.push(
                            [element]
                        );
                        array_IDroles.push(element)
                    });
                }
                else {
                    id_roles = 0
                }

                list.push({
                    id_user: obj.id_user,
                    name: obj.name + " " + obj.paternal_surname + " " + obj.maternal_surname,
                    name_user: obj.name,
                    paternal_surname: obj.paternal_surname,
                    maternal_surname: obj.maternal_surname,
                    username: obj.username,
                    id_helen_users: obj.id_helen_users,
                    id_external: obj.id_external,
                    job: obj.job,
                    card_code: obj.card_code,
                    areas: obj.areas,
                    array_roles: array_roles,
                    name_roles: name_roles,
                    ids_roles: obj.ids_roles,
                    ids_sections: obj.ids_sections,
                    sections: obj.sections,
                    visibleTable: visibleTable
                });
            });
            setListUsers(list);
            setListUsersSearch(list);
        }

    }, [history]);

    const handleGetRoles = async () => {
        let listTmp = await handleRequest("GET", "Roles/List", "Roles");

        if (listTmp !== null && listTmp.length > 0) {

            let list = [];
            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_role,
                    label: obj.name
                });
            });

            setListRol(list);
        }
    }

    const handleUpdate = (id_user) => {

        //::todo DESACTIVAR ESTA OPCION SI LOS USUARIOS NO USAN PAGINACION Y BUSQUEDA
        // let findUser = listUsers.find(x => x.id_user === parseInt(id_user));

        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        //console.log(findUser);

        if (findUser) {

            let listRolesUser = [];

            if (typeof findUser.name_roles === "string") {
                listRolesUser = [];
            }
            else {
                findUser.ids_roles.forEach((obj, index) => {//lo modifique por array_roles este estaba


                    listRolesUser.push({
                        value: obj,
                        label: findUser.name_roles[index]
                    })
                });
            }

            handleUpdateValues({
                name_user: findUser.name,
                listRoles: listRolesUser
            });

            setUserId(id_user);
            handleGetRoles();
            setMethodUser("update_roles");
            setModalTitle("Editar usuario");
            toggleUser();
        }
    }

    const saveUpdatePatient = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (handleValidationsUser()) {
            setLoading(false);
            return;
        }
        //assing

        let ids_roles = []; //listRoles

        formUser.listRoles.forEach(obj => {
            ids_roles.push(obj.value);
        });

        let findUser = listUsersSearch.find(x => x.id_user === parseInt(userId));

        let body = {
            id_user: userId,
            ids_roles: ids_roles
        };

        let listTmp = await handleRequest("PUT", 'Users/AssingRoles', 'Roles', body);

        if (listTmp !== null) {
            //console.log("******si paso mi metodo para actualizar****");
            setUserId(0);
            setLoading(false);
            toggleUser();
            handleGetUsersRoles();
            createSweet("update", "info", "Usuario actualizado");
        }
    }

    const handleSearchUser = (e) => {
        let busqueda = e.target.value;
        let newMethod = [];

        listUsersSearch.forEach(obj => {
            let search_by_name = obj.name.toUpperCase();
            let search_by_username = obj.username.toUpperCase();

            if ((search_by_name.indexOf(busqueda.toUpperCase()) > -1) || (search_by_username.indexOf(busqueda.toUpperCase()) > -1)) {
                obj.visibleTable = true;
                newMethod.push(obj);
            }
        });

        setListUsers(newMethod);
    }

    //#endregion USERS METHOD


    //#region SECTIONS
    const [imagen, setImagen] = useState("")

    const handleListSection = (id_user) => {
        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        if (findUser) {
            let listSectionsUser = [];
            findUser.ids_sections.forEach((section, indexSection) => {
                if (findUser.sections[indexSection] !== undefined) {
                    //console.log(indexSection);
                    listSectionsUser.push({
                        value: section,
                        label: findUser.sections[indexSection]
                    });
                }
            });

            handleUpdateValues({
                name_user: findUser.name,
                listSection: listSectionsUser
            });

            setTypeModal("typeSection");
            setModalTitleSection("Secciones/Áreas")
            toggleSection();
            getSection();
            setSelected([]);
            setId(id_user);
            handleGetSections();
            setUserId(id_user);
        }
    }

    const [listSect, setListSect] = useState([]);
    const [sectionSelected, setSectionSelected] = useState([]);

    const handleGetSections = async () => {
        let listTmp = await handleRequest("GET", "Section/List/false", "Sectionss");

        if (listTmp !== null && listTmp.length > 0) {

            let list = [];
            let arreglo = [];

            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_section,
                    label: obj.name,
                    check: false,
                    ...obj
                });
                arreglo.push(obj.id_section);
            });
            setListSect(list);
            setSectionSelected(arreglo)

        }
    }

    // const [selectedSections, setselectedSections] = useState([]);
    // const [sectiondefault,setSectiondefault]=useState([]);

    // const handleGetSections2 = async () => {
    //     let listTmp = await handleRequest("GET", "Section/List/false", "Sectionss");

    //     if (listTmp !== null && listTmp.length > 0) {

    //         let secciones = [];
    //         let sectionmultiselect=[];

    //         let list = [];
    //         listTmp.forEach(obj => {
    //             list.push({
    //                 value: obj.id_section,
    //                 label: obj.name
    //             });
    //         });
    //         setListSect(list);
    //     }
    // }








    const [selected, setSelected] = useState([]);

    const onChange = (selected) => {
        setSelected(selected)
    };

    const [data, setData] = useState([]);


    const getSection = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {
            let array = []

            respuesta.data.forEach(element => {

                if (element.is_imagenology) {
                    setImagen("true")

                }
                array.push({
                    "value": element.id_section,//ID
                    "label": element.name,
                    "id": element.id_section,//ID PRECODTEXT,
                    "id_section": element.id_section,
                    //"id_type_precoded_text": element.id_type_precoded_text
                })
            });

            setData(array);
        }
        else if (respuesta.code === 401) {
            // validarSesion(history, respuesta.code, getSection);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Exámen", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Exámen", respuesta.data.msg);
        }
    }

    const [id, setId] = useState(0)

    // {
    //     "id_user": 0,
    //     "ids_sections": [
    //       0
    //     ]
    //   }


    const saveUpdateSections = async (e) => {
        e.preventDefault();
        setLoading(true);

        let array2 = []

        formUser.listSection.forEach(obj => {
            array2.push(obj.value);
        });

        let body = {
            id_user: userId,
            ids_sections: array2

        };


        let listTmp = await handleRequest("PUT", 'Users/AssingSections', 'Sections', body);

        if (listTmp !== null) {
            //console.log("pasa");

            setUserId(0);
            setLoading(false);
            toggleSection();
            handleGetUsersRoles();
            createSweet("update", "info", "Secciones actualizadas");
        }
    }

    //#endregion SECTIONS

    //#region Modal Section And Rol

    const [modalTitleSection2, setModalTitleSection2] = useState(false);
    const [modalSection2, setModalSection2] = useState(false);
    const [typeModalSection, setTypeModalSection] = useState("viewSection");
    const [dataSections, setDataSections] = useState([]);


    const toggleSection2 = () => {
        setModalSection2(!modalSection2)
    }


    const handleListSections = (id_user) => {

        let findUser = listUsersSearch.find(x => x.id_user === parseInt(id_user));
        setDataSections(findUser.sections)



        setTypeModalSection("viewSection");
        setModalTitleSection2("Secciones");
        toggleSection2();
    }



    //#end region Modal Section And Rol



    //#region PAGINATION             
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listUsers.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;
        startItems = endItems - numberItems;

        let listTmp = [];

        listUsers.forEach((data, key) => {
            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listTmp.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListUsers(listTmp);

    }, [totalPageCount, currentPage]);

    //#endregion PAGINATION             

    //#region USERS USE EFFECT

    useEffect(() => {
        handleGetUsersRoles();
    }, [handleGetUsersRoles]);


    //#endregion USERS USE EFFECT

    //#endregion USERS



    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const handleValidationsUser = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formUser.listRoles.length === 0) {
            newValidations = {
                ...newValidations,
                listRoles_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                listRoles_valid: false
            }
        }

        setValidationsUser(newValidations);
        return statusValidation;
    }

    return {
        modalTitle, modalUser, methodUser, toggleUser,
        //USERS MODEL
        listUsers, listRol, loading,
        name_user, listRoles, handleInputChange, handleSelectValues,
        validationsUser,
        //USERS METHODS
        handleUpdate, saveUpdatePatient,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearchUser,
        //SECTION   
        handleListSection,
        modalTitleSection, modalSection, toggleSection, typeModal,
        selected, onChange, data, imagen,
        saveUpdateSections,
        //sections
        listSection, listSect,
        //viewa
        modalTitleSection2, modalSection2, typeModalSection, handleListSections, toggleSection2, dataSections,
        sectionSelected

    }
}