import React, { Fragment, useState } from 'react'
//import { SearchAsync } from '../../components/elements/SearchAsync';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { Button, Card, CardBody, Col, Container, FormGroup, FormText, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup } from 'reactstrap';
import { useQuoter } from '../../../hooks/micro/useQuoter'
import './QuoteStyle.css';
import { useHistory } from 'react-router-dom';
import 'animate.css';
import { TableQuoter } from './TableQuoter';
import { ModalViewExamsQuoter } from './ModalViewExamsQuoter';
import { ModalQuoterFind } from './ModalQuoterFind';
import { ModalPatient } from './ModalPatient';
import { ModalRepeatProfile } from '../../../components/workOrders/ModalRepeatProfile';
import Select from 'react-select';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { Data } from '../../../constant';



import { CollapseData } from './DataGrid/CollapseData';
import { ComponentDataGrid } from './DataGrid/ComponentDataGrid';



export const IndexQuoterMic = () => {

	let versionLayout = localStorage.getItem('layout_version');
	const history = useHistory();

	let b = 0;

	const {
		//MODALS
		method, modalTitle, modal, modalRepeatProfile, modalViewExamsP, loading, validationsPatient, savePatient,
		toggle, toggleModalViewExams, toggleRepeatProfile,

		//QUOTER VALUES
		observations, id_profileExam, name, paternal_surname, maternal_surname, email,
		handleInputChange,
		cardExam, cardProfile, totalW, subTotalW, totalTaxesW,
		examProfileState, viewExamsProfile, times,
		viewButtons, viewButtonsCreate, validationsRepeat, listQuoterTest,

		//QUOTER METHODS
		handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
		deleteExamProfile, handleCheckUrgent, handleCheckSpecimen,
		handleViewExamsProfile, handleCancellQuoter, saveCreate,
		printPdf, sentPdf, downdloadpdf,
		//new const
		checkTypeClient, handleChangeTypeClient, id_company, informationCompany, handleChangeCompany,
		listCompanies, viewCompany,
		//PATIENT
		id_patient, informationPatient, handleCreatePatient, togglePatient, modalPatient,
		handleChangePatient, searchPatientState, handleUpdatePatient, viewPatient,
		formValuesPatient, handleInputChangePatient, resetPatient, handleUpdateValuesPatient,

		// NEW PATIENT
		id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
		id_use_cfdi, rfc, type_person, code,
		curp, address, cp, colony, phone, membership, cardnumber, new_social_gender,
		//SAMPLE
		handleCreateSampleMic, id_type_sample, searchSampleState, type_sample_select,
		enabledatagrid, enableDetermination, handleSelectDetermination,
		AddNewDetermination,
		id_exam_temp,
		//DETERMINATIONS
		list_type_samples, deleteSample, check_cotizar, date_sample, handleUpdateValues,
		is_sampling, totalDiscountW, origin, name_human, paternal_human, maternal_human, handleInputChangeQ,
		handlePickerValues,
		newquoter, removecant, addcant, toggleModalFindQuoter,
		handleFindQuoter, modalFindQuoter, id_quoter_find, findQuoter, handleInputChangeFind,
		saveUpdate, update_quoter, labelDateSample, habilitar_picket, updatedatesample, date_sample_up,
		//NORMAS
		listNom, nomSelected, list_nom_det, changenom, changeobservacionesinput, CodeQuoter, muestreo,
		//TIPO DE MUESTRA
		AddNewSample,changediscount_part
	} = useQuoter();

	const [filterDate, setFilterDate] = useState(true);
	const [observationm, setObservationm] = useState(true)

	return (
		<Fragment>
			<Container fluid={true}>
				<Col sm="12">
					<Row>
						<Col sm="6" md="6" lg="6" xl="6" >
							<Card className="shadow sizeModalDP">
								<CardHeader className='bg-primary p-2'>
									<h6 className='m-t-5 m-b-0 m-l-5'>
										<i className="fa fa-user f-15"></i>&nbsp; &nbsp;
										<Label className='labelSearchPatient f-10'>Busqueda del cliente</Label>
									</h6>
								</CardHeader>
								<CardBody className='p-3'>
									<Row className='m-b-15'>
										<Col sm="12" md="12" lg="12" xl="12">

											<button type="button" class="btn btn-outline-primary btn-xs mb-2" onClick={() => handleFindQuoter()} style={{ float: "right" }}>Buscar Cotización</button>
											<ButtonGroup md="12" className='btn-group-pill btn-block'>
												<Button color='primary' type='button' onClick={() => handleChangeTypeClient(1)} outline={checkTypeClient === 1 ? false : true}><i className="icofont icofont-id-card"></i>Particular</Button>
												{/* <Button color='primary' type='button' onClick={() => handleChangeTypeClient(2)} outline={checkTypeClient === 2 ? false : true}><i className="icofont icofont-user"></i>Nuevo Paciente</Button> */}
												<Button color='primary' type='button' onClick={() => handleChangeTypeClient(3)} outline={checkTypeClient === 3 ? false : true}><i className="icofont icofont-industries-alt-4"></i>Empresa</Button>
											</ButtonGroup>
											<br />

										</Col>
									</Row>

									<Row>
										<Col sm="12">
											<div className={checkTypeClient === 1 ? 'text-right d-none' : 'text-right'}>
												<Label className='m-t-15 m-b-20'>Cliente <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
												<Select
													classNamePrefix="select"
													name="id_company"
													value={id_company}
													placeholder="Selecciona una empresa"
													options={listCompanies}
													onChange={(e) => handleChangeCompany(e, "id_company")}
													valid
													theme={
														(theme) => ({
															...theme,
															colors: {
																...theme.colors,
																primary25: (versionLayout === 'dark-only') ? '#4c9aff' : "#deebff"
															}
														})
													}
												/>

											</div>
										</Col>
									</Row>

									{informationCompany.code !== undefined && checkTypeClient === 3 ? (
										<Row className='m-t-15 animate__animated animate__flipInX'>
											<Col xs="3" sm="3" md="3" lg="3" xl="3">
												<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
												<small>
													Código
													<br />
													<b>{informationCompany.code}</b>
												</small>
											</Col>
											<Col xs="3" sm="3" md="3" lg="3" xl="3">
												<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
												<small>Teléfono <br />
													<b>{informationCompany.phone}</b>
												</small>
											</Col>
											<Col xs="6" sm="6" md="6" lg="6" xl="6">
												<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
												<small>
													Correo <br />
													<b>{informationCompany.email}</b>
												</small>
											</Col>
										</Row>
									) : (
										checkTypeClient !== 3 && (
											<Row className="m-t-10">
												<Col sm="12" md="12" lg="12" xl="12">
													<Button
														style={{ textAlignLast: "center" }}
														outline
														color="primary"
														size="xs"
														onClick={() => handleCreatePatient()}
													>
														<i className="fa fa-user p-1"></i>
														&nbsp; <Label className="labelcreatePatient p-1">Nuevo paciente</Label>
													</Button>
												</Col>
											</Row>
										)
									)}

									<Row className={checkTypeClient === 3 ? 'text-right d-none m-t-10' : 'text-right m-t-10'}>
										<Col sm="12" md="12" lg="12" xl="12">
											<SearchAsync
												cacheOptions={false}
												value={id_patient}
												name="id_patient"
												method={handleChangePatient}
												loincState={searchPatientState}
												url="Patient/SearchPatient"
												maxLenght={3}
												placeholder="Búsqueda por nombre o curp"
											/>
										</Col>
									</Row>

									{
										informationPatient.name !== null && checkTypeClient === 1
											?
											<>
												<Row className='m-b-5'>
													<Col sm="12" md="12" lg="12" xl="12" className='text-xs-center'>
														<FormText className='text-center animate__animated animate__fadeIn animate__infinite infinite animate__slow' color="danger" hidden={informationPatient.is_complete === false ? false : true}>
															<b className='f-12'><i className="icofont icofont-warning text-danger"></i> Actualiza los datos del paciente</b>
														</FormText>
													</Col>
												</Row>
												<Row className='m-t-15 animate__animated animate__flipInX'>
													<Col xs="4" sm="4" md="4" lg="4" xl="4">
														<i className='fa fa-circle iconResults iconPatien'></i>
														<Label className='labelPatientSize'>  F. nacimiento</Label>&nbsp;
														<br />
														<b className='labelPatientSize'>{informationPatient.birthday}</b>
													</Col>
													<Col xs="3" sm="3" md="3" lg="3" xl="3">
														<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
														<label className='labelPatientSize'>  Télefono:</label>
														<br />
														<b className='labelPatientSize'>{informationPatient.phone}</b>
													</Col>
													<Col xs="3" sm="3" md="3" lg="3" xl="3">
														<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
														<label className='labelPatientSize'> Sexo: </label>
														<br />
														<b className='labelPatientSize'>{informationPatient.gender}</b>
													</Col>
													<Col xs="2" sm="2" md="2" lg="2" xl="2">
														<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
														<label className='labelPatientSize'> Edad: </label>
														<br />
														<b>{informationPatient.age}</b>
													</Col>
												</Row>
												<Row className='m-t-10 animate__animated animate__flipInX'>
													<Col xs="5" sm="5" md="5" lg="5" xl="5">
														{
															informationPatient.email === "" || informationPatient.email === null
																?
																<div>
																	<i className="fa fa-circle iconResults iconPatien text-danger"></i>&nbsp;
																	<label className='labelPatientSize'> E-mail: </label>
																	<br />
																	<b>{informationPatient.email}</b>
																</div>
																:
																<div>
																	<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
																	<label className='labelPatientSize'> E-mail: </label>
																	<br />
																	<b>{informationPatient.email}</b>
																</div>
														}
													</Col>
													<Col xs="4" sm="4" md="4" lg="4" xl="4">
														{
															informationPatient.curp === ""
																?
																<div>
																	<i className="fa fa-circle iconResults iconPatien text-danger"></i>&nbsp;
																	<label className='labelPatientSize'>Curp:</label>
																</div>
																:
																<div>
																	<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
																	<label className='labelPatientSize'>Curp:</label>
																	<br />
																	<b>{informationPatient.curp}</b>
																</div>
														}
													</Col>
													<Col xs="3" sm="3">
														<i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
														<label className='labelPatientSize'> Membresia: </label>
														<br />
														<b>{informationPatient.membership}</b>
													</Col>
												</Row>
												<Row>
													{/* <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                <Button outline color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                                    <i className="icofont icofont-ui-edit "></i>
                                                                </Button>
                                                            </Col> */}
												</Row>
											</>
											: ""
									}
								</CardBody>
							</Card>
						</Col>
						<Col sm="6" md="6" lg="6" xl="6" className={id_patient != 0 || id_company != 0 ? "" : "d-none"}>
							<Card className="shadow sizeModalDP">
								<CardHeader className='bg-primary p-2'>
									<h6 className='m-t-5 m-b-0 m-l-5'>
										<i className="fa fa-user f-15"></i>&nbsp; &nbsp;
										<Label className='labelSearchPatient f-10'>Datos Generales</Label>
									</h6>
								</CardHeader>
								<CardBody className='p-3'>
									<Row>

										<Col xs="3" sm="3" md="3" lg="3" xl="3">
											<Label className="labelCheckPrint">Muestreo</Label> <br />
											<Label className="switch">
												<Input type="checkbox" name="is_sampling" onChange={handleInputChange} checked={is_sampling} />
												<span className="slider round"></span>
											</Label>
										</Col>


										<Col md="4" className={is_sampling == true ? '' : 'd-none'}>
											<FormGroup>
												{/* <Label className="col-form-label">Fecha del Muestreo <span className='f-14' style={{ color: "#0079C7" }}>*</span></Label>
												<Input type="date" className="form-control form-control-sm input-air-primary" name="date_sample" value={date_sample} onChange={handleInputChange} />
												 */}
												{labelDateSample != "" ?
													<div>
														<span class='badge badge-primary f-right' onClick={() => habilitar_picket()} ><i className="icofont icofont-ui-edit"></i></span>

														<label>{labelDateSample}</label>
														{updatedatesample == true ? <Datetime
															inputProps={{
																placeholder: 'dd-mm-aaaa hh:mm',
																name: 'date_sample_up',
																autoComplete: "off",
																className: "form-control digits form-control-sm",
															}}
															timeFormat="HH:mm"
															timeConstraints={{
																hours: { min: 0, max: 23 },
																minutes: { min: 0, max: 59 },
																seconds: { min: 0, max: 59 }
															}}
															value={date_sample_up}
															dateFormat={true}
															locale="es"
															onChange={(e) => handlePickerValues(e, "date_sample_up")}
														/> : <span></span>}
													</div> :
													<div>
														<Datetime
															inputProps={{
																placeholder: 'dd-mm-aaaa hh:mm',
																name: 'date_sample',
																autoComplete: "off",
																className: "form-control digits form-control-sm",
															}}
															timeFormat="HH:mm"
															timeConstraints={{
																hours: { min: 0, max: 23 },
																minutes: { min: 0, max: 59 },
																seconds: { min: 0, max: 59 }
															}}
															value={date_sample}
															dateFormat={true}
															locale="es"
															onChange={(e) => handlePickerValues(e, "date_sample")}
														/>

													</div>}


											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="8">
											<FormGroup>
												<Label className="col-form-label">Observaciones <span className='f-14' style={{ color: "#0079C7" }}>*</span></Label>
												<textarea
													name="observations"
													className="form-control input-air-primary form-control-sm gridQuoter" placeholder="Observaciones.."
													value={observations}
													onChange={handleInputChange}
												/>
											</FormGroup>
										</Col>
									</Row>
									{/* <Row>
									<Col sm="12">
										<div className={checkTypeClient === 2 ? 'text-right d-none' : 'text-right'}>
											<Label className='m-t-15 m-b-20'>Paciente <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
										</div>
									</Col>
								</Row> */}
								</CardBody>

								{/* __________________CONSOLE.LOG_______________________ */}
								{/* <Card>
								<CardBody>
									<Col>
										<Row>
											<Col sm="12">
												<div className={checkTypeClient === 2 ? 'text-right d-none' : 'text-right'}>
													<Label className='m-t-15 m-b-20'>Paciente <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
												</div>
												{//console.log('Aquí se selecciona la opción de pacientes')}
											</Col>
										</Row>
									</Col>
								</CardBody>
							</Card> */}
							</Card>
						</Col>
						<Col sm="12" className={id_patient != 0 || id_company != 0 ? "" : "d-none"}>
							<Card className='shadow'>
								<CardHeader className='bg-primary p-3'>
									<h6> <i className="icofont icofont-laboratory f-16"></i> &nbsp;Tipo de Muestras y Determinaciones</h6>
								</CardHeader>
								<CardBody>

									<Row>
										<Col sm="5"></Col>
										{/* <Col sm="7">
												<div className="text-right">
													<Button style={{ textAlignLast: "center" }} outline color='primary' size='sm' onClick={handleCreateSampleMic}><i className="icofont icofont-microscope"></i> Nueva muestra</Button>
												</div>
											</Col> */}
									</Row>
									<br />
									<Row>
										<Col sm="6" md="6" lg="6" xl="6" className='p-2'>
											<Label>{"Tipo de Muestra"} <span style={{ color: "#0079C7" }}>*</span></Label>
											<SearchAsync
												cacheOptions={false}
												value={type_sample_select}
												name="id_sample"
												method={handleSelectDetermination}
												loincState={searchSampleState}
												url="TypeSamples/SearchTypeSample"
												maxLenght={3}
												placeholder="Búsqueda de muestras"
											/>
										</Col>
										{/* <Col sm="5" md="5" lg="5" xl="5" >
											<Label>{"Determinación"} <span style={{ color: "#0079C7" }}>*</span></Label>
											<FormGroup>
												<SearchAsync
													className="col-md-12"
													value={id_exam_temp}
													method={handleSelectExamChange}
													loincState={examProfileState}
													url="Profiles/SearchProfileExam"
													maxLenght={3}
													selectSize="col-md-12"
												/>

											</FormGroup>
										</Col> */}
										{/* <Col xs="3" sm="3" md="3" lg="3" xl="3" className={origin==1?'':'d-none'}>
                                            <Label className="labelCheckPrint">{"Nombre"}</Label> <br />
                                             <Input type="text" name="name_human" onChange={handleInputChangeQ} value={name_human} />
                                        </Col>
										<Col xs="3" sm="3" md="3" lg="3" xl="3" className={origin==1?'':'d-none'}>
                                            <Label className="labelCheckPrint">{"Apellido Paterno"}</Label> <br />
                                             <Input type="text" name="paternal_human" onChange={handleInputChangeQ} value={paternal_human} />
                                        </Col>
										<Col xs="3" sm="3" md="3" lg="3" xl="3" className={origin==1?'':'d-none'}>
                                            <Label className="labelCheckPrint">{"Apellido Materno"}</Label> <br />
                                             <Input type="text" name="maternal_human" onChange={handleInputChangeQ} value={maternal_human} />
                                        </Col> */}
										<Col sm="6" md="6" lg="6" xl="6" style={origin == 0 ? { marginTop: "2.5rem" } : { marginTop: "1.5rem" }}>
											<Button outline color='primary' size='sm' onClick={AddNewSample}>
												Crear
											</Button>
										</Col>
										<Col sm="12" md="12" lg="12" xl="12" className='p-2'>
											<Row>
												{list_type_samples != null ?

													list_type_samples.length >= 1 && list_type_samples.map((item, index) => {
														return <CollapseData
															key={index}
															items={item}
															{...{
																handleCheckUrgent, deleteExamProfile, changeSelectSamplings, changeValueTimeSampling, times, handleCheckSpecimen, deleteExamProfile,
																viewButtons, handleViewExamsProfile, deleteSample, removecant, addcant, listNom, nomSelected, list_nom_det,
																handleInputChange, changenom, changeobservacionesinput, checkTypeClient,
																id_exam_temp, handleSelectExamChange, examProfileState, id_delete: index,
																AddNewDetermination,changediscount_part
															}}
														/>

													})
													: ""


												}

											</Row>
										</Col>


									</Row>
									<br /> <br />
									<div className='table-responsive'>
										<Col sm="12">
											<Row>
												<Col sm="6">
													<Row>
														<Col sm="5">

														</Col>
														<Col sm="6" className='p-l-0 p-r-0' > <br /> <br /> <br /> <br /> <br />
															{/* <div  >
																<Col sm="12">
																	<Row>
																		<Col sm="4" className={viewButtons==true ? "d-none" : "p-2"}> <br />
																			<button color="secondary" class="btn btn-outline-secondary   btn-xs" onClick={() => sentPdf()}>{"Enviar"} &nbsp; <i className="icofont icofont-envelope colorbutton"></i></button>
																		</Col> 
																		<Col sm="6" className={viewButtons==true ? "d-none" : "p-2"}> <br />
																			<button color="info" class="btn btn-outline-info  btn-xs" onClick={() => downdloadpdf()}>{"Descargar"}&nbsp;<i className="icofont icofont-download-alt"> </i></button>
																		</Col>
																		<Col sm="6" className={viewButtons==true ? "d-none" : "p-2"}> <br />
																			<button class={checkTypeClient !== 1? "d-none" : "btn btn-outline-success b btn-xs"} color="info" onClick={() => muestreo(CodeQuoter)}>{"Continuar..."}</button>
																		</Col>
																	</Row>
																</Col>
															</div> */}
														</Col>
														<Col sm="1" >
														</Col>
													</Row>
												</Col>
												<Col sm="6">
													<Row className='m-t-15' style={{ textAlignLast: 'center' }}>
														<Col sm="12">
															<Table className="table-xs">
																<thead>
																	<tr>
																		<th className='p-1 f-12' colSpan={2}>
																			<b className='labelResumen'>Resúmen</b>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td className='f-14'><label className='labelPrices'>Subtotal</label></td>
																		<td className='text-right f-14'><label className='labelPrices'>$ {(subTotalW).toFixed(2)}</label></td>

																	</tr>
																	<tr className='border-bottom-info'>
																		<td className='f-14 text-danger'><label className='labelDiscount'>Descuentos</label></td>
																		<td className='text-right text-danger f-14'><label className='labelDiscounts'>$ {(totalDiscountW).toFixed(2)}</label></td>
																	</tr>

																	<tr className='border-bottom-info'>
																		<td className='f-14'><label>I.V.A</label></td>
																		<td className='text-right f-14'><label><b>${(totalTaxesW.toFixed(2))}</b></label></td>
																	</tr>
																	<tr>
																		<td className='f-14'> <b className='f-14'>Total</b></td>
																		<td className="text-right f-14"><b className='f-14'>$ {(totalW).toFixed(2)}</b></td>
																	</tr>
																</tbody>
															</Table>
														</Col> <br />
														<Col sm="12"> <br />
															{
																check_cotizar == true ?
																	<ButtonGroup className="btn-group-pill btn-group-sm" size='sm' style={{ width: '100%' }}>
																		<Button
																			className={viewButtons == false ? "btn-group-pill btn-group-sm" : "d-none"}
																			outline
																			color='primary-2x'
																			onClick={() => downdloadpdf()}
																		>
																			<label className="labelSaveCreate">{"Descargar"}</label>


																		</Button>

																		<Button
																			className={viewButtons == false && checkTypeClient === 1 ? "btn-group-pill btn-group-sm" : "d-none"}
																			outline
																			color='success'
																			onClick={() => muestreo(CodeQuoter)}
																		>
																			<label className='labelSaveCreate'>Continuar...</label>
																		</Button>
																		<Button
																			className={viewButtons ? "btn-group-pill btn-group-sm" : "d-none"}
																			//className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
																			outline
																			color='primary-2x'
																			onClick={update_quoter == true ? saveUpdate : saveCreate}
																		>
																			{update_quoter == true ? <label className="labelSaveCreate">{"Modificar"}</label> :
																				<label className="labelUpdateCreate">{"Cotizar"}</label>}


																		</Button>

																		<Button
																			className='btn-air-danger'
																			outline
																			color='danger'
																			onClick={() => newquoter()}
																		>
																			<label className='labelSaveCreate'>Cancelar</label>
																		</Button>
																	</ButtonGroup> : ""
															}

														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</div>
									<br />
								</CardBody>
							</Card>  <br />
						</Col>
					</Row>
				</Col>
			</Container >
			<ModalViewExamsQuoter
				{
				...{ modalViewExamsP, toggleModalViewExams, viewExamsProfile, handleViewExamsProfile }
				}
			/>

			<ModalQuoterFind
				{
				...{
					toggleModalFindQuoter, handleFindQuoter, modalFindQuoter, id_quoter_find, findQuoter, handleInputChangeFind
				}
				}
			/>
			<ModalRepeatProfile
				{
				...{
					modalRepeatProfile, validationsRepeat, modalTitle, toggleRepeatProfile,
				}
				}
			/>
			<ModalPatient
				{
				...{


					modalPatient, togglePatient, modalTitle, method, loading, validationsPatient, savePatient, name,
					paternal_surname, phone, email, maternal_surname, formValuesPatient, handleInputChangePatient,
					resetPatient, handleUpdateValuesPatient
				}
				}
			/>
		</Fragment >
	)
}