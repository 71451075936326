import React, { Fragment, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { DollarSign } from 'react-feather';
import PropTypes from 'prop-types'
import './styleCash.css';
import { Col, Row } from 'reactstrap';
import "react-vertical-timeline-component/style.min.css";



export const TimeLineCash = ({ items, formatter }) => {

    return (
        <Fragment>
            <VerticalTimeline>
                {
                    items !== undefined && items.map((o, i) => {
                        //console.log(o);
                        return <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#0079C7', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(243 33 198)' }}
                            date={o.fech}
                            iconStyle={{ background: '#46AFE5', color: '#fff' }}
                            icon={<DollarSign />}
                        >
                            <Col sm="12">
                                <Row>
                                    <Col sm="2" className='p-r-0 p-l-0' >
                                        <img className="img-35 mr-1 rounded-circle"
                                            src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} style={{ width: "100%", filter: "blur(1px)" }}
                                            alt="GlobalCash" />
                                    </Col>
                                    <Col sm="10">
                                        <label className=' f-14'>Cantidad Depositada:</label> <b>{formatter.format(o.amount_deposit)}</b> <br />
                                        <label className=' f-14'>Cantidad Real:</label> <b>{formatter.format(o.total_amount)}</b>
                                    </Col>
                                </Row>
                                <br />
                                <Col sm="12">
                                    {/* <Button outline color ></Button> */}
                                </Col>
                            </Col>
                        </VerticalTimelineElement>
                    })
                }
            </VerticalTimeline>
        </Fragment>
    )
}

TimeLineCash.propTypes = {
    items: PropTypes.array.isRequired,
}