import React, { useEffect, useRef } from 'react';
import { Card, CardBody, Col, Row, Button, ButtonGroup  } from 'reactstrap';
import List from 'list.js';
import { ToolTips } from '../../../components/tooltips/ToolTips';

export const ListCommercialLine = (props) => {

    const {
        filterProps = null, type = "list", inputFiter = false, items, handleUpdate = null, handleDelete = null, methodsAbsolutes = null, 
    } = props;

    //console.log(props);

    const refList = useRef(null);

    useEffect(() => {
        if (items.length > 0) {
            new List(refList.current, {
                valueNames: filterProps,
                listClass: type
            });
        }
    }, [items]);



  return (
        <>
        <div ref={refList}>
            <Row >
                <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3">
                        {
                        inputFiter ?  <input className="form-control form-control-sm search" placeholder="Buscar" /> : ''
                        }
                </Col>
            </Row> <br />
            <Row className={ type }>
                {
                    items.length >0 
                    ?
                    items.map((data,key) =>{
                        return(
                            <Col md="6" sm="12" xl="3" key={key}>
                                <Card>
                                <div className={ data.theme_color_commercial_line !== "" ? " ribbon ribbon-clip ribbon-left " : " ribbon ribbon-clip ribbon-left ribon-success" } style={{backgroundColor: data.theme_color}}>
                                      {data.short_name}                                                   
                                </div>
                                    <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                        {/* <ButtonGroup>
                                            <Button outline className="btn btn-air-info" color="info" size="xs" onClick={() => handleUpdate(data.id_commercial_line)}>
                                            <i className="icofont icofont-ui-edit f-14"></i>
                                            </Button>
                                            <Button outline className="btn btn-air-info" color="danger" size="xs" onClick={() => handleDelete(data.id_commercial_line)}>
                                            <i className="icofont icofont-ui-delete f-14"></i>
                                            </Button>
                                        </ButtonGroup> */}
                                    </div>
                                        <Col sm="12">
                                        <div className="profile-vector" style={{ textAlign: "-webkit-center" }}>
                                            <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Reason/Reason.svg")} style={{ width: "42%" }} alt="" />
                                        </div>
                                        </Col> <br />
                                        <Col sm="12">
                                            <Row>
                                                <Col sm="6" style={{ textAlign :"center" }}>
                                                <label className=' f-12 text-secondary'>Razón Social</label> <br />
                                                <label className='namePrinter f-12'>{ data.long_name }</label>
                                                </Col>
                                                <Col sm="6" style={{ textAlign :"center" }}>
                                                <label className=' text-secondary f-12'>RFC</label> <br />
                                                    <label className='f-12'>{data.rfc}</label>
                                                </Col>
                                            </Row>
                                        </Col>
                                </Card>
                            </Col>
                        )
                    })
                    : 
                    ""
                }

            </Row>
           
        </div>
            
        </>
  )
}
