import React from 'react'
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { SearchAsync } from '../elements/SearchAsync';

export const ModalAddExtraPatient = (props) => {

    const {
        searchPatientExtra, searchPatientValue, modalExtraPatients, toggleModalExtraPatients, modalTitle, handleChangeExtraPatient, listTempExtraPatients, handleAddExtraPatients,
        loadingExtraPatients
    } = props;

    return (
        
        <Modal isOpen={modalExtraPatients} toggle={toggleModalExtraPatients} centered={true} size='lg' >
            <ModalHeader toggle={toggleModalExtraPatients} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Búsqueda por nombre o por nim</Label>
                            <SearchAsync
                                cacheOptions={false}
                                name="searchPatientExtra"
                                value={searchPatientValue}
                                method={handleChangeExtraPatient}
                                loincState={searchPatientExtra}
                                url="Invoice/GetFilterWorkOrders"
                                maxLenght={3}
                                placeholder="Búsqueda por nombre o nim"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <Table className='table-stripped table-bordered'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Descripción</th>
                                    <th>Valor unitario</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listTempExtraPatients.length > 0 && listTempExtraPatients.map((listTest) => {
                                        return (
                                            <>
                                                <tr key={listTest.id_work_order}>
                                                    <td colSpan={4}>
                                                        <b>{ listTest.nim + " " + listTest.name_patient }</b>
                                                    </td>
                                                </tr>
                                                {
                                                    listTest.list_test.length > 0 && listTest.list_test.map((_test, indexTest) => {
                                                        return (
                                                            <tr key={listTest.id_work_order_exam === null ? listTest.id_work_order_profile : listTest.id_work_order_exam}>
                                                                <td>{ indexTest + 1 }</td>
                                                                <td>{ _test.name }</td>
                                                                <td>{ _test.price + _test.price_urgent }</td>
                                                                <td>{ _test.price + _test.price_urgent }</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="button" onClick={handleAddExtraPatients} size="sm" disabled={loadingExtraPatients} className={loadingExtraPatients ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    Agregar pacientes
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggleModalExtraPatients}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>    
    )
}
