import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../../hooks/requests/useRequest';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

export const SearchAsync = ({
    value, name, method, loincState, url, maxLenght, placeholder = "Buscar(Min. 3 caracteres)",
    cacheOptions = true, selectSize = '', mDisabled = false, mMultiple = false, isWorkOrder = false
}) => {

    const [data, setData] = useState([]);
    const [defaultValue, setDefaultValue] = useState([]);
    const history = useHistory();

    let versionLayout = localStorage.getItem('layout_version');

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 999
        })
    }

    useEffect(() => {
        if (loincState.value) {

            if (mMultiple) {
                let defValue = defaultValue;

                let findValue = defValue.find(x => x.value === loincState.value);
                let findIndexValue = defValue.findIndex(x => x.value === loincState.value);

                if (findValue) {
                    findValue.label = loincState.label;

                    defValue[findIndexValue] = findValue;
                }
                else {

                    defValue.push(loincState);
                }
                setDefaultValue([...defValue]);
                setData([...defValue])
            }
            else {
                setDefaultValue([loincState]);
                setData([loincState]);
            }

        } else {
            setDefaultValue([]);
            setData([]);
        }
    }, [loincState]);

    const searchPeople = async (searchQuery = "") => {
        if (searchQuery.trimStart().length < maxLenght) {
            setData([]);
            return [];
        }

        let dataResult = [];

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        //Se usa https://helen-sw.com/api/ porque la libreria pide una URL Absoluta
        let uri = new URL('https://helen-sw.com/api/'+url);

        uri.searchParams.append('query', searchQuery);
        if(isWorkOrder) uri.searchParams.append('isWorkOrder', 'true');

        console.log(uri.toString());

        var newUrl = uri.toString().replace('https://helen-sw.com/api/','');

        const respuesta = await sendRequest(requestOptions, newUrl);
        if (respuesta.code === 200) {

            respuesta.data.map(function (obj) {
                dataResult.push({ label: obj.component, value: obj.id_component });
            });

            if (!mMultiple) {
                setDefaultValue([]);
            }

            setData(dataResult);
        }
        /*else {
            validarSesion(history, respuesta.code, searchPeople);
        }*/

        return dataResult;
    }

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            resolve(searchPeople(inputValue));
        });

    const _loadSuggestions = (query, callback) => {
        searchPeople(query)
            .then(resp => callback(resp));
    };


    const loadSuggestions = debounce(_loadSuggestions, 1000);


    const handleInputChange = (e, { action }) => {
        let target = {};

        switch (action) {
            case "select-option":

                if (mMultiple) {
                    setDefaultValue([...e]);
                }
                else {
                    setDefaultValue([{ ...e }]);
                }

                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: mMultiple ? e : e.value
                    }
                }
                break;
            case "remove-value":

                setDefaultValue([...e]);

                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: e
                    }
                }
                break;
            case "clear":

                if (mMultiple) {

                    setDefaultValue([...e]);
                }
                else {
                    setDefaultValue([]);
                }

                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: e
                    }
                }
                break;
            case "pop-value":
                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: e
                    }
                }
                break;
            default:
                break;
        }
        method(target);
    }

    return (
        <AsyncSelect
            isClearable
            onChange={handleInputChange}
            name="loinc_num"
            cacheOptions={cacheOptions}
            loadOptions={loadSuggestions}
            defaultOptions={data}
            value={defaultValue}
            placeholder={placeholder}
            // className={selectSize !== '' ? "basic-multi-select form-control form-control-plaintext form-control-sm " + selectSize : "basic-multi-select form-control form-control-plaintext form-control-sm "}
            //className="basic-multi-select form-control form-control-plaintext form-control-sm"
            classNamePrefix="select"
            noOptionsMessage={() => "0 resultados"}
            loadingMessage={() => "Cargando..."}
            styles={
                {
                    placeholder: () => ({
                        color: "#b6bdc4"
                    })
                }
            }
            isDisabled={mDisabled}
            isMulti={mMultiple}
            theme={
                (theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: (versionLayout === 'dark-only') ? '#4c9aff' : "#deebff"
                    }
                })
            }
        />
    )

}