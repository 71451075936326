import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateSection, ModalUpdateSection, ModalDeleteSection } from '../../constant';
//import { usePagination } from '../pagination/usePagination';
import { usePagination } from '../pagination/usePagination';


export const useSections = () => {

    const history = useHistory();

    const [sections, setSections] = useState([]);
    const [sectionId, setSectionId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [processes, setProcesses] = useState([]);
    const [processesSelected, setProcessesSelected] = useState([]);
    const [processesDeleted, setProcessesDeleted] = useState([]);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        abbreviation: "",
        enable_reception: false,
        enable_racks: false,
        is_imagenology: false,
        is_micro: false
    });

    const { name, abbreviation, enable_reception, enable_racks,is_imagenology,is_micro } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(sections.length, numberItems);
    const txtsection = localStorage.getItem('TextAS');
    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listSections = [];
        sections.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listSections.push({
                ...data,
                visibleTable: visibleTable
            });
        });
        //console.log(listSections);
        setSections(listSections);
    }, [totalPageCount, currentPage])


    // //console.log(sections);


    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        abbreviation_valid: false
    });

    const getSections = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {
            let listSections = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }


                    listSections.push({
                        id_section: data.id_section,
                        name: data.name,
                        abbreviation: data.abbreviation,
                        enable_reception: data.enable_reception,
                        enable_racks: data.enable_racks,
                        is_imagenology: data.is_imagenology,
                        is_micro: data.is_micro,
                        visibleTable: visibleTable
                    });
                });

                setSections(listSections);
                setSectiondata(listSections);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setSections([]);
            }
        }
        else {
            validarSesion(history, respuesta.code, getSections);
        }
    }, [history]);

    useEffect(() => {
        getSections();
    }, [getSections]);

    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            abbreviation: "",
            enable_reception: false,
            enable_racks: false,
            is_imagenology: false,
            is_micro: false
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setSectionId(0);
        setMethod("create");
        setModalTitle(ModalCreateSection);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Section");
        if (respuesta.code === 200) {
            getSections();
            reset();
            createSweet("create", "success", "Exito!", txtsection+" creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Secciones", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Secciones", respuesta.data.msg);
            setLoading(false);
        }
        
        
    }

    const handleUpdate = (id_section) => {
        let section = sections.find(s => s.id_section === parseInt(id_section));

        handleUpdateValues({
            name: section.name,
            abbreviation: section.abbreviation,
            enable_reception: section.enable_reception,
            enable_racks: section.enable_racks,
            is_imagenology: section.is_imagenology,
            is_micro: section.is_micro
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setSectionId(id_section);
        setMethod("update");
        setModalTitle(ModalUpdateSection);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_section: sectionId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Section/Update");

        if (respuesta.code === 200) {
            getSections();
            createSweet("create", "info", "Exito!", txtsection+" actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Secciones", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Secciones", respuesta.data.msg);
            setLoading(false);
        }
        
    }

    const handleDelete = (id_section) => {
        let section = sections.find(s => s.id_section === id_section);

        handleUpdateValues({
            name: section.name,
            abbreviation: section.abbreviation,
            enable_reception: section.enable_reception,
            enable_racks: section.enable_racks,
            is_imagenology: section.is_imagenology,
            is_micro: section.is_micro
        });

        setSectionId(id_section);
        setMethod("delete");
        setModalTitle(ModalDeleteSection);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Section/${sectionId}`);
        if (respuesta.code === 200) {
            getSections();
            setLoading(false);
            createSweet("create", "info", "Exito!", txtsection+" eliminada");
            toggle();
        }
        else {
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
    }


    const handleModalAddProcess = async (id_section) => {
        // setProcessesSelected([]);
        await getProcessesInSection(id_section).then(() => {
            toggle();
            setSectionId(id_section);
            setMethod("addProcesses");
            setModalTitle("Gestionar procesos");
            getProcesses();
            setProcessesDeleted([]);
        });
    }

    const getProcesses = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/List");

        if (respuesta.code === 200) {
            respuesta.data.map(element => {
                element.label = element.name + "[" + element.code + "]";
                element.value = element.id_process;
            });
            setProcesses(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getProcesses);
        }
    }

    const changeSelectProcesses = (array, actionData) => {
        // //console.log(array, actionData);
        setProcessesSelected(array);
        if (actionData.action === "remove-value") {
            setProcessesDeleted([...processesDeleted, actionData.removedValue])
        }
    }

    const updateProccesInSection = async (e) => {
        setLoading(true);

        e.preventDefault();

        let newProcessesSelected = processesSelected;
        let newProcessesDeleted = processesDeleted;

        newProcessesSelected.forEach(element => {
            newProcessesDeleted = newProcessesDeleted.filter(x => x.id_process !== element.id_process);
        });

        newProcessesDeleted = newProcessesDeleted.filter((item, index) => {
            return newProcessesDeleted.indexOf(item) === index;
        })

        let data = {
            "id_section": sectionId,
            "list_processes": newProcessesSelected,
            "list_processes_delete": newProcessesDeleted
        }

        let raw = JSON.stringify(data);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/SaveProccesInSection");
        if (respuesta.code === 200) {
            createSweet("create", "success", "Éxito", "Procesos actualizados");
            toggle();
            getProcesses();
            setProcessesSelected([]);
            setProcessesDeleted([]);
        } else {
            validarSesion(history, respuesta.code, updateProccesInSection);
        }
        setLoading(false);
    }

    const getProcessesInSection = async (id_section) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/GetProccessInSection/" + id_section + "/false");

        if (respuesta.code === 200) {
            respuesta.data.map(element => {
                element.label = element.name + "[" + element.code + "]";
                element.value = element.id_process;
            });
            setProcessesSelected(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getProcessesInSection);
        }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.abbreviation === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                abbreviation_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                abbreviation_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const [sectiondata, setSectiondata] = useState([]);

    
    const handleSearchSection = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        sectiondata.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setSections(newMethod);
    }





    return {
        sections, method, validaciones,
        name, abbreviation, enable_reception, enable_racks,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleModalAddProcess, processes, processesSelected, changeSelectProcesses, updateProccesInSection,
        handleSearchSection,is_imagenology,is_micro
    }
}
