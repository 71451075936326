import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Badge, Button, Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Input, Label, Row, Table } from 'reactstrap';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { PlaceHolderOption } from '../../constant';
import './StyleInvoiceClient.css';

export const TabConceptsInvoice = (props) => {

    const {
        loadingFilterWorkOrders,

        listExamsInvoice, listNims, listFilterPayMethods,
        handleChangeNim,

        subtotalInvoice, totalDiscountsInvoice, totalTaxInvoice, totalInvoice, totalRetentions,
        iconCoin,

        validationsInvoice,

        comments_invoice, handleInputChange,

        is_general_public, id_periodicity, id_month, viewFilterNims,

        handleInputChangeGenPub, date_periodicity, week_from_periocity, week_to_periocity, idFilterPayMethod, filterWorkOrder,

        handleChangeMonthGenPub,

        validDateFrom, validDateTo,

        handleFilterSearchNims,

        filterTypeClient, handleSearchExtraP,

        id_branchI, branch, handleSelectValuesGenPub,

        handleCloseFilterCompany, view_filter_company, handleViewCompany,
        dateFrom, dateTo, handleInputChangeFech,
        removeInvoice,

        type_invoice_company, dicountGlobal, totalGlobal, description, formInvoice,
        examInvoice, priceInvoice, id_work_order_r, request_id_invoice,

        handleChangeDescription, is_general_public_type_description, typeGlobal

    } = props;

    const {
        _list_exams_invoice_valid,
    } = validationsInvoice;

    const InputDatePeriodicity = <Col sm="10" md="10" lg="10" className='p-l-0 p-r-0' >
        <FormGroup>
            <Input
                className='form-control form-control-sm input-air-primary'
                type='date'
                name='date_periodicity'
                value={date_periodicity}
                onChange={(e) => handleChangeMonthGenPub(e)}
                min={validDateFrom}
                max={validDateTo}
            />
        </FormGroup>
    </Col>

    const InputWeekFromPeriodicity = <Col sm="10" md="10" lg="10">
        <FormGroup>
            <Label className='col-form-label'>Fecha de inicio</Label>
            <Input
                className='form-control form-control-sm input-air-primary'
                type='date'
                name='week_from_periocity'
                value={week_from_periocity}
                onChange={(e) => handleChangeMonthGenPub(e)}
                min={validDateFrom}
                max={validDateTo}
                readOnly={id_periodicity.code !== undefined ? (id_periodicity.code === "04" || id_periodicity.code === "05" ? true : false) : false}
            />
        </FormGroup>
    </Col>

    const InputWeekToPeriodicity = <Col sm="10" md="10" lg="10">
        <FormGroup>

            <Label className='col-form-label'>Fecha de cierre</Label>
            <Input
                className='form-control form-control-sm input-air-primary'
                type='date'
                name='week_to_periocity'
                onChange={handleInputChangeGenPub}
                value={week_to_periocity}
                min={validDateFrom}
                max={validDateTo}
                readOnly={id_periodicity.code !== undefined ? (id_periodicity.code === "04" || id_periodicity.code === "05" ? true : false) : false}
            />
        </FormGroup>
    </Col>

    return (
        <Row>
            <Col sm="12" md="12" lg="12">
                <Card>
                    <CardBody className='p-2'>
                        <Row>
                            {
                                !viewFilterNims && is_general_public && id_month.value !== undefined
                                    ?
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="4">
                                                <label className='txt-secondary'>Selecciona el tipo de descripción:</label>
                                                <br />
                                                <Row>
                                                    <Col sm="2" className='p-l-0 p-r-0' >
                                                    </Col>
                                                    <Col sm="3" className='p-l-0' >
                                                        <div id='btn-tooltip-filter-public' className={is_general_public_type_description === '1' ? 'typeInvoicelabel' : 'InvalidtypeInvoicelabel'} onClick={() => handleChangeDescription("1")}  >
                                                            <i className="fa fa-user f-16">
                                                                <ToolTips
                                                                    placement="top"
                                                                    dataTarget='btn-tooltip-filter-public'
                                                                    dataText='Ventas'
                                                                />
                                                            </i>
                                                        </div>
                                                    </Col>

                                                    <Col sm="4" className='p-l-0' >
                                                        <div id='btn-tooltip-filter-public_detail' className={is_general_public_type_description === '2' ? 'typeInvoicelabel' : 'InvalidtypeInvoicelabel'} onClick={() => handleChangeDescription("2")} >
                                                            <i className="icofont icofont-laboratory f-16">
                                                                <ToolTips
                                                                    placement="top"
                                                                    dataTarget='btn-tooltip-filter-public_detail'
                                                                    dataText='Detallado por Periodo'
                                                                />

                                                            </i>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm="5">
                                                <label className='txt-secondary'>Selecciona el rango de fechas a facturar</label>
                                                <Row>
                                                    <Col sm="6" >
                                                        {
                                                            !viewFilterNims && is_general_public && (id_periodicity.code !== undefined && (id_periodicity.code !== "01")) && (id_month.value !== undefined && id_month.value > 0)
                                                                ? InputWeekFromPeriodicity : ""
                                                        }
                                                        {
                                                            !viewFilterNims && is_general_public && (id_periodicity.code !== undefined && id_periodicity.code === "01") && (id_month.value !== undefined && id_month.value > 0)
                                                                ? InputDatePeriodicity : ""
                                                        }
                                                    </Col>
                                                    <Col sm="6">
                                                        {
                                                            !viewFilterNims && is_general_public && (id_periodicity.value !== undefined && (id_periodicity.code !== "01")) && (id_month.value !== undefined && id_month.value > 0)
                                                                ? InputWeekToPeriodicity : ""
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>


                                            <Col sm="3" className='p-l-0' >
                                                <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0' >
                                                    <Label className='txt-secondary'>Filtrar por Sucursal</Label>
                                                    <Select
                                                        name="id_branchI"
                                                        isClearable={true}
                                                        placeholder="Selecciona una sucursal"
                                                        noOptionsMessage="Sin resultados"
                                                        options={branch}
                                                        onChange={(e) => handleSelectValuesGenPub(e, 'id_branchI')}
                                                        value={id_branchI}
                                                        className="b-r-9"
                                                    />
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>

                                    :
                                    ""
                            }
                        </Row>

                        <Row>
                            {
                                !viewFilterNims && is_general_public && id_month.value !== undefined
                                    ?
                                    <Col sm="8" md="8" lg="8">
                                        <Row>
                                            <Col sm="5" md="" lg="5"> <br />
                                                <Label className='txt-secondary'>Método de pago</Label>
                                                <Select
                                                    name="idFilterPayMethod"
                                                    isClearable={true}
                                                    placeholder="Método de pago"
                                                    noOptionsMessage="Sin resultados"
                                                    options={listFilterPayMethods}
                                                    onChange={(e) => handleSelectValuesGenPub(e, 'idFilterPayMethod')}
                                                    value={idFilterPayMethod}
                                                    className="b-r-9 input-sm"
                                                />
                                            </Col>
                                            <Col sm="1"></Col>
                                            <Col sm="4" md="4" lg="4"> <br />
                                                <Label></Label>
                                                <Button outline color='primary' type='button' className='btn btn-sm mt-4' onClick={handleFilterSearchNims}>
                                                    Búscar resultados
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    : ""
                            }
                            {filterTypeClient === "3" ? '' :  //aqui estoy modificando pruebas
                                <Col sm="5" md="5" lg="5" className={viewFilterNims ? '' : 'd-none' || view_filter_company ? "" : ""} hidden={view_filter_company} >
                                    <FormGroup>
                                        <Label className='col-form-label'>Selecciona el/los NIM</Label>
                                        <Select
                                            className="input-air-primary input-xs"
                                            classNamePrefix='select'
                                            placeholder={PlaceHolderOption}
                                            options={listNims}
                                            value={filterWorkOrder}
                                            onChange={(e) => handleChangeNim(e)}
                                        />
                                        <FormText color='danger' hidden={!_list_exams_invoice_valid}>Selecciona al menos un Nim</FormText>
                                    </FormGroup>
                                </Col>
                            }
                            {
                                filterTypeClient === "1" && <Col sm="2" md="2" lg="2" >
                                    <br />
                                    <br />
                                    <Button color='primary' id='btn-tooltip-filter-new-patients' type='button' className='btn btn-pill btn-sm' onClick={handleSearchExtraP}>
                                        <i className="fa fa-group"></i>
                                        <ToolTips
                                            placement="top"
                                            dataTarget={'btn-tooltip-filter-new-patients'}
                                            dataText='Añadir más pacientes'
                                        />
                                    </Button>
                                </Col>
                            }
                            {
                                filterTypeClient === "2" && <Col sm="1" md="1" lg="1" className={id_work_order_r === undefined ? "" : "d-none"} hidden={view_filter_company}  > <br /> <br />
                                    <Button id='btn-tooltip-filter-company' outline color='secondary' onClick={handleViewCompany} className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 60 }}
                                    >
                                        <i className="icofont icofont-filter"></i>
                                        <ToolTips
                                            placement="top"
                                            dataTarget='btn-tooltip-filter-company'
                                            dataText='Seleccionar rango de fechas'
                                        />
                                    </Button>
                                </Col>
                            }
                            {
                                filterTypeClient === "2" && <Col sm="4" md="4" lg="4" className={`${view_filter_company ? 'animate__animated animate__flipInX m-t-10' : 'd-none'} `}>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label className='col-form-label'>Fecha de inicio</Label>
                                                <Input
                                                    className='form-control form-control-sm input-air-primary'
                                                    type='date'
                                                    value={dateFrom}
                                                    name='dateFrom'
                                                    onChange={handleInputChangeFech}
                                                    readOnly={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label className={`${view_filter_company ? 'animate__animated animate__flipInX m-t-10' : 'd-none'} `}>Fecha de inicio</Label>
                                                <Input
                                                    className='form-control form-control-sm input-air-primary'
                                                    type='date'
                                                    value={dateTo}
                                                    name='dateTo'
                                                    onChange={handleInputChangeFech}
                                                    readOnly={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                </Col>
                            }
                            {
                                filterTypeClient === "2" && <Col sm="2" md="2" lg="" className={`${view_filter_company ? 'animate__animated animate__flipInX m-t-14' : 'd-none'} `}>
                                    <br />
                                    <Button color='primary' type='button' onClick={() => handleChangeNim(0, true)} className='btn btn-sm mt-4' disabled={loadingFilterWorkOrders}>
                                        <span className='icon icon-filter'></span> &nbsp; Búscar resultados
                                    </Button>
                                </Col>
                            }
                            {
                                filterTypeClient === "2" && <Col sm="1" md="1" lg="" className={`${view_filter_company ? 'animate__animated animate__flipInX m-t-10' : 'd-none'} `}>
                                    <br /> <br />
                                    <Button id='btn-tooltip-filter-company-d' outline color='danger' onClick={handleCloseFilterCompany} className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 60 }}
                                    >
                                        X
                                        <ToolTips
                                            placement="top"
                                            dataTarget='btn-tooltip-filter-company-d'
                                            dataText='Regresar a Busqueda individual'
                                        />
                                    </Button>


                                </Col>
                            }
                        </Row>

                        <Row>
                            {
                                <div>
                                    <Col sm="12" md="12" lg="12" className={type_invoice_company === "1" || filterTypeClient === '1' || filterTypeClient === '3' ? "p-l-0" : 'd-none'}>
                                        <div className='table-responsive'> <br /> <br />
                                            <Table className='table-sm table-stripped' >
                                                <thead className="theadInvoice">
                                                    <tr>
                                                        <th className='text-center labeltheadInvoice'>Cantidad</th>
                                                        <th className='text-center labeltheadInvoice'>Clave de producto</th>
                                                        <th className='text-center labeltheadInvoice'>Clave unidad</th>
                                                        <th className='text-center labeltheadInvoice'>No. Identificación</th>
                                                        <th className='text-center labeltheadInvoice'>Descripción</th>
                                                        <th className='text-center labeltheadInvoice'>Valor unitario</th>
                                                        <th className='text-center labeltheadInvoice'>Descuento</th>
                                                        <th className='text-center labeltheadInvoice'>Importe</th>
                                                        {/* <th className='text-center labeltheadInvoice'>IVA</th>
                                                        <th className='text-center labeltheadInvoice'>Total</th> */}
                                                        <th className={id_work_order_r === undefined ? "labeltheadInvoice" : "d-none"} >Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        loadingFilterWorkOrders && <tr>
                                                            <td colSpan={9} className='bg-info text-center f-16'>
                                                                <i className="fa fa-spin fa-spinner"></i> Cargando...
                                                            </td>
                                                        </tr>
                                                    }
                                                    {
                                                        !loadingFilterWorkOrders && listExamsInvoice.length > 0 && listExamsInvoice.map((obj, _index) => {
                                                            return (
                                                                <tr key={`td-invoice-test-id-${obj.id_work_order_exam}`} className={obj.is_deleted ? 'd-none' : ''}>
                                                                    <td style={{ textAlign: "center" }} >1</td>
                                                                    <td style={{ textAlign: "center" }} >{obj.is_general_public ? obj.product_code_service : obj.class_product_code_service}</td>
                                                                    <td style={{ textAlign: "center" }} >{obj.code_unit + ' ' + obj.name_unit}</td>
                                                                    <td style={{ textAlign: "center" }} >{obj.nim}</td>
                                                                    <td style={{ textAlign: "center" }} className='text-center'>{obj.is_general_public ? typeGlobal : obj.name + " " + obj.name_patient}</td>
                                                                    <td style={{ textAlign: "center" }} >{obj.price}</td>
                                                                    <td style={{ textAlign: "center" }} >{obj.total_discount}</td>
                                                                    <td style={{ textAlign: "center" }} >{obj?.import}</td>
                                                                    {/* <td style={{textAlign : "center"}} >{ obj?.price_tax }</td>
                                                                    <td style={{textAlign : "center"}} >{ obj?.total }</td> */}
                                                                    <td style={{ textAlign: "center" }} className={id_work_order_r === undefined ? "" : "d-none"} >
                                                                        {
                                                                            <Button className=" btn-air-danger " outline color="danger" size="xs" key={_index} onClick={() => removeInvoice(obj.id_work_order, obj.id_invoice_detail, (request_id_invoice !== undefined ? true : false))}>
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </Button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="12" lg="12" className={type_invoice_company === "2" ? "" : "d-none"}>
                                        <div className='table-responsive'>
                                            <Table className='table table-sm table-stripped'>
                                                <thead className="theadInvoice">
                                                    <tr>
                                                        <th className='text-center labeltheadInvoice'>Clave de producto</th>
                                                        <th className='text-center labeltheadInvoice'>Clave unidad</th>
                                                        <th className='text-center labeltheadInvoice'>No. Identificación</th>
                                                        <th className='text-center labeltheadInvoice'>Descripción</th>
                                                        <th className='text-center labeltheadInvoice'>Valor unitario</th>
                                                        <th className='text-center labeltheadInvoice'>Importe</th>
                                                        <th className='text-center labeltheadInvoice'>Descuento</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr >
                                                        <td className='text-center'>{"85121800"}</td>
                                                        <td className='text-center'>{"E48"}</td>
                                                        <td className='text-center'>{"1"}</td>
                                                        <td className='text-center'>
                                                            <Input size={'sm'} value={description} onChange={handleInputChange} name='description' autoComplete='off' className='form-control form-control-sm input-air-primary' /></td>
                                                        <td className='text-center'>{(totalGlobal).toFixed(2)}</td>
                                                        <td className='text-center'>{(totalGlobal).toFixed(2)}</td>
                                                        <td className='text-center'>{(dicountGlobal).toFixed(2)}</td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </div>

                                    </Col>
                                    <Col sm="12" md="12" lg="12" className={type_invoice_company === "3" ? "" : "d-none"}>
                                        <div className="table-responsive">
                                            <Table className='table table-sm table-stripped'>
                                                <thead className="theadInvoice">
                                                    <tr>
                                                        <th className='text-center labeltheadInvoice'>Clave de producto</th>
                                                        <th className='text-center labeltheadInvoice'>Clave unidad</th>
                                                        <th className='text-center labeltheadInvoice'>Cantidad</th>
                                                        <th className='text-center labeltheadInvoice'>Descripción</th>
                                                        <th className='text-center labeltheadInvoice'>Valor unitario</th>
                                                        <th className='text-center labeltheadInvoice'>Importe</th>
                                                        <th className='text-center labeltheadInvoice'>Descuento</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        examInvoice.length > 0 && examInvoice.sort() && examInvoice.map((a, b) => {
                                                            console.log(a);
                                                            return (
                                                                <tr>
                                                                    <td className='text-center'>{"85121800"}</td>
                                                                    <td className='text-center'>{"E48"}</td>
                                                                    <td className='text-center'>{a.amount}</td>
                                                                    <td className='text-center'>{a.NameExamAndProfile}</td>
                                                                    <td className='text-center'>${a.price}</td>
                                                                    <td className='text-center'>${a.price}</td>
                                                                    <td className='text-center'>${a.priceDiscount}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </div>
                            }
                        </Row>
                        <Row className='mt-5'>
                            <Col sm="6" md="6" lg="6">
                                <Badge className='f-12 badgeInvoice' style={{ width: '100%' }}>Comentarios de la remisión</Badge>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <Input type='textarea' name='comments_invoice' value={comments_invoice} onChange={handleInputChange} className='form-control form-control-sm input-air-primary' rows="5" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Badge className='f-12 badgeInvoice' style={{ width: '100%' }}>Detalle</Badge>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <div className='table-responsive'>
                                            <Table className='table table-sm table-stripped'>
                                                <tbody>
                                                    <tr>
                                                        <td className='text-right'><b>Subtotal</b></td>
                                                        <td><b><span className={iconCoin}></span> {subtotalInvoice} </b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'><b>Descuento</b></td>
                                                        <td><b><span className={iconCoin}></span> {totalDiscountsInvoice}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'><b>IVA</b></td>
                                                        <td><b><span className={iconCoin}></span> {totalTaxInvoice}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'><b>Ret. I.S.R</b></td>
                                                        <td><b><span className={iconCoin}></span> {totalRetentions}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'><b>Total</b></td>
                                                        <td><b><span className={iconCoin}></span> {totalInvoice}</b></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
