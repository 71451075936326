import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';



export const usePantalla1 = () => {

	//local Storage
	let id = localStorage.getItem('id');

	const history = useHistory();
	const [method, setMethod] = useState("");
	const [modal, setModal] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({})
	const [setFocusNim, setSetFocusNim] = useState(true);
	const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate] = useSweetAlert();

	const [formNim, handleInputChangeNim, resetNim, handleUpdateValuesNim, handleSelectValuesNim] = useForm({
		nim: "",
	});

	const { nim } = formNim;

	var hoy = new Date();
	var fecha = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();
	var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
	var fechaYHora = fecha + ' ' + hora;

	const nimAutoFocus = () => {
		if (setFocusNim) {
			if (document.getElementById("inputFocusNim") !== null) {
				document.getElementById("inputFocusNim").focus();
			}
		}
	}

	useEffect(() => {
		nimAutoFocus()
	}, [setFocusNim]);

	//#region modal

	const openModal = () => {
		setModal(true);
		setSetFocusNim(false);
	}

	const closeModal = () => {
		setModal(false);
		setSetFocusNim(true);
		handleUpdateValuesNim({
			nim: ""
		})
	}

	const handleCardID = () => {
		setMethod("list");
		setModalTitle("Codigo de Barras");
		openModal();
		setSetFocusNim(false);
	}
	//#endregion modal

	//#region Pstient
	const getDataNim = (e) => {
		e.preventDefault();
		setLoading(true);

		if (formNim.nim.length > 0) {
			getDataFromNimPost();
		}
		else {
			createSweett("", "Debes de ingresar un NIM válido", "warning");
			setLoading(false);
			return;
		}
		setLoading(false);
	}

	const getDataFromNimPost = async () => {

		let _newForm = {
			nim: nim.substring(0, 13),
			id_departament: localStorage.getItem('depW')
		}


		let listTmp = await handleRequest("POST", "Sass/GetDataFromPatient", "Sample", _newForm);

		if (listTmp !== null) {
			let _name = ""
			let _surname = ""

			listTmp.lista_pruebas.forEach(_find => {
				_name = _find.name
				_surname = _find.surname
			});
			setData(listTmp.lista_pruebas)
			saveCreateSample(_name, _surname)
		}
		else {
			handleUpdateValuesNim({
				nim: ""
			});
			createSweett("", "Debes de ingresar un nim valido ", "warning");
		}
	}
	//#endregion Patient

	function onKeyPressNim(_event) {
		if (_event.keyCode === 13) {
			let _mValue = _event.target.value;
			setLoading(true);

			if (_mValue.length > 0) {
				getDataFromNimPost();
			}
			else {
				createSweett("", "Debes de ingresar un nim valido", "warning");
				handleUpdateValuesNim({
					nim: ""
				});
				setSetFocusNim(true);
				setLoading(false);
			}
		}
	}

	const saveCreateSample = async (_name, _surname) => {
		let _newForm = {
			id_user: id,
			nim: nim.substring(0, 13),
			name: _name,
			surnames: _surname,
			init: fechaYHora
		}

		//console.log(_newForm);

		//aqui esta../paciente/

		let listTmp = await handleRequest("POST", "Sample/Create", "Sample", _newForm);

		if (listTmp !== null) {
			localStorage.removeItem('timeInit');
			localStorage.setItem('timeInit', fechaYHora);
			history.push('../paciente/' + nim.substring(0, 13));
		}
		else {
			createSweet("info", "info", "Atención", listTmp.msg);//wilson
			handleUpdateValuesNim({
				nim: ""
			});
			closeModal()
			setSetFocusNim(true);
			setLoading(false);
		}
	}

	const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
		let requestOptions = {
			method: method
		};

		if (body !== null) {
			requestOptions = {
				...requestOptions,
				body: JSON.stringify(body)
			};
		}
		const response = await sendRequest(requestOptions, methodUrl);

		if (response.code === 200) {
			return response.data;
		}
		else if (response.code === 404) {
			createSweet("info", "info", "Atención", response.data.msg);
		}
		else if (response.code === 409) {
			createSweet("info", "info", "Atención", response.data.msg);
		}
		else {
		}
		return null;
	}

	return {
		closeModal, handleCardID, method, modalTitle, modal, loading,
		formNim, getDataNim, data, handleInputChangeNim, nim, fechaYHora, getDataFromNimPost,
		setFocusNim, nimAutoFocus, onKeyPressNim, handleSelectValuesNim
	}
}