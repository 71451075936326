import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useDebounce } from '../debounce/useDebounce';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import moment from 'moment';

export const usePatients = () => {

    //#region GLOBAL VARIABLES
    const history = useHistory();
    //#endregion

    //#region MODALS
    const [loading, setLoading] = useState(false);

    const [method, setMethod] = useState("");

    const [modal, setModal] = useState(false);
    const [modalQr, setModalQr] = useState(false);

    const [modalTitle, setModalTitle] = useState("");

    const toggle = () => {
        setModal(!modal);
        setLoading(false);
        setIsInvoiceService(false);
    }

    const toggleModalQr = () => {
        setModalQr(!modalQr);
    }
    //#endregion

    //#region SHOW DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //#endregion

    //#region PATIENS

    //#region PATIENTS MODEL
    //#region MODELS
    const [isInvoiceService, setIsInvoiceService] = useState(false);
    const [idPatient, setIdPatient] = useState(0);
    const [viewNewSocialGender, setViewNewSocialGender] = useState(false);
    //#endregion

    //#region LIST
    const [listPatients, setListPatients] = useState([]);
    const [listPatientsData, setListPatientsData] = useState([]);
    const [listStates, setListStates] = useState([]);
    const [listMunicipalities, setListMunicipalities] = useState([]);
    const [listBloodType, setListBloodType] = useState([]);
    const [listTaxRegime, setListTaxRegime] = useState([]);
    const [listUseCfdi, setListUseCfdi] = useState([]);
    const [listSocialGender, setListSocialGender] = useState([]);
    //#endregion

    //#region FILTERS
    const [searchQuery, setSearchQuery] = useState("");
    const [filters, setFilters] = useState("");
    const debounceValue = useDebounce(searchQuery, 500);
    const [filtersForm, setFiltersForm] = useState(true);
    //#endregion

    //#region FORM VALUES
    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues, handleDinamicInput, handleDate] = useForm({
        id_gender: 0,
        id_state: 0,
        id_municipality: 0,
        id_blood_type: 0,
        id_social_gender: 0,
        id_tax_regime: 0,
        id_use_cfdi: 0,
        rfc: "",
        type_person: 1,
        code: "",
        paternal_surname: "",
        maternal_surname: "",
        name: "",
        birthday: "",
        age: "",
        curp: "",
        address: "",
        cp: "",
        colony: "",
        phone: "",
        email: "",
        membership: "",
        cardnumber: "",
        new_social_gender: ""
    });

    const {
        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
        id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, curp, age,
        address, cp, colony, phone, email, membership, cardnumber, new_social_gender
    } = formValues;

    const [formFilter, handleInputFilter, resetFilter, handleUpdateValuesFilter] = useForm({
        curpFilter: "",
        nameFilter: "",
        phoneFilter: "",
        emailFilter: "",
        membershipFilter: ""
    });

    const {
        curpFilter, nameFilter, phoneFilter, emailFilter, membershipFilter
    } = formFilter;

    //#endregion

    //#region VALIDATIONS
    const [validationsPatient, setValidationsPatient] = useState({
        id_gender_valid: false,
        paternal_surname_valid: false,
        maternal_surname_valid: false,
        name_valid: false,
        birthday_valid: false,
        age_valid: false,
        phone_valid: false,
        email_valid: false,
        curp_valid: false,
        cp_patient_valid: false,
        id_tax_regime_valid: false,
        rfc_valid: false,
        id_use_cfdi_valid: false
    });
    //#endregion

    //#endregion        

    //#region PATIENTS METHODS

    //#region PAGINATIONS
    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        'Patient/ListPatient',
        history,
        searchQuery,
        filters
    );
    //#endregion

    //#region GET METHODS
    const getStates = async () => {
        const _list = await _handleRequest("GET", "State/List", "Estados");

        if (_list !== null && _list.length > 0) {

            let _list_states = [];
            _list.forEach(_state => {
                _list_states.push({
                    label: _state.name,
                    value: _state.id_state
                });
            });

            setListStates(_list_states);
        }
        else {
            setListStates([]);
        }
    }

    const   getMunicipality = async (_id_state) => {
        const _list = await _handleRequest("GET", `Municipality/List/${_id_state}`, "Municipios");

        if (_list !== null && _list.length > 0) {
            let _list_municipalities = [];

            _list.forEach(_municipality => {
                _list_municipalities.push({
                    label: _municipality.name,
                    value: _municipality.id_municipality
                });
            });

            setListMunicipalities(_list_municipalities);
        }
        else {
            setListMunicipalities([]);
        }
    }

    const getBloodType = async () => {
        const _list = await _handleRequest("GET", "BloodType/List", "Tipo sanguineo");

        if (_list !== null && _list.length > 0) {
            let _list_blood = [];
            _list.forEach(_blood => {
                _list_blood.push({
                    label: _blood.blood_type,
                    value: _blood.id_blood_type
                });
            });

            setListBloodType(_list_blood);
        }
        else {
            setListBloodType([]);
        }
    }

    const getTaxRegime = async (_type_person = 1) => {
        const _list = await _handleRequest("GET", `CfdiCatalog/ListTaxRegime/4.0?${_type_person === 1 ? 'physical_person=true' : 'moral_person=true'}`, 'Régimen fiscal');

        if (_list !== null && _list.length > 0) {
            let _list_tax_regime = [];

            _list.forEach(_tax => {
                _list_tax_regime.push({
                    value: _tax.id_tax_regime,
                    label: _tax.code + " " + _tax.name
                });
            });

            setListTaxRegime(_list_tax_regime);
        }
        else {
            setListTaxRegime([]);
        }
    }

    const getUseCfdi = async (_id_tax_regime, _type_person = 1) => {
        const _list = await _handleRequest("GET", `CfdiCatalog/ListUseCfdi/${_id_tax_regime}/${_type_person === 1 ? true : false}`);

        if (_list !== null && _list.length > 0) {
            let _list_use = [];

            _list.forEach(_use => {
                _list_use.push({
                    label: _use.code + " " + _use.name,
                    value: _use.id_use_cfdi
                });
            });

            setListUseCfdi(_list_use);
        }
        else {
            setListUseCfdi([]);
        }
    }

    const getSocialGender = async () => {
        const _list = await _handleRequest("GET", "SocialGender/List", "Género social");

        if (_list !== null && _list.length > 0) {
            let _list_social_gender = [{
                value: 0,
                label: "OTRO"
            }];

            _list.forEach(_gender => {
                _list_social_gender.push({
                    label: _gender.name_social_gender,
                    value: _gender.id_social_gender
                });
            });

            setListSocialGender(_list_social_gender);
        }
        else {
            setListSocialGender([]);
        }
    }
    //#endregion

    //#region HANDLE METHODS

    const handleSearch = (e) => {
        let _search = e.target.value;

        setSearchQuery(_search);
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_gender: 0,
            id_state: 0,
            id_municipality: 0,
            id_blood_type: 0,
            id_social_gender: 0,
            id_tax_regime: 0,
            id_use_cfdi: 0,
            rfc: "",
            type_person: 1,
            code: "",
            paternal_surname: "",
            maternal_surname: "",
            name: "",
            birthday: "",
            age: "",
            curp: "",
            address: "",
            cp: "0",
            colony: "",
            phone: "",
            email: "",
            membership: "",
            new_social_gender: ""
        });

        setValidationsPatient({
            id_gender_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: false,
            name_valid: false,
            birthday_valid: false,
            age_valid: false,
            phone_valid: false,
            email_valid: false,
            curp_valid: false,
            cp_patient_valid: false,
            id_tax_regime_valid: false,
            rfc_valid: false,
            id_use_cfdi_valid: false
        });

        setMethod("create");
        setModalTitle("Nuevo paciente");

        getStates();
        getBloodType();
        getTaxRegime();
        getSocialGender();

        setViewNewSocialGender(false);

        toggle();
    }

    const handleChangeState = (e) => { 
        handleSelectValues(e, "id_state");

        if (e.value !== undefined) {
            let _value = e.value;

            getMunicipality(_value);
        }
    }

    const handleChangeTypePerson = (e) => {

        let _value = e.target.value;

        handleUpdateValues({
            ...formValues,
            id_tax_regime: 0,
            type_person: _value
        });

        getTaxRegime(_value !== '' ? parseInt(_value) : 1);
    }

    const handleChangeTaxRegime = (e) => {

        handleSelectValues(e, "id_tax_regime");

        if (e.value !== undefined) {

            getUseCfdi(e.value, formValues.type_person);
        }
    }

    const handleChangeSocialGender = (e) => {


        handleSelectValues(e, "id_social_gender");

        if (e.value !== undefined) {

            if (e.value === 0) {
                setViewNewSocialGender(true);
            }
            else {
                setViewNewSocialGender(false);
            }
        }
    }

    const handleUpdate = (_id_patient) => {
        let _findPatient = listPatients.find(p => p.id_patient === _id_patient);

        if (_findPatient !== undefined) {

            setIdPatient(_id_patient);

            handleUpdateValues({
                id_gender: _findPatient.id_gender,
                id_state: _findPatient.id_state === null ? 0 : {
                    label: _findPatient.name_state,
                    value: _findPatient.id_state
                },
                id_municipality: _findPatient.id_municipality === null ? 0 : {
                    label: _findPatient.name_municipality,
                    value: _findPatient.id_municipality
                },
                id_blood_type: _findPatient.id_blood_type === null ? 0 : {
                    value: _findPatient.id_blood_type,
                    label: _findPatient.name_blood_type
                },
                id_social_gender: _findPatient.id_social_gender === null ? 0 : {
                    label: _findPatient.name_social_gender,
                    value: _findPatient.id_social_gender
                },
                id_tax_regime: _findPatient.id_tax_regime === null ? 0 : {
                    label: _findPatient.name_tax_regime,
                    value: _findPatient.id_tax_regime
                },
                id_use_cfdi: _findPatient.id_use_cfdi === null ? 0 : {
                    value: _findPatient.id_use_cfdi,
                    label: _findPatient.name_use_cfdi
                },
                rfc: _findPatient.rfc === null ? "" : _findPatient.rfc,
                type_person: _findPatient.type_person,
                paternal_surname: _findPatient.paternal_surname,
                maternal_surname: _findPatient.maternal_surname,
                name: _findPatient.name,
                birthday: _findPatient.birthday,
                age: _findPatient.age,
                curp: _findPatient.curp === null ? "" : _findPatient.curp,
                address: _findPatient.address === null ? "" : _findPatient.address,
                cp: _findPatient.cp,
                colony: _findPatient.colony === null ? "" : _findPatient.colony,
                phone: _findPatient.phone,
                email: _findPatient.email,
                membership: _findPatient.membership === null ? "" : _findPatient.membership,
                cardnumber: "",
                new_social_gender: ""
            });

            getStates();
            if (_findPatient.id_state !== null) {
                getMunicipality(_findPatient.id_state);
            }

            getTaxRegime(_findPatient.type_person);
            if (_findPatient.id_tax_regime !== null) {
                getUseCfdi(_findPatient.id_tax_regime, _findPatient.type_person);
            }

            getSocialGender();
            getBloodType();

            setValidationsPatient({
                id_gender_valid: false,
                paternal_surname_valid: false,
                maternal_surname_valid: false,
                name_valid: false,
                birthday_valid: false,
                age_valid: false,
                phone_valid: false,
                email_valid: false,
                curp_valid: false,
                cp_patient_valid: false,
                id_tax_regime_valid: false,
                rfc_valid: false,
                id_use_cfdi_valid: false
            });

            setMethod("update");
            setModalTitle("Editar paciente");
            toggle();

            setViewNewSocialGender(false);

            if (_findPatient.id_tax_regime !== null)
                setIsInvoiceService(true);
        }
    }

    const handleDelete = (_id_patient) => {
        let _findPatient = listPatients.find(p => p.id_patient === _id_patient);

        if (_findPatient !== undefined) {
            handleUpdateValues({
                paternal_surname: _findPatient.paternal_surname,
                maternal_surname: _findPatient.maternal_surname,
                name: _findPatient.name
            });

            setIdPatient(_id_patient);
            setMethod("delete");
            setModalTitle("Eliminar paciente");
            toggle();
        }
    }

    const handleFilters = () => {
        let _query = "";

        if (curpFilter !== "") {
            _query += `&curp=${curpFilter}`
        }

        if (nameFilter !== "") {
            _query += `&name=${nameFilter}`
        }

        if (phoneFilter !== "") {
            _query += `&phone=${phoneFilter}`
        }

        if (emailFilter !== "") {
            _query += `&email=${emailFilter}`
        }

        if (membershipFilter !== "") {
            _query += `&membership=${membershipFilter}`
        }

        setFilters(_query);
    }

    const handleClearFilters = () => {
        setFilters("");

        handleUpdateValuesFilter({
            curpFilter: "",
            nameFilter: "",
            phoneFilter: "",
            emailFilter: "",
            membershipFilter: ""
        });
    }
    //#endregion

    //#region POST METHODS
    const savePatient = async () => {

        setLoading(true);

        if (validationsPatientForm()) {
            setLoading(false);

            return;
        }

        let _arrBirthday = formValues.birthday.split('/');

        let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

        let _formData = {
            id_gender: formValues.id_gender,
            id_state_patient: formValues.id_state.value === undefined ? null : formValues.id_state.value,
            id_municipality_patient: formValues.id_municipality.value === undefined ? null : formValues.id_municipality.value,
            id_social_gender_patient: formValues.id_social_gender.value === undefined ? null : formValues.id_social_gender.value,
            id_blood_type: formValues.id_blood_type.value === undefined ? null : formValues.id_blood_type.value,
            id_tax_regime: formValues.id_tax_regime.value === undefined ? null : formValues.id_tax_regime.value,
            id_use_cfdi: formValues.id_use_cfdi.value === undefined ? null : formValues.id_use_cfdi.value,
            paternal_surname: formValues.paternal_surname,
            maternal_surname: formValues.maternal_surname,
            name: formValues.name,
            birthday: _stringBirthday,
            age: formValues,
            curp: formValues.curp,
            address_patient: formValues.address,
            cp_patient: formValues.cp,
            colony_patient: formValues.colony,
            phone: formValues.phone,
            email_patient: formValues.email,
            membership: formValues.membership,
            cardnumber: formValues.cardnumber,
            rfc: formValues.rfc,
            type_person: formValues.type_person,
            new_social_gender: formValues.new_social_gender
        };

        let _list = await _handleRequest("POST", "Patient", "Crear paciente", _formData);

        if (_list !== null) {

            createSweet("create", "success", "Exito!", "Paciente creado");
            setLoading(false);

            setListTaxRegime([]);
            setListUseCfdi([]);
            setIsInvoiceService(false);

            handleUpdateValues({
                id_gender: 0,
                id_state: 0,
                id_municipality: 0,
                id_blood_type: 0,
                id_social_gender: 0,
                id_tax_regime: 0,
                id_use_cfdi: 0,
                rfc: "",
                type_person: 1,
                code: "",
                paternal_surname: "",
                maternal_surname: "",
                name: "",
                birthday: "",
                age: "",
                curp: "",
                address: "",
                cp: "0",
                colony: "",
                phone: "",
                email: "",
                membership: "",
            });

            toggle();
        }
    }

    const saveUpdate = async () => {
        setLoading(true);

        if (validationsPatientForm()) {
            setLoading(false);
            return;
        }

        let _arrBirthday = formValues.birthday.split('/');

        let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

        let body = {
            id_patient: idPatient,
            id_gender: formValues.id_gender,
            id_state_patient: formValues.id_state.value === undefined ? null : formValues.id_state.value,
            id_municipality_patient: formValues.id_municipality.value === undefined ? null : formValues.id_municipality.value,
            id_social_gender_patient: formValues.id_social_gender.value === undefined ? null : formValues.id_social_gender.value,
            id_blood_type: formValues.id_blood_type.value === undefined ? null : formValues.id_blood_type.value,
            id_tax_regime: formValues.id_tax_regime.value === undefined ? null : formValues.id_tax_regime.value,
            id_use_cfdi: formValues.id_use_cfdi.value === undefined ? null : formValues.id_use_cfdi.value,
            paternal_surname: formValues.paternal_surname,
            maternal_surname: formValues.maternal_surname,
            name: formValues.name,
            birthday: _stringBirthday,
            age: formValues.age,
            curp: formValues.curp,
            address_patient: formValues.address,
            cp_patient: formValues.cp,
            colony_patient: formValues.colony,
            phone: formValues.phone,
            email_patient: formValues.email,
            membership: formValues.membership,
            rfc: formValues.rfc,
            type_person: formValues.type_person,
            new_social_gender: formValues.new_social_gender
        };

        let _list = await _handleRequest("PUT", "Patient/Update", "Actualizar paciente", body);
        
        if (_list !== null) {
            createSweet("create", "info", "", "Paciente actualizado");
            setLoading(false);

            handleUpdateValues({
                id_gender: 0,
                id_state: 0,
                id_municipality: 0,
                id_blood_type: 0,
                id_social_gender: 0,
                id_tax_regime: 0,
                id_use_cfdi: 0,
                rfc: "",
                type_person: 1,
                code: "",
                paternal_surname: "",
                maternal_surname: "",
                name: "",
                birthday: "",
                age: "",
                curp: "",
                address: "",
                cp: "",
                colony: "",
                phone: "",
                email: "",
                membership: "",
                cardnumber: "",
                new_social_gender: ""
            });

            setIsInvoiceService(false);
            setListTaxRegime([]);
            setListUseCfdi([]);

            toggle();
        }
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        const _list = _handleRequest("DELETE", `Patient/${idPatient}`, "Eliminar paciente");

        if (_list !== null) {
            setFilters("");
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Paciente eliminado");
            toggle();
        }
    }
    //#endregion

    //#endregion

    //#region USE EFFECTS   
    useEffect(() => {

        let _list_patients = [];

        results.forEach(_patient => {
            //console.log(_patient);
            let visibleTable = true;

            _list_patients.push({
                id_patient: _patient.id_patient,
                id_gender: _patient.id_gender,
                id_state: _patient.id_state_patient,
                id_municipality: _patient.id_municipality_patient,
                id_blood_type: _patient.id_blood_type,
                id_social_gender: _patient.id_social_gender,
                id_tax_regime: _patient.id_tax_regime,
                id_use_cfdi: _patient.id_use_cfdi,
                code: _patient.code,
                paternal_surname: _patient.paternal_surname,
                maternal_surname: _patient.maternal_surname,
                name: _patient.name,
                name_gender: _patient.id_gender === 1 ? "FEMENINO" : "MASCULINO",
                birthday: moment(_patient.birthday).format("DD/MM/YYYY"),
                age: _patient.age,
                curp: _patient.curp,
                phone: _patient.phone,
                email: _patient.email,
                address: _patient.address_patient,
                cp: _patient.cp_patient,
                colony: _patient.colony_patient,
                membership: _patient.membership,
                name_state: _patient.name_state_patient,
                name_municipality: _patient.name_municipality_patient,
                visibleTable: visibleTable,
                unique_uuid: _patient.unique_uuid,
                qr_unique_id: _patient.qr_unique_id,
                rfc: _patient.rfc,
                type_person: _patient.type_person,
                name_social_gender: _patient.name_social_gender,
                name_tax_regime: _patient.name_tax_regime,
                name_use_cfdi: _patient.name_use_cfdi,
                name_blood_type: _patient.name_blood_type
            });
        });

        setListPatients(_list_patients);
        setListPatientsData(results);

    }, [results]);

    useEffect(() => {
        searchAsync();
    }, [debounceValue]);

    useEffect(() => {
        searchAsync();
    }, [filters]);
    //#endregion
    //#endregion

    //#region VALIDATIONS
    const validationsPatientForm = () => {

        let _statusValidation = false;
        let _newValidations = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formValues.name.length < 2) {
            _newValidations = {
                ..._newValidations,
                name_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                name_valid: false
            };
        }

        if (formValues.paternal_surname.length < 2) {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: false
            };
        }

        if (formValues.birthday === "") {
            _newValidations = {
                ..._newValidations,
                birthday_valid: true
            };

            _statusValidation = true;
        }
        else if (formValues.birthday !== "") {

            let _stringBirthday = "";

            if (formValues.birthday.length === 10) {
                let _arrBirthday = formValues.birthday.split('/');

                _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

                let _validate_date = moment(_stringBirthday).format("YYYY-MM-DD");

                if (_validate_date === "Fecha inválida") {
                    _newValidations = {
                        ..._newValidations,
                        birthday_valid: true
                    };

                    _statusValidation = true;
                }
                else {
                    let _actualDate = new Date();
                    let _newDate = new Date(_validate_date);

                    if (_actualDate < _newDate) {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: true
                        };

                        _statusValidation = true;
                    }
                    else {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: false
                        };
                    }
                }
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    birthday_valid: true
                };

                _statusValidation = true;
            }
        }

        if (formValues.id_gender !== "" && (formValues.id_gender === 0 || formValues.id_gender === "0")) {
            _newValidations = {
                ..._newValidations,
                id_gender_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                id_gender_valid: false
            };
        }

        if (formValues.curp.length < 10) {
            _newValidations = {
                ..._newValidations,
                curp_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                curp_valid: false
            };
        }

        if (formValues.cp === "" || formValues.cp !== "" && (formValues.cp === 0 || formValues.cp === "0")) {
            _newValidations = {
                ..._newValidations,
                cp_patient_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                cp_patient_valid: false
            };
        }

        if (formValues.phone.length < 3) {
            _newValidations = {
                ..._newValidations,
                phone_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                phone_valid: false
            };
        }

        if (!pattern.test(formValues.email)) {
            _newValidations = {
                ..._newValidations,
                email_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                email_valid: false
            };
        }

        if (isInvoiceService) {
            if ((formValues.type_person == 1 && formValues.rfc.length < 12) || (formValues.type_person === 2 && formValues.rfc.length < 11)) {
                _newValidations = {
                    ..._newValidations,
                    rfc_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    rfc_valid: false
                };
            }

            if (formValues.id_tax_regime.value == undefined) {
                _newValidations = {
                    ..._newValidations,
                    id_tax_regime_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    id_tax_regime_valid: false
                };
            }

            if (formValues.id_use_cfdi.value == undefined) {
                _newValidations = {
                    ..._newValidations,
                    id_use_cfdi_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    id_use_cfdi_valid: false
                }
            }
        }

        setValidationsPatient(_newValidations);

        return _statusValidation;
    }

    //#endregion

    //#region GLOBAL REQUEST
    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un problema al intentar la petición");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }
    //#endregion

    return {
        //MODALS
        modal, modalTitle, method, loading,
        toggle,

        //MODELS
        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender,
        //LIST
        listPatients, listStates, listMunicipalities, listBloodType,
        listTaxRegime, listUseCfdi, listSocialGender,

        //FORMS
        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
        id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
        curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender,

        //FORM METHODS
        handleInputChange, handleSelectValues, handleDate,

        //FILTERS FORM
        curpFilter, nameFilter, phoneFilter, emailFilter, membershipFilter,

        //FILTERS
        formFilter, filtersForm, setFiltersForm, handleInputFilter,

        //VALIDATIONS
        validationsPatient,

        //HANDLE METHODS
        handleSearch, handleCreate, handleChangeState, handleChangeTypePerson, handleChangeTaxRegime,
        handleUpdate, handleDelete, handleFilters, handleClearFilters, handleChangeSocialGender,

        //METHODS
        savePatient, saveUpdate, saveDelete,

        //PAGINATION
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,

    }
}
