import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';


export const useUsers = () => {

    const history = useHistory();

    const [users, setUsers] = useState([]);
    const [idUser, setIdUser] = useState(0);
    const [userSearch, setUserSearch] = useState([]);

    const [departaments, setDepartaments] = useState([]);

    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(false);
    const [method, setMethod] = useState("");

    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        username: "",
        name: "",
        paternal_surname: "",
        maternal_surname: "",
        job: "",
        card_code: "",
        areas: [],
    });

    const [validaciones, setValidaciones] = useState({
        username_valid: false,
        name_valid: false,
        paternal_surname_valid: false,
        maternal_surname_valid: false,
        job_valid: false,
        card_code_valid: false,
        areas_valid: false,
    });

    const { id_external, name, paternal_surname, maternal_surname, username, areas, job, card_code, } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(users.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listUsers = [];
        users.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listUsers.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setUsers(listUsers);
    }, [totalPageCount, currentPage])

    const getUsers = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let listUsers = [];

        const respuesta = await sendRequest(requestOptions, "Users");

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;

                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    let name_areas;

                    if (data.areas.length > 0) {
                        name_areas = [];
                        data.areas.forEach(element => {
                            name_areas.push([
                                false,
                                element.name
                            ])
                        });
                    } else {
                        name_areas = "Sin departamentos asignados";
                    }
                    if (name_areas !== null) {
                        //console.log('Voy entrar')
                        listUsers.push({
                            ...data,
                            name_areas: name_areas,
                            visibleTable: visibleTable
                        });
                    }
                });
                setUsers(listUsers);
                setUserSearch(listUsers);
            }
        } else {
            validarSesion(history, respuesta.code, getUsers);
        }
    }

    useEffect(() => {
        getUsers();
        getDepartament();
    }, [history]);


    const getDepartament = async () => {

        let listTmp = await handleRequest("GET", "Departaments/List", "Departamentos");

        if (listTmp !== null && listTmp.length > 0) {

            let listDepartaments = [];

            listTmp.forEach((obj, key) => {
                listDepartaments.push({
                    value: obj.id_departament,
                    label: obj.name,
                    name: obj.name
                });
            });
            setDepartaments(listDepartaments);
        }
    }

    const toggle = () => {
        setModal(!modal);
    }

    //#region CRUD USUARIO
    const handleCreate = () => {
        handleUpdateValues({
            username: "",
            name: "",
            paternal_surname: "",
            maternal_surname: "",
            job: "",
            card_code: "",
            areas: [],
        });

        setValidaciones({
            id_external_valid: false,
            username_valid: false,
            name_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: false,
            job_valid: false,
            card_code_valid: false,
            areas_valid: false,
        });

        setMethod("create");
        setModalTitle("Crear nuevo usuario");
        toggle();
        getDepartament();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }


        let listTmp = await handleRequest("POST", `Users/Create`, 'Usuario', formValues);

        if (listTmp !== null) {

            setLoading(false);
            handleUpdateValues({
                username: "",
                name: "",
                paternal_surname: "",
                maternal_surname: "",
                job: "",
                card_code: "",
                areas: [],
            })
            createSweet("create", "success", "Exito!", "Se creo correctamente");
            getUsers();
            toggle();
        }
    }

    const handleUpdate = (id_user) => {
        let user = users.find(p => p.id_user === parseInt(id_user));
        handleUpdateValues({
            username: user.username,
            name: user.name,
            paternal_surname: user.paternal_surname,
            maternal_surname: user.maternal_surname,
            job: user.job,
            card_code: user.card_code,
            areas: user.areas,
        });

        setValidaciones({
            id_external_valid: false,
            username_valid: false,
            name_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: false,
            job_valid: false,
            card_code_valid: false,
            areas_valid: false,
        });
        setIdUser(id_user);
        setMethod("update");
        setModalTitle("Actualizar usuario");
        toggle();
    }


    const saveUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = {
            id_user: idUser,
            id_departament: departaments,
            ...{ ...formValues }
        };

        let listTmp = await handleRequest("PUT", `Users/Update`, 'Usuario', mValues);

        if (listTmp !== null) {

            createSweet("update", "info", "Exito!", "Usuario Actualizado");
            getUsers();
            setLoading(false);
            toggle();
        }
    }


    const handleDelete = (id_user) => {
        let user = users.find(p => p.id_user === parseInt(id_user));

        if (user) {
            handleUpdateValues({
                username: user.username,
                name: user.name,
                paternal_surname: user.paternal_surname,
                maternal_surname: user.maternal_surname,
            });
            setIdUser(id_user);
            setMethod("delete");
            setModalTitle("Eliminar usuario");
            toggle();
        }
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Users/${idUser}`);
        if (respuesta !== null) {
            getUsers();
            setIdUser(0);
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Se elimino correctamente");
            toggle();
        }
        else {
            validarSesion(history, respuesta.code, saveDelete);
        }
    }


    //#endregion CRUD USUARIO

    //#region list Section and Departament

    const [dataSections, setDataSections] = useState([]);
    const [typeModal, setTypeModal] = useState("viewP");
    const [modalSection, setModalSection] = useState(false);
    const [dataAreas, setDataAreas] = useState([]);
    const [typeModal2, setTypeModal2] = useState("viewA");
    const [modalArea, setModalArea] = useState(false);

    const toggle2 = () => {
        setModalSection(!modalSection);
    }

    const toggle3 = () => {
        setModalArea(!modalArea);
    }

    const handleListSection = (id_user) => {

        let departament = users.find(a => a.id_user === parseInt(id_user));

        if (departament != null) {
            if (departament.sections.length >= 1) {
                setDataSections(departament.sections)
            }
            else {
                setDataSections([]);
            }

            setTypeModal("viewP");
            toggle2();
        }
    }


    const handleListArea = (id_user) => {

        let area = users.find(a => a.id_user === parseInt(id_user));

        if (area != null) {
            if (area.areas.length >= 1) {
                setDataAreas(area.areas)
            }
            else {
                setDataAreas([]);
            }

            setTypeModal2("viewA");
            toggle3();
        }
    }


    //#endregion list Section and Departament

    //#region VALIDATION FORM
    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name === "" || formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            };
        }


        if (formValues.paternal_surname === "" || formValues.paternal_surname.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                paternal_surname_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                paternal_surname_valid: false
            };
        }


        if (formValues.maternal_surname === "" || formValues.maternal_surname.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                maternal_surname_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                maternal_surname_valid: false
            };
        }

        if (formValues.username === "" || formValues.username.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                username_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                username_valid: false
            };
        }

        if (formValues.areas === "" || formValues.areas === 0) {
            newValidaciones = {
                ...newValidaciones,
                areas_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                areas_valid: false
            };
        }
        setValidaciones(newValidaciones);

        return statusValidacion;

    }

    //#endregion form

    //Buscador
    const handleSearchUser = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        userSearch.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setUsers(newMethod);
    }

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }


    return {
        users, handleInputChange, handleSelectValues, validaciones, handleSearchUser,
        modal, modalTitle, toggle, method, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        id_external, name, paternal_surname, maternal_surname, username, job, card_code, areas, departaments,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        //list
        handleListSection, dataSections, typeModal, modalSection, toggle2, handleListArea, dataAreas, typeModal2, modalArea, toggle3
    }
}
