import { Select } from '@mui/material';
import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader,Col, Form, Row, Label, Input, FormFeedback, FormGroup } from 'reactstrap';

export const ModalAuthorize = (props) => {
    const {
        modal, toggle, user_authorize, password_authorize, id_return_reason_woc, percentage_return,
        validationsCancelWorkOrder, totalCancellationWorkOrder, listDevolutions, loading,

        handleInputChangeCancelWorkOrder, handleValidateReturnAmount,
        saveCancelWorkOrderUpdate
    } = props;

    const {
        user_authorize_valid,
        password_authorize_valid,
        id_return_reason_cancel_valid,
        percentage_return_cancel_valid        
    } = validationsCancelWorkOrder;
    
    
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                Autorizar Cancelación
            </ModalHeader>
            <Form className="form theme-form" role="form">
                <ModalBody>                
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Usuario: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="text"
                                    name="user_authorize"
                                    value={user_authorize}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChangeCancelWorkOrder}
                                    invalid={user_authorize_valid}
                                    className="form-control form-control-sm input-air-primary"

                                />
                                <FormFeedback>Escribe el usuario</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Contraseña: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="password"
                                    name="password_authorize"
                                    value={password_authorize}
                                    required={true}
                                    autoComplete="nope"
                                    invalid={password_authorize_valid}
                                    onChange={handleInputChangeCancelWorkOrder}
                                    className="form-control form-control-sm input-air-primary"

                                />
                                <FormFeedback>Escribe la contraseña</FormFeedback>
                            </FormGroup>
                            
                        </Col>
                        <Col md="6">
                            <FormGroup className='p-1'>
                                <Label className="col-form-label">Mótivo de devolución<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label><br/>                               
                                 <Input
                                    type="select"
                                    name="id_return_reason_woc"
                                    required={true}
                                    invalid={id_return_reason_cancel_valid}
                                    onChange={handleInputChangeCancelWorkOrder}
                                    className="form-control form-control-sm input-air-primary p-1"
                                    defaultValue={id_return_reason_woc}>
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                            listDevolutions.length >= 1 && listDevolutions.map((sg, key) => {
                                                return <option value={sg.value} key={key}>{sg.label}</option>
                                            })
                                    }
                                </Input>
                                <FormFeedback>Selecciona un motivo de devolución</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Porcentaje a Devolver: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="number"
                                    name="percentage_return"
                                    value={percentage_return}
                                    required={true}
                                    autoComplete="nope"
                                    invalid={percentage_return_cancel_valid}
                                    min={0}
                                    max={100}
                                    onChange={(e) =>handleValidateReturnAmount(e)}
                                    className="form-control form-control-sm input-air-primary"
                                />
                                
                                <FormFeedback>Escribe un porcentaje valido a devolver</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <h6 class="primary text-center">La cantidad a devolver es: ${totalCancellationWorkOrder}</h6>
                        </Col>                        
                    </Row>                
                </ModalBody>
            </Form>
            <ModalFooter>
                    <Button
                            size="sm"
                            outline color="primary"
                            type="submit"
                            onClick={saveCancelWorkOrderUpdate}
                            className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                            Autorizar
                    </Button>
                    <Button outline color="primary" type="button" onClick={toggle}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}
