import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { ModalButtonCancel } from '../../constant';

export const FormTransports = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, name, abbreviation, code, button
    } = props;

    const {
        name_valid, abbreviation_valid, code_valid
    } = validaciones;

    return (
        <Form className="theme-form" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Nombre del transporte <span style={{ color: "#ff0000" }}>*</span></Label>
                            <Input
                                type="text"
                                placeholder="Nombre del tipo de transporte"
                                name="name"
                                onChange={handleInputChange}
                                value={name}
                                autoComplete="off"
                                invalid={name_valid}
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Abreviación <span style={{ color: "#ff0000" }}>*</span></Label>
                            <Input
                                type="text"
                                placeholder="Abreviación"
                                name="abbreviation"
                                onChange={handleInputChange}
                                value={abbreviation}
                                autoComplete="off"
                                invalid={abbreviation_valid}
                            />
                            <FormFeedback>La abreviación debe tener más de un caracter</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">Código <span style={{ color: "#ff0000" }}>*</span></Label>
                            <Input
                                type="text"
                                placeholder="Código"
                                name="code"
                                onChange={handleInputChange}
                                value={code}
                                autoComplete="off"
                                invalid={code_valid}
                            />
                            <FormFeedback>El código debe tener más de un caracter</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>
                    {ModalButtonCancel}
                </Button>
            </ModalFooter>
        </Form>
    )
}
