import React, { Fragment } from 'react';
import { Container, Col, Row, Card, CardBody, Button, Input, Form, FormGroup } from 'reactstrap';
import { ModalExam } from '../../../components/exams/ModalExam';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useExam } from '../../../hooks/exams/useExam';
import Breadcrumb from '../../../layout//breadcrumb'
import { ModalIndicationExam } from '../../../components/exams/ModalIndicationExam';
import { ModalReleaseExam } from '../../../components/exams/ModalReleaseExam';
import { ModalExamImagenology } from '../../../components/exams/ModalExamImagenology';



export const IndexExams = () => {
    const txtsection = localStorage.getItem('TextAS');
    const {
        exams, method, handleInputChange, validaciones,
        sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced, print_new_page, listExternalLaboratories, is_critic,
        toggle, modal, modalTitle, loading, examId, getUpdateDefaultExternalLaboratory,
        changeSelect, handleSelectValues,
        handleCreate, saveCreate, saveUpdate,
        handleDelete, saveDelete,
        navigateCreateOrUpdateAnalytes, navigateMicroAnalytes,
        navigateConfigExam, navigateCostProduction, navigateCreateExamSimple, navigateEditExamSimple,
        handleChangePrice,
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages,
        formValues, handleSearch,
        //INDICATION
        toggle2, modal2, handleListProfile, listIndications, indication, handleChangeIndication,
        id_exam_indication, indicationn, listIndication, selectIndication, removeExamProfile,
        CreateOrUpdateExamIndications,
        handleGetValidReleaseExam, releaseExam, modalReleaseExam, toggleRelease, loadingReleaseExam,
        saveUpdateStatusComplete,
        //filter
        is_referencedf, is_simplef, individual_salef, is_criticf, is_completef,
        handleInputChangeFilter, handleFilters, id_sectionf, listSection, handleChangeSection, clearFilter,
        handleSelectValuesIndication, dataIndication,
        //IMAGENOLOGIA
        methodImagenolofia, handleCreateExamImagenologia, titleImagenologia, modalImagenologia, toggleImagenologia,
        //nuevo_campo,nuevo_campo2
        sections2, number_projections, enable_analyte_configuration, handleUpdateImagenology,
        isImagenologyy, examEditIm, validationSectionArea

    } = useExam();

    return (
        <Fragment>
            <Container fluid={true} className="p-4 p-l-0 p-r-0">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de examenes
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn-pill btn-air-secondary" outline color="secondary" onClick={navigateCreateExamSimple}><span className="fa fa-plus-circle"></span> Examen sencillo</Button>
                                    <Button className="btn-pill btn-air-secondary" outline color="secondary" onClick={handleCreate}><span className="fa fa-plus-circle"></span> Examen complejo</Button>
                                    <Button className="btn-pill btn-air-secondary" outline color="secondary" onClick={handleCreateExamImagenologia}  ><span className="icofont icofont-heartbeat"></span> Examen Imagenología</Button>
                                </div>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Row>

                                        <Col xs="12" sm="12" md="12" lg="4" xl="2">
                                            <br />  <br />
                                            <label>Sección</label> <br />
                                            <Input
                                                className=" form-control input-air-primary SelectFilterExam"
                                                type='select'
                                                placeholder="Selecciona una opción"
                                                name="id_sectionf"
                                                onChange={handleChangeSection}
                                                value={id_sectionf} >
                                                <option value={0}>{"Selecciona una opción"}</option>
                                                {
                                                    listSection.length >= 1 && listSection.map((obj, index) => {
                                                        return <option value={obj.id_section}>{obj.name}</option>
                                                    })
                                                }
                                            </Input>
                                        </Col> <br />


                                        <Col xs="12" sm="12" md="12" lg="4" xl="2">
                                            <br />  <br />
                                            <label>Completo</label> <br />
                                            <FormGroup className="m-0">
                                                <Input
                                                    className='form-control input-air-primary  InputFilterExam'
                                                    type='select'
                                                    name="is_completef"
                                                    value={is_completef}
                                                    onChange={handleInputChangeFilter}
                                                >
                                                    <option value={""}>{"-----"}</option>
                                                    <option value={true}>{"Si"}</option>
                                                    <option value={false}>{"No"}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col xs="12" sm="12" md="12" lg="4" xl="2">
                                            <br />  <br />
                                            <label>Critico</label> <br />
                                            <FormGroup className="m-0">
                                                <Input
                                                    className='form-control input-air-primary  InputFilterExam'
                                                    type='select'
                                                    name="is_criticf"
                                                    value={is_criticf}
                                                    onChange={handleInputChangeFilter}
                                                >
                                                    <option value={""}>{"-----"}</option>
                                                    <option value={true}>{"Si"}</option>
                                                    <option value={false}>{"No"}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col> <br />

                                        <Col xs="12" sm="12" md="12" lg="3" xl="2">
                                            <br />  <br />
                                            <label>Tipo de Exámen</label> <br />
                                            <FormGroup className="m-0">
                                                <Input
                                                    type='select'
                                                    name="is_simplef"
                                                    value={is_simplef}
                                                    onChange={handleInputChangeFilter}
                                                    className="form-control input-air-primary "
                                                >
                                                    <option value={""}>{"-----"}</option>
                                                    <option value={true}>{"Sencillo"}</option>
                                                    <option value={false}>{"Complejo"}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col> <br />
                                        <Col xs="12" sm="12" md="12" lg="2" xl="1" style={{ textAlign: "-webkit-center" }} className="p-2">
                                            <br /> <br />   <br />
                                            <Button color='primary' size='SM' outline type='button' onClick={handleFilters}>Filtrar</Button>
                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="3">
                                            <br />  <br />
                                            <label>Buscar Exámen</label> <br />
                                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />

                                        </Col>
                                    </Row>
                                </Col> <br />
                                <div className="table-responsive">
                                    <TableStriped
                                        responsive={true}
                                        notMaped={["alias", "name_subsection", "visibleTable", "id_section", "id_subsection", "id_specimen", "id_specimen_provenance", "id_specimen_origin", "is_referenced", "is_critic", "listExternalLaboratories",
                                            "select_specimen_provenance", "select_transports", "select_specimen", "id_info_analyte", "id_app_code_method_default", "name_method_default", "is_simple", "validation", "is_curve", "name_specimen_provenance",
                                            "enable_analyte_configuration", "number_projections", "is_imagenology", "is_micro", "delivery_time", "use_antibiogram", "print_new_page"]}
                                        // methodsActions={false}
                                        // methodsModal={false}
                                        // methodsActions={true}
                                        // methodActiveDropdown={true}
                                        methodsActions={true}
                                        methodsModal={false}
                                        methodActiveDropdown={false}
                                        dataBadge={false}
                                        statusOrder={false}
                                        actionDashboard={false}

                                        cabeceras={["Clave", "Nombre", "Abreviatura", "Venta individual", txtsection, "Especimen", "Examen terminado", "Tipo de examen"]}
                                        items={exams}
                                        {...{
                                            handleDelete
                                        }}
                                        methodsAbsolutes={
                                            [
                                                {
                                                    type: "linkId",
                                                    method: navigateConfigExam,
                                                    icon: "fa fa-cogs",
                                                    backgroundColor: "#70cbe8",
                                                    color: "#fff",
                                                    name: "navigateConfigExam",
                                                    tooltip: "Método del Exámen"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: navigateCreateOrUpdateAnalytes,
                                                    icon: "fa fa-cog",
                                                    backgroundColor: "#0ba1d6",
                                                    color: "#fff",
                                                    name: "navigateCreateOrUpdateAnalytes",
                                                    tooltip: "Agregar Análito"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: navigateCostProduction,
                                                    icon: "icofont icofont-cur-dollar-plus",
                                                    backgroundColor: "#ecde03",
                                                    color: "#fff",
                                                    name: "navigateCostProduction",
                                                    tooltip: "Costo de Producción"

                                                },
                                                {
                                                    type: "linkId",
                                                    method: handleGetValidReleaseExam,
                                                    icon: "fa fa-check",
                                                    backgroundColor: "#01839d",
                                                    color: "#fff",
                                                    name: "navigateCompleteExam",
                                                    tooltip: "Liberar examen"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: navigateEditExamSimple,
                                                    icon: "fa fa-edit",
                                                    backgroundColor: "#1875c5",
                                                    color: "#fff",
                                                    name: "navigateEditExamSimple",
                                                    tooltip: "Editar Exámen"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: handleListProfile,
                                                    icon: "icofont icofont-prescription",
                                                    backgroundColor: "#016392",
                                                    color: "#fff",
                                                    tooltip: "Indicaciones"
                                                },

                                            ]
                                        }
                                        nextPage={goNextPage}
                                        previousPage={goPreviousPage}
                                        totalPageCount={totalPages}
                                        currentPage={actualPage}
                                        goToPage={goToPage}
                                        paginingBack={true}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalExam
                {
                ...{
                    formValues, method, handleInputChange, validaciones, handleSelectValues, 
                    sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
                    id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced,
                    toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, is_critic,
                    print_new_page,
                    changeSelect,
                    saveCreate,
                    saveUpdate,
                    saveDelete,
                    handleChangePrice, isImagenologyy, validationSectionArea

                }
                }
            />

            <ModalIndicationExam
                {
                ...{
                    toggle2, modal2, modalTitle, loading, method, listIndications, indication, handleChangeIndication,
                    id_exam_indication, indicationn, listIndication, selectIndication, removeExamProfile,
                    CreateOrUpdateExamIndications,
                    handleSelectValuesIndication, dataIndication
                }
                }
            />

            <ModalReleaseExam
                {
                ...{
                    releaseExam, modalReleaseExam, toggleRelease, loadingReleaseExam,
                    saveUpdateStatusComplete
                }
                }
            />

            <ModalExamImagenology
                {
                ...{
                    methodImagenolofia, handleCreateExamImagenologia, titleImagenologia, modalImagenologia, toggleImagenologia,
                    formValues, method, handleInputChange, validaciones, handleSelectValues,
                    sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
                    id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced,
                    toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, is_critic,
                    changeSelect,
                    saveCreate, handleChangePrice,
                    saveDelete, sections2, number_projections, enable_analyte_configuration, handleUpdateImagenology,
                    isImagenologyy, saveUpdate, examEditIm
                }
                }
            />
        </Fragment >
    )
}
