import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Card, CardBody,CardTitle, Container, Row, Col, Button, CardHeader, Collapse, UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem, InputGroup, InputGroupAddon, InputGroupText, Input, Tooltip, ButtonGroup,Label } from 'reactstrap';
import { useCaptureResults } from '../../../hooks/captureResults/useCaptureResults';
import { ViewExam } from './ViewExam';
import { useHistory } from 'react-router-dom';
import { ModalPreViewMedicalOrder } from '../../../components/ResultsCapture/ModalPreViewMedicalOrder';
import { ModalViewMedicalOrder } from '../../../components/ResultsCapture/ModalViewMedicalOrder';
import { ButtonGoBack, Email } from '../../../constant';
import './styleCaptureResults.css';
import { ButtonValidate } from './ButtonValidate';
import { ModalConfirmReleaseAll } from '../../../components/ResultsCapture/ModalConfirmReleaseAll';
import { ModalViewDocImagenology } from '../../../components/ResultsCapture/ModalViewDocImagenology';
import '../../../assets/css/cardFloting.css';

export const CaptureResultsFromNim = () => {
    const txtsection = localStorage.getItem('TextAS');
    const [cabecera, setCabecera] = useState(true);
    const {
        loadingButton, analytes, profiles, sections, patient, listDoctors, sendDoctor, saveIndividualAnalyte, saveAllAnalytes, releaseExam, releaseAll, validateAnalyte, validateExam, validateProfile, printAvailable, dobleValidacion, resultsPartial, printWorkOrder, previewWorkOrder, sendResultForEmail, observations, commentsPrintResult,
        handleEditCommentsPrintResults, saveCommentsWorkOrder,
        nimPatient, commentsFlebo, modalTitle, loading,
        microBacteriums, addMicroAislado, removeMicroAislado, validateBacterium, changeDataMicro, microAntibiograms, addAntibiogram, changeDataAntibiogram, workMedicalOrder, workMedicalOrderPDF, workMedicalOrderPDFDownload, workMedicalOrderPDFLoading,
        handleCreate, toggle, method, modal, modalTitle1, typeModal, type_file, modalRelease, toggleRelease, loadingRelease, getViewPreview, saveIndividualExamImagenology, handleGetDocumentImagenology,
        modalViewImagenology, modalTitleImg, toggleModalViewImagenology, documentImagenology, loadingPatient,
        removeValidationFromExamOrProfile,updateMethod,paid,credit_payment,setAnalytes,
        backNim, nextNim, gotoNim
    } = useCaptureResults();

    const history = useHistory();
    return (
        <Fragment>
            <Container fluid={true} className='position-relative pb-3'>
                
                {
                loadingPatient === true ?
                <Card className='floating-card p-1'>
                    <CardBody className='p-1'>
                        <ButtonGroup
                            vertical
                        >
                            <Button
                                block={true}
                                size="lg"
                                onClick={() => gotoNim(backNim)}
                                outline color='danger'
                                disabled={loadingButton || backNim == null}
                                className={loadingButton ? "disabled progress-bar-animated progress-bar-striped" : ""}
                            >
                                <i className="icofont icofont-arrow-left"></i>
                            </Button>
                            <Button
                                block={true}
                                size="lg"
                                onClick={() => gotoNim(nextNim)}
                                outline color='success'
                                disabled={loadingButton || nextNim == null}
                                className={loadingButton ? "disabled progress-bar-animated progress-bar-striped" : ""}
                            >
                                <i className="icofont icofont-arrow-right"></i>
                            </Button>
                            <Button
                                block={true}
                                size="lg "
                                onClick={saveAllAnalytes}
                                outline color='info'
                                disabled={loadingButton}
                                className={loadingButton ? "disabled progress-bar-animated progress-bar-striped" : ""}
                                
                            >
                                <i className="icofont icofont-diskette"></i>
                            </Button>
                        </ButtonGroup>
                    </CardBody>
                </Card>
                :
                ""
                }


                <Col sm="12" className='p-l-0 p-r-0' >
                    {
                        loadingPatient === false ?
                            <Col md="12" className='text-center p-l-0 p-r-0'>
                                <Card className='shadow' style={{ height: "380px" }}>
                                    <Row className='mt-2'> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>  <br /> <br /> <br /> <br />
                                            <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm="3" md="3" lg="3" className='text-center'></Col>
                                        <Col sm="2" md="2" lg="2" className='text-left p-r-0'>
                                            <p className='f-18 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p> <br />

                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                    </Row>

                                </Card>
                            </Col>
                            :
                            <Row>
                                <Col sm="4" className='p-l-0' >
                                    <Card className='shadow' style={{ height: "275px" }} >
                                        <CardHeader className='p-2 bg-primary'>
                                            <label className='p-1'>Datos del Paciente</label>
                                        </CardHeader>
                                        <Col sm="12">
                                            <Row>
                                                <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0' > <br />
                                                    {
                                                        (patient.name_gender) === "MASCULINO" ?
                                                            <div className="profile-vector"><img className="imgGender" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "80%" }} alt="" /></div>
                                                            :
                                                            <div className="profile-vector"><img className="imgGender" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "80%" }} alt="" /></div>
                                                    }
                                                </Col>
                                                <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0' >
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />
                                                            <i className="fa fa-circle-o iconResults f-12"></i> <b className='labelPatient'>Nombre:</b><label className='pPatient'>{patient.name} {patient.paternal_surname} {patient.maternal_surname}</label><br />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            <i className="fa fa-circle-o iconResults f-12"></i> <b className='labelPatient'>Edad:</b> <label className='pPatient'>{patient.age > 0 ? patient.age + " año(s)" : patient.format_age}  </label> <br />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            <i className="fa fa-circle-o iconResults f-12"></i> <b className='labelPatient'>Sexo:</b> <label className='pPatient'>{patient.name_gender}</label>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            <i className="fa fa-circle-o iconResults f-12"></i> <b className='labelPatient'>Nim:</b> <label className='pPatient'>{nimPatient}</label>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            {
                                                                patient.email === "" ?
                                                                    <div>
                                                                        <i className="fa fa-circle-o iconResults f-12 text-danger"></i>  <b className='labelPatient'>Email:</b>
                                                                        <label className='pPatient'>{"Sin correo registrado"}</label>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <i className="fa fa-circle-o iconResults f-12"></i>  <b className='labelPatient'>Email:</b> <label className='pPatient'>{patient.email}</label>
                                                                    </div>
                                                            }
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            {
                                                                patient.phone === "" ?
                                                                    <div>
                                                                        <i className="fa fa-circle-o iconResults f-12 text-danger"></i> <b>Télefono:</b> <br /> <label className='pPatient'>{"Sin registro telefonico"}</label>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <i className="fa fa-circle-o iconResults f-12"></i>  <b>Télefono:</b> <label className='pPatient'>{patient.phone}</label>
                                                                    </div>
                                                            }
                                                        </Col>
                                                    </Row>



                                                </Col>
                                            </Row>
                                        </Col>
                                    </Card>
                                </Col>
                                <Col sm="5" className='p-l-0'>
                                    <Card className='shadow' style={{ height: "275px" }}>
                                        <CardHeader className='p-2 bg-primary'>
                                            <label className='p-1'>Información del Quimico</label>
                                        </CardHeader>
                                        <Col sm="12">
                                            <Row>
                                                <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0' > <br />
                                                    <div className="profile-vector"><img className="" src={require("../../../assets/images/loginHelenLabs/WorkOrderDay/Ultrasonido.svg")} style={{ width: "95%" }} alt="" /></div>
                                                </Col>
                                                <Col sm="9" className='p-l-0' >
                                                    <Row>
                                                        {/* <Col xs="12" sm="12" md="12" lg="12" xl="12" >
                                                            {
                                                                workMedicalOrder == null || workMedicalOrder == "" ?
                                                                    <Row>
                                                                        <Col sm="4">
                                                                        </Col>
                                                                        <Col xs="8" sm="7" md="12" lg="12" xl="8" className='p-r-0' >
                                                                            <label className="f-w-600 f-12 badge badge-light-danger">Sin Orden Médica Registrada</label>
                                                                        </Col>

                                                                    </Row>
                                                                    :
                                                                    <Row>
                                                                        <Col sm="7">
                                                                        </Col>
                                                                        <Col xs="8" sm="7" md="12" lg="12" xl="5" className='p-1' >
                                                                            <Button block={true} size="xs" onClick={handleCreate} outline color="primary-2x"   >
                                                                                Ver Orden Médica
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                            }
                                                        </Col> */}
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />
                                                            {
                                                                observations == "" ?
                                                                    <div>
                                                                        <i className="icofont icofont-warning-alt  f-18 iconResults"></i>  <b className='labelPatient'>Observaciones:</b> <br />
                                                                        <label className='pPatient'>{"Sin observaciones"}</label>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <i className="icofont icofont-warning-alt  f-18 text-danger"></i>  <b className='labelPatient' >Observaciones:</b> <br />
                                                                        <label className='pPatient'>{observations}</label>
                                                                    </div>
                                                            }
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            {
                                                                commentsFlebo == "" ?
                                                                    <div>
                                                                        <i className="icofont icofont-doctor-alt  f-20 iconResults"></i>  <b className='labelPatient'>Comentarios del flebotomista:</b> <br />
                                                                        <label className='pPatient'>{"Sin observaciones del flebotomista"}</label>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <i className="icofont icofont-doctor-alt  f-20 text-danger"></i>  <b className='labelPatient' >Observaciones del flebotomista:</b> <br />
                                                                        <label className='pPatient txt-danger'>{commentsFlebo}</label>
                                                                    </div>
                                                            }
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" >
                                                            <i className="fa fa-comment f-18 iconResults"></i><b>Comentarios adjuntos: </b> <br />
                                                            <InputGroup className='input-group-sm'>
                                                                <Input type="text" className='form-control form-control-sm input-air-primary' placeholder='Escribe tus comentarios' value={commentsPrintResult} onChange={(e) => handleEditCommentsPrintResults(e)} />
                                                                <InputGroupAddon addonType='append'>
                                                                    <InputGroupText>
                                                                        <Button className='p-0' color='transparent' size='xs' onClick={saveCommentsWorkOrder}>
                                                                            <i className='fa fa-save text-danger'></i>
                                                                        </Button>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" > <br />
                                                            {
                                                                workMedicalOrder == null || workMedicalOrder == "" ?
                                                                    <Row>

                                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-r-0' >
                                                                            <label className="f-w-600 f-12 badge badge-light-danger">Sin Orden Médica Registrada</label>
                                                                        </Col>

                                                                    </Row>
                                                                    :
                                                                    <Row>

                                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-1' >
                                                                            <Button block={true} size="xs" onClick={handleCreate} outline color="primary-2x"   >
                                                                                Ver Orden Médica
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                            }
                                                        </Col>

                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Card>
                                </Col>
                                <Col sm="3" className='p-l-0'>
                                    <Card className='shadow' style={{ height: "275px" }}>
                                        <CardHeader className='p-2 bg-primary'>
                                            <label className='p-1'>Acciones Generales</label>
                                        </CardHeader> <br />
                                        <Row>
                                            <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='text-center'>
                                                <ButtonGroup vertical>
                                                    {
                                                        credit_payment=="True"?
                                                        
                                                            printAvailable  && paid ?
                                                                resultsPartial ? <>
                                                                    {
                                                                        dobleValidacion ? <>

                                                                            {
                                                                                listDoctors.length && sendDoctor > 0 ? <>

                                                                                    <UncontrolledDropdown color='danger' className='p-0 m-0'>
                                                                                        <DropdownToggle size='sm' block outline color='danger'>
                                                                                            &nbsp; Enviar Resultados  &nbsp;
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu >
                                                                                            <DropdownItem header>Selecciona una opción</DropdownItem>
                                                                                            <DropdownItem onClick={ () => sendResultForEmail(false) }>{"Enviar al Paciente"}</DropdownItem>
                                                                                            <DropdownItem onClick={ () => sendResultForEmail(true) }>{"Enviar al Doctor"}</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                
                                                                                </> : <>
                                                                                <Button block={true} size="sm" onClick={() => sendResultForEmail(false)} outline color="danger">
                                                                                    Enviar resultados
                                                                                </Button>
                                                                                </>
                                                                            }

                                                                            <Button block={true} size="sm" onClick={() => printWorkOrder(true)} outline color="primary">
                                                                                Imprimir resultados
                                                                            </Button>
                                                                        </>
                                                                            : ""
                                                                    }
                                                                    <Button block={true} size="sm" onClick={getViewPreview} outline color="primary">
                                                                        Previsualizar
                                                                    </Button>
                                                                    <Button block={true} size="sm" onClick={saveAllAnalytes} outline color='info' disabled={loadingButton} className={loadingButton ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                                                                        Guardar resultados
                                                                    </Button>
                                                                </>
                                                                    :

                                                                    dobleValidacion ? <>
                                                                        {
                                                                            listDoctors.length && sendDoctor ? <>
                                                                                <UncontrolledDropdown color='danger' className='p-0 m-0'>
                                                                                    <DropdownToggle size='sm' block outline color='danger'>
                                                                                        &nbsp; Enviar Resultados  &nbsp;
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu >
                                                                                        <DropdownItem header>Selecciona una opción</DropdownItem>
                                                                                        <DropdownItem onClick={ () => sendResultForEmail(false) }>{"Enviar al Paciente"}</DropdownItem>
                                                                                        <DropdownItem onClick={ () => sendResultForEmail(true) }>{"Enviar al Doctor"}</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </> : <>
                                                                                <Button block={true} size="sm" onClick={() => sendResultForEmail(false)} outline color="primary">
                                                                                    Enviar resultados
                                                                                </Button>
                                                                            </>
                                                                        }
                                                                        <Button block={true} size="sm" onClick={() => printWorkOrder(true)} outline color="primary">
                                                                            Imprimir resultados
                                                                        </Button>
                                                                    </> : ""
                                                                :
                                                                <Button block={true} size="sm" onClick={saveAllAnalytes} outline color='info' disabled={loadingButton} className={loadingButton ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                                                                    Guardar resultados &nbsp;
                                                                </Button>
                                                        : printAvailable?
                                                                resultsPartial ? <>
                                                                    {
                                                                        dobleValidacion ? <>

                                                                            {
                                                                                listDoctors.length && sendDoctor > 0 ? <>

                                                                                    <UncontrolledDropdown color='danger' className='p-0 m-0'>
                                                                                        <DropdownToggle size='sm' block outline color='danger'>
                                                                                            &nbsp; Enviar Resultados  &nbsp;
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu >
                                                                                            <DropdownItem header>Selecciona una opción</DropdownItem>
                                                                                            <DropdownItem onClick={ () => sendResultForEmail(false) }>{"Enviar al Paciente"}</DropdownItem>
                                                                                            <DropdownItem onClick={ () => sendResultForEmail(true) }>{"Enviar al Doctor"}</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                
                                                                                </> : <>
                                                                                <Button block={true} size="sm" onClick={() => sendResultForEmail(false)} outline color="danger">
                                                                                    Enviar resultados
                                                                                </Button>
                                                                                </>
                                                                            }

                                                                            <Button block={true} size="sm" onClick={() => printWorkOrder(true)} outline color="primary">
                                                                                Imprimir resultados
                                                                            </Button>
                                                                        </>
                                                                            : ""
                                                                    }
                                                                    <Button block={true} size="sm" onClick={getViewPreview} outline color="primary">
                                                                        Previsualizar
                                                                    </Button>
                                                                    <Button block={true} size="sm" onClick={saveAllAnalytes} outline color='info' disabled={loadingButton} className={loadingButton ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                                                                        Guardar resultados
                                                                    </Button>
                                                                </>
                                                                    :

                                                                    dobleValidacion ? <>
                                                                        {
                                                                            listDoctors.length && sendDoctor ? <>
                                                                                <UncontrolledDropdown color='danger' className='p-0 m-0'>
                                                                                    <DropdownToggle size='sm' block outline color='danger'>
                                                                                        &nbsp; Enviar Resultados &nbsp;
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu >
                                                                                        <DropdownItem header>Selecciona una opción</DropdownItem>
                                                                                        <DropdownItem onClick={ () => sendResultForEmail(false) }>{"Enviar al Paciente"}</DropdownItem>
                                                                                        <DropdownItem onClick={ () => sendResultForEmail(true) }>{"Enviar al Doctor"}</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </> : <>
                                                                                <Button block={true} size="sm" onClick={() => sendResultForEmail(false)} outline color="primary">
                                                                                    Enviar resultados
                                                                                </Button>
                                                                            </>
                                                                        }
                                                                        <Button block={true} size="sm" onClick={() => printWorkOrder(true)} outline color="primary">
                                                                            Imprimir resultados
                                                                        </Button>
                                                                    </> : ""
                                                                :
                                                                <Button block={true} size="sm" onClick={saveAllAnalytes} outline color='info' disabled={loadingButton} className={loadingButton ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                                                                    Guardar resultados &nbsp;
                                                                </Button>
                                                            
                                                            
                                                    }
                                                  
                                                    <Button block={true} size="sm" onClick={() => toggleRelease()} outline color='success'>
                                                        Liberar todo
                                                    </Button>
                                                </ButtonGroup>
                                                <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                                </Col>
                                                <Col sm="12" className="mt-2" style={{ textAlign: "-webkit-center" }}>
                                                     {paid==false ?<Label className="f-w-600 f-12 badge badge-light-danger">Con adeudo</Label>:<Label></Label>}
                                                </Col>
                                                <Col sm="12" style={{ textAlign: "-webkit-center" }} > <br />
                                                    <Button color="light" className=" btn-air-light m-1" onClick={() => window.location.href = `/busqueda/trabajo-dia`}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button>
                                                </Col>
                                                
                                            </Col>
                                          
                                        </Row>
                                       
                                    </Card>
                                </Col>
                            </Row>

                    }



                </Col>
                <Row style={{ zIndex: -1 }}>
                    {
                        sections.length > 0 &&
                        sections.map((seccion, key) => {
                            return <Col sm="12" key={key}>
                                <CardHeader className="b-l-primary pb-4 pt-4 fondo">
                                    <label className='Label-input'>{seccion.name}-{txtsection}</label>
                                </CardHeader>
                                <CardBody className='p-l-0 p-r-0' >
                                    {
                                        seccion.exams.map((examen, key2) => {
                                            return <ViewExam key={key2}
                                                typeArray="section"
                                                sectionProfileId={seccion.id}
                                                releaseExam={releaseExam}
                                                examen={examen}
                                                age={patient.age}
                                                days_age={patient.days_age}
                                                gender={patient.id_gender}
                                                saveIndividualAnalyte={saveIndividualAnalyte}
                                                validateAnalyte={validateAnalyte}
                                                validateExam={validateExam}
                                                analytes={analytes}
                                                idPat={patient.id_patient}
                                                namePatiente={patient.name + patient.paternal_surname + patient.maternal_surname}
                                                nameP={patient.name}
                                                surnameP={patient.paternal_surname}
                                                surnameM={patient.maternal_surname}
                                                addMicroAislado={addMicroAislado}
                                                removeMicroAislado={removeMicroAislado}
                                                validateBacterium={validateBacterium}
                                                changeDataMicro={changeDataMicro}
                                                microBacteriums={microBacteriums}
                                                microAntibiograms={microAntibiograms}
                                                addAntibiogram={addAntibiogram}
                                                changeDataAntibiogram={changeDataAntibiogram}
                                                previewWorkOrder={previewWorkOrder}
                                                saveFileExamImagenology={saveIndividualExamImagenology}
                                                handleGetDocumentImagenology={handleGetDocumentImagenology}
                                                removeValidationFromExamOrProfile={removeValidationFromExamOrProfile}
                                                updateMethod={updateMethod}
                                                setAnalytes={setAnalytes}
                                                is_profile={false}



                                            />
                                        })
                                    }
                                </CardBody>
                            </Col>
                        })
                    }
                    {
                        profiles.length > 0 &&
                        profiles.map((perfil, key) => {
                            return <Col sm="12" key={`perfil ` + key}>
                                {
                                    perfil.exams.length > 0 ? <>
                                        <CardHeader className="b-l-primary pb-4 pt-4 fondo">
                                            <label className='Label-input'>{perfil.name} / Perfil</label>
                                            <div className='float-right'>
                                                {
                                                    perfil.readyToValidateProfile === true ?
                                                        (perfil.readyToReleaseProfile === true ? "" : <ButtonValidate validateProfile={validateProfile} ready={true} id_profile={perfil.id_profile} id_work_order={perfil.id_work_order} id_work_order_profile={perfil.id_work_order_profile} />)
                                                        : <>
                                                            <ButtonValidate ready={false} />
                                                        </>
                                                }

                                            </div>
                                        </CardHeader>
                                        <CardBody className='p-l-0 p-r-0' >
                                            {
                                                perfil.exams.map((examen, key2) => {
                                                    return <ViewExam key={key2}
                                                        typeArray="profile"
                                                        sectionProfileId={perfil.id_profile}
                                                        releaseExam={releaseExam}
                                                        examen={examen}
                                                        age={patient.age}
                                                        days_age={patient.days_age}
                                                        gender={patient.id_gender}
                                                        saveIndividualAnalyte={saveIndividualAnalyte}
                                                        validateAnalyte={validateAnalyte}
                                                        validateExam={validateExam}
                                                        analytes={analytes}
                                                        idPat={patient.id_patient}
                                                        namePatiente={patient.name + patient.paternal_surname + patient.maternal_surname}
                                                        nameP={patient.name}
                                                        surnameP={patient.paternal_surname}
                                                        surnameM={patient.maternal_surname}
                                                        curves={perfil.curves !== null ? perfil.curves : null}
                                                        addMicroAislado={addMicroAislado}
                                                        removeMicroAislado={removeMicroAislado}
                                                        validateBacterium={validateBacterium}
                                                        changeDataMicro={changeDataMicro}
                                                        microBacteriums={microBacteriums}
                                                        microAntibiograms={microAntibiograms}
                                                        addAntibiogram={addAntibiogram}
                                                        changeDataAntibiogram={changeDataAntibiogram}
                                                        saveFileExamImagenology={saveIndividualExamImagenology}
                                                        handleGetDocumentImagenology={handleGetDocumentImagenology}
                                                        removeValidationFromExamOrProfile={removeValidationFromExamOrProfile}
                                                        updateMethod={updateMethod}
                                                        setAnalytes={setAnalytes}
                                                        is_profile={true}
                                                    />
                                                })
                                            }
                                        </CardBody>
                                    </>
                                        : ""
                                }
                            </Col >
                        })
                    }
                </Row >
            </Container >
            <ModalViewMedicalOrder
                {
                ...{
                    modal, modalTitle1, workMedicalOrder, loading, method, toggle, typeModal, type_file
                }
                }
            />
            <ModalPreViewMedicalOrder
                {
                ...{
                    modal, workMedicalOrderPDF, workMedicalOrderPDFDownload, loading, method, toggle, typeModal, type_file, workMedicalOrderPDFLoading,
                }
                }
            />
            <ModalConfirmReleaseAll
                {...{
                    modalRelease, toggleRelease, loadingRelease, releaseAll
                }}
            />
            <ModalViewDocImagenology
                {
                ...{
                    modalViewImagenology, modalTitleImg, toggleModalViewImagenology, documentImagenology
                }
                }
            />
        </Fragment >
    )
}
