import React, { Fragment } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, Container, FormFeedback, FormGroup, FormText, Input, Label, Row, Table } from 'reactstrap';
import { ButtonGoBack, CompleteEmail, CompleteInput, CompleteSelect, InstructionAgreementBranch, PlaceHolderOption, SelectExamOrProfile } from '../../constant';
import { useAddAgreement } from '../../hooks/agreements/useAddAgreement';
import { SearchAsync } from '../../components/elements/SearchAsync';
import DeniReactTreeView from 'deni-react-treeview';
import Select from 'react-select';
import { TableAgreement } from '../../components/agreement/TableAgreement';
import { Indexprueba } from './Indexprueba';
import 'animate.css';
import { InformationAgreement } from './InformationAgreement';
import { ModalCodeCsv } from './ModalCodeCsv';

export const IndexAddAgreement = () => {

    const {
        //#region AGREEMENTS LIST
        listCompanies, listPayForms, listFinancingMethods, listUseCfdi, listSellers, listCommercialLines,
        ListBranchesTree, listDoctors,
        //#endregion

        //#region AGREEMENTS MODELS
        informationCompany, informationDoctor, examProfileState, loading, globalPercentage, loadingAgreement, typeFilterAgreement,
        pageSizeSelectDoctor,

        //FORM
        id_agreement, id_company, id_doctor, id_pay_method, id_financing_methods, id_commercial_line, id_seller, id_use_cfdi,
        contract_number, validity, sales_name, sales_phone, sales_email, payment_name, payment_phone,
        payment_email, observations, swift_code, is_global, payment_periodicity, credit_limit, percentage_credit_limit,
        checkSpecimenQuantity, quantity_sample, list_agrement_test, is_pay_for_compamy, required_invoice_company,

        //FORM METHODS
        handleInputChangeA, handleSelectValuesA,
        //REFS
        treeViewRef,
        //#endregion

        //#region VALIDATIONS AGREEMENTS
        validationsAgreement, visibilityInputs,
        //#endregion  

        //#region AGREEMENTS METHODS
        handleChangeCompany, handleChangeDoctor, handleSelectTest, handleChangeRange, handleChangePercentage,
        handleDeleteRange, handleAddRange, handleDeleteTest, handleChangePrice, handleGoBack,
        saveOrUpdateAgreement, handleCalculateGlobalPercentage, handleChangeTypeFilter, handleScrollPaginingDoctor,

        //#endregion
        number_format,

        //#region csv Exam and Profile
        setDatacsv, handleCsvLoading,
        //#region sanitaria
        check_sanitary,on_check_sanitary,handleCant,sample_periodicity,is_micro,

         //RETURN PAGINATION
         goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
         prevPage, nextPage, totalRecords, resultCount, handleSearch,
         validCode,
         modalListEgress,toggleModalListEgress,listCode,handlemove,
         listRepit,priceTarifarion,profileT

    } = useAddAgreement();

    const {
        id_company_valid,
        id_doctor_valid,
        id_pay_method_valid,
        id_financing_methods_valid,
        id_commercial_line_valid,
        id_use_cfdi_valid,
        id_seller_valid,
        contract_number_valid,
        validity_valid,
        sales_name_valid,
        sales_phone_valid,
        sales_email_valid,
        payment_name_valid,
        payment_phone_valid,
        payment_email_valid,
        payment_periodicity_valid,
        credit_limit_valid,
        percentage_credit_limit_valid,
        list_agrement_test_valid,
        quantity_sample_validity,
        branch_tree_valid
    } = validationsAgreement;



    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md="12" lg="12" hidden={!loadingAgreement}>
                        <Col sm="12" style={{ textAlign: "center" }} >
                            <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'150px' }} />                            
                            <br /> <p className='f-w-600 f-14 badge badge-light-primary' >Cargando información...</p>
                        </Col>
                    </Col>
                    <Col sm="12" md="12" lg="12" hidden={loadingAgreement}>
                        <Row>
                            <Col sm="12">
                                <Card className='ribbon-wrapper shadow p-t-0 p-b-0'>
                                    <CardBody className='p-3'>
                                        <Row className='mb-2'>
                                            <Col sm="12" md="6" lg="6">
                                                <ButtonGroup size='sm' className='btn-group-pill btn-block'>
                                                    <Button color='primary' outline={typeFilterAgreement === 1 ? false : true} onClick={() => handleChangeTypeFilter(1)}><i className="fa fa-building-o"></i> Convenio para empresas</Button>
                                                    <Button color='primary' outline={typeFilterAgreement === 2 ? false : true} onClick={() => handleChangeTypeFilter(2)}> <i className="fa fa-user-md"></i> Convenio para doctores</Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col sm="12" md="6" lg="6"></Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12" md="6" lg="6">
                                                {
                                                    typeFilterAgreement === 1 && <FormGroup>
                                                        <Label>Filtrar por cliente pruebas</Label>
                                                        <Select
                                                            name="id_company"
                                                            isClearable={true}
                                                            placeholder="Selecciona una opción"
                                                            options={listCompanies}
                                                            defaultValue={id_company}
                                                            value={id_company}
                                                            className='input-air-primary b-r-9'
                                                            onChange={(e) => handleChangeCompany(e)}
                                                        />
                                                        <FormText color='danger' hidden={!id_company_valid}>Selecciona un cliente</FormText>
                                                    </FormGroup>
                                                }
                                                {
                                                    typeFilterAgreement === 2 && <FormGroup>
                                                        <Label>Filtrar por doctores</Label>
                                                        <Select
                                                            name="id_company"
                                                            isClearable={true}
                                                            placeholder="Selecciona una opción"
                                                            options={listDoctors}
                                                            defaultValue={id_doctor}
                                                            value={id_doctor}
                                                            className='input-air-primary b-r-9'
                                                            onChange={(e) => handleChangeDoctor(e)}
                                                            onMenuScrollToBottom={() => handleScrollPaginingDoctor(pageSizeSelectDoctor)}
                                                        />
                                                        <FormText color='danger' hidden={!id_doctor_valid}>Selecciona un cliente</FormText>
                                                    </FormGroup>
                                                }
                                            </Col>
                                            <Col sm="12" md="6" lg="6">
                                                <FormGroup>
                                                    <Label>Selecciona una razón social</Label>
                                                    <Select
                                                        name="id_commercial_line"
                                                        defaultValue={id_commercial_line}
                                                        value={id_commercial_line}
                                                        isClearable={false}
                                                        placeholder="Selecciona una opción"
                                                        className="input-air-primary b-r-9"
                                                        options={listCommercialLines}
                                                        onChange={(e) => handleSelectValuesA(e, 'id_commercial_line')}
                                                        number={8}
                                                        
                                                    />
                                                    <FormText color='danger' hidden={!id_commercial_line_valid}>Selecciona una razón social</FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className={(informationCompany.rfc !== undefined || informationDoctor.specialty) ? "d-none ribbon-wrapper" : ""}>
                            <Col sm="12" style={{ textAlign: "center" }} >
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" />
                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >Selecciona un Cliente y una Razón Social para iniciar un Convenio.</p>
                            </Col>
                        </Row>
                        {
                            typeFilterAgreement === 1 && informationCompany.rfc !== undefined && <Row>
                                <Col sm="12" md="12" lg="12" className='animate__animated animate__flipInX'>
                                    <Card className='ribbon-wrapper shadow p-t-0 p-b-0'>
                                        <CardBody className='p-3'>
                                            <Row>
                                                <Col sm="12" md="2" lg="2">
                                                    <Button outline color='primary' className=" btn-air-light m-1" onClick={handleGoBack}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-id-card f-16 mr-2 dClientes '></span> RFC</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationCompany.rfc}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-map f-18 mr-2 dClientes'></span> Dirección</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationCompany.address}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-phone f-18 mr-2 dClientes'></span> Teléfono</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationCompany.phone}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-email f-18 mr-2 dClientes'></span> E-mail</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationCompany.email}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-book-mark f-18 mr-2 dClientes'></span> Giro comercial</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationCompany.company_turn}
                                                    </p>
                                                </Col>                                                
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {
                            typeFilterAgreement === 2 && informationDoctor.specialty !== undefined && <Row>
                                <Col sm="12" md="12" lg="12" className='animate__animated animate__flipInX'>
                                    <Card className='ribbon-wrapper shadow p-t-0 p-b-0'>
                                        <CardBody className='p-3'>
                                            <Row>
                                                <Col sm="12" md="2" lg="2">
                                                    <Button outline color='primary' className=" btn-air-light m-1" onClick={handleGoBack}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='fa fa-stethoscope f-16 mr-2 dClientes '></span> Especialidad</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationDoctor.specialty}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-map f-18 mr-2 dClientes'></span> Dirección</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationDoctor.address}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-phone f-18 mr-2 dClientes'></span> Teléfono</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationDoctor.phone}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-email f-18 mr-2 dClientes'></span> E-mail</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationDoctor.email}
                                                    </p>
                                                </Col>
                                                <Col sm="12" md="2" lg="2">
                                                    <p className='text-center f-14 mb-0'><span className='icofont icofont-book-mark f-18 mr-2 dClientes'></span> Licencia profesional</p>
                                                    <p className='text-center f-12 mt-1 f-w-600'>
                                                        {informationDoctor.professional_license}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row className={(informationCompany.rfc === undefined && informationDoctor.specialty === undefined) ? "d-none ribbon-wrapper" : ""}>
                            {
                                <InformationAgreement
                                    {
                                        ...{
                                            //FORM
                                            contract_number, validity, is_global, payment_periodicity, id_pay_method, id_financing_methods,
                                            credit_limit, percentage_credit_limit, id_use_cfdi,
                                            sales_name, sales_phone, sales_email, payment_name, payment_phone, payment_email, id_seller,
                                            observations, swift_code,

                                            //METHODS
                                            handleInputChangeA, handleSelectValuesA,

                                             //VALIDATIONS
                                            contract_number_valid, validity_valid, payment_periodicity_valid, id_pay_method_valid, id_financing_methods_valid,
                                            credit_limit_valid, percentage_credit_limit_valid, id_use_cfdi_valid, sales_name_valid,
                                            sales_phone_valid, sales_email_valid, payment_name_valid, payment_phone_valid, payment_email_valid, id_seller_valid,

                                            //INFORMATION COMPANY AND DOCTORS
                                            informationCompany,

                                            //LISTS
                                            listPayForms, listFinancingMethods, listUseCfdi, listSellers,
                                            
                                            //SANITARY
                                            sample_periodicity, is_micro,

                                            on_check_sanitary, is_pay_for_compamy, required_invoice_company,
                                        }
                                    }
                                    visibility_inputs={visibilityInputs}
                                />
                            }
                            <Col sm="12" md="5" lg="5">
                                <Card className='ribbon-wrapper shadow p-t-0 p-b-0'>
                                    <CardHeader className='bg-primary p-3'>
                                        <h6>Sucursales</h6>
                                    </CardHeader>
                                    <CardBody className='p-2'>
                                        <Row>
                                            <Col sm="12" md="12" lg="12">
                                                <p>{InstructionAgreementBranch}</p>
                                                <DeniReactTreeView
                                                    items={ListBranchesTree}
                                                    ref={treeViewRef}
                                                    showCheckbox={true}
                                                    showIcon={true}
                                                    style={{ width: "auto" }}
                                                />
                                                <FormText color='danger' className='f-16' hidden={!branch_tree_valid}>Selecciona al menos una sucursal</FormText>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className={(informationCompany.rfc === undefined && informationDoctor.specialty === undefined )? "d-none ribbon-wrapper mb-5" : "mb-5"}>
                            <Col sm="12" md="12" lg="12">
                                <Card className='ribbon-wrapper shadow p-t-0 p-b-0'>
                                    <CardHeader className='bg-primary p-3'>
                                        <h6>Búsqueda de exámenes / Perfiles</h6>
                                    </CardHeader>
                                    <CardBody className='p-2'>
                                        <Col sm="12" md="12" lg="12">
                                            <Row>
                                                <Col lg="5" xl="5">
                                                    <Label className='f-w-500 f-12 badge badge-light-primary'>{"Busqueda de Exámen / Perfil"}</Label> <br /> <br />
                                                    <FormGroup>
                                                        <SearchAsync
                                                            name={'id_exam_profile'}
                                                            method={handleSelectTest}
                                                            loincState={examProfileState}
                                                            url="Profiles/SearchProfileExam"
                                                            maxLenght={3}
                                                        />
                                                        <FormText color='danger' hidden={!list_agrement_test_valid}>Selecciona al menos un examen</FormText>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="2" md="2" lg="2">
                                                    <Label className='f-w-500 f-12 badge badge-light-primary'>{"% Descuento global"}</Label> <br /> <br />
                                                    <Input className='form-control form-control-sm input-air-primary b-r-9' type='number' disabled={list_agrement_test.length === 0 ? true : false} value={globalPercentage} onChange={(e) => handleCalculateGlobalPercentage(e)} />
                                                </Col>
                                                <Col sm="5" md="5" lg="5" xl="5">
                                                    <Label className='f-w-500 f-12 badge badge-light-primary'>{"Cargar archivo csv."}</Label> <br /> <br />
                                                    <Indexprueba
                                                        {
                                                        ...{ setDatacsv, handleCsvLoading }
                                                        }
                                                    />
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Row>
                                            <Col sm="12" md="12" lg="12"> <br /> <br />
                                                {
                                                    list_agrement_test.length === 0 ?
                                                        <div>
                                                            <Col sm="12" style={{ textAlign: "center" }} >
                                                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '110px' }} />
                                                            </Col>
                                                            <br />
                                                            <Col sm="12" style={{ textAlign: "center" }}>
                                                                <p className='f-14 text-primary'> <b>Seleccione los estudios que conformaran en el convenio.</b></p>
                                                            </Col>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                id_agreement > 0 ?
                                                                <Row>
                                                                    <Col className='offset-8 pt-1 pb-2' md="4">
                                                                        <label className='txt-secondary f-14'>Cuando modifiques un precio, actualiza el convenio.</label>
                                                                        <Input placeholder='Buscar...' className='form-control input-air-primary b-r-9' onChange={handleSearch}/>
                                                                    </Col>
                                                                </Row> 
                                                                : ""  
                                                            }
                                                            <div className='table-responsive'>
                                                                <TableAgreement
                                                                    {
                                                                    ...{
                                                                        handleChangeRange, handleChangePercentage, handleDeleteRange, handleAddRange,
                                                                        handleDeleteTest, handleChangePrice,
                                                                        list_agrement_test,
                                                                        number_format,handleCant,check_sanitary,is_micro,
                                                                        goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
                                                                        prevPage, nextPage, totalRecords, resultCount
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className='text-right'>
                                        <Button outline color='primary' onClick={() => saveOrUpdateAgreement()}>{id_agreement > 0 ? "Actualizar convenio" : "Guardar convenio"}</Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ModalCodeCsv
                {
                    ...{
                       
                        modalListEgress,toggleModalListEgress,listCode,handlemove,listRepit,priceTarifarion,profileT

                    }
                }
            />
        </Fragment>
    )
}
