import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Table, FormGroup, Badge, TabContent, TabPane, Nav, NavLink, NavItem, Button, Input, Label, Pagination } from 'reactstrap';
import { useMicrositePatients } from '../../../hooks/micrositioCompany/useMicrositePatients';
import Chart from 'react-apexcharts'
import { useState } from 'react';
import { ComponentBalanceCompanyAgreement } from './ComponentBalanceCompanyAgreement';
import { ComponentPayments } from './ComponentPayments';
import './styleMicrositio.css';
import { ComponentInformationAgreement } from './ComponentInformationAgreement';
import { ModalEdit } from './ModalEdit';
import { TableStriped } from '../../../components/tables/TableStriped'
import { ModalPreview } from './ModalPreview';
import Select from 'react-select';



const Sampl = (props) => {


    const {
        patients,
        iNim, iDateFrom, iDate_to, handleInputChangeFilters, handleFilters, handleCleanFilter,
        //RETURN PAGINATION
        goNextPage, goPreviousPage, totalPages, actualPage, goToPage,
        prevPage, nextPage, totalRecords, resultCount, key,

        //Modal preview
        modalPreview, togglePreview, handlePreview, typeModall, loadingPdf, previewWorkOrder, workMedicalOrderPDF,

        //Download pdf
        printWorkOrder,

        statusOrder, mSelectStatus, handleSelectValues,

        //Descargas
        numberDownloads

    } = useMicrositePatients();

    let consecutivo = 1;

    return (
        <Fragment>
            <Container fluid={true} className="p-r-0 p-l-0">
                <br /> <br /> <br />
                <Row>
                    {/* Parte de las búsquedas */}
                    <Col sm="3">
                        <Card className='shadow'>
                            <CardBody>
                                <FormGroup>
                                    <Label className='txt-secondary'>NIM o Nombre</Label>
                                    <Input type='text' value={iNim} name="iNim" onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='Nim o nombre' />
                                </FormGroup>
                                <FormGroup>
                                    <Label className='form-col-label txt-secondary'>Fecha de inicio</Label>
                                    <Input type='date' value={iDateFrom} name='iDateFrom' onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                </FormGroup>
                                <FormGroup>
                                    <Label className='form-col-label txt-secondary'>Fecha de cierre</Label>
                                    <Input type='date' value={iDate_to} name="iDate_to" onChange={handleInputChangeFilters} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                </FormGroup>
                                <FormGroup>

                                    <Label className='form-col-label txt-secondary'>Estatus</Label>
                                    <Select
                                        name="mSelectStatus"
                                        isClearable={true}
                                        placeholder="Selecciona un estatus"
                                        noOptionsMessage="Sin resultados"
                                        options={statusOrder}
                                        onChange={(e) => handleSelectValues(e, 'mSelectStatus')}
                                        value={mSelectStatus}


                                        className="b-r-9"
                                    />

                                </FormGroup>
                                <Row>
                                    <Col sm="6">
                                        <Button type='button' outline color='secondary' size='sm' onClick={() => handleFilters()}>Buscar</Button>
                                    </Col>
                                    <Col sm="6">
                                        <Button outline color='danger' size='sm' onClick={() => handleCleanFilter()}>Limpiar</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    {/* Tarjeta de la tabla de pacientes */}
                    <Col sm="9">
                        <Card className='shadow'>
                            <CardBody>
                                <Row>
                                    <Col sm="9">
                                        <i className='fa fa-user'></i> &nbsp;
                                        <label className='f-16'>Pacientes</label>
                                    </Col>
                                </Row>
                                <hr />
                                {/* Tabla de pacientes */}
                                {
                                    patients.length === 0 ?
                                        <div style={{ textAlign: "-webkit-center" }}>
                                            <p className='f-w-600 f-14 badge badge-light-primary'>No se encontraron resultados.</p>

                                            <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "32%" }} alt="" />
                                        </div>
                                        :


                                        <div className="table-responsive">
                                            <Table className="table-border-vertical">
                                                <thead className="theadA">
                                                    <tr>
                                                        {/* <th className='labelThead' scope="col">#</th> */}
                                                        <th className='labelThead' scope="col">Nim</th>
                                                        <th className='labelThead' scope="col">Nombre</th>
                                                        <th className='labelThead' scope="col">Fecha</th>
                                                        <th className='labelThead' scope="col">Estatus</th>
                                                        <th className='labelThead' scope='col'>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {

                                                        patients.length > 0 && patients.map((_patients, _key) => {
                                                            return (
                                                                <tr key={_key} style={{ verticalAlign: "middle" }} >
                                                                    {/* <td>
                                                                        {(_key + 1)}
                                                                    </td> */}
                                                                    <td>
                                                                        {_patients.nim}
                                                                    </td>
                                                                    <td>
                                                                        {_patients.name_patient}
                                                                    </td>
                                                                    <td>
                                                                        {_patients.fechAndDate}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            _patients.id_estatus_order === 1 ? "ABIERTA" :
                                                                                _patients.id_estatus_order === 5 ? "EN PROCESO" :
                                                                                    _patients.id_estatus_order === 6 ? "EN PROCESO" :
                                                                                        _patients.id_estatus_order === 7 ? "AUTORIZADA" :
                                                                                            _patients.id_estatus_order === 8 ? "AUTORIZADA" :
                                                                                                _patients.id_estatus_order === 9 ? "AUTORIZADA" :
                                                                                                    _patients.id_estatus_order === 10 ? "AUTORIZADA" :
                                                                                                        _patients.id_estatus_order === 13 ? "AUTORIZADA" :
                                                                                                            _patients.id_estatus_order === 15 ? "AUTORIZADA" :
                                                                                                                _patients.id_estatus_order === 18 ? "AUTORIZADA" :
                                                                                                                    _patients.id_estatus_order === 20 ? "CANCELADA" : ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <Row>
                                                                            {(_patients.id_estatus_order === 5 || _patients.id_estatus_order === 6 || _patients.id_estatus_order === 7 || _patients.id_estatus_order == 8 || _patients.id_estatus_order === 10
                                                                                || _patients.id_estatus_order === 13 || _patients.id_estatus_order === 15 || _patients.id_estatus_order === 18) ? (
                                                                                <>
                                                                                    <Col>
                                                                                        <Row style={{ alignContent: "center", alignItems: "center", position: "center", justifyContent: "center" }}>
                                                                                            &nbsp;
                                                                                            <Button color='primary' size="xs" type='button' className='btn btn-pdf' onClick={() => handlePreview(_patients.id_work_order)}>
                                                                                                <i className="fa fa-eye"></i>
                                                                                            </Button> &nbsp; &nbsp;
                                                                                            {_patients.downloads > 0 ?
                                                                                                <Button color='backgroundDownloads' size="xs" type="button" className="btn btn-pdf backgroundDownloads" onClick={() => printWorkOrder(_patients.id_work_order)}>
                                                                                                    <i className="fa fa-file-pdf-o"></i>
                                                                                                    <Label>{_patients.downloads}</Label>
                                                                                                </Button>
                                                                                                :
                                                                                                <Button color='backgroundZeroDownloads' size="xs" type='button' className='btn btn-pdf  backgroundZeroDownloads' onClick={() => printWorkOrder(_patients.id_work_order)}>
                                                                                                    <i className="fa fa-file-pdf-o"></i>
                                                                                                    <Label>{_patients.downloads}</Label>
                                                                                                </Button>
                                                                                            }
                                                                                        </Row>
                                                                                    </Col>

                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </Row>
                                                                    </td>


                                                                </tr>



                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "300px" }}>
                                                            <p>
                                                                Mostrando {actualPage} a {resultCount} de {totalRecords} resultados
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <Pagination aria-label='...' style={{ justifyContent: "center" }}>
                                                                <ul className='pagination pagination-primary'>
                                                                    {
                                                                        actualPage === 1 ?
                                                                            <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                                            : <li style={{ cursor: "pointer" }} className='page-item' onClick={goPreviousPage} ><span className='page-link'>Anterior</span></li>
                                                                    }
                                                                    {
                                                                        actualPage > 1 &&
                                                                        <>
                                                                            <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => goPreviousPage(1)} ><span className='page-link'>1</span></li>
                                                                            {
                                                                                actualPage > 2 &&
                                                                                <>
                                                                                    <li className="page-item"><span className='page-link'>...</span></li>
                                                                                    <li style={{ cursor: "pointer" }} onClick={() => goToPage(actualPage - 1)} className='page-item'><span className='page-link'>{actualPage - 1}</span></li>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                    <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{actualPage}</span></li>
                                                                    {
                                                                        actualPage < totalPages &&
                                                                        <>
                                                                            {
                                                                                actualPage < (totalPages - 1) &&
                                                                                <>
                                                                                    <li style={{ cursor: 'pointer' }} onClick={() => goToPage(actualPage + 1)} className='page-item'><span className='page-link'>{actualPage + 1}</span></li>
                                                                                    <li className='page-item'><span className='page-link'>...</span></li>
                                                                                </>
                                                                            }
                                                                            <li style={{ cursor: 'pointer' }} onClick={() => goToPage(totalPages)} className='page-item'><span className='page-link'>{totalPages}</span></li>
                                                                        </>
                                                                    }
                                                                    {
                                                                        totalPages === actualPage ?
                                                                            <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                                            : <li style={{ cursor: "pointer" }} className='page-item primary' onClick={goNextPage}><span className='page-link primary'>Siguiente</span></li>
                                                                    }
                                                                </ul>
                                                            </Pagination>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalPreview
                {...{
                    modalPreview, togglePreview, handlePreview, typeModall, loadingPdf, previewWorkOrder, workMedicalOrderPDF
                }}
            />
        </Fragment>
    );
}

export default Sampl;