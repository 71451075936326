import React from 'react';
import { Card, Col, Button } from 'reactstrap'


export const CardItemCredit = (props) => {

    const {//
        items
    } = props;


    let amount = items.amount_deposit
    let fech = items.fech
    let patient = items.name_patient
    let gender = items.gender
    let nim = items.nim


    return (
        <>
            <Col sm="12" md="3" lg="3" xl="3" className={`${!items.visibleTable && 'd-none'} `}>
                <Card className='shadow custom-card widget-profile b-info border-3 p-1' style={{ width: "265px", height: "170px" }}  >
                    <div className='text-center profile-details mb-0 p-b-0'>
                        <Col sm="12">
                            {
                                (gender) === "1" ?
                                    <img className="" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "20%" }} />
                                    :
                                    <img className="" src={require("../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "20%" }} />
                            }
                        </Col>
                        <Col sm="12">
                            <label className='txt-secondary'>{patient}</label> <br />
                            <label className='txt-secondary'>{nim}</label>
                        </Col>
                        <Col sm="12">
                            <b className='f-14 txt-danger'>${amount}</b>
                        </Col> <br />
                        <Col sm="12">
                            <label>{fech}</label>

                        </Col>
                    </div>
                    <br />
                </Card>
            </Col>
        </>
    )
}
