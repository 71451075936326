import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormAppCode } from './FormAppCode';

export const ModalAppCode = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        brands, machineModels, infoAnalytes, methods,
        id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids,
        handleInputChange, handleValueSelect, handleMethods, loading, metodosSelect,
        saveCreate, saveUpdate,
        validacionAnalyte
        //  saveUpdate, saveDelete
    } = props;

    return <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg' keyboard={false} centered={true} >
        <ModalHeader toggle={toggle} className="bg-primary f-12">
            {modalTitle}
        </ModalHeader>
        {
            method === "create"
                ? <FormAppCode
                    button={"Crear nuevo"}
                    {...{
                        validacionAnalyte, brands, machineModels, infoAnalytes, methods,
                        id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids, metodosSelect,
                        toggle, loading, handleInputChange, handleValueSelect, handleMethods, validaciones, methodAction: saveCreate
                    }}
                />
                : (method === "update")
                    ? <FormAppCode
                        button={"Editar"}
                        {...{
                            validacionAnalyte, brands, machineModels, infoAnalytes, methods,
                            id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids, metodosSelect,
                            toggle, loading, handleInputChange, handleValueSelect, handleMethods, validaciones, methodAction: saveUpdate
                        }}
                    />
                    : ""
        }
    </Modal>
};
