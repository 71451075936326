import React from 'react'
//import { useForm } from '../forms/useForm';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';



export const useSatisfactionQuestion = () => {


    const history = useHistory();
    const [dinamica, setDinamica] = useState({});
    const [showAlternativeQ, setShowAlternativeQ] = useState(false);

    useEffect(() => {

        getSatisfaction();

    }, [history.location])


    const getSatisfaction = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SatisfactionQuestion/ListPaintQuestion");
        if (respuesta.code === 200) {
            setDinamica(respuesta.data)
        }
    }


    const navigatePrev = (indice) => {

        let respuestas = dinamica;

        if (respuestas[indice - 1] !== undefined) {
            respuestas[indice - 1].visibility = true;
            respuestas[indice].visibility = false;
        }

        setDinamica([...respuestas])

    }

    const navigateNext = (indice) => {

        let respuestas = dinamica;

        if (respuestas[indice + 1] !== undefined) {
            respuestas[indice + 1].visibility = true;
            respuestas[indice].visibility = false;

        }
        setShowAlternativeQ(false);
        setDinamica([...respuestas])
    }

    const saveQuestion = (idParent, idChildren, idP2 = "", idC2 = "", nextnavigate = 0) => {

        let copy = dinamica;

        //console.log("12");

        if (idP2 === "" && idC2 === "") {
            if (copy[idParent].name_type == "multiple") {

                let r = copy[idParent].listReagents[idChildren]

                r.selected_response = !r.selected_response

                r.selected_response = r.selected_response;
                copy[idParent].listReagents[idChildren] = r;

            }
            else if (copy[idParent].name_type == "cerrada") {

                for (let i = 0; i < copy[idParent].listReagents.length; i++) {

                    if (i == idChildren) {
                        copy[idParent].listReagents[i].selected_response = true;

                        if (copy[idParent].listReagents[i].alternativeQuestions.length > 0) {
                            copy[idParent].listReagents[i].alternativeQuestions[0].visibility = true;
                            setShowAlternativeQ(true);
                        }
                    }
                    else {
                        copy[idParent].listReagents[i].selected_response = false;

                        if (copy[idParent].listReagents[i].alternativeQuestions.length > 0) {
                            copy[idParent].listReagents[i].alternativeQuestions[0].visibility = false;
                        }
                    }
                }
            }
        }
        else {
            if (copy[idParent].name_type == "cerrada") {
                for (let a = 0; a < copy[idParent].listReagents[idChildren].alternativeQuestions[idP2].listPaintAlternativeReagents.length; a++) {

                    if (a == idC2) {
                        copy[idParent].listReagents[idChildren].alternativeQuestions[idP2].listPaintAlternativeReagents[a].selected_response = true;
                    }
                    else {
                        copy[idParent].listReagents[idChildren].alternativeQuestions[idP2].listPaintAlternativeReagents[a].selected_response = false
                    }
                }
            }
        }


        if (nextnavigate == 1) {//sin boton
            setShowAlternativeQ(false);

            copy[idParent].visibility = false

            if (copy[idParent + 1] != undefined) {
                copy[idParent + 1].visibility = true
            }

        }


        setDinamica([...copy])
    }

    const navigateNextAbierta = (idParent, idChildren, idP2 = "", idC2 = "", nextnavigate = 0) => {

        let copy = dinamica;


        if (nextnavigate == 0) {//pregunta cerrada con botón de siguiente

            copy[idParent].visibility = false

            if (copy[idParent + 1] != undefined) {
                copy[idParent + 1].visibility = true
            }
        }
        setDinamica([...copy])
    }

    const questionOpen = (idParent, e) => {

        let copyOpen = dinamica

        if (idParent !== "") {

            copyOpen[idParent].comments = e
        }
        setDinamica([...copyOpen])
    }


    return {
        dinamica, navigateNext, navigatePrev, saveQuestion, questionOpen
        , showAlternativeQ
    }
}
