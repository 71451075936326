import jwt_decode from "jwt-decode";
import moment from 'moment';
import {
    useHistory
} from "react-router-dom";
import { ConfigServer } from "../../data/config";


export const validarSesion = (history, request_code, func = null) => {
    return;
    /*
    let token = localStorage.getItem("token")
    if (token === null) {
        // alert("No has iniciado sesión");
        try {
            window.location.href = `${process.env.PUBLIC_URL}/login`
        } catch (error) {
            //console.log("Este es el error");
            //console.log(error);
            let history1 = useHistory;
            window.location.href = `${process.env.PUBLIC_URL}/login`
        }
    } else {
        if (validarToken(token, request_code, func)) {
            // alert("La sesión termino");
            localStorage.removeItem("token");
            setTimeout(() => {
                window.location.href = `${process.env.PUBLIC_URL}/login`
            }, 500);
        }
    }
    */
}

const validarToken = (token, request_code, func) => {

    let {
        exp
    } = jwt_decode(token);

    let hoy = moment();

    exp = moment.unix(exp);

    let validacion = moment(exp).isSameOrBefore(hoy);

    if (validacion) {
        let exp_aux = moment(exp).add(10, 'minutes');

        if (moment(hoy).isBetween(exp, exp_aux)) {
            //console.log("Reiniciando la sesión");
            refreshToken(func, token);
            validacion = false;
        }
        else {
            validacion = true;
        }
    }
    return validacion;
}

export const refreshToken = async (func = null, token = localStorage.getItem("token")) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    // const response = await fetch("http://localhost:5000/api/Users/Refresh", requestOptions);
    const response = await fetch(ConfigServer.serverUrl + "/api/Users/Refresh", requestOptions);

    if (response.status === 200) {
        const resp = await response.json();
        localStorage.setItem("token", resp.token);
        if (func !== null) {
            func();
        }
        //console.log("Se refresco correctamente");
        return true;
    } else {
        // alert("No se refresco");
        return false;
    }
}