import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import Select from "react-select";
import { Button, Collapse, Label, Row, CardHeader, Col, Card, CardBody } from "reactstrap";
import { useCosts } from "../../../hooks/exams/useCosts";
import './StyleExam.css';


export const IndexCosts = forwardRef(({ examId = null, profileId = null, sendKitSampling = null }, ref) => {
  const { samplings, samplingSelected, samplingSelecteds, items, handleSelect, handleSelectMultiple, loading, saveSampling, totalGlobal } =
    useCosts(examId, profileId, sendKitSampling);



  useImperativeHandle(ref, () => ({
    getTotal() {
      return totalGlobal;
    },
  }))

  const [filterDate, setFilterDate] = useState(true);

 
  return (
    <>
      <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12">
        <Row className="position-sticky" style={{ top: 140 }}>
          <Col xl="12">
            <Card className="form theme-form">
              <CardHeader className=' bg-secondary '>
                <h5 className="mb-0">
                  <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
                    data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="icofont icofont-stethoscope-alt f-22 "></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kit Toma de Muestra</Button>
                </h5>
              </CardHeader>
              <Collapse isOpen={filterDate}>
                <CardBody className="p-3">
                  <Row>
                    <Col sm="12">
                      <p className="text-secondary f-14">
                        Selecciona un kit de muestra predeterminado para asignarle al {examId !== null ? "examen" : "perfil"}
                      </p>
                      <Col sm="8">
                        <Select
                          classNamePrefix="select"
                          options={samplings}
                          name="id_exam_simple"
                          isClearable={false}
                          placeholder={`Busqueda de kit de muestra`}
                          noOptionsMessage={() => "Sin opciones"}
                          onChange={examId !== null ? (e) => handleSelect(e) : (e) => handleSelectMultiple(e)}
                          value={examId !== null ? samplingSelected : samplingSelecteds}
                          isMulti={examId !== null ? false : true}
                        />
                      </Col> <br /> <br />
                      <Col sm="12">
                        {
                          examId !== null &&
                          <table className="table table-border-horizontal table-border-vertical">
                            <tbody>
                              <tr className="table-Encabezado border-bottom-info">
                                <th style={{ textAlign: "-webkit-center" }}>Insumo</th>
                                <th style={{ textAlign: "-webkit-center" }}>Código</th>
                                <th style={{ textAlign: "-webkit-center" }}>Costo</th>
                              </tr>
                              {items !== undefined && items.map((x, key) => {
                                //console.log(x);
                                return (
                                  <tr key={"kit" + key} className="border-bottom-info">
                                    <td><label className="labelSamplingg"> {x[0]}</label>  </td>
                                    <td><label className="labelSamplingg"> {x[1]}</label> </td>
                                    <td><label className="labelSamplingg"> ${x[2]}</label> </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        }
                        {
                          profileId !== null &&
                          samplingSelecteds.length > 0 &&
                          <table className="table table-border-horizontal table-border-vertical">
                            <thead className="table tableFondo">
                              <tr className="table-Encabezado border-bottom-info">
                                <th style={{ textAlign: "-webkit-center" }}>Insumo</th>
                                <th style={{ textAlign: "-webkit-center" }}>Código</th>
                                <th style={{ textAlign: "-webkit-center" }}>Costo</th>
                              </tr>
                              {samplingSelecteds.map((x, llave) => {
                                return (
                                  <Fragment key={`amksd${llave}`}>
                                    <tr><td colSpan={3}> <h6 className="text-secondary">{x.name}</h6> </td></tr>
                                    {x.items.map((y, key) => {
                                      return (
                                        <tr key={"kit2" + key} className="border-bottom-info">
                                          {/* <td>{y[1]}</td> */}
                                          {/* <td>{y[0]}</td> */}
                                          <td ><i className="icofont icofont-ui-press kit-icon"></i>&nbsp;{y[0]}</td>
                                          <td> <i className="icofont icofont-ui-press kit-icon"></i>&nbsp; {y[1]}   </td>
                                          <td style={{ textAlignLast: "right" }}>${y[2].toFixed(2)}</td>
                                        </tr>
                                      );
                                    })}
                                    <tr><td colSpan={3}><hr /></td></tr>
                                  </Fragment>
                                )
                              })}
                            </thead>
                          </table>
                        }
                        {
                          (items.length > 0 || (samplingSelecteds.length > 0 && profileId > 0)) &&
                          <div className="float-right"> <br />
                            <Button
                              outline color="primary"
                              onClick={saveSampling}
                              //color="primary"
                              type="button"
                              className={
                                loading ? "disabled progress-bar-animated progress-bar-striped" : ""
                              }
                            >
                              Guardar
                            </Button>
                          </div>
                        }
                      </Col>
                    </Col>
                  </Row>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
});
