import React, { useMemo } from 'react'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ConfigServer } from '../../data/config';
import moment from 'moment/moment';
import { useRef } from 'react';

export const useWorkOrderMicrobiology = () => {

    const history = useHistory();

    //LOCAL VARIABLES
    let id_commercial_line = localStorage.getItem('commercialLineId');
    let id_branch = localStorage.getItem('branchId');
    let percentage_urgent_work_order = localStorage.getItem('percentage_urgent_work_order');

    //SOCIAL GENDER
    const [Listsocialgender, setListsocialgender] = useState([]);
    //END SOCIAL GENDER

    //#region MODALS
    const [modalTitle, setModalTitle] = useState("");
    const [modalPatient, setModalPatient] = useState(false);
    const [modalDoctor, setModalDoctor] = useState(false);
    const [modalViewExamsP, setModalViewExamsP] = useState(false);
    const [modalSpecialDiscounts, setModalSpecialDiscounts] = useState(false);
    const [modalCommonDiscount, setModalCommonDiscount] = useState(false);
    const [modalQuoter, setModalQuoter] = useState(false);
    const [modalInvoice, setModalInvoice] = useState(false);
    const [modalScannQrPatient, setModalScannQrPatient] = useState(false);
    const [modalRepeatProfile, setModalRepeatProfile] = useState(false);
    const [modalSocialGender, setModalSocialGender] = useState(false);

    const [methodPatient, setMethodPatient] = useState("");
    const [methodDoctor, setMethodDoctor] = useState("");
    const [methodQuoter, setMethodQuoter] = useState("");
    const [methodInvoice, setMethodInvoice] = useState("");

    const toggleModalSocialGender = () => {
        setModalSocialGender(!modalSocialGender);
    }
    const togglePatient = () => {
        setModalPatient(!modalPatient);
    }

    const toggleModalScannQr = () => {
        setModalScannQrPatient(!modalScannQrPatient);
    }

    const toggleDoctor = () => {
        setModalDoctor(!modalDoctor);
    }

    const toggleModalViewExams = () => {
        setModalViewExamsP(!modalViewExamsP);
    }

    const toggleSpecialDiscount = () => {
        setModalSpecialDiscounts(!modalSpecialDiscounts);
    }

    const toggleCommonDiscount = () => {
        setModalCommonDiscount(!modalCommonDiscount);
    }

    const toggleModalQuoter = () => {
        setModalQuoter(!modalQuoter);
    }

    const toggleModalInvoice = () => {
        setModalInvoice(!modalInvoice);
    }

    const toggleRepeatProfile = () => {
        setModalRepeatProfile(!modalRepeatProfile);
    }
    //#endregion MODALS

    //SHOW DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();
    //END SHOW DISPLAT MESSAGE

    //#region WORK ORDER 

    //#region WORK ORDER MODEL

    const [searchExamProfileState, setSearchExamProfileState] = useState([]);
    const [loading, setLoading] = useState(false);

    //TAB TYPE WORK ORDER
    const [checkTypeClient, setCheckTypeClient] = useState(1);

    //PRELOADED VALUES
    const [listPrinterPoint, setListPrinterPoint] = useState([]);
    const [listPayMethod, setListPayMethod] = useState([]);
    const [listCompanies, setListCompanies] = useState([]);
    const [listUseCfdi, setListUseCfdi] = useState([]);
    const [times, setTimes] = useState([]);

    //INFORMATION WORK ORDER
    const [totalW, setTotalW] = useState(0.00);
    const [subTotalW, setSubTotalW] = useState(0.0);
    const [totalDiscountW, setTotalDiscountW] = useState(0.0);
    const [totalTaxesW, setTotalTaxesW] = useState(0.0);
    const [moneyEntered, setMoneyEntered] = useState(0);
    const [showMessageW, setShowMessageW] = useState({
        labelMoney: "",
        amount: 0.0
    });
    const [viewExamsProfile, setViewExamsProfile] = useState([]);
    const [viewWorkOrder, setViewWorkOrder] = useState(false);
    const [enableBtnSaveWorkOrder, setEnableBtnSaveWorkOrder] = useState(false);

    const [idWorkOrder, setIdWorkOrder] = useState(0);
    const [checkDiscount, setcheckDiscount] = useState();
    //NEW CONTS
    const [informationCompany, setInformationCompany] = useState({});
    const [viewCompany, setviewCompany] = useState(false);
    //END CONST
    //MEDICAL ORDER MESSAGE
    const [showMessageWF, setShowMessageWF] = useState({
        labelWF: "Cargar Orden Médica"
    });

    const [showClassSD, setshowClassSD] = useState({
        ClassSD: "f-w-600 f-12 badge badge-primary ml-5"
    });

    const [showClass, setshowClass] = useState({
        ClassName: "d-none"
    });

    //COMMENTS GENERAL MEDICAL ORDER
    const [ShowClassNameC, SetShowClassNameC] = useState("d-none");
    const [checkComments, setcheckComments] = useState(false);
    //STATUS MEDICAL ORDER
    const [OrderMedicalEstatus, setOrderMedicalEstatus] = useState(1);
    const [medical_order, setFile] = useState("");

    //#region COMMON DISCOUNTS
    const [arrayNameDiscount, setArrayNameDiscount] = useState([]);
    const [arrayCommonDiscounts, setArrayCommonDiscounts] = useState([]);
    const [arrayAgreementDiscounts, setArrayAgreementDiscounts] = useState([]);
    //#endregion
    //#regioncommonts
    const [valid_commont, setvalid_commont] = useState(true);
    //endregioncommont
    //#region MEMBERSHIP PATIENT
    const [is_membership, setIs_membership] = useState(false);
    //#endregion

    //FORM VALUES WORK ORDER
    const [formWorkOrder, handleInputChangeWorkOrder, resetWorkOrder, handleUpdateValuesWorkOrder, handleSelectValuesWorkOrder, handlePickerValuesWorkOrder, handleDinamicInputWorkOrder] = useForm({
        id_patient: 0,
        id_branch: id_branch,
        id_company: 0,
        id_printer_point: 0,
        id_quoter: null,
        observations: "",
        observations_sample: "",
        observations_general: "",
        valid_commont_: true,
        checkPrint: true,
        checkPartial: true,
        checkEmail: false,
        checkWhats: false,
        checkDoctor: false,
        checkInvoice: false,
        business_name: "",
        rfc: "",
        phone: "",
        email: "",
        id_use_cfdi: 0,
        print_invoice: 0,
        send_invoice: 0,
        listTest: [],
        listPayMethods: [{
            id_pay_method: 0,
            amount: 0.0
        }],
        listDoctors: [],
        iva: 0.0
    });

    const {
        id_patient,
        id_company,
        id_printer_point,
        observations,
        observations_sample,
        valid_commont_,
        observations_general,
        checkPrint,
        checkPartial,
        checkEmail,
        checkWhats,
        checkDoctor,
        checkInvoice,
        business_name,
        rfc,
        phone,
        email,
        id_use_cfdi,
        print_invoice,
        send_invoice,
        listTest,
        listPayMethods,
        listDoctors,
        iva
    } = formWorkOrder;
    //#endregion WORK ORDER MODEL 

    //#region VALIDATIONS WORK ORDER
    const [validationsWorkOrder, setValidationsWorkOrder] = useState({
        id_patient_valid: false,
        id_printer_point_valid: false,
        id_company_valid: false,
        listTest_valid: false,
        listPayMethods_valid: false,
        listid: false
    });

    const [validationsTest, setValidationsTest] = useState({
        valid_repeat_test: false,
        valid_exist_exam_profile: false,
        valid_conteins_exam: false,
        message_validation: ""
    });

    const [validationsRepeat, setValidationsRepeat] = useState({
        name_profile: "",
        repeat_profiles: [],
        repeat_exams: []
    });
    //#endregion VALIDATIONS WORK ORDER

    //#region WORK ORDER USE EFFECT
    useEffect(() => {
        if (listPrinterPoint.length > 0) {
            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_printer_point: listPrinterPoint[0]
            });
        }
    }, [listPrinterPoint]);

    useEffect(() => {
        handleGetPrinterPoints();
        handleGetTimes();
        handleGetPayMethods();
        handleGetUseCfdi();
    }, []);

    useEffect(() => {

        if (idWorkOrder > 0) {
            UpdateFilePatient(idWorkOrder);
            //printTicket(idWorkOrder);
        }

    }, [idWorkOrder])

    //#endregion WORK ORDER USE EFFECT

    //#region WORK ORDER METHODS
    const handleChangeTypeClient = (typeClient, validClient = -1) => {
        
        if (formWorkOrder.id_patient === 0) {
            setCheckTypeClient(typeClient);
            handleGetClients(typeClient);
        }
        else {
            if (validClient === -1) {
                sweetConfirmationChangeClient("Al cambiar el tipo de ingreso, perderas los datos ingresados", "Estas seguro de realizar esta acción", "warning", typeClient, true, handleChangeTypeClient)
                return;
            }
        }


        if (!validClient) {
            return;
        }
        else {
            setCheckTypeClient(typeClient);
            handleGetClients(typeClient);
            cancellWorkOrder();
        }
        setInformationCompany({
            code: undefined,
            phone: undefined,
            email: undefined
        });
    }

    //FILES
    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        setShowMessageWF({
            labelWF: "Orden Médica Cargada"
        });
        setshowClassSD({
            ClassSD: "f-w-600 f-12 badge badge-secondary"
        });
        setshowClass({
            ClassName: ""
        });
    };

    const DeleteFile = (e) => {

        setOrderMedicalEstatus(0);
        setShowMessageWF({
            labelWF: "Cargar Orden Médica"
        });
        setshowClassSD({
            ClassSD: "f-w-600 f-12 badge badge-primary"
        });
        setshowClass({
            ClassName: "d-none"
        });
    };

    const UpdateFilePatient = async (id_work_order) => {
        if (id_work_order > 0) {
            if (OrderMedicalEstatus == 1) {
                var formData = new FormData();
                formData.append('medical_order', medical_order);
                let requestOptions = {
                    method: 'PUT',
                    body: formData
                };
                const respuesta = await sendRequest(requestOptions, "WorkOrder/" + id_work_order, 'multipart/form-data');
                setShowMessageWF({
                    labelWF: ""
                });
            }
        }
    }

    const handleGetTimes = async () => {
        let listTemp = await handleRequest("GET", "Price/GetTimes", "Tiempos");

        if (listTemp !== null && listTemp.length > 0) {
            setTimes(listTemp);
        }
    }

    const handleGetPrinterPoints = async () => {
        let listTmp = await handleRequest("GET", "PrinterPoints", "Puntos de impresión");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach((obj, i) => {

                if (i === 0) {
                    handleUpdateValuesWorkOrder({
                        ...formWorkOrder,
                        id_printer_point: {
                            value: obj.id_printer_point,
                            label: obj.name
                        }
                    });
                }

                list.push({
                    value: obj.id_printer_point,
                    label: obj.name
                });
            });

            setListPrinterPoint([...list]);
        }
    }

    const handleGetPayMethods = async () => {
        let listTmp = await handleRequest("GET", "PayMethod/List", "Metodos de pago");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    id_pay_method: obj.id_pay_method,
                    name: obj.name,
                    abbreviation: obj.abbreviation
                });
            });

            setListPayMethod(list);
        }
    }

    const handleGetUseCfdi = async () => {
        let listTmp = await handleRequest("GET", "UseCfdi/List", "Uso de cfdi");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    id_use_cfdi: obj.id_use_cfdi,
                    description: obj.description,
                });
            });

            setListUseCfdi(list);
        }
    }

    const handleGetClients = async (typeClient) => {
        let listTmp = await handleRequest("GET", `Company/ListTypeCompanies/${typeClient}`, 'Clientes');

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_company,
                    label: obj.code + ' ' + obj.name
                });
            });

            setListCompanies(list);
        }
    }

    //FIND EXAMS AND PROFILES
    const handleSelectExamChange = (e) => {
        let examProfile = e.target.value;

        if (examProfile !== 0 && examProfile !== null) {

            examProfile = examProfile.split('-');

            let idTest = examProfile[0];
            let typeTest = examProfile[1];
            let nameTypeTest = typeTest === "0" ? "Exam" : "Profile";

            if (nameTypeTest === "Profile") {
                handleGetProfile(idTest);
            }
            else {
                handleGetExam(idTest);
            }
        }
    }

    const handleGetProfile = async (id_profile, valid_exist = -1) => {
        let mIdPatient = 0;
        let mIdClient = 0;

        if (formWorkOrder.id_company.value !== undefined) {
            mIdPatient = 0;
            mIdClient = formWorkOrder.id_company.value;
        }
        else {
            mIdClient = 0;
            mIdPatient = specialDiscount !== null ? 0 : id_patient;
        }

        let alternative_method = "";

        if (is_membership) {
            alternative_method = "&is_membership=true";
        }
        else {
            alternative_method = "&is_membership=false";
        }

        var listTemp = await handleRequest("GET", `Profiles/FindProfileId/${id_profile},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},0?validation_strict=true${alternative_method}`, "Buscar perfles")

        let list = listTest;

        if (listTemp !== null) {
            let descuentoEspecial = null;
            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;

            let validTest = validationTest(null, id_profile, listTemp.listExams, listTemp.name);

            if (validTest !== -1 && validTest !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }
            else if (validTest === 2 && valid_exist === -1) {
                toggleRepeatProfile();
                setModalTitle("Perfiles con exámenes duplicados");
                return;
            }

            let array_specimens = [];

            listTemp.exams_specimens.forEach(obj => {
                if (!array_specimens.find(x => x.id_specimen === obj.id_specimen_origin)) {

                    let valorCheck = true;
                    let valorCheckForwarded = false;

                    listTest.forEach(item => {
                        if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                            valorCheck = item.check_specimen;
                            valorCheckForwarded = false;
                        }
                    });

                    array_specimens.push({
                        specimen_name: obj.name_specimen_origin,
                        id_specimen: obj.id_specimen_origin,
                        check_specimen: valorCheck,
                        check_forwarded: valorCheckForwarded,
                    });
                }
            });

            let porcentaje_descuento = 0;

            if (specialDiscount !== null) {
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_profile === listTemp.id_profile) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    })
                }
            }
            else if (listTemp.percentage_discount > 0) {
                if (checkDiscount == undefined) {
                    setcheckDiscount(listTemp.auto_redeem);
                }

                porcentaje_descuento = listTemp.percentage_discount;
            }
            else if (mIdClient > 0) {
                if (listTemp.agreement.id_agreement > 0) {
                    let listAgrrementDiscounts = arrayAgreementDiscounts;
                    let convenio = listTemp.agreement;

                    porcentaje_descuento = convenio.percentage;
                    id_agreement = convenio.id_agreement;
                    id_agreement_test_range = convenio.id_agreement_test_range;
                    contract_number = convenio.contract_number;

                    listAgrrementDiscounts.push({
                        percentage_discount: porcentaje_descuento,
                        id_agreement: id_agreement,
                        id_agreement_test_range: id_agreement_test_range,
                        contract_number: contract_number,
                        test: listTemp.name
                    });

                    setcheckDiscount(listTemp.auto_redeem);
                    setArrayAgreementDiscounts(listAgrrementDiscounts);
                }
            }
            var hoy = new Date();
            let day = hoy.getDate();
            let month = hoy.getMonth() + 1;
            let year = hoy.getFullYear();
            if (day < 10) {
                day = "0" + day;
            }
            if (month < 10) {
                month = "0" + month;
            }
            var limit = new Date(listTemp.valid_to);
            let day_valid_to = limit.getDate();
            let month_valid_to = limit.getMonth() + 1;
            let year_valid_to = limit.getFullYear();
            let validcommont = true;
            if (year > year_valid_to) {
                validcommont = false;
            } else {
                if (month > month_valid_to) {
                    validcommont = false;
                } else {
                    if (day > day_valid_to) {
                        validcommont = false;

                    }
                }
            }
            setvalid_commont(validcommont);
            list.push({
                id_profile: listTemp.id_profile,
                id_exam: null,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_rate: listTemp.id_rate,
                id_price: listTemp.id_price,
                name_rate: "",
                price: listTemp.price,
                specimen_name: "",
                id_specimen: null,
                array_specimens: array_specimens,
                discount: porcentaje_descuento,
                id_common_discount: listTemp.id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: id_agreement,
                id_agreement_test_range: id_agreement_test_range,
                contract_number: contract_number,
                listIndications: listTemp.listExamIndications,
                is_urgent: false,
                is_curve: listTemp.is_curve,
                curves: listTemp.listCurves,
                listExams: listTemp.listExams,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                is_quotation: false,
                id_profile_curve_price: 0,
                urgent_price: 0,
                auto_redeem: listTemp.auto_redeem,
                valid_commont: validcommont
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });

            setSearchExamProfileState({});
        }
    }

    const handleGetExam = async (id_exam) => {
        let mIdPatient = 0;
        let mIdClient = 0;

        if (formWorkOrder.id_company.value !== undefined) {
            mIdPatient = 0;
            mIdClient = formWorkOrder.id_company.value;
        }
        else {
            mIdClient = 0;
            mIdPatient = specialDiscount !== null ? 0 : id_patient;
        }

        let alternative_method = "";

        if (is_membership) {
            alternative_method = "?is_membership=true";
        }

        let list = listTest;

        let listTemp = await handleRequest("GET", `Exam/GetPriceFromExam/${id_exam},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},0,true${alternative_method}`, "Exámenes");

        if (listTemp !== null) {
            let descuentoEspecial = null;
            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;

            let validationExam = validationTest(id_exam);

            if (validationExam !== -1 && validationExam !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }

            let valorCheck = true;
            let valorCheckForwarded = false;


            list.forEach(item => {
                if (item.id_specimen === parseInt(listTemp.id_specimen)) {
                    valorCheck = item.check_specimen;
                    valorCheckForwarded = false;
                }
            });

            let porcentaje_descuento = 0;

            if (specialDiscount !== null) {
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_exam === listTemp.id_exam) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    });
                }
                else {
                    if (!listTemp.is_referenced) {
                        porcentaje_descuento = specialDiscount.percentege;
                        descuentoEspecial = specialDiscount.id_special_discount;
                    }
                }
            }
            else if (listTemp.percentage_discount > 0) {

                if (checkDiscount == undefined) {
                    setcheckDiscount(listTemp.auto_redeem);
                }
                porcentaje_descuento = listTemp.percentage_discount;
            }
            else if (mIdClient > 0) {
                if (listTemp.agreement.id_agreement > 0) {
                    let listAgreementDiscounts = arrayAgreementDiscounts;

                    let convenio = listTemp.agreement;

                    porcentaje_descuento = convenio.percentage;
                    id_agreement = convenio.id_agreement;
                    id_agreement_test_range = convenio.id_agreement_test_range;
                    contract_number = convenio.contract_number;

                    listAgreementDiscounts.push({
                        percentage_discount: porcentaje_descuento,
                        id_agreement: id_agreement,
                        id_agreement_test_range: id_agreement_test_range,
                        contract_number: contract_number,
                        test: listTemp.name
                    });

                    setcheckDiscount(convenio.auto_reedem);
                    setArrayAgreementDiscounts(listAgreementDiscounts);
                }
            }

            var hoy = new Date();
            let day = hoy.getDate();
            let month = hoy.getMonth() + 1;
            let year = hoy.getFullYear();
            if (day < 10) {
                day = "0" + day;
            }
            if (month < 10) {
                month = "0" + month;
            }
            var fecha = day + "/" + month + '/' + hoy.getFullYear();
            var limit = new Date(listTemp.valid_to);
            let day_valid_to = limit.getDate();
            let month_valid_to = limit.getMonth() + 1;
            let year_valid_to = limit.getFullYear();
            let validcommont = true;
            if (year > year_valid_to) {
                validcommont = false;
            } else {
                if (month > month_valid_to) {
                    validcommont = false;
                } else {
                    if (day > day_valid_to) {
                        validcommont = false;

                    }
                }
            }
            setvalid_commont(validcommont);

            list.push({
                id_exam: listTemp.id_exam,
                id_profile: null,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_rate: listTemp.id_rate,
                id_price: listTemp.id_price,
                name_rate: listTemp.name_rate,
                price: listTemp.price,
                specimen_name: listTemp.name_specimen,
                id_specimen: listTemp.id_specimen,
                check_specimen: valorCheck,
                check_forwarded: valorCheckForwarded,
                discount: porcentaje_descuento,
                id_common_discount: listTemp.id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: id_agreement,
                id_agreement_test_range: id_agreement_test_range,
                contract_number: contract_number,
                listIndications: listTemp.listIndications,
                is_urgent: false,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                listExams: null,
                urgent_price: 0,
                auto_redeem: listTemp.auto_redeem,
                valid_commont: validcommont
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });


            setSearchExamProfileState({});
        }
    }

    const changeSelectSamplings = (e, id_profileAux) => {
        let allRequisitionExams = listTest;

        let id_profile_curve_price = parseInt(e.target.value);
        let findRequestExam = allRequisitionExams.find(r => r.id_profile === parseInt(id_profileAux));

        let curva_seleccionada = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile == parseInt(id_profileAux)) {

                let curves_aux = [];

                r.price = curva_seleccionada.price;

                for (let index = 1; index <= curva_seleccionada.number_sampling; index++) {

                    curves_aux.push({
                        ...curva_seleccionada,
                        time: "",
                        number_sampling_aux: index
                    });
                }
                r.curves_aux = curves_aux;
            }

            return r;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [...nuevos]
        });
    }

    const changeValueTimeSampling = (e, id_profileAux, id_profile_curve_price, number_sampling) => {
        let allRequisitionExams = listTest;

        let tiempo_axu = e;

        let findRequestExam = allRequisitionExams.find(x => x.id_profile === parseInt(id_profileAux));

        let curva_seleccionada = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile === parseInt(id_profileAux)) {
                let curves_aux = r.curves_aux;
                let nuevas_curvas = curves_aux.map(c => {
                    if (c.number_sampling_aux === number_sampling) {
                        c.time = tiempo_axu;
                    }

                    return c;
                });
                r.curves_aux = nuevas_curvas;
            }
            return r;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [...nuevos]
        });
    }

    const removeExamProfile = (id_exam, id_profile = null) => {
        let findTest = listTest.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        if (findTest !== null) {
            let newListTest = listTest.filter(item => {
                if ((id_exam !== null && item.id_exam === parseInt(id_exam)) || (id_profile !== null && item.id_profile === parseInt(id_profile))) {
                    return false;
                }
                else {
                    return true;
                }
            });

            if (newListTest.length == 0) {

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest,
                    listPayMethods: [{
                        id_pay_method: 0,
                        amount: 0.0
                    }]
                });

                setMoneyEntered(0.00);
            }
            else {
                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest
                });
            }
        }
    }

    const handleCheckUrgent = (index, e) => {
        let listTmp = listTest;

        listTmp[index].is_urgent = e.target.checked;

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [...listTmp]
        });
    }
    //cambiar reward
    // const handleCheckForwarded = (id_exam, id_profile = null, id_specimen = null) => {
    //     let allRequisitionExams = listTest;

    //     let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

    //     let valor = 0;
    //     let sub = null;
    //     if (id_specimen !== null) {
    //         sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
    //         valor = !sub.check_forwarded;
    //     }
    //     else {
    //         valor = !findRequestExam.check_forwarded;
    //     }

    //     allRequisitionExams.map(item => {
    //         if (item.array_specimens) {
    //             item.array_specimens.map(element => {
    //                 if (sub === null) {
    //                     if(item.id_exam!=null){
    //                         if (element.id_specimen === findRequestExam.id_specimen && ) {
    //                             element.check_forwarded = valor;
    //                         }
    //                     }

    //                 }
    //                 else {
    //                     if(item.id_profile!=null){
    //                         if (element.id_specimen === sub.id_specimen) {
    //                             element.check_forwarded = valor;
    //                         }
    //                     }
    //                 }

    //                 return element;
    //             });
    //         }
    //         else {
    //             if (sub === null) {
    //                 if (item.id_specimen === findRequestExam.id_specimen) {
    //                     item.check_forwarded = valor;
    //                 }
    //             }
    //             else {
    //                 if (item.id_specimen === sub.id_specimen) {
    //                     item.check_forwarded = valor;
    //                 }
    //             }
    //         }
    //         return item;
    //     });

    //     handleUpdateValuesWorkOrder({
    //         ...formWorkOrder,
    //         listTest: allRequisitionExams
    //     });
    // }
    const handleCheckForwarded = (id_exam, id_profile = null, id_specimen = null) => {
        let allRequisitionExams = listTest;

        let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let valor = 0;
        let sub = null;
        if (id_specimen !== null) {
            sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
            valor = !sub.check_forwarded;
        }
        else {
            valor = !findRequestExam.check_forwarded;
        }

        allRequisitionExams.map(item => {
            if (item.array_specimens) {
                item.array_specimens.map(element => {
                    if (sub === null) {
                        if (item.id_exam != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                                element.check_forwarded = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                                element.check_forwarded = valor;
                            }
                        }
                    }
                    else {
                        if (item.id_exam != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                                element.check_forwarded = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                                element.check_forwarded = valor;
                            }
                        }
                    }

                    return element;
                });
            }
            else {
                if (sub === null) {
                    if (item.id_exam != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                            item.check_forwarded = valor;
                        }
                    }
                    else if (item.id_profile != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                            item.check_forwarded = valor;
                        }
                    }
                }
                else {
                    if (item.id_exam != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                            item.check_forwarded = valor;
                        }
                    } else if (item.id_profile != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                            item.check_forwarded = valor;
                        }
                    }

                }
            }
            return item;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: allRequisitionExams
        });
    }

    const handleCheckSpecimen = (id_exam, id_profile = null, id_specimen = null) => {
        let allRequisitionExams = listTest;

        let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let valor = 0;
        let sub = null;
        if (id_specimen !== null) {
            sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
            valor = !sub.check_specimen;
        }
        else {
            valor = !findRequestExam.check_specimen;
        }

        allRequisitionExams.map(item => {
            if (item.array_specimens) {
                item.array_specimens.map(element => {
                    if (sub === null) {
                        if (item.id_exam != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                                element.check_specimen = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                                element.check_specimen = valor;
                            }
                        }
                    }
                    else {
                        if (item.id_exam != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                                element.check_specimen = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                                element.check_specimen = valor;
                            }
                        }
                    }

                    return element;
                });
            }
            else {
                if (sub === null) {
                    if (item.id_exam != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                            item.check_specimen = valor;
                        }
                    }
                    else if (item.id_profile != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                            item.check_specimen = valor;
                        }
                    }
                }
                else {
                    if (item.id_exam != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                            item.check_specimen = valor;
                        }
                    } else if (item.id_profile != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                            item.check_specimen = valor;
                        }
                    }

                }
            }
            return item;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: allRequisitionExams
        });
    }

    const handleViewExamsProfile = (id_profile) => {

        let findExams = listTest.find(x => x.id_profile === parseInt(id_profile));
        if (findExams) {

            setViewExamsProfile(findExams.listExams);
            toggleModalViewExams();
        }
    }

    const handleAddPayMethodInputs = () => {
        let listPayMethod = listPayMethods;

        listPayMethod.push({
            id_pay_method: 0,
            amount: 0.0
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: listPayMethod
        });
    }

    const handleGiveChange = (e, nameInput, nameArraysInput) => {
        handleDinamicInputWorkOrder(e, nameInput, nameArraysInput);

        let totalIngresado = 0;

        listPayMethods.forEach(obj => {
            totalIngresado += (obj.amount === "") ? 0.00 : (parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount));
        });

        totalIngresado = !isNaN(totalIngresado) ? totalIngresado : 0;

        setMoneyEntered(totalIngresado.toFixed(2));
    }

    const handleDeletePayMethod = (index) => {
        let copyListPayMethods = listPayMethods;
        let money = 0;

        if (listPayMethods.length > 1) {
            copyListPayMethods.splice(index, 1);
        }

        copyListPayMethods.forEach(obj => {
            money += parseFloat(obj.amount)
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: [...copyListPayMethods]
        });

        setMoneyEntered(money.toFixed(2));
    }

    const saveWorkOrder = async (validPercentagePrice = -1) => {
        setLoading(true);

        if (validationWorkOrder()) {
            setLoading(false);
            return;
        }

        if (validArrayCurva1()) {
            setLoading(false);
            createSweet("create", "warning", "Curvas", "Selecciona opción de la toma");
            return;
        }

        if (validArrayCurva()) {
            setLoading(false);
            createSweet("create", "warning", "Curvas", "Configura el tiempo de la toma");
            return;
        }


        if (moneyEntered > 0) {
            let percentage = (moneyEntered * (50 / 100));
            percentage = parseFloat(percentage.toFixed(2));

            let handleChange = (parseFloat(moneyEntered) - totalW);
            handleChange = !isNaN(handleChange) ? parseFloat(handleChange.toFixed(2)) : 0;


            if (handleChange > percentage && validPercentagePrice === -1) {
                sweetConfirmation("El cambio supera el 50% del total", "Deseas cambiar la cantidad ingresada", "warning", true, saveWorkOrder);
                return;
            }
        }

        if (!validPercentagePrice) {
            return;
        }

        let arrayDoctors = [];
        if (formWorkOrder.id_doctor !== undefined && formWorkOrder.id_doctor.length > 0) {
            arrayDoctors.forEach(obj => {
                arrayDoctors.push({
                    id_doctor: obj.value
                });
            })
        }
        let body = {
            id_patient: formWorkOrder.id_patient,
            id_branch: formWorkOrder.id_branch,
            id_company: (formWorkOrder.id_company.value === undefined) ? 0 : formWorkOrder.id_company.value,
            id_printer_point: formWorkOrder.id_printer_point.value,
            id_work_order_priority: null,
            id_quoter: formWorkOrder.id_quoter,
            listExamProfile: formWorkOrder.listTest,
            observations: formWorkOrder.observations,
            observations_sample: formWorkOrder.observations_sample,
            observations_general: formWorkOrder.observations_general,
            print_results: formWorkOrder.checkPrint,
            results_partial: formWorkOrder.checkPartial,
            send_email: formWorkOrder.checkEmail,
            send_whatsapp: formWorkOrder.checkWhats,
            send_doctor: formWorkOrder.checkDoctor,
            requiered_invoice: formWorkOrder.checkInvoice,
            business_name: formWorkOrder.business_name,
            rfc: formWorkOrder.rfc,
            phone: formWorkOrder.phone,
            email: formWorkOrder.email,
            id_use_cfdi: formWorkOrder.id_use_cfdi,
            print_invoice: formWorkOrder.print_invoice,
            send_invoice: formWorkOrder.send_invoice,
            listDoctors: arrayDoctors,
            id_income_type: checkTypeClient,
            listPayMethods: listPayMethods,
            checkDiscount: checkDiscount,
            iva: totalTaxesW,
            valid_commont: valid_commont
        };

        let listTmp = await handleRequest("POST", "WorkOrder/Create", "Admisión", body);

        if (listTmp !== null) {

            let id_work_order = listTmp.id_work_order;
            setIdWorkOrder(id_work_order);

            setLoading(false);
            createSweet("create", "success", "Exito!");

            cancellWorkOrder();
        }
        else {
            setLoading(false);
        }

    }

    const cancellWorkOrder = () => {
        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            id_patient: 0,
            id_branch: id_branch,
            id_company: 0,
            observations: "",
            observations_sample: "",
            observations_general: "",
            checkPrint: true,
            checkPartial: true,
            checkEmail: false,
            checkWhats: false,
            checkDoctor: false,
            checkInvoice: false,
            business_name: "",
            rfc: "",
            phone: "",
            email: "",
            id_use_cfdi: 0,
            print_invoice: 0,
            send_invoice: 0,
            listTest: [],
            listPayMethods: [{
                id_pay_method: 0,
                amount: 0.0
            }],
            listDoctors: [],
            valid_commont: true,
        });
        setArrayCommonDiscounts([]);
        setArrayNameDiscount([]);
        setMoneyEntered(0.0);

        //PATIENT
        setSearchPatientState([]);
        setInformationPatient({
            birthday: null,
            is_complete: false,
            phone: null,
            gender: null,
            age: null,
            email: null,
            curp: null,
            membership: null,
            name: null,
            paternal_surname: null,
            maternal_surname: null
        });

        handleUpdateValuesPatient({
            id_gender: 0,
            id_state_patient: 0,
            id_municipality_patient: 0,
            id_social_gender_patient: 0,
            id_blood_type: 0,
            code: "",
            paternal_surname: "",
            maternal_surname: "",
            name: "",
            birthday: "",
            curp: "",
            address_patient: "",
            cp_patient: "",
            colony_patient: "",
            phone_patient: "",
            email_patient: "",
            who_collects_exams: "",
            membership: "",
            cardnumber: "",
        });

        //DOCTOR
        setSearchDoctorState([]);
        setInformationDoctor([]);
        setIdDoctor(0);
        setIdDoctorSelect(0);

        handleUpdateValuesDoctor({
            id_specialty: 0,
            name_doctor: "",
            professional_license: "",
            phone_doctor: "",
            address_doctor: "",
            email_doctor: "",
            name_specialty: "",
            isCommercialPartner: false
        });

        setViewWorkOrder(false);

        //SPECIAL DISCOUNTS
        setSpecialDiscount(null);
        setIs_membership(false);

        //VARIABLES
        setcheckComments(false);
        SetShowClassNameC("d-none mb-2");
        setcheckDiscount();
        setArrayAgreementDiscounts([]);

    }

    const printTicket = async (id_work_order) => {

        if (id_work_order > 0) {

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl + "/api/PrintTicket/PrintTicket/" + id_work_order, {
                method: 'POST',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }

                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();

                    setIdWorkOrder(0);
                })
        }
    }
    //#endregion WORK ORDER METHODS

    //CALCULATE AMOUNT OF WORK ORDER
    useMemo(() => {
        let total = 0.0;
        let subtotal = 0.0;
        let descuento = 0.0;
        let total_iva = 0.0;

        if (formWorkOrder.listTest.length > 0) {
            formWorkOrder.listTest.forEach(data => {
                switch (data.config_iva) {
                    case true://LOS PRECIOS YA TIENEN IVA

                        let with_discount = 0.0;
                        let price = 0.0;

                        let value_urgent = parseInt(percentage_urgent_work_order);

                        //HAY QUE QUITAR EL IVA
                        let valueIva = 1 + (data.value_iva / 100);

                        price = (data.price / valueIva);

                        if (data.is_urgent) {
                            let with_urgent = (price * value_urgent) / 100;
                            data.urgent_price = !isNaN(with_urgent) ? parseFloat(with_urgent.toFixed(2)) : 0;

                            price = price + with_urgent;
                        }
                        else {
                            data.urgent_price = 0;
                        }
                        if (data.valid_commont == true) {
                            if (data.auto_redeem == true || checkDiscount == true) {
                                if (checkDiscount == true) {
                                    if (data.discount !== undefined && data.discount !== 0) {
                                        with_discount = price * (data.discount / 100);

                                        arrayCommonDiscounts.push(data.id_common_discount);
                                    }
                                }

                            } else if (data.auto_redeem == false && checkDiscount == true) {

                                if (data.discount !== undefined && data.discount !== 0) {
                                    with_discount = price * (data.discount / 100);

                                    arrayCommonDiscounts.push(data.id_common_discount);
                                }
                            } else if (data.auto_redeem == true && checkDiscount == false) {


                            } else {

                                if (data.discount !== undefined && data.discount !== 0) {
                                    arrayCommonDiscounts.push(data.id_common_discount);
                                }
                            }
                        }
                        else {
                            arrayCommonDiscounts.push(data.id_common_discount);
                        }
                        //let with_iva = (data.value_iva / 100) * price;
                        let with_iva = ((price - with_discount) * data.value_iva) / 100;

                        subtotal += price;
                        descuento += with_discount;
                        total_iva += with_iva;

                        break;
                    case false:
                        let desc = 0.0;
                        let simple_price = data.price;

                        let value_urgent_2 = parseInt(percentage_urgent_work_order);

                        if (data.is_urgent) {
                            let urgent = ((simple_price * value_urgent_2) / 100);
                            simple_price = simple_price + urgent;

                            data.urgent_price = !isNaN(urgent) ? parseFloat(urgent.toFixed(2)) : 0;
                        }
                        else {
                            data.urgent_price = 0;
                        }
                        ////console.log(data.auto_redeem);
                        ////console.log(checkDiscount);
                        if (data.valid_commont == true) {
                            if (data.auto_redeem == true || checkDiscount == true) {
                                if (checkDiscount == true) {
                                    if (data.discount !== undefined && data.discount !== 0) {
                                        desc = simple_price * (data.discount / 100);
                                        descuento += desc;
                                        arrayCommonDiscounts.push(data.id_common_discount);
                                    }
                                }


                            } else if (data.auto_redeem == false && checkDiscount == true) {

                                if (data.discount !== undefined && data.discount !== 0) {
                                    desc = simple_price * (data.discount / 100);
                                    descuento += desc;
                                    arrayCommonDiscounts.push(data.id_common_discount);
                                }
                            } else if (data.auto_redeem == true && checkDiscount == false) {


                            } else {

                                if (data.discount !== undefined && data.discount !== 0) {
                                    arrayCommonDiscounts.push(data.id_common_discount);
                                }

                            }
                        } else {
                            arrayCommonDiscounts.push(data.id_common_discount);
                        }


                        let iva = ((simple_price - desc) * data.value_iva) / 100;
                        subtotal += simple_price;
                        total_iva += iva;

                        break;
                }
            });
        }

        total = (subtotal - descuento) + total_iva;

        descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
        subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
        total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
        total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;

        setSubTotalW(subtotal);
        setTotalDiscountW(descuento);
        setTotalTaxesW(total_iva);
        setTotalW(total);
        setArrayCommonDiscounts(arrayCommonDiscounts);

    }, [formWorkOrder, checkDiscount]);

    useMemo(() => {
        if (totalW === 0) {
            setMoneyEntered(0);
            setShowMessageW({
                labelMoney: "",
                amount: 0
            });
            setEnableBtnSaveWorkOrder(true);
        }
        else {
            if (moneyEntered >= totalW) {

                let change = (parseFloat(moneyEntered) - totalW);

                setShowMessageW({
                    labelMoney: "Cambio",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });
                setEnableBtnSaveWorkOrder(false);
            }
            else {
                let change = (totalW - parseFloat(moneyEntered));

                setShowMessageW({
                    labelMoney: "Faltan",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });

                setEnableBtnSaveWorkOrder(true);
            }
        }
    }, [totalW, moneyEntered]);
    //#endregion WORK ORDER

    //#region PATIENT
    //#region PATIENT MODEL
    //USE STATES
    const [listBloodType, setListBloodType] = useState([]);
    const [listStates, setListStates] = useState([]);
    const [listMunicipalities, setListMunicipalities] = useState([]);
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [focusQrPatient, setFocusQrPatient] = useState(true);

    //INFORMATION SELECT PATIENT
    const [searchPatientState, setSearchPatientState] = useState([]);
    const [informationPatient, setInformationPatient] = useState({
        birthday: null,
        is_complete: false,
        phone: null,
        gender: null,
        age: null,
        email: null,
        curp: null,
        membership: null,
        name: null,
        paternal_surname: null,
        maternal_surname: null,
        cardnumber: null,
    });

    //FORM VALUES PATIENT
    const [formValuesPatient, handleInputChangePatient, resetPatient, handleUpdateValuesPatient, handleSelectValuesPatient, handlePickerValuesPatient, handleDinamicInputPatient, handleDatePatient] = useForm({
        id_gender: 0,
        id_state_patient: 0,
        id_municipality_patient: 0,
        id_social_gender_patient: 0,
        id_blood_type: 0,
        code: "",
        paternal_surname: "",
        maternal_surname: "",
        name: "",
        birthday: "",
        curp: "",
        address_patient: "",
        cp_patient: "",
        colony_patient: "",
        phone: "",
        email: "",
        email_patient: "",
        who_collects_exams: "",
        membership: "",
        cardnumber: "",
    });
    const [formSocialGender, handleInputChangeSocialGender, handleUpdateValuesSocialGender] = useForm({
        name_genderc: "",
        abreviaturac: "",
    });
    const [validationsSG, setvalidationsSG] = useState({
        name_genderc_valid: false,
        abreviaturac_valid: false,
    });

    const [validationsPatient, setValidationsPatient] = useState({
        id_gender_valid: false,
        paternal_surname_valid: false,
        maternal_surname_valid: false,
        name_valid: false,
        birthday_valid: false,
        phone_patient_valid: false,
        email_patient_valid: false,
        curp_valid: false,
        cp_patient_valid: false
    });
    //#endregion PATIENT MODEL

    //#region PATIENT METHODS

    const getBloodType = async () => {
        let listTemp = await handleRequest("GET", "BloodType/List", "Tipo sanguineo");

        if (listTemp !== null && listTemp.length > 0) {
            setListBloodType(listTemp);
        }
    }

    const getStates = async () => {
        let listTmp = await handleRequest("GET", "State/List", "Estados");

        if (listTmp !== null && listTmp.length > 0) {

            let listTmpStates = [];

            listTmp.forEach(obj => {
                listTmpStates.push({
                    id_state: obj.id_state,
                    name: obj.name
                });
            });

            setListStates(listTmpStates);
        }
    }

    const handleChangeStatePatient = (e) => {
        handleInputChangePatient(e);

        getMunicipality(e.target.value);

    }

    const getMunicipality = async (id_state) => {
        let listTmp = await handleRequest("GET", `Municipality/List/${id_state}`, 'Municipios');


        if (listTmp !== null && listTmp.length > 0) {
            let listTmpMunicipalities = [];

            listTmp.forEach(obj => {
                listTmpMunicipalities.push({
                    id_municipality: obj.id_municipality,
                    id_state: obj.id_state,
                    name: obj.name
                });
            });

            setListMunicipalities(listTmpMunicipalities);
        }
    }
    const getSocialGender = async () => {
        let listTmp = await handleRequest("GET", `SocialGender/List`, 'Generos Sociales');

        if (listTmp !== null && listTmp.length > 0) {
            let listTmpSocialGenders = [];

            listTmp.forEach(obj => {
                listTmpSocialGenders.push({
                    id_social_gender: obj.id_social_gender,
                    name_social_gender: obj.name_social_gender,
                    abrev: obj.abrev
                });
            });

            setListsocialgender(listTmpSocialGenders);
            //console.log(Listsocialgender);
        }

    }

    const handleChangePatient = (e) => {
        handleInputChangeWorkOrder(e);

        let patient = e.target.value;

        if (patient !== 0 && patient !== null && patient !== '') {
            handleGetInformationPatient(patient);
            setViewWorkOrder(true);
        }
        else {
            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
                cardnumber: null
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: 0
            });

            setViewWorkOrder(false);
        }
    }
    //COMPANY
    const handleChangeCompany = (e, nameInput) => {
        handleSelectValuesWorkOrder(e, nameInput);

        if (e.value !== undefined) {
            let id_company = e.value;

            if (id_company > 0) {
                handleGetInformationCompany(id_company);
                setviewCompany(true);
            }
        }
    }

    const handleGetInformationCompany = async (id_company) => {
        let requestOptions = {
            method: 'GET'
        };

        const response = await sendRequest(requestOptions, `Company/GetInformationCompany/${id_company}`);

        if (response.code === 200) {
            
            if (response.data.id_company !== undefined) {
                setInformationCompany({
                    code: response.data.code,
                    phone: response.data.phone,
                    email: response.data.contact_email
                });
            }
            else{
                setInformationCompany({
                    code: "",
                    phone: "",
                    email: ""
                });
            }
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleGetInformationCompany);
            setLoading(false);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);
            setLoading(false);
        }
    }

    const handleGetInformationPatient = async (id_patient, unique_uuid = 0) => {

        let alternative_methods = "";
        if (unique_uuid != 0) {
            alternative_methods = `?unique_uuid=${unique_uuid}`;
        }

        let listTmp = await handleRequest("GET", `Patient/FindPatientId/${id_patient}${alternative_methods}`, 'Paciente');
        if (listTmp != null) {
            let tmpPatient = {
                birthday: listTmp.birthday,
                is_complete: listTmp.is_complete,
                phone: listTmp.phone,
                gender: listTmp.gender,
                age: listTmp.age,
                email: listTmp.email,
                email_patient: listTmp.email,
                curp: listTmp.curp,
                membership: listTmp.membership,
                name: listTmp.name,
                paternal_surname: listTmp.paternal_surname,
                maternal_surname: listTmp.maternal_surname,
                cardnumber: listTmp.unique_uuid,
                id_social_gender_patient: listTmp.id_social_gender_patient
            };

            setInformationPatient(tmpPatient);

            if (listTmp.membership !== null) {
                setIs_membership(true);
            }

            if (unique_uuid !== 0) {
                setSearchPatientState({
                    value: listTmp.id_patient,
                    label: listTmp.name_patient + " - " + listTmp.curp
                });

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    id_patient: listTmp.id_patient
                });
                setViewWorkOrder(true);
            }
        }
        else {
            setSearchPatientState([]);

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: 0
            });

            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                cardnumber: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
            });
        }
    }

    const handleCreatePatient = () => {
        handleUpdateValuesPatient({
            id_gender: 0,
            id_state_patient: 0,
            id_municipality_patient: 0,
            id_social_gender_patient: 0,
            id_blood_type: 0,
            code: "",
            paternal_surname: "",
            maternal_surname: "",
            name: "",
            birthday: "",
            curp: "CURPDEFAULT",
            address_patient: "",
            cp_patient: "",
            colony_patient: "",
            phone_patient: "",
            email_patient: "",
            email: "",
            who_collects_exams: "",
            membership: "",
        });

        setValidationsPatient({
            id_gender_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: false,
            name_valid: false,
            birthday_valid: false,
            phone_patient_valid: false,
            email_patient_valid: false,
            curp_valid: false,
            cp_patient_valid: false
        });


        setMethodPatient("create");
        setModalTitle("Registrar nuevo paciente");
        togglePatient();
        getStates();
        getBloodType();
        getSocialGender();
    }

    const saveCreatePatient = async (e) => {
        e.preventDefault();
        setLoadingPatient(true);

        let p_birthday = "";
        if (formValuesPatient.birthday !== "") {
            let arrBirthday = formValuesPatient.birthday.split('/');
            p_birthday = `${arrBirthday[2]}-${arrBirthday[1]}-${arrBirthday[0]}`;

            let validDate = moment(p_birthday).format("YYYY-MM-DD");

            if (validDate === "Fecha inválida") {
                createSweet("warning", "warning", "Paciente", "Verifica la fecha de nacimiento del paciente: " + p_birthday);
                setLoadingPatient(false);
                return;
            }
        }

        if (formValuesPatient.id_gender == 0) {
            createSweet("warning", "warning", "Paciente", "Verifica el genero.");
            setLoadingPatient(false);
            return;
        }

        let mData = {
            ...{ ...formValuesPatient },
            birthday: p_birthday
        };

        let listTmp = await handleRequest("POST", `Patient`, 'Paciente', mData);

        if (listTmp !== null) {
            setSearchPatientState({
                value: listTmp.id_patient,
                label: listTmp.name_patient
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: listTmp.id_patient
            });

            setViewWorkOrder(true);

            await handleGetInformationPatient(listTmp.id_patient);
            setLoadingPatient(false);
            togglePatient();
            createSweet("create", "success", "Paciente creado");
        }
    }

    const handleUpdatePatient = () => {
        let {
            id_social_gender_patient,
            birthday, phone, gender, email, email_patient, curp, membership, name, paternal_surname, maternal_surname, cardnumber,
        } = informationPatient;

        handleUpdateValuesPatient({
            id_gender: gender === "MASCULINO" ? 2 : 1,
            id_state_patient: 0,
            id_municipality_patient: 0,
            id_social_gender_patient: id_social_gender_patient,
            id_blood_type: 0,
            code: "",
            paternal_surname: paternal_surname,
            maternal_surname: maternal_surname,
            name: name,
            birthday: birthday,
            curp: curp,
            address_patient: "",
            cp_patient: "",
            colony_patient: "",
            phone: phone,
            email: email,
            email_patient: email_patient,
            who_collects_exams: "",
            membership: membership,
            cardnumber: cardnumber
        });

        setValidationsPatient({
            id_gender_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: false,
            name_valid: false,
            birthday_valid: false,
            phone_patient_valid: false,
            email_patient_valid: false,
            curp_valid: false,
            cp_patient_valid: false
        });
        getSocialGender();
        setMethodPatient("updateData");
        setModalTitle("Actualizar datos del paciente");
        togglePatient();
    }

    const saveUpdatePatient = async (e) => {
        e.preventDefault();

        setLoadingPatient(true);

        let p_birthday = "";

        if (formValuesPatient.birthday !== "") {
            let arrBirthday = formValuesPatient.birthday.split('/');
            p_birthday = `${arrBirthday[2]}-${arrBirthday[1]}-${arrBirthday[0]}`;

            let validDate = moment(p_birthday).format("YYYY-MM-DD");

            if (validDate === "Fecha inválida") {
                createSweet("warning", "warning", "Paciente", "Verifica la fecha de nacimiento del paciente: " + p_birthday);
                return;
            }
        }

        let body = {
            ...{ ...formValuesPatient },
            birthday: p_birthday,
            id_patient: id_patient
        };

        let name_patient = `${body.name} ${body.paternal_surname} ${body.maternal_surname} - ${body.curp}`;
        let listTmp = await handleRequest("PUT", `Patient/UpdateShort`, 'Paciente', body);
        if (listTmp !== null) {
            setInformationPatient({
                value: id_patient,
                label: name_patient
            });

            await handleGetInformationPatient(id_patient);
            setLoadingPatient(false);
            togglePatient();
            createSweet("create", "info", "Paciente actualizado");
        }
    }

    //#endregion PATIENT METHODS

    //#endregion PATIENT

    //#region QR PATIENT

    //MODEL
    const [formValuesQr, handleInputChangeQr, resetQr, handleUpdateValuesQr, handleSelectValuesQr] = useForm({
        user_unique_code: ""
    });

    const {
        user_unique_code
    } = formValuesQr;

    const handleScannQrPatient = () => {
        toggleModalScannQr();

        handleUpdateValuesQr({
            user_unique_code: ""
        });
    }

    const inputReadQrCode = useRef(null);

    const qrCodeAutoFocus = () => {
        if (focusQrPatient) {
            if (inputReadQrCode.current !== null) {

                inputReadQrCode.current.focus();
            }
        }
    }

    const onKeyPressQr = (event) => {
        if (event.keyCode === 13) {

            let valueQrCode = event.target.value;

            //console.log(valueQrCode);
            if (valueQrCode !== '') {

                handleGetInformationPatient(0, valueQrCode);
                toggleModalScannQr();
            }
            else {
                createSweet("warning", "warning", "Membresia", "El código QR ingresado no contiene información");
            }
        }
    }

    useEffect(() => {
        qrCodeAutoFocus();
    }, [modalScannQrPatient]);

    //#endregion QR PATIENT

    //#region DOCTOR
    //#region DOCTOR MODEL
    //USE STATE 
    const [searchDoctorState, setSearchDoctorState] = useState([]);
    const [informationDoctor, setInformationDoctor] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [idDoctor, setIdDoctor] = useState(0);
    const [loadingDoctor, setLoadingDoctor] = useState(false);
    const [idDoctorSelect, setIdDoctorSelect] = useState([]);

    //FORM VALUES DOCTOR
    const [formDoctor, handleInputChangeDoctor, resetDoctor, handleUpdateValuesDoctor] = useForm({
        id_specialty: 0,
        name_doctor: "",
        professional_license: "",
        phone_doctor: "",
        address_doctor: "",
        email_doctor: "",
        name_specialty: "",
        isCommercialPartner: false
    });

    const {
        id_specialty, name_doctor, professional_license, phone_doctor, address_doctor, email_doctor, name_specialty, isCommercialPartner
    } = formDoctor;


    //VALIDATIONs DOCTOR
    const [validationsDoctor, setValidationsDoctor] = useState({
        id_specialty: false,
        name: false,
        name_specialty: false
    });

    //DOCTOR NAME INPUTS
    const [nameInputsDoctor, setNameInputsDoctor] = useState({
        id_specialty: "id_specialty",
        name: "name_doctor",
        professional_license: "professional_license",
        phone: "phone_doctor",
        address: "address_doctor",
        email: "email_doctor"
    });
    //#endregion DOCTOR MDDEL

    //#region DOCTOR METHODS
    const handleGetSpecialties = async () => {
        let listTmp = await handleRequest("GET", `Specialties/List`, 'Especialidades');

        if (listTmp !== null && listTmp.length > 0) {
            setSpecialties(listTmp);
        }
    }

    const handleChangeDoctor = (e) => {
        handleInputChangeWorkOrder(e);

        let doctor = e.target.value;
        let tmpDoctor = [];

        if (doctor.length > 0) {
            setIdDoctorSelect(doctor);

            doctor.forEach(d => {
                let iddoctor = d.value;

                handleGetInformationDoctor(iddoctor, tmpDoctor);
            })
        }
    }

    const handleGetInformationDoctor = async (id_doctor, tmpDataDoctor) => {
        let listTmp = await handleRequest("GET", `Doctors/FindDoctorId/${id_doctor}`, 'Doctor');

        if (listTmp !== null) {
            tmpDataDoctor.push(listTmp);

            setInformationDoctor([
                ...tmpDataDoctor
            ]);
        }
    }

    const handleCreateDoctor = () => {
        handleUpdateValuesDoctor({
            id_specialty: 0,
            name_doctor: "",
            professional_license: "",
            phone_doctor: "",
            address_doctor: "",
            email_doctor: "",
            name_specialty: "",
            isCommercialPartner: false
        });

        setValidationsDoctor({
            id_specialty: false,
            name: false,
            name_specialty: false
        });

        setIdDoctor(0);
        setMethodDoctor("create");
        setModalTitle("Registrar nuevo doctor");
        toggleDoctor();
        handleGetSpecialties();
    }

    const saveCreateDoctor = async (e) => {
        e.preventDefault();

        setLoadingDoctor(true);

        let newForm = {
            id_specialty: formDoctor.id_specialty === "new" ? null : formDoctor.id_specialty,
            name: formDoctor.name_doctor,
            professional_license: formDoctor.professional_license,
            phone: formDoctor.phone_doctor,
            address: formDoctor.address_doctor,
            email: formDoctor.email_doctor,
            name_specialty: formDoctor.name_specialty,
            isCommercialPartner: formDoctor.isCommercialPartner === true ? 1 : null

        };

        let listTmp = await handleRequest("POST", "Doctors", "Doctores", newForm);

        //console.log(listTmp);

        if (listTmp !== null) {

            setSearchDoctorState({
                value: listTmp.id_doctor,
                label: name_doctor
            });

            let tmpIdDoctor = idDoctorSelect;

            tmpIdDoctor.push({
                value: listTmp.id_doctor,
                label: listTmp.name
            });

            setIdDoctorSelect([...tmpIdDoctor]);

            let tmpArray = [];

            idDoctorSelect.forEach(obj => {

                handleGetInformationDoctor(obj.value, tmpArray);
            });

            handleUpdateValuesDoctor({
                id_specialty: 0,
                name_doctor: "",
                professional_license: "",
                phone_doctor: "",
                address_doctor: "",
                email_doctor: "",
                name_specialty: "",
                isCommercialPartner: false

            });

            setLoadingDoctor(false);
            toggleDoctor();
            createSweet("create", "success", "Doctor creado");
        }
    }

    const handleUpdateDoctor = (id_doctor) => {
        let findDoctor = informationDoctor.find(d => d.id_doctor === id_doctor);

        if (findDoctor) {
            handleUpdateValuesDoctor({
                id_specialty: findDoctor.id_specialty,
                name_doctor: findDoctor.name,
                professional_license: findDoctor.professional_license,
                phone_doctor: findDoctor.phone,
                address_doctor: findDoctor.address,
                email_doctor: findDoctor.email,
                name_specialty: findDoctor.name_specialty,
                isCommercialPartner: findDoctor.isCommercialPartner,

            });

            setValidationsDoctor({
                id_specialty: false,
                name: false,
                name_specialty: false
            });

            setMethodDoctor("update");
            setModalTitle("Actualizar información del doctor");
            toggleDoctor();
            handleGetSpecialties();
            setIdDoctor(id_doctor)
        }
    }

    const saveUpdateDoctor = async (e) => {
        e.preventDefault();

        setLoadingDoctor(true);

        let newForm = {
            id_doctor: idDoctor,
            id_specialty: (formDoctor.id_specialty === "new" ? null : formDoctor.id_specialty),
            name: formDoctor.name_doctor,
            professional_license: formDoctor.professional_license,
            phone: formDoctor.phone_doctor,
            address: formDoctor.address_doctor,
            email: formDoctor.email_doctor,
            isCommercialPartner: formDoctor.isCommercialPartner === true ? 1 : null

        };

        let listTmp = await handleRequest("PUT", "Doctors/Update", "Doctores", newForm);

        if (listTmp !== null) {
            setSearchDoctorState({
                value: idDoctor,
                label: formDoctor.name_doctor
            });

            let tmpArray = [];

            idDoctorSelect.forEach(obj => {
                handleGetInformationDoctor(obj.value, tmpArray);
            });

            handleUpdateValuesDoctor({
                id_specialty: 0,
                name_doctor: "",
                professional_license: "",
                phone_doctor: "",
                address_doctor: "",
                email_doctor: "",
                name_specialty: ""
            });

            setLoadingDoctor(false);
            toggleDoctor();
            createSweet("create", "success", "Doctor actualizado correctamente");
            setIdDoctor(0);
        }
    }
    //#endregion DOCTOR METHODS

    //#endregion DOCTOR

    //#region SPECIAL DISCOUNTS

    //#region SPECIAL DISCOUNTS MODEL
    //SPECIAL DISCOUNT
    const [idSpecialDiscount, setIdSpecialDiscount] = useState(0);
    const [specialDiscount, setSpecialDiscount] = useState(null);

    const [formSpecialDiscount, handleInputChangeSpecialDiscount, resetSpecialDiscount, handleUpdateValuesSpecialDiscount] = useForm({
        special_discount_code: ""
    });

    const {
        special_discount_code
    } = formSpecialDiscount;
    //#endregion SPECIAL DISCOUNTS MODEL

    //#region SPECIAL DISCOUNTS METHODS
    const handleModalSpecialDiscount = () => {
        handleUpdateValuesSpecialDiscount({
            special_discount_code: ""
        });

        toggleSpecialDiscount();
    }

    const validateCodeSpecialDiscount = async () => {
        let listTmp = await handleRequest("GET", `SpecialDiscount/ValidateCode/${special_discount_code}`, "Descuento especial");

        let examenes_perfiles_seleccionados = formWorkOrder.listTest;

        if (listTmp !== null) {

            if (listTmp.examProfileInclude.length > 0) {

                listTmp.examProfileInclude.forEach(element => {
                    examenes_perfiles_seleccionados.map(ep => {
                        if (element.id_exam !== null) {
                            if (ep.id_exam !== null && element.id_exam === ep.id_exam) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }
                        else {
                            if (ep.id_profile !== null && element.id_profile === ep.id_profile) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }

                        return ep;
                    });
                });
            }
            else {
                examenes_perfiles_seleccionados.map(ep => {
                    ep.id_common_discount = 0;
                    ep.id_special_discount = listTmp.id_special_discount;
                    ep.discount = listTmp.percentage;

                    return ep;
                })
            }

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: examenes_perfiles_seleccionados
            });

            setSpecialDiscount(listTmp);
            createSweet("info", "success", "El código ingresado es válido");
            toggleSpecialDiscount();
        }
    }
    //#endregion

    //#endregion SPECIAL DISCOUNTS

    //#region COMMON DISCOUNTS        
    //#region COMMON DISCOUNTS METHODS
    const handleModalListCommonDiscounts = async () => {
        if (specialDiscount !== null) {
            setArrayNameDiscount([`Descuento especial: COD-00${specialDiscount.id_special_discount}`]);
            toggleCommonDiscount();
        }
        else if (checkTypeClient === 3) {
            let convenios = [];

            listTest.forEach(element => {
                if (element.id_agreement !== null && element.id_agreement > 0) {
                    convenios.push(`Convenio: Contrato - ${element.contract_number}`);
                }
            });

            setArrayNameDiscount(convenios);
            toggleCommonDiscount();
        }
        else {
            let data = arrayCommonDiscounts;


            let listTmp = await handleRequest('POST', 'CommonDiscount/GetNameList', 'Descuentos', data);


            if (listTmp !== null) {
                let array = [];

                // Object.values(listTmp).map(obj => {
                //     array.push(obj);
                // })
                listTmp.forEach(obj => {
                    array.push(obj);
                });

                setArrayNameDiscount(array);

            }

            toggleCommonDiscount();
        }
    }
    const handleModalSocialGender = async () => {
        toggleModalSocialGender();
        setvalidationsSG({
            name_genderc_valid: false,
            abreviaturac_valid: false,
        });
    }
    //SAVE SOCIAL GENDER
    // const saveSocialGender = async (e) => {
    //     e.preventDefault();

    //     if (validationSocialGender()) {
    //         //setLoading(false);
    //         return;
    //     }


    //     let mData = {
    //         ...{ ...formSocialGender }
    //     };

    //     let listTmp = await handleRequest("POST", `SocialGender`, 'Género Social', mData);
    //     //console.log(listTmp.code);
    //     if (listTmp.code === 200) {
    //         createSweet("success", "success", "Género Social Agregado");
    //     }
    //     else if (listTmp.code === 401) {
    //         validarSesion(history, listTmp.code, handleRequest);
    //     }
    //     else if (listTmp.code === 500) {
    //         createSweet("error", "error", "Ocurrio un error en el servidor");
    //     }
    //     else {
    //         createSweet("warning", "warning", listTmp.data.msg);
    //     }


    // }
    const saveSocialGender = async (e) => {
        e.preventDefault();

        if (validationSocialGender()) {
            return;
        }
        let mData = {
            ...{ ...formSocialGender }
        };
        let mValues = JSON.stringify(mData);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SocialGender");

        if (respuesta.code === 200) {

            createSweet("create", "success", "Exito!", "Género Social creado con exito");
            getSocialGender();
            formSocialGender.name_genderc = "";
            formSocialGender.abreviaturac = "";

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveSocialGender);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Género Social", "Ocurrio un problema en el servidor");

        }
        else {
            createSweet("error", "warning", "Género Social", respuesta.data.msg);

        }
    }
    const clearDiscount = async () => {

        if (checkDiscount == true) {
            setcheckDiscount(false);
        } else {

            setcheckDiscount(true);
        }
    }
    //#endregion
    //#endregion

    //#region QUOTER
    //#region QUOTER MODELS
    const [listQuoter, setListQuoter] = useState([]);
    const [loadingQuoter, setLoadingQuoter] = useState(false);
    const [viewTestQuoter, setViewTestQuoter] = useState({});
    const [enableViewTest, setEnableViewTest] = useState(false);


    const [formQuoter, handleInputChangeQuoter, resetQuoter, handleUpdateValuesQuoter] = useForm({
        code_quoter: "",
        date_quoter: ""
    });

    const { code_quoter, date_quoter } = formQuoter;

    const [validationsQuoter, setValidationsQuoter] = useState({
        code_quoter_valid: false,
        date_quoter_valid: false
    });
    //#endregion         

    //#region QUOTER METHODS
    const handleSearchQuoter = () => {
        setModalTitle("Búscar cotización");
        setMethodQuoter("list");
        toggleModalQuoter();
        handleUpdateValuesQuoter({
            code_quoter: "",
            date_quoter: ""
        });
        setListQuoter([]);
    }

    const handleGetQuoter = async (e) => {
        e.preventDefault();

        setLoadingQuoter(true);

        if (validationQuoter()) {
            return;
        }

        let listTmp = await handleRequest("GET", `Quoter/FilterQuoter/${id_branch}/${id_commercial_line}?code_or_name=${code_quoter}&date=${date_quoter}`, "Cotización");

        if (listTmp !== null && listTmp.length > 0) {
            setListQuoter(listTmp);
            setLoadingQuoter(false);
        }
        else {
            setLoadingQuoter(false);
        }
    }

    const handleViewTest = (id_quoter) => {
        setViewTestQuoter({});

        if (id_quoter > 0) {
            let find_quoter = listQuoter.find(x => x.id_quoter === id_quoter);





            if (find_quoter) {
                setViewTestQuoter(find_quoter);
                setEnableViewTest(true);
            }
        }
    }

    const handleCloseViewTest = () => {
        setEnableViewTest(!enableViewTest);
        setViewTestQuoter({});
    }

    const searchQuoterByCode = async (codeQuoter) => {

        setLoadingQuoter(true);

        let listTmp = await handleRequest("GET", `Quoter/GetTestByWorkOrder/${codeQuoter},${id_branch},${id_commercial_line},0,0`, "Cotización");

        if (listTmp != null) {

            let listExams = listTmp.listExamPrices;
            let listProfiles = listTmp.listProfilePrices;
            let id_quoter = listTmp.id_quoter;

            //let listTestQuoter = listTmp.listTestQuoter;

            let listTmpTest = listTest;

            listExams.forEach(e => {

                let descuentoEspecial = null;
                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;
                let valorCheck = false;

                let validationExam = validationTest(e.id_exam);

                if (validationExam !== -1 && validationExam !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                    return;
                }

                listTmpTest.forEach(item => {
                    if (item.id_specimen === parseInt(e.id_specimen)) {
                        valorCheck = item.check_specimen;
                    }
                });

                let porcentaje_descuento = 0;

                if (specialDiscount !== null) {
                    if (specialDiscount.examProfileInclude.length > 0) {
                        specialDiscount.examProfileInclude.forEach(obj => {
                            if (obj.id_exam === parseInt(e.id_exam)) {
                                porcentaje_descuento = obj.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        });
                    }
                    else {
                        if (!e.is_referenced) {
                            porcentaje_descuento = specialDiscount.percentage;
                            descuentoEspecial = specialDiscount.id_special_discount;
                        }
                    }
                }
                else if (e.percentage_discount > 0) {
                    porcentaje_descuento = e.percentage_discount;
                }
                else if (id_company > 0) {
                    if (e.agreement !== null) {
                        let convenio = e.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number;
                    }
                }

                listTmpTest.push({
                    id_exam: e.id_exam,
                    id_profile: null,
                    name: e.name,
                    abbreviation: e.abbreviation,
                    code: e.code,
                    id_price: e.id_price,
                    name_rate: e.name_rate,
                    price: e.price,
                    specimen_name: e.name_specimen,
                    id_specimen: e.id_specimen,
                    check_specimen: valorCheck,
                    check_forwarded: true,
                    discount: porcentaje_descuento,
                    id_common_discount: e.id_common_discount,
                    id_special_discount: descuentoEspecial,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,
                    listIndications: e.listIndications,
                    is_urgent: false,
                    config_iva: e.config_iva,
                    value_iva: e.value_iva,
                    listExams: null
                });

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: listTmpTest
                });
            });

            listProfiles.forEach(p => {
                let array_specimens = [];
                let descuentoEspecial = null;
                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;

                let validTest = validationTest(null, p.id_profile, p.listExams, p.name);

                if (validTest !== -1 && validTest !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationTest.message_validation, "warning");
                }
                else if (validTest === 2) {
                    toggleRepeatProfile();
                    setModalTitle("Perfiles con exámenes duplicados");
                    return;
                }

                // if (validationTest(null, p.id_profile, p.listExams)) {
                //     sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                //     return;
                // }

                p.exams_specimens.forEach(obj => {
                    if (!array_specimens.find(x => x.id_specimen === parseInt(obj.id_specimen_origin))) {
                        let valorCheck = true;
                        let valorCheckForwarded = true;
                        listTmpTest.forEach(item => {
                            if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                                valorCheck = item.check_specimen;
                                valorCheckForwarded = item.check_specimen;
                            }
                        });

                        array_specimens.push({
                            specimen_name: obj.name_specimen_origin,
                            id_specimen: obj.id_specimen_origin,
                            check_specimen: valorCheck,
                            check_forwarded: valorCheckForwarded
                        });
                    }
                });

                let porcentaje_descuento = 0;

                if (specialDiscount !== null) {
                    if (specialDiscount.examProfileInclude.length > 0) {
                        specialDiscount.examProfileInclude.forEach(obj => {
                            if (obj.id_profile === parseInt(p.id_profile)) {
                                if (obj.percentage > 0) {
                                    porcentaje_descuento = obj.percentage;
                                    descuentoEspecial = specialDiscount.id_special_discount;
                                }
                                else {
                                    porcentaje_descuento = specialDiscount.percentage;
                                    descuentoEspecial = specialDiscount.id_special_discount;
                                }
                            }
                        });
                    }
                }
                else if (id_company > 0) {
                    if (p.agreement !== null) {
                        let convenio = p.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number === null ? "" : convenio.contract_number;
                    }
                }

                let price = p.price;

                if (p.is_curve) {
                    if (p.listCurvesQuoter.length > 0) {

                        p.listCurvesQuoter.forEach((obj, i) => {
                            if (i == 0) {
                                price = obj.price;
                            }
                            obj.number_sampling_aux = (i + 1);
                        })
                    }
                }

                listTmpTest.push({
                    id_exam: null,
                    id_profile: p.id_profile,
                    name: p.name,
                    abbreviation: p.abbreviation,
                    code: p.code,
                    id_rate: null,
                    id_price: p.id_price,
                    name_rate: "",
                    price: price,
                    specimen_name: "",
                    id_specimen: null,
                    check_specimen: false,
                    check_forwarded: true,
                    discount: porcentaje_descuento,
                    id_common_discount: p.id_common_discount,
                    id_special_discount: descuentoEspecial,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,
                    array_specimens: array_specimens,
                    listIndications: p.listExamIndications,
                    is_urgent: false,
                    is_curve: p.is_curve,
                    curves: p.listCurves,
                    curves_aux: p.listCurvesQuoter,
                    listExams: p.listExams,
                    config_iva: p.config_iva,
                    value_iva: p.value_iva,
                    is_quotation: true,
                    id_profile_curve_price: (p.is_curve ? (p.listCurvesQuoter.length > 0 ? p.listCurvesQuoter[0].id_profile_curve_price : 0) : 0)
                });
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: listTmpTest,
                id_quoter: id_quoter
            });

            toggleModalQuoter();
            setLoadingQuoter(false);
        }
        else {
            setLoadingQuoter(false);
        }
    }

    //#endregion
    //#endregion QUOTER

    //#region INVOICING
    //#region INVOICING MODELS
    const [loadingInvoice, setLoadingInvoice] = useState(false);

    const [validationsInvoice, setValidationsInvoice] = useState({
        business_name_valid: false,
        rfc_valid: false,
        phone_valid: false,
        email_valid: false,
        id_use_cfdi_valid: false,
    });
    //#endregion


    useEffect(() => {
        handleGetTimess();
    }, []);



    const handleGetTimess = async () => {
        let listTemp = await handleRequest("GET", `WorkOrder/DetailPbi`, 'Pbi');
        //console.log(listTemp);

        // if (listTemp !== null && listTemp.length > 0) {
        //     setTimes(listTemp);
        // }
    }






    //#region INVOICING METHODS
    const handleCreateInvoice = (e) => {
        handleInputChangeWorkOrder(e);

        if (e.target.checked) {
            setModalTitle("Emitir factura");
            setMethodInvoice("create");
            toggleModalInvoice();
        }
        else {
            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                business_name: "",
                rfc: "",
                phone: "",
                email: "",
                id_use_cfdi: 0,
                print_invoice: false,
                send_invoice: false,
            })
        }
    }
    //ENABLE COMmENTS
    const enableComments = () => {
        if (checkComments == false) {
            setcheckComments(true);
            SetShowClassNameC("");
        } else {
            setcheckComments(false);
            SetShowClassNameC("d-none mb-2");
        }

    }

    const saveInvoice = () => {
        setLoadingInvoice(true);

        if (validateInvoice()) {
            setLoadingInvoice(false);
            return;
        }

        setLoadingInvoice(false);
        toggleModalInvoice();
    }

    const cancelInvoice = () => {
        toggleModalInvoice();

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            checkInvoice: false
        });
    }

    const validateInvoice = () => {
        let statusValidation = false;

        let newValidations = {};

        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formWorkOrder.business_name.length < 3) {
            newValidations = {
                ...newValidations,
                business_name_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                business_name_valid: false
            };
        }

        if (formWorkOrder.rfc.length < 12) {
            newValidations = {
                ...newValidations,
                rfc_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                rfc_valid: false
            };
        }

        if (formWorkOrder.phone.length < 10 || formWorkOrder.phone === "") {
            newValidations = {
                ...newValidations,
                phone_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                phone_valid: false
            };
        }

        if (!pattern.test(formWorkOrder.email)) {
            newValidations = {
                ...newValidations,
                email_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                email_valid: false
            };
        }

        if (formWorkOrder.id_use_cfdi === 0) {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: false
            };
        }


        setValidationsInvoice(newValidations);

        return statusValidation;
    }
    //#endregion INVOICING METHODS
    //#endregion

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        // else if (response.code === 500) {
        //     createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        // }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }


    //#region validation Curva 

    const validArrayCurva = () => {

        let statusValidacion = false;

        if (formWorkOrder.listTest.length >= 1) {

            formWorkOrder.listTest.forEach(element => {
                if (element.is_curve) {
                    element.curves_aux.forEach(l => {
                        if (l.time === "") {
                            statusValidacion = true;
                            return;

                        }

                    });

                }
            });

        }
        else {
            statusValidacion = true;
        }
        return statusValidacion;
    }

    const validArrayCurva1 = () => {

        let statusValidacion = false;

        if (formWorkOrder.listTest.length >= 1) {

            formWorkOrder.listTest.forEach(element => {

                if (element.is_curve) {
                    if (element.price === 0) {
                        statusValidacion = true;
                        return;
                    }

                }
            });

        }
        else {
            statusValidacion = true;
        }
        return statusValidacion;
    }


    //#endregion validation Curva

    //#region ALL VALIDATIONS
    const validationTest = (id_exam, id_profile = null, listExams = [], name_profile = "") => {

        // //console.log(listExams);

        let statusValidation = -1;
        let newValidations = {};
        let valid_exist_test = {
            name_profile: "",
            repeat_profiles: [],
            repeat_exams: []
        };

        if (formWorkOrder.listTest.length > 0) {
            let findTest = formWorkOrder.listTest.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

            if (findTest) {
                newValidations = {
                    ...newValidations,
                    valid_repeat_test: true,
                    message_validation: `${id_exam !== null ? 'El examen: ' + findTest.name + ', ya existe' : 'El perfil: ' + findTest.name + ', ya existe'}`
                };

                statusValidation = 0;
            }
            else {
                if (id_exam !== null) {
                    let listProfiles = formWorkOrder.listTest.filter(x => x.id_profile !== null);

                    listProfiles.forEach(p => {

                        if (p.listExams.find(x => x.id_exam === parseInt(id_exam))) {

                            newValidations = {
                                message_validation: "El examen seleccionado ya existe en el perfil: " + p.name
                            };
                            statusValidation = 1;

                            return true;
                        }
                    })
                }
                else if (id_exam === null && id_profile !== null) {
                    let filterProfiles = formWorkOrder.listTest.filter(x => x.id_profile !== null);

                    filterProfiles.forEach(p => {
                        let examsRepeat = [];
                        let examsNotRepeat = [];

                        p.listExams.forEach(e => {

                            if (listExams.find(x => x.id_exam === e.id_exam)) {
                                examsRepeat.push(e);
                            }
                        });

                        let notExist = [];
                        listExams.forEach(x => {
                            if (!examsRepeat.find(r => r.id_exam === x.id_exam)) {
                                notExist.push(x);
                            }
                        });

                        examsNotRepeat = notExist;

                        if (examsRepeat.length > 0) {
                            valid_exist_test.repeat_profiles.push({
                                name_profile: p.name,
                                listExamsRepeat: examsRepeat,
                                listExamsNotRepeat: examsNotRepeat
                            });
                        }

                        if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                            valid_exist_test.name_profile = name_profile;
                            newValidations = {
                                message_validation: "Hay examenes o perfiles repetidos"
                            };

                            statusValidation = 2;
                        }
                    });

                    if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                        valid_exist_test.name_profile = name_profile;
                        newValidations = {
                            message_validation: "Hay examenes o perfiles repetidos"
                        };

                        statusValidation = 2;
                    }

                    let listExamsW = formWorkOrder.listTest.filter(x => x.id_exam !== null);

                    listExamsW.forEach(e => {
                        if (listExams.find(x => x.id_exam === parseInt(e.id_exam))) {

                            newValidations = {
                                message_validation: "Tienes exámenes duplicados: " + e.name + ", al seleccionar el perfil"
                            };

                            statusValidation = 3;

                            return true;
                        }
                    });
                }
            }
        }

        setValidationsTest(newValidations);
        setValidationsRepeat({
            ...validationsRepeat,
            name_profile: valid_exist_test.name_profile,
            repeat_profiles: valid_exist_test.repeat_profiles,
            repeat_exams: valid_exist_test.repeat_exams
        });
        // //console.log(valid_exist_test);

        return statusValidation;
    }

    const validationQuoter = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formQuoter.code_quoter === "" && formQuoter.date_quoter === "") {
            newValidations = {
                ...newValidations,
                code_quoter_valid: true,
                date_quoter_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_quoter_valid: false,
                date_quoter_valid: false
            };
        }

        setValidationsQuoter(newValidations);
        return statusValidation;
    }

    const validationWorkOrder = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formWorkOrder.id_patient === 0) {
            newValidations = {
                ...newValidations,
                id_patient_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_patient_valid: false
            };
        }

        if (formWorkOrder.id_printer_point.value === undefined) {
            newValidations = {
                ...newValidations,
                id_printer_point_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_printer_point_valid: false
            };
        }

        if (formWorkOrder.listTest.length === 0) {
            newValidations = {
                ...newValidations,
                listTest_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                listTest_valid: false
            };
        }

        if (formWorkOrder.listPayMethods.length > 0) {

            formWorkOrder.listPayMethods.forEach(obj => {
                if (obj.id_pay_method === "0" || obj.id_pay_method === "") {

                    newValidations = {
                        ...newValidations,
                        listPayMethods_valid: true
                    };

                    statusValidation = true;

                    return;
                }

                if (obj.amount <= 0 || obj.amount === "") {
                    newValidations = {
                        ...newValidations,
                        listPayMethods_valid: true
                    };

                    statusValidation = true;

                    return;
                }
            })
        }
        else {
            newValidations = {
                ...newValidations,
                listPayMethods_valid: true
            };

            statusValidation = true;
        }

        if (checkTypeClient !== 1) {
            if (formWorkOrder.id_company === 0) {
                newValidations = {
                    ...newValidations,
                    id_company_valid: true
                };
                statusValidation = true;
            } else {
                newValidations = {
                    ...newValidations,
                    id_company_valid: false
                };
            }
        }

        setValidationsWorkOrder(newValidations);

        return statusValidation;
    }
    const validationSocialGender = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formSocialGender.name_genderc === "") {
            newValidations = {
                ...newValidations,
                name_genderc_valid: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_genderc_valid: false,
            };
        }

        setvalidationsSG(newValidations);
        return statusValidation;
    }
    //#endregion

    return {
        modalTitle, number_format,
        //WORK ORDER
        //VALUES
        checkTypeClient,
        listPrinterPoint, listPayMethod, arrayAgreementDiscounts,
        id_patient, id_company, id_printer_point,
        observations, observations_sample, observations_general, checkPrint, checkPartial, checkEmail, checkWhats,
        checkDoctor, checkInvoice, listTest, listPayMethods, listDoctors,
        listCompanies, times, loading,
        searchExamProfileState, subTotalW, totalDiscountW, totalTaxesW, totalW,
        showMessageW, showMessageWF, showClassSD, showClass, OrderMedicalEstatus, viewExamsProfile, validationsWorkOrder, viewWorkOrder,
        enableBtnSaveWorkOrder, validationsRepeat, checkComments, ShowClassNameC,
        //FILES PATIENT
        medical_order, handleOnChange, DeleteFile,
        //METHODS
        handleChangeTypeClient, handleInputChangeWorkOrder, handleDinamicInputWorkOrder, handleSelectValuesWorkOrder,
        handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
        removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, handleAddPayMethodInputs, handleGiveChange,
        handleDeletePayMethod, handleViewExamsProfile, saveWorkOrder, cancellWorkOrder,

        //MODALS WORK ORDER
        toggleModalViewExams, modalViewExamsP, modalRepeatProfile, toggleRepeatProfile,

        //PATIENT
        //VALUES
        formValuesPatient, listBloodType, formSocialGender,
        searchPatientState, informationPatient, listStates, listMunicipalities,
        modalPatient, togglePatient,
        methodPatient, loadingPatient,
        handleInputChangePatient, handleInputChangeSocialGender,
        handlePickerValuesPatient, validationsPatient, validationsSG,
        toggleModalScannQr, modalScannQrPatient, focusQrPatient, inputReadQrCode, Listsocialgender, modalSocialGender,
        //METHODS
        handleChangePatient,
        handleChangeStatePatient, handleDatePatient,
        handleCreatePatient, saveCreatePatient,
        handleUpdatePatient, saveUpdatePatient,
        handleScannQrPatient, toggleModalSocialGender, saveSocialGender,
        //DOCTOR
        //VALUES
        formDoctor, informationDoctor,
        modalDoctor, toggleDoctor,
        methodDoctor, loadingDoctor,
        handleInputChangeDoctor, nameInputsDoctor, validationsDoctor,
        handleChangeDoctor, specialties, searchDoctorState,
        id_specialty, name_doctor, professional_license, phone_doctor,
        address_doctor, email_doctor, name_specialty, isCommercialPartner,
        //METHODS
        handleCreateDoctor, saveCreateDoctor,
        handleUpdateDoctor, saveUpdateDoctor,

        //SPECIAL DISCOUNTS
        //VALUES
        specialDiscount, special_discount_code, handleInputChangeSpecialDiscount,

        //SPECIAL DISCOUNTS METHODS
        handleModalSpecialDiscount, validateCodeSpecialDiscount,

        //MODALS SPECIAL DISCOUNTS
        modalSpecialDiscounts, toggleSpecialDiscount,

        //COMMON DISCOUNTS
        checkDiscount, clearDiscount,
        //VALUES
        arrayCommonDiscounts, arrayNameDiscount, valid_commont,

        //METHODS
        handleModalListCommonDiscounts, handleModalSocialGender,

        //MODALS COMMON DISCOUNTS
        modalCommonDiscount, toggleCommonDiscount,

        //QUOTER
        //VALUES
        loadingQuoter, code_quoter, validationsQuoter, date_quoter,
        handleInputChangeQuoter, listQuoter, enableViewTest, viewTestQuoter,

        //METHODS
        handleGetQuoter, handleSearchQuoter, searchQuoterByCode,
        handleViewTest, handleCloseViewTest,

        //MODALS QUOTER
        modalQuoter, toggleModalQuoter, methodQuoter,

        //INVOICE
        //VALUES
        validationsInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice, listUseCfdi,
        loadingInvoice,
        //METHODS
        handleCreateInvoice, saveInvoice, cancelInvoice, enableComments,

        //MODALS INVOICE
        modalInvoice, toggleModalInvoice, methodInvoice,

        //PATIENT QR
        qrCodeAutoFocus, onKeyPressQr,
        user_unique_code, handleSelectValuesQr,
        //NEW METODS
        handleChangeCompany,informationCompany,viewCompany
    }
}
