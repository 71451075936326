import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';


export const useMethods = () => {


    const history = useHistory();

    const [methods, setMethods] = useState([]);
    const [methodId, setMethodId] = useState(0);


    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        code: "",
    });

    const { name, code } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        code_valid: false,
    });

    const toggle = () => {
        setModal(!modal);
    }


    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(methods.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listMethod = [];
        methods.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listMethod.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setMethods(listMethod);

    }, [totalPageCount, currentPage])




    const getMethods = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Method/List");

        if (respuesta.code === 200) {

           let listMethod1 = [];
           let startItems = 0;
           let endItems = numberItems;
           let aux = numberItems * currentPage;

           endItems = aux;

           startItems = endItems - numberItems;

           if (respuesta.data.length >= 1) {
            respuesta.data.forEach((data,index) => {

                let posicion = index + 1;
                    let visibleTable = false;
                    
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listMethod1.push({
                        id_method: data.id_method,
                        name: data.name,
                        code: data.code,
                        visibleTable: visibleTable

                    });   
                });
                setMethods(listMethod1)  
                setMethodData(listMethod1);

              }   
            }

        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMethods);

        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Métodos", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Métodos", respuesta.data.msg);

        }
     
    }, [history]);

    useEffect(() => {
        getMethods();
    }, [getMethods]);


    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            code: "",
        });

        setValidaciones({
            name_valid: false,
            code_valid: false,
        });

        setMethodId(0);
        setMethod("create");
        setModalTitle("Nuevo método");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Method/Create");

        if (respuesta.code === 200) {
            getMethods();
            reset();
            createSweet("create", "success", "Exito!", "Método creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Métodos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Métodos", respuesta.data.msg);
            setLoading(false)
        }
       
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.code === undefined || formValues.code.length < 2) {
            newValidations = {
                ...newValidations,
                code_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const [methodData, setMethodData] = useState([])

    const handleSearch = (e) => {

        //console.log(methodData);
        let busqueda = e.target.value;
        let newMethod = [];

        methodData.forEach(element => {
            //console.log(element);

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setMethods(newMethod);
    }





    return {
        methods, method, validaciones,
        name, code,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearch

    }
};
