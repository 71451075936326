import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { LostConnection, ModalCreateRates, ModalUpdateRates, ModalDeleteRates } from '../../constant';
import { validarSesion } from '../tokens/useToken';
import { ConfigServer } from '../../data/config';

export const useRates = () => {
    
    const history = useHistory();

    useEffect(() => {
        
        getRates();

    }, [history.location]);
    
    const [rates, setRates] = useState([]);
    const [rateId, setRateId] = useState(0);

    const [commercialLines, setCommercialLines] = useState([]);
    const [branches, setBranches] = useState([]);
    const [typeRate, setTypeRate] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [viewDivBranches, setviewDivBranches] = useState(false);

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_branch: 0,
        id_commercial_line: 0,
        id_type_rate: 0,
        name: '',
        abbreviation: '',
        default_rate: false,
    });

    const { id_branch, id_commercial_line, id_type_rate, name, abbreviation, default_rate } = formValues;

    const [validacionesRates, setValidacionesRates] = useState({
        name_valid: false,
        abbreviation_valid: false,
        id_commercial_line_valid: false,
        id_branch_valid: false,
        id_type_rate_valid: false
    });

    const toggle = () => {
        setModal(!modal);
        setviewDivBranches(false);
    }

    const getRates = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let newRates = [];

        const respuesta = await sendRequest(requestOptions, "Rate/List");

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                
                respuesta.data.forEach((element) => {
                    newRates.push({                        
                        id_rate: element.id_rate,
                        id_commercial_line: element.id_commercial_line,
                        id_branch: element.id_branch,
                        id_type_rate: element.id_type_rate,
                        name: element.name,
                        abbreviation: element.abbreviation,
                        default_rate: element.default_rate,
                        short_name_commercial_line: element.short_name_commercial_line,
                        long_name_commercial_line: element.long_name_commercial_line,
                        theme_color_commercial_line: element.theme_color_commercial_line,
                        name_type_rate: element.name_type_rate === null ? "" : element.name_type_rate,
                        abbreviation_type_rate: element.abbreviation_type_rate === null ? "" : element.abbreviation_type_rate
                    });
                });

                setRates(newRates);
            }
            else{
                setRates([]);
            }
        }
        else{
            createSweet("error", "error","Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, getRates);
        }
    }

    const getBranches = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let newRates = [];

        const respuesta = await sendRequest(requestOptions, "Branch/List");

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                
                respuesta.data.forEach((element) => {
                    newRates.push({                        
                        id_branch: element.id_branch,
                        name: element.name,
                        code: element.code
                    });
                });

                setBranches(newRates);
            }
            else{
                setBranches([]);
            }
        }
        else{
            createSweet("error", "error","Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, getBranches);
        }
    }

    const getCommercialLines = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "CommercialLine/List");

        if (respuesta.code === 200) {
            let newCommercialLine = [];
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((item) => {
                    newCommercialLine.push({
                        id_commercial_line: item.id_commercial_line,
                        long_name: item.long_name,
                        short_name: item.short_name,
                        rfc: item.rfc
                    });
                });

                setCommercialLines(newCommercialLine);
            }
            else{
                setCommercialLines([]);
            }
        }
        else{
            setCommercialLines([]);
            validarSesion(history, respuesta.code, getCommercialLines);
        }        
    }

    const getTypeRates = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "TypeRate/List");

        if (respuesta.code === 200) {
            let newTypeRate = [];
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((item) => {
                    newTypeRate.push({
                        id_type_rate: item.id_type_rate,
                        name: item.name,
                        abbreviation: item.abbreviation
                    });
                });

                setTypeRate(newTypeRate);
            }
            else{
                setTypeRate([]);
            }
        }
        else{
            setTypeRate([]);
            validarSesion(history, respuesta.code, getTypeRates);
        }  
    }

    const toggleDivCommercialLine = (e) => {
        handleInputChange(e);

        if (e.target.checked) {
            setviewDivBranches(true);
            handleUpdateValues({
                ...formValues,
                id_branch: 0,
                id_type_rate:0,
                default_rate: e.target.checked
            });
        }
        else{
            setviewDivBranches(false);
            handleUpdateValues({
                ...formValues,
                id_branch: 0,
                id_type_rate:0,
                default_rate: e.target.checked
            });
        }
    }
    
    const handleCreate = () => {
        handleUpdateValues({
            id_branch: 0,
            id_commercial_line: 0,
            id_type_rate: 0,
            name: "",
            abbreviation: "",
            default_rate: false
        });

        setValidacionesRates({
            name_valid: false,
            abbreviation_valid: false,
            id_commercial_line_valid: false,
            id_branch_valid: false,
            id_type_rate_valid: false
        });

        setRateId(0);
        getCommercialLines();
        getBranches();
        getTypeRates();
        setMethod("create");
        setModalTitle(ModalCreateRates);
        toggle();     
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let data = {
            //id_branch: (formValues.id_branch.value === undefined) ? null : formValues.id_branch.value,
            id_branch: formValues.id_branch,
            id_commercial_line: formValues.id_commercial_line,
            id_type_rate: formValues.id_type_rate,
            name: formValues.name,
            abbreviation: formValues.abbreviation,
            default_rate: formValues.default_rate       
        };

        let mValues = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Rate/Create");

        if (respuesta.code === 200) {
            getRates();
            reset();
            createSweet("create", "success", "Exito!", "Tarifario creado");
            setLoading(false);
            toggle();
        }
        else{
            setLoading(false);
            validarSesion(history, respuesta.code, saveCreate);
        }
    }

    const handleUpdate = (id_rate) => {
        let rate = rates.find(r => r.id_rate === parseInt(id_rate));

        if (rate !== null) {
            handleUpdateValues({
                id_branch: rate.id_branch,
                id_commercial_line: rate.id_commercial_line,
                id_type_rate: rate.id_type_rate,
                name: rate.name,
                abbreviation: rate.abbreviation,
                default_rate: rate.default_rate
            });

            setValidacionesRates({
                name_valid: false,
                abbreviation_valid: false,
                id_commercial_line_valid: false,
                id_branch_valid: false,
                id_type_rate_valid: false
            });            

            toggle();

            if (rate.default_rate !== 0) {
                setviewDivBranches(true);
            }
            
            setRateId(id_rate);
            getCommercialLines();
            getBranches();
            getTypeRates();
            setMethod("update");
            setModalTitle(ModalUpdateRates);
        }
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let data = {
            id_rate: rateId,
            //id_branch: (formValues.id_branch.value === undefined) ? null : formValues.id_branch.value,
            id_branch: formValues.id_branch,
            id_commercial_line: formValues.id_commercial_line,
            id_type_rate: formValues.id_type_rate,
            name: formValues.name,
            abbreviation: formValues.abbreviation,
            default_rate: formValues.default_rate            
        };

        let mValues = JSON.stringify(data);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Rate");

        if (respuesta.code === 200) {
            getRates();
            createSweet("create", "info", "Exito!", "Tarifario actualizado");
            setLoading(false);
            toggle();
        }
        else{
            setLoading(false);
            validarSesion(history, respuesta.code, saveUpdate);
        }
    }

    const handleDelete = (id_rate) => {
        let rate = rates.find(r => r.id_rate === id_rate);

        handleUpdateValues({
            name: rate.name,
            abbreviation: rate.abbreviation
        });

        setRateId(id_rate);
        setMethod("delete");
        setModalTitle(ModalDeleteRates);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions ={ 
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Rate/${rateId}`);
        if (respuesta.code === 200) {
            getRates();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Tarifario eliminado");
            toggle();
        }
        else{
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
    }

    const navigateConfigPrices = (rateId) => {
        history.push("../configuraciones/"+rateId+"/precios");
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 2) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                name_valid: false
            };
        }

        if (formValues.abbreviation === undefined || formValues.abbreviation.length < 2) {
            newValidations = {
                ...newValidations,
                abbreviation_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                abbreviation_valid: false
            };
        }

        if (formValues.id_commercial_line === 0) {
            newValidations = {
                ...newValidations,
                id_commercial_line_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                id_commercial_line_valid: false
            }
        }

        if (!formValues.default_rate) {
            
            if (formValues.id_branch === 0) {
                newValidations = {
                    ...newValidations,
                    id_branch_valid: true
                };
                statusValidation = true;
            }
            else{
                newValidations = {
                    ...newValidations,
                    id_branch_valid: false
                };                
            }
            
            if (formValues.id_type_rate === 0) {
                newValidations = {
                    ...newValidations,
                    id_type_rate_valid: true
                };
                statusValidation = true;
            }
            else{
                newValidations = {
                    ...newValidations,
                    id_type_rate_valid: false
                };
            }
        }

        setValidacionesRates(newValidations);

        return statusValidation;
    }

    const navigatePriceCost = (rateId) => {
        history.push("../configuraciones/"+rateId+"/costos");
    }

    const downloadExcel = async (rateId) => {
        let token = localStorage.getItem("token");
        
        await fetch (ConfigServer.serverUrl+`/api/Rate/CreateExcelRate/${rateId}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/vnd.ms-excel"
            })
        })
        .then(response => {
            if (response.status !== 200) {
                createSweet("warning", "warning", "No se descargo el archivo excel");  
                return;              
            }

            return response.blob();
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "tarifario.xlsx");

            document.body.appendChild(link);
            link.style.display = 'none';
            link.click();            
        });
    }


    return {
        rates, branches, commercialLines, typeRate, method, validacionesRates,
        name, abbreviation, id_commercial_line, id_branch, id_type_rate, default_rate,
        handleInputChange, handleSelectValues, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        navigateConfigPrices,
        toggleDivCommercialLine,
        viewDivBranches,
        navigatePriceCost,
        downloadExcel
    }
}
