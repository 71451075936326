import React from 'react';
import { Button, Card, CardBody,Input} from 'reactstrap';
import { ModalBrand } from '../../../components/brands/ModalBrand';
import { useMethods } from '../../../hooks/analytes/useMethods';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalMethod } from '../../../components/methods/ModalMethod';

export const IndexMethods = () => {

    const {methods, method, validaciones,
        name,code,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearch

    } = useMethods();


  return (
    <>
        <Card>
            <CardBody className="p-1">
                <div className="ribbon ribbon-clip ribbon-primary">
                    Listado de métodos
                </div> 
                <div className="text-right" style={{ margin: 5, padding: 0 }}>
                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                </div> 
                <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch}  />
                        </div>
                    </div>

                <div className="table-responsive">
                    <TableStriped
                        notMaped={["visibleTable"]}
                        methodsActions={false}
                        cabeceras={["Nombre","Clave"]}
                        items={methods}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        totalPageCount={totalPageCount}
                        currentPage={currentPage}
                        goToPage={goToPage}
                        // {...{ handleUpdate, handleDelete }}
                    />
                </div>
            </CardBody>
        </Card>
        <ModalMethod
            {
            ...{
                modal, modalTitle, toggle, method,
                validaciones,
                name,code,
                handleInputChange, loading,
                saveCreate
            }
            }
        />
    </>
  );
};
