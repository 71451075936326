import React, { useState, useEffect } from 'react';
import { TableStriped } from '../tables/TableStriped';
import { Button, Form, ModalBody, ModalHeader, FormGroup, Label, Row, Col, Input, Container, FormFeedback, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { RangePrecoded } from './FormsModalRange/RangePrecoded';
import { ReferenceValues } from './FormsModalRange/ReferenceValues';


export const ModalRange = (props) => {

    const {
        name, gender, init_value, end_value, years_from, months_from, days_from, years_to, months_to, days_to, text_range,
        handleInputRange, validacionesRange, method, methods, id_type_analyte,
        id_type_precoded_text, precodedTexts, listRanges, addAnalyteNumberRange, removeAnalyteNumberRange, saveTempAnalytesNumberRanges, editAnalyteNumberRange,
        saveEditAnalyteRangeNumber, methodActiveRange, backToCreateRange,
        id_analyte_age_gender_range, handleInputRangePrecoded,
        listRangesPrecoded, addRangePrecoded, saveTempRangePrecoded, removeRangePrecoded, editRangePrecoded, saveEditRangePrecoded, methodActiveRangePrecoded, backToCreateRangePrecoded,
        saveTempAnalytesNumberAlerts, listReferenceValues, isSimple = false,
        tabTypeAnalyte, toggleTypeAnalyte,
        saveTempAnalytesTextAlerts, listReferenceTexts,
    } = props;
    const {
        text_range: vTextRange,
        range: vRange,
        overhead: vOverhead,
        method: vMethod,
    } = validacionesRange;

    const [activeTab, setActiveTab] = useState(1);
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const type_analyte = parseInt(id_type_analyte);

    let pruebas = new Array();

    listRangesPrecoded.forEach((obj) => {
        let validador = pruebas.find(x => x.id === obj.id_analyte_age_gender_range);
        if (validador === undefined) {
            pruebas.push({
                id: obj.id_analyte_age_gender_range,
                name: obj.age_gender_range_format,
                items: [
                    {
                        id_init: obj.id_analyte_number_custom_value,
                        init_value: obj.init_value,
                        end_value: obj.end_value,
                        text_range: obj.text_range,
                        text_format: obj.text_format
                    }
                ]
            })
        } else {
            validador.items = [
                ...validador.items,
                {
                    id_init: obj.id_analyte_number_custom_value,
                    init_value: obj.init_value,
                    end_value: obj.end_value,
                    text_range: obj.text_range,
                    text_format: obj.text_format
                }
            ]
        }
    });

    useEffect(() => {
        setActiveTab(1);
    }, [name])


    // //console.log(listRanges, "-----rangos");
    // alert("hgika");

    return (
        <Container>
            <hr />
            <h6>Analito: {name}</h6>
            <Nav
                className="nav-fill flex-column flex-md-row mt-3   "
                pills
                role="tablist"
            >
                <NavItem>
                    <NavLink
                        aria-selected={activeTab === 1}
                        className={classnames("mb-sm-3 mb-md-0", {
                            active: activeTab === 1
                        })}
                        onClick={() => { toggle(1); }}
                        href="#"
                        role="tab"
                    >
                        Rango de edades y género
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        aria-selected={activeTab === 2}
                        className={classnames("mb-sm-3 mb-md-0", {
                            active: activeTab === 2
                        })}
                        onClick={() => { toggle(2); }}
                        href="#"
                        role="tab"
                    >
                        Valores de referencia y alertas
                    </NavLink>
                </NavItem>
                {
                    (type_analyte === 4 || type_analyte === 5) &&
                    <NavItem>
                        <NavLink
                            aria-selected={activeTab === 3}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: activeTab === 3
                            })}
                            onClick={() => { toggle(3); }}
                            href="#"
                            role="tab"
                        >
                            {type_analyte === 4 ?
                                "Rango precodificado"
                                :
                                "Texto condicionado"
                            }
                        </NavLink>
                    </NavItem>
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <Row>
                        <Col sm="12">
                            <ModalHeader className="pb-1 pl-2 pr-2">
                                Gestión de valores de referencia
                            </ModalHeader>
                            <ModalBody className="pt-1">
                                <Row className="position-relative">
                                    <Col sm="4">
                                        <Form className="position-sticky top-4" role="form" onSubmit={methodActiveRange === "create" ? addAnalyteNumberRange : saveEditAnalyteRangeNumber}>
                                            <Row>
                                                {
                                                    methodActiveRange === "edit" &&
                                                    <>
                                                        <a style={{ cursor: "pointer" }} className="link" onClick={backToCreateRange}>Regresar a creación</a>
                                                    </>
                                                }
                                                <FormGroup className={`mb-3 pl-1 pr-1 col-12 ${methodActiveRange === "edit" && "mt-2 border-top"}`}>
                                                    <Label className="text-xs">Género</Label>
                                                    <Input
                                                        disabled={tabTypeAnalyte === 2}
                                                        className="form-control form-control-sm input-air-primary"
                                                        bsSize="sm"
                                                        placeholder="Género"
                                                        type="select"
                                                        name="gender"
                                                        value={gender}
                                                        required={true}
                                                        onChange={handleInputRange}
                                                    // className="text-xs"
                                                    >
                                                        <option value={0}>Indistinto</option>
                                                        <option value={1}>Femenino</option>
                                                        <option value={2}>Masculino</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup className={`mb-3 pl-1 pr-1 col-12`}>
                                                    <Label className="text-xs">Método</Label>
                                                    <Input
                                                        disabled={tabTypeAnalyte === 2}
                                                        className="form-control form-control-sm input-air-primary"
                                                        bsSize="sm"
                                                        placeholder="Método"
                                                        type="select"
                                                        name="method"
                                                        value={method}
                                                        required={true}
                                                        onChange={handleInputRange}
                                                        //className="text-xs"
                                                        invalid={vMethod}
                                                    >
                                                        <option value={0}>Selecciona una opción</option>
                                                        {
                                                            isSimple ?
                                                                methods.map((m, key) => {
                                                                    return <option
                                                                        key={key}
                                                                        value={m.id_app_code_method}
                                                                    >
                                                                        {m.name_method} / {m.name_machine_model}
                                                                    </option>
                                                                })
                                                                :
                                                                methods.map((m, key) => {
                                                                    return <option
                                                                        key={key}
                                                                        value={m.id_app_code_method}
                                                                    >
                                                                        {m.name_method} / {m.name_machine_model}
                                                                    </option>
                                                                    {/* return <option
                                                                        key={key}
                                                                        value={m.id_exam_method}
                                                                    >
                                                                        {m.name}
                                                                    </option> */}
                                                                })

                                                        }
                                                    </Input>
                                                    <FormFeedback>Debes de seleccionar un método</FormFeedback>
                                                </FormGroup>
                                                <FormGroup className={`mb-0 col-12 border-right`}>
                                                    <Row>
                                                        <FormGroup className={`mb-0 pl-1 pr-1 col-12 text-center`}>
                                                            <Label className="text-xs">Edad inicial</Label>
                                                        </FormGroup>
                                                        <FormGroup className={`mb-3 pl-1 pr-1 col-4`}>
                                                            <Label className="text-xs">Años</Label>
                                                            <Input
                                                                readOnly={tabTypeAnalyte === 2}
                                                                bsSize="sm"
                                                                placeholder="Edad"
                                                                type="number"
                                                                name="years_from"
                                                                value={years_from}
                                                                required={true}
                                                                autoComplete="off"
                                                                onChange={handleInputRange}
                                                                invalid={vOverhead}
                                                                className="form-control form-control-sm input-air-primary"

                                                                //className="text-xs"
                                                                min="0"
                                                                step="1"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className={`mb-3 pl-1 pr-1 col-4`}>
                                                            <Label className="text-xs">Meses</Label>
                                                            <Input
                                                                readOnly={tabTypeAnalyte === 2}
                                                                bsSize="sm"
                                                                placeholder="Edad"
                                                                type="number"
                                                                name="months_from"
                                                                value={months_from}
                                                                required={true}
                                                                autoComplete="off"
                                                                onChange={handleInputRange}
                                                                invalid={vOverhead}
                                                                className="form-control form-control-sm input-air-primary"

                                                            //className="text-xs"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className={`mb-3 pl-1 pr-1 col-4`}>
                                                            <Label className="text-xs">Dias</Label>
                                                            <Input
                                                                readOnly={tabTypeAnalyte === 2}
                                                                bsSize="sm"
                                                                placeholder="Edad"
                                                                type="number"
                                                                name="days_from"
                                                                value={days_from}
                                                                required={true}
                                                                autoComplete="off"
                                                                onChange={handleInputRange}
                                                                invalid={vOverhead}
                                                                className="form-control form-control-sm input-air-primary"
                                                            //className="text-xs"
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup className={`mb-0 border-left col-12`}>
                                                    <Row>
                                                        <FormGroup className={`mb-0 pl-1 pr-1 col-12 text-center`}>
                                                            <Label className="text-xs">Edad final</Label>
                                                        </FormGroup>
                                                        <FormGroup className={`mb-3 pl-1 pr-1 col-4`}>
                                                            <Label className="text-xs">Años</Label>
                                                            <Input
                                                                readOnly={tabTypeAnalyte === 2}
                                                                bsSize="sm"
                                                                placeholder="Edad"
                                                                type="number"
                                                                name="years_to"
                                                                value={years_to}
                                                                required={true}
                                                                autoComplete="off"
                                                                onChange={handleInputRange}
                                                                invalid={vOverhead}
                                                                className="form-control form-control-sm input-air-primary"

                                                            //className="text-xs"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className={`mb-3 pl-1 pr-1 col-4`}>
                                                            <Label className="text-xs">Meses</Label>
                                                            <Input
                                                                readOnly={tabTypeAnalyte === 2}
                                                                bsSize="sm"
                                                                placeholder="Edad"
                                                                type="number"
                                                                name="months_to"
                                                                value={months_to}
                                                                required={true}
                                                                autoComplete="off"
                                                                onChange={handleInputRange}
                                                                invalid={vOverhead}
                                                                className="form-control form-control-sm input-air-primary"

                                                            //className="text-xs"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className={`mb-3 pl-1 pr-1 col-4`}>
                                                            <Label className="text-xs">Dias</Label>
                                                            <Input
                                                                readOnly={tabTypeAnalyte === 2}
                                                                bsSize="sm"
                                                                placeholder="Edad"
                                                                type="number"
                                                                name="days_to"
                                                                value={days_to}
                                                                required={true}
                                                                autoComplete="off"
                                                                onChange={handleInputRange}
                                                                invalid={vOverhead}
                                                                className="form-control form-control-sm input-air-primary"

                                                            // className="text-xs"
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup className={`mb-4 pl-1 pr-1 col-12`}>
                                                    <Input type="hidden" invalid={vOverhead} />
                                                    <FormFeedback>El rango se esta empalmando con uno ya existente. Verifique su rango</FormFeedback>
                                                </FormGroup>
                                                {
                                                    tabTypeAnalyte !== 2 &&
                                                    <FormGroup className={`mb-3 pl-1 pr-1 col-12`}>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className={"btn-block  btn-sm" + (null ? "disabled progress-bar-animated progress-bar-striped" : "")}
                                                        >
                                                            {
                                                                methodActiveRange === "create" ?
                                                                    "Añadir rango de edad" : "Editar rango de edad"
                                                            }
                                                        </Button>
                                                    </FormGroup>
                                                }
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col sm="8">
                                        <h5 className="pt-1">Lista de valores</h5>
                                        <p>Eliminar o editar un rango, eliminara las alertas</p>
                                        {
                                            tabTypeAnalyte === 2 ?
                                                <TableStriped
                                                    notMaped={["gender", "days_from", "days_to", "months_from", "id_method", "machine_model",
                                                        "years_from", "years_to", "months_to", "id_gender", "id_analyte_number_range", "reference_values", "precoded_text", isSimple ? "method" : "method", "id_exam_method", "edited", "id_app_code_method"]}
                                                    methodsActions={false}
                                                    cabeceras={["Género", "Rango de edad", "Método", "Valores de Referencia"]}
                                                    items={listRanges}
                                                />
                                                :
                                                <TableStriped
                                                    notMaped={["gender", "days_from", "text_reference_values", "days_to", "months_from", "years_from", "id_method", "machine_model",
                                                        "years_to", "months_to", "id_gender", "id_analyte_number_range", "reference_values", "precoded_text", isSimple ? "method" : "method", "id_exam_method", "edited", "id_app_code_method"]}
                                                    methodsActions={true}
                                                    cabeceras={["Género", "Rango de edad", "Método", "valores de Referencia"]}
                                                    items={listRanges}
                                                    handleDelete={removeAnalyteNumberRange}
                                                    handleUpdate={editAnalyteNumberRange}
                                                />
                                        }
                                        {
                                            tabTypeAnalyte !== 2 &&
                                            listRanges.length > 0 &&
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn-block mt-2 btn-sm"
                                                onClick={saveTempAnalytesNumberRanges}
                                            >
                                                Confirmar rango de edades
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={2}>
                    <Row>
                        <Col sm="12 pt-5">
                            <ReferenceValues
                                {...{
                                    name, tabTypeAnalyte, listRanges, saveTempAnalytesNumberAlerts, listReferenceValues, saveTempAnalytesTextAlerts, listReferenceTexts,
                                }}
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={3}>
                    <Row>
                        <Col sm="12">
                            <ModalHeader className="pb-1 pl-2 pr-2">
                                Gestión de rangos precodificados
                            </ModalHeader>
                            <ModalBody className="pt-1">
                                <Row className="position-relative">
                                    <Col sm="4">
                                        <Form className="position-sticky top-4" role="form" onSubmit={methodActiveRangePrecoded === "create" ? addRangePrecoded : saveEditRangePrecoded}>
                                            <Row>
                                                {
                                                    methodActiveRangePrecoded === "edit" &&
                                                    <>
                                                        <a style={{ cursor: "pointer" }} className="link" onClick={backToCreateRangePrecoded}>Regresar a creación</a>
                                                    </>
                                                }
                                                <FormGroup className={`mb-3 pl-1 pr-1 col-12 ${methodActiveRangePrecoded === "edit" && "mt-2 border-top"}`}>
                                                    <Label className="text-xs">Rango</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder="Género"
                                                        type="select"
                                                        name="id_analyte_age_gender_range"
                                                        value={id_analyte_age_gender_range}
                                                        required={true}
                                                        onChange={handleInputRangePrecoded}
                                                        className="text-xs"
                                                    >
                                                        <option value={0}>Selecciona una opción</option>
                                                        {
                                                            listRanges.map((l, key) => {
                                                                return <option
                                                                    key={key}
                                                                    value={l.id_analyte_age_gender_range}
                                                                >
                                                                    {l.rango_edad} // {l.metodo} // {l.genero}
                                                                </option>
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup className={`mb-3 pl-1 pr-1 col-6`}>
                                                    <Label className="text-xs">Valor inicial</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder="Valor inicial"
                                                        type="number"
                                                        name="init_value"
                                                        value={init_value}
                                                        required={true}
                                                        autoComplete="off"
                                                        onChange={handleInputRangePrecoded}
                                                        className="text-xs"
                                                        invalid={vRange}
                                                    />
                                                </FormGroup>
                                                <FormGroup className={`mb-3 pl-1 pr-1 col-6`}>
                                                    <Label className="text-xs">Valor final</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder="Valor final"
                                                        type="number"
                                                        name="end_value"
                                                        value={end_value}
                                                        required={true}
                                                        autoComplete="off"
                                                        onChange={handleInputRangePrecoded}
                                                        className="text-xs"
                                                        invalid={vRange}
                                                        step="any"
                                                    />
                                                </FormGroup>
                                                <FormGroup className={`mb-0 pl-1 pr-1 col-12`}>
                                                    <Input type="hidden" invalid={vRange} />
                                                    <FormFeedback>El rango establecido no es valido, el valor final debe ser mayor al inicial</FormFeedback>
                                                </FormGroup>
                                                <FormGroup className={`mb-3 pl-1 pr-1 col-12`}>
                                                    <Label className="text-xs">Texto</Label>
                                                    {
                                                        parseInt(id_type_precoded_text) === 0
                                                            ?
                                                            <Input
                                                                bsSize="sm"
                                                                placeholder="Texto"
                                                                type="text"
                                                                name="text_range"
                                                                value={text_range}
                                                                autoComplete="off"
                                                                onChange={handleInputRangePrecoded}
                                                                className="text-xs"
                                                                invalid={vTextRange}
                                                            />
                                                            :
                                                            precodedTexts.length > 0 ?
                                                                <Input
                                                                    bsSize="sm"
                                                                    placeholder="Género"
                                                                    type="select"
                                                                    name="text_range"
                                                                    value={text_range}
                                                                    onChange={handleInputRangePrecoded}
                                                                    className="text-xs"
                                                                    invalid={vTextRange}
                                                                >
                                                                    <option value={0}>Selecciona una opción</option>
                                                                    {
                                                                        precodedTexts.map((p, key) => {
                                                                            return <option
                                                                                key={key}
                                                                                value={p.id_precoded_text}
                                                                            >
                                                                                {p.text}
                                                                            </option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                :
                                                                <Input
                                                                    bsSize="sm"
                                                                    placeholder="Texto"
                                                                    type="text"
                                                                    name="text_range"
                                                                    value={text_range}
                                                                    autoComplete="off"
                                                                    onChange={handleInputRangePrecoded}
                                                                    className="text-xs"
                                                                    invalid={vTextRange}
                                                                />
                                                    }
                                                    <FormFeedback>Este campo no puede estar vacio</FormFeedback>
                                                </FormGroup>
                                                <FormGroup className={`mb-3 pl-1 pr-1 col-12`}>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className={"btn-block  btn-sm" + (null ? "disabled progress-bar-animated progress-bar-striped" : "")}
                                                    >
                                                        {
                                                            methodActiveRangePrecoded === "create" ?
                                                                "Añadir rango precodificado" : "Editar rango precodificado"
                                                        }
                                                    </Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col sm="8">
                                        <TableStriped
                                            notMaped={["id_analyte_age_gender_range", "text_range", "id_precoded_text", "from", "to"]}
                                            methodsActions={true}
                                            cabeceras={["Rango de edad - método - Género", "Valor inicial", "Valor final", "Texto"]}
                                            items={listRangesPrecoded}
                                            handleDelete={removeRangePrecoded}
                                            handleUpdate={editRangePrecoded}
                                        />
                                        {
                                            listRangesPrecoded.length > 0 &&
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn-block mt-2 btn-sm"
                                                onClick={saveTempRangePrecoded}
                                            >
                                                Confirmar rangos precodificados
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </Container>
    )
}