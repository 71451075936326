import { useEffect, useState } from 'react';
import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from 'react-router';
import { usePagination } from '../pagination/usePagination';

export const useUnits = () => {
    const history = useHistory;

    useEffect(() => {
        getUnits();
    }, [history.location]);


    const [units, setUnits] = useState([]);
    const [method, setMethod] = useState("");
    const [unitId, setUnitId] = useState(0);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const toggle = () => {
        setModal(!modal);
    }

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        unit: "",
        abbreviation: ""
    });

    const { unit, abbreviation } = formValues;

    const getUnits = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Units");
        if (respuesta.code === 200) {
            setUnits(respuesta.data);
            setUnitData(respuesta.data);

            let startItems = 0;
            let endItems = numberItems;

            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;
            let listUnits = [];

            respuesta.data.forEach( (o, i) => {
                let posicion = i + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }
                listUnits.push({
                    ...o,
                    visibleTable: visibleTable
                })
            });

        } else {
            validarSesion(history, respuesta.code, getUnits);
        }
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(units.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listUnits = [];
        units.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listUnits.push({
                ...data,
                //  typeExam: data.is_simple ? 
                //datos mostrados de la misma liena
                visibleTable: visibleTable
            });
        });

        setUnits(listUnits);

    }, [totalPageCount, currentPage])

    const handleCreate = () => {
        handleUpdateValues({
            unit: "",
            abbreviation: ""
        });
        setUnitId(0);
        setMethod("create");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        if (formValues.unit.length < 3) {
            alert("El campo nombre de unidad no puede ser menor a 3 caracteres.");
        } else if (formValues.abbreviation === "") {
            alert("El campo abreviación no puede estar vacio.");
        } else {
            setLoading(true);
            let raw = JSON.stringify(formValues);
            let requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            const respuesta = await sendRequest(requestOptions, "Units");

            if (respuesta.code === 200) {
                let newUnits = units;
                newUnits.push({
                    id_unit: respuesta.data.id_unit,
                    unit: unit,
                    abbreviation: abbreviation
                });
                setUnits(newUnits);
                reset();
                setLoading(false);
                createSweet("create", "success");
                toggle();
            } else {
                //console.log("Error al crear la unidad...");
                validarSesion(history, respuesta.code, saveCreate);
            }
        }
    }

    const handleUpdate = (id_unit) => {
        let unit = units.find(u => u.id_unit === id_unit);
        handleUpdateValues({
            unit: unit.unit,
            abbreviation: unit.abbreviation
        });
        setUnitId(id_unit);
        setMethod("update");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        let body = {
            id_unit: unitId,
            ...{ ...formValues }
        }
        setLoading(true);
        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'PUT',
            body: raw,
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "Units");
        if (respuesta.code === 200) {
            let newUnits = units.map(function (obj) {
                if (obj.id_unit === unitId) {
                    obj.unit = unit;
                    obj.abbreviation = abbreviation
                }
                return obj;
            });
            setUnits(newUnits);
            setLoading(false);
            createSweet("update", "success");
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveUpdate);
            //console.log("Error");
        }
    }

    const handleDelete = (id_unit) => {
        let unit = units.find(u => u.id_unit === id_unit);
        handleUpdateValues({
            unit: unit.unit,
            abbreviation: unit.abbreviation
        });
        setUnitId(id_unit);
        setMethod("delete");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        let requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };
        setLoading(true);
        const respuesta = await sendRequest(requestOptions, `Units/${unitId}`);
        if (respuesta.code === 200) {
            let newUnits = units.filter(u => u.id_unit !== unitId);
            setUnits(newUnits);
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveDelete);
            //console.log("Error");
        }
    }

    const [unitData, setUnitData] = useState([]);

    const handleSearchUnit = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        unitData.forEach(element => {            

            let cadena = element.unit.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setUnits(newMethod);
    }







    return {
        units, method,
        unit, abbreviation, handleInputChange,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchUnit
    };
}
