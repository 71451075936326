import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap'
import { sendRequest } from '../../hooks/requests/useRequest';
import { useForm } from '../../hooks/forms/useForm';
import { classes } from '../../data/layouts';
import '../../index.scss'
import jwt_decode from "jwt-decode";
import '../../assets/css/loginCaptura.css'
//import { useLogin } from '../../hooks/tomaMuestra/useLogin';
//import { useSweetAlert } from '../../sweetAlerts/useSweetAlert';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';


const LoginEnvioMuestras = (props) => {



    const [loading, setLoading] = useState(false);
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    const [loged, setLoged] = useState(false);
    const jwt_token = localStorage.getItem('token');
    const tomaMuestra = localStorage.getItem('tomaMuestra');
    const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();
    const [togglePassword, setTogglePassword] = useState(false)
    const [show, setShow] = useState(true);
    const [brach, setBrach] = useState([]);

    //#region view
    const [cardView, setCardView] = useState(false);

    const handleViewCard = () => {
        setCardView(true);

    }

    const handleCloseCard = () => {
        setCardView(!cardView);

    }


    //#endregion view

    useEffect(() => {
        getBranches();

    }, [])



    useEffect(() => {
        if (loged === true) {
            window.location.href = `${process.env.PUBLIC_URL}`;
        }
    }, [loged]);

    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }



    const getBranches = async () => {
        let _listTemp = await handleRequest("GET", "Branch/List", "Sucursal");
        if (_listTemp !== null) {
            let _newBranches = [];
            _listTemp.forEach((_obj) => {
                _newBranches.push({
                    value: _obj.id_branch,
                    label: _obj.name
                });
            });
            setBrach(_newBranches);
        }
    }


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_departament: 0,
        id_area: 0,
        card_codess: "",
        userName: "",
        password: "",
        id_branch: "",


    });

    const [setFocusCard, setSetFocusCard, id_branch] = useState(true);

    const { card_codess } = formValues;

    const [showText, setShowText] = useState(false);

    const [shown, setShown] = React.useState(false);

    const switchShown = () => setShown(!shown);

    const iniciarSesion = async (e) => {
        e.preventDefault();
        if (validationsend()) {
            return;
        }
        //setShowText(false);

        setLoading(true);

        let raw = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "Users/LoginSendSample");

        if (respuesta.code === 200) {

            localStorage.setItem("branchIdSample", formValues.id_branch);

            if (respuesta.data.msg !== "Éxito") {
                createSweet("", "warning", "Alerta", respuesta.data.msg, "warning");
                setLoading(false);
            }
            else {

                localStorage.setItem("token", respuesta.data.token);
                localStorage.setItem("id", respuesta.data.id_user);
                localStorage.setItem("flebotomisaNombre", respuesta.data.username);
                localStorage.setItem("tomaMuestra", 4);
                localStorage.setItem("IdBranch", respuesta.data.idBranch);
                //  localStorage.setItem("sample", respuesta.data.toma);
                //localStorage.setItem("_sampleBranch", (use_consent ? _sampleBranch.use_consent : false));

                setLoged(true);
            }

            handleUpdateValues({
                card_code: "",
            })
        }
        else {

            handleUpdateValues({
                card_code: "",
            })
            setLoading(false);
            setShowText(true);

        }
    }


    const [passwordd, setPasswordd] = React.useState('');
    const onChange = ({ currentTarget }) => setPasswordd(currentTarget.value);


    const cardAutoFocus = () => {
        if (setFocusCard) {
            if (document.getElementById("inputFocusCard") !== null) {
                document.getElementById("inputFocusCard").focus();
            }
        }
    }

    useEffect(() => {

        cardAutoFocus()

    }, [setFocusCard]);

    function onKeyPressCard(event) {

        if (event.keyCode === 13) {

            let mValue = event.target.value;

            setLoading(true);

            if (mValue.length > 0) {

                iniciarSesion();
            }
            else {
                createSweett("", "Debes de ingresar una credencial valida", "warning");

                handleUpdateValues({
                    card_codess: "",
                    id_area: "",
                    id_departament: "",
                    userName: "",
                    password: "",
                });

                setSetFocusCard(true);
                setLoading(false);
            }
        }
    }

    const [validationsSG, setvalidationsSG] = useState({
        id_return_reason: false
    });

    const validationsend = () => {
        let _statusValidation = false;
        let _newValidations = {};
        let _id_return_reason2 = formValues.id_branch

        if (_id_return_reason2 === undefined) {
            _newValidations = {
                ..._newValidations,
                id_return_reason: true,
            };
            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                id_return_reason: false,
            };
        }
        setvalidationsSG(_newValidations);
        return _statusValidation;
    }

    useEffect(() => {

        if (jwt_token !== null && tomaMuestra !== null) {
            props.history.replace(`${process.env.PUBLIC_URL}/dashboard/envios`);
        }

    }, [props.history])

    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            //createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }

    return (
        <Container className="login-main-container-toma bg-toma" fluid={true} style={{ backgroundColor: "#ffffff", boxSizing: "border-box" }}>
            <Row>
                <Col className="center-container-toma" xs="12" sm="8" md="12" lg="12" xl="12" style={{ padding: "0px" }}>
                    <div className="login-card-toma p-0">
                        <div className="login-main-toma">
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                <img src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/LogoHelenLabs2.svg")} alt="LogoDNA" className={cardView ? "imgCardT2" : "imgCartT"} />   <br />   <br />
                            </Col>
                            <Form onSubmit={iniciarSesion} >
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }}>
                                        <label className='txt-white text-white f-12'>Selecciona tu Sucursal</label>
                                        <FormGroup>
                                            <select
                                                className="tamañoinput"
                                                style={{ border: "#FFF 2px solid" }}
                                                name="id_branch"
                                                onChange={handleInputChange}
                                                value={id_branch}
                                            >
                                                <option value={0}>{"Selecciona una opción"} </option>
                                                {
                                                    brach.length >= 1 && brach.map((obj, index) => {
                                                        return <option value={obj.value} key={index} >{obj.label}</option>
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }}>
                                        <Row>
                                            <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                            </Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8">
                                                <FormGroup className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `}>
                                                    <input id="pnombre" placeholder='tarjeta' className='input-field' name={`card_codess`} type="text" style={{ border: "#FFF 2px solid" }} onChange={handleInputChange} required="" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={`${!cardView ? 'm-t-10 animate__animated animate__flipInX' : 'animate__animated animate__flipOutX'}`} hidden={cardView}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                        <div style={{ position: 'absolute', left: "-1000", top: "-1000", zIndex: 3 }} className={'ocultar-input'}	>
                                            <input
                                                id='inputFocusCard'
                                                autoFocus={setFocusCard}
                                                value={card_codess}
                                                onChange={(e) => handleSelectValues(e.target.value, "card_codess")}
                                                onKeyDown={onKeyPressCard}
                                                onBlur={cardAutoFocus}
                                            />
                                        </div>
                                        <img className="" src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/CardLogin.gif")} alt="LogoDNA" style={{ width: "15%" }} />  <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                            <label className='txt-white text-white'>Portal de Registros de envios.</label>
                                            <img className="" style={{ filter: "brightness(0.5)", width: "5%" }} src={require("../../assets/images/loginHelenLabs/Envios/envios.gif")} alt="LogoDNA" />  <br />
                                        </Col>
                                        <br />
                                        {
                                            (validationsSG.id_return_reason) && <p className={`incorrect-loginn`}><i class="fa fa-exclamation-circle" ></i> Selecciona tu sucursal de opereación</p>
                                        }
                                    </Col>
                                </Row>
                                <Row className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                                        <Button size="xs" type="submit" color="" className={"tamañoButton" + (loading && " disabled progress-bar-animated progress-bar-striped")} >
                                            <img style={{ width: "55%" }} src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/LoginIn.svg")} /> </Button> <br /> <br />
                                        {
                                            (validationsSG.id_return_reason) && <p className={`incorrect-loginn`}><i class="fa fa-exclamation-circle" ></i> Selecciona tu sucursal de opereación</p>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
                <div className={cardView ? "d-none" : "tap-top-B"} style={{ display: "block" }} onClick={() => handleViewCard()} >
                    Inicia Seción Manual
                </div>

                <div className={cardView ? "tap-top-regresar" : "d-none"} style={{ display: "block" }} onClick={() => handleCloseCard()} >
                    Regresar
                </div>
            </Row>
        </Container >
    );

}

export default LoginEnvioMuestras;


