import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import React from 'react'
import { usePagination } from '../pagination/usePagination';


export const useUsedDiscounts = () => {

    const history = useHistory();

    const [discounts, setDiscounts] = useState([]);

    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);


    const now = moment();

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues, handleDinamicInput] = useForm({
        date_from: now.format("YYYY-MM-DD"),
        date_to: now.format("YYYY-MM-DD"),
        type_discount: 0,
        nim: ""
    });



    useEffect(() => {
        getWorkOrdersDiscounts();
    }, []);

    const {
        date_from, date_to, type_discount, nim
    } = formValues;


    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(discounts.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listdiscount = [];
        discounts.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listdiscount.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setDiscounts(listdiscount);

    }, [totalPageCount, currentPage]);







    const getWorkOrdersDiscounts = async () => {

        let requestOptions = {
            method: 'GET',
        };

        setLoading(true);

        let inicio = moment(date_from).format("YYYY-MM-DD");
        let fin = moment(date_to).format("YYYY-MM-DD");
        let nimF = nim !== "" ? nim : "NA";

        const respuesta = await sendRequest(requestOptions, "WorkOrder/GetWorkOrdersDiscounts/" + inicio + "/" + fin + "/" + type_discount + "/" + nimF);
        if (respuesta.code === 200) {
            
            if (respuesta.data.length >= 1) {
                let datos = [];
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;
    
                endItems = aux;
                startItems = endItems - numberItems;
                respuesta.data.forEach((element,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    let elemento = {
                        "id_work_order_discount": element.id_work_order_discount,
                        "date": moment(element.date).format("DD/MM/YY h:m a"),
                        "type_discount": element.type_discount,
                        "nim": element.nim,
                        "examProfile": element.id_exam !== null ? element.name_exam : element.name_profile,
                        "patient": element.name_patient,
                        "percentage": element.percentage + "%",
                        "price": "$" + element.price.toFixed(2),
                        "discount": "$" + element.discount.toFixed(2),
                        "price_discount": "$" + element.price_discount.toFixed(2),
                        visibleTable:visibleTable
                    }
                    datos.push(elemento);
                });
                setDiscounts(datos);
                setDiscountData(datos);
               // setDiscountdata(datos);
                createSweet("create", "success", "Busqueda realizada");
            } 
        } 
         else if (respuesta.code === 401) {
            setDiscounts([]);
            validarSesion(history, respuesta.code, getWorkOrdersDiscounts);
        }
        else if(respuesta.code === 500){
            setDiscounts([]);
            createSweet("error", "error", "Descuentos usados", "Ocurrio un problema en el servidor");
        }
          else{
            setDiscounts([]);
            createSweet("error", "warning", "Descuentos usados", respuesta.data.msg);
        }
    }

    const [discountData, setDiscountData] = useState([]);

    const handleSearchDiscount = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        discountData.forEach(element => {    
            //console.log(element);        

            let cadena = element.type_discount.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setDiscounts(newMethod);
    }





   
    return {
        discounts, getWorkOrdersDiscounts,
        date_from, date_to, type_discount, nim, handlePickerValues, handleInputChange,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount

    }
}
