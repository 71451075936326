import React from 'react';
import { Modal, ModalHeader, Media, ModalBody, ModalFooter, Col, Button, Row } from 'reactstrap';
import { ConfigServer } from '../../data/config';
export const ModalViewMedicalOrder = (props) => {

    const {
        modal, toggle, modalTitle1, workMedicalOrder, typeModal = "view",
        type_file,
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className="modal-xl" >
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle1}
            </ModalHeader>
            <div id='mt-10'>
                <Row>
                    <Col sm="4">
                    </Col>
                    <Col sm="4">
                    </Col>
                    <Col sm="2">
                    </Col>
                    <Col sm="2" >
                        {
                            type_file === ".png" ?
                                <a alt="Orden Medica" className="left btn text-white" download={"Orden Médica"} href={'data:image/png;base64,' + workMedicalOrder}>
                                    Descargar <i className="fa fa-download"></i>
                                </a>
                                : type_file === ".jpg" ?
                                    <a alt="Orden Medica" className="left btn text-white" download={"Orden Médica"} href={'data:image/jpg;base64,' + workMedicalOrder}>
                                        Descargar <i className="fa fa-download"></i>
                                    </a>
                                    :
                                    <label></label>
                        }
                    </Col>
                </Row>
            </div>
            {
                typeModal === "view" ?
                    <ModalBody>
                        {
                            <div>
                                {
                                    type_file === ".pdf" ?
                                        <Col sm="12">
                                            <embed src={'data:application/pdf;base64,' + workMedicalOrder} type="application/pdf" width="100%" height="600px" />
                                        </Col>
                                        : type_file === ".png" ?
                                            <Col sm="12">
                                                <img src={'data:image/png;base64,' + workMedicalOrder} width="100%" height="80%" />

                                            </Col>
                                            : type_file === ".jpg" ?
                                                <Col sm="12">
                                                    <img src={'data:image/jpg;base64,' + workMedicalOrder} width="100%" height="80%" />

                                                </Col>
                                                :
                                                <Col sm="12">

                                                    <label>No se encontro ningún visualizador adecuado para el tipo de formato.</label>
                                                </Col>
                                }
                            </div>
                        }
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
            </ModalFooter>

        </Modal>
    )
}