import { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useEditWorkOrder = () => {

	const history = useHistory();

	//LOCAL VARIABLES
	const { id_work_order } = useParams();
	const [id_work_order_descbase64, setid_work_order_descbase64] = useState();
	let id_commercial_line = localStorage.getItem('commercialLineId');
	let id_branch = localStorage.getItem('branchId');
	let percentage_urgent_work_order = localStorage.getItem('percentage_urgent_work_order');


	//#region MODALS
	const [modalTitle, setModalTitle] = useState("");
	const [modalViewExamsP, setModalViewExamsP] = useState(false);
	const [modalSpecialDiscounts, setModalSpecialDiscounts] = useState(false);
	const [modalCommonDiscount, setModalCommonDiscount] = useState(false);
	const [modalDevolutions, setModalDevolutions] = useState(false);
	const [modalAutorizeWO, setModalAutorizeWO] = useState(false);
	const [modalLiquidedWO, setModalLiquidedWO] = useState(false);
	const [percentagereturn, setpercentagereturn] = useState(0);

	const toggleModalViewExams = () => {
		setModalViewExamsP(!modalViewExamsP);
	}

	const toggleSpecialDiscount = () => {
		setModalSpecialDiscounts(!modalSpecialDiscounts);
	}

	const toggleCommonDiscount = () => {
		setModalCommonDiscount(!modalCommonDiscount);
	}
	const toggleDevolutions = () => {
		setModalDevolutions(!modalDevolutions);
	}
	const toggleAutorizeWO = () => {
		setModalAutorizeWO(!modalAutorizeWO);
	}
	const toggleLiquidedWO = () => {
		setModalLiquidedWO(!modalLiquidedWO)
	}

	//#endregion MODAL
	//DECUENTOS CAMPAÑA
	const [checkDiscount, setcheckDiscount] = useState();
	//VARIABLE PARA SABER SI ESTA LIQUIDADA LA ORDEN
	const [checkpaid, setcheckpaid] = useState();
	//#region SHOW DISPLAY MESSAGE
	const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient, sweetConfirmationCancel] = useSweetAlert();

	//#endregion SHOW DISPLAY MESSAGE
	//#regioncommonts
	const [valid_commont, setvalid_commont] = useState(true);
	//endregioncommont
	//#region WORK ORDER 
	//#region WORK ORDER MODEL
	const [searchExamProfileState, setSearchExamProfileState] = useState([]);
	const [firstLoadingWorkOrder, setFirstLoadingWorkOrder] = useState(false);
	const [loading, setLoading] = useState(false);

	//PRELOADED VALUES
	const [listPrinterPoint, setListPrinterPoint] = useState([]);
	const [listPayMethod, setListPayMethod] = useState([]);
	const [listReturnReason, setListReturnReason] = useState([]);
	const [times, setTimes] = useState([]);
	//DETAIL WORK ORDER
	const [totalDevolutionsa, setTotalDevolutionsa] = useState(0.00);
	//INFORMATION WORK ORDER
	const [totalW, setTotalW] = useState(0.00);
	const [subTotalW, setSubTotalW] = useState(0.00);
	const [totalDiscountW, setTotalDiscountW] = useState(0.00);
	const [totalTaxesW, setTotalTaxesW] = useState(0.00);
	const [totalDevolutionsW, setTotalDevolutionsW] = useState(0.00);
	const [moneyEntered, setMoneyEntered] = useState(0);
	const [previousmoney, setpreviousmoney] = useState(0);
	const [showMessageW, setShowMessageW] = useState({
		labelMoney: "",
		amount: 0.00
	});
	//BOOL PARA SABER SI VIENE DEL DETALLE DE LA ORDEN
	const [statusTotal, setstatusTotal] = useState(true);
	//INFORMATION WORK ORDER CANCELATION
	const [totalDevolutionsWOC, setTotalDevolutionsWOC] = useState(0.00);
	const [showMessageWOC, setShowMessageWOC] = useState({
		labeldevoluction: "",
		classDevolution: "primary d-none"
	});
	//END INFORMATION WORK ORDER CANCELATION
	const [viewExamsProfile, setViewExamsProfile] = useState([]);
	const [enableBtnSaveWorkorder, setEnableBtnSaveWorkorder] = useState(false);
	const [enableBtnCancelWorkorder, setEnableBtnCancelWorkorder] = useState(false);
	const [devolutionsState, setDevolutionsState] = useState({
		id_work_order: 0,
		id_return_reason: 0,
		listTestDevolution: []
	});

	const [validateDevolutions, setValidateDevolutions] = useState({
		devolutionValid: false,
		testDevolution: false
	});
	//COMMENTS GENERAL MEDICAL ORDER
	const [ShowClassNameC, SetShowClassNameC] = useState("d-none");
	const [checkComments, setcheckComments] = useState(false);
	//COMMON DISCOUNTS
	const [arrayNameDiscount, setArrayNameDiscount] = useState([]);
	const [arrayCommonDiscounts, setArrayCommonDiscounts] = useState([]);
	//LIQUIDACIÓN
	const [formLiquidedWO, handleInputChangeLiquided, handleUpdateValuesformLiquidedWO] = useForm(
		{
			id_method_liquided: 0,
			amount_liquided: 0.0,
			id_method_pay: 1,
			amount_liq: 0.0,
		}
	);
	const {
		id_method_liquided,
		amount_liquided,
		id_method_pay,
		amount_liq
	} = formLiquidedWO;

	const [validationsliquided, setvalidationsliquided] = useState({
		id_method_pay_valid: false,
		amount_liquided_valid: false,
	});
	//AUTORIZACIÓN WORK ORDER 
	const [formAutorizeWO, handleInputChangeAuthorize, handleUpdateValuesformAutorizeWO] = useForm(
		{
			user_autorize: "",
			password_autorize: "",
			id_return_reason_woc: 0,
			percentage_return: 0,
		}
	);
	const {
		user_autorize,
		password_autorize,
		id_return_reason_woc,
		percentage_return,
	} = formAutorizeWO;

	const [validationsAutorize, setvalidationsAutorize] = useState({
		user_autorize_valid: false,
		password_autorize_valid: false,
		id_return_reason_woc_valid: false,
		percentagereturn_valid: false
	});
	//MEMBERSHIP PATIENT
	const [is_membership, setIs_membership] = useState(false);

	const [formWorkOrder, handleInputChangeWorkOrder, resetWorkOrder, handleUpdateValuesWorkOrder, handleSelectValuesWorkOrder, handlePickerValuesWorkOrder, handleDinamicInputWorkOrder] = useForm(
		{
			id_work_order: 0,
			id_patient: 0,
			id_branch: id_branch,
			id_company: 0,
			id_income_type: 0,
			id_printer_point: 0,
			id_quoter: null,
			id_special_discount: 0,
			nim: "",
			observations: "",
			observations_sample: "",
			observations_general: "",
			valid_commont_: true,
			print_results: true,
			send_whatsapp: false,
			send_doctor: false,
			results_partial: false,
			send_email: false,
			check_invoice: false,
			name_branch: "",
			name_company: "",
			listTest: [],
			listIndicationsExams: [],
			listIndicationsProfile: [],
			listPayMethods: [],
			listdevolution: [],
			listDoctors: [],
			selectListDoctors: [],
		}
	);

	const {
		id_patient,
		id_company,
		id_income_type,
		id_printer_point,
		id_quoter,
		id_special_discount,
		nim,
		observations,
		observations_sample,
		observations_general,
		print_results,
		send_whatsapp,
		send_doctor,
		results_partial,
		send_email,
		check_invoice,
		valid_commont_,
		name_branch,
		name_company,
		listTest,
		listIndicationsExams,
		listIndicationsProfile,
		listPayMethods,
		listdevolution,
		listDoctors,
		selectListDoctors,
	} = formWorkOrder;

	////console.log(percentage_return);

	//#endregion MODEL

	//#region VALIDATIONS WORK ORDER
	const [validationsWorkOrder, setValidationsWorkOrder] = useState({
		id_printer_point_valid: false,
		listTest_valid: false,
		listPayMethods_valid: false,
		validDevolutions: false
	});

	const [validationsTest, setValidationsTest] = useState({
		valid_repeat_test: false,
		message_validation: ""
	});
	//#endregion VALIDATIONS WORK ORDER

	//#region WORK ORDER USE EFFECT
	useEffect(() => {
		handleGetTimes();
		handleGetPayMethods();
	}, []);
	useEffect(() => {
		if (id_work_order !== undefined) {

			setFirstLoadingWorkOrder(true);

			let tmp_id_work_order = Buffer.from(id_work_order, 'base64');

			handleGetWorkOrder(tmp_id_work_order, id_commercial_line);

			handleGetPrinterPoints();
		}
	}, [history.location]);
	useEffect(() => {

		if (listPrinterPoint.length > 0) {

			handleUpdateValuesWorkOrder({
				...formWorkOrder,
				id_printer_point: listPrinterPoint[0]
			});
		}
	}, [listPrinterPoint]);


	//#endregion

	//#region WORK ORDER METHODS
	const handleGetTimes = async () => {
		let listTmp = await handleRequest("GET", "Price/GetTimes", "Tiempos");

		if (listTmp !== null && listTmp.length > 0) {
			setTimes(listTmp);
		}
	}

	const handleGetPrinterPoints = async () => {
		let listTmp = await handleRequest("GET", "PrinterPoints", "Puntos de impresión");

		if (listTmp !== null && listTmp.length > 0) {
			let list = [];

			listTmp.forEach(obj => {
				list.push({
					value: obj.id_printer_point,
					label: obj.name
				});
			});

			setListPrinterPoint([...list]);
		}
	}

	const handleGetPayMethods = async () => {
		let listTmp = await handleRequest("GET", "PayMethod/List", "Metodos de pago");
		if (listTmp !== null && listTmp.length > 0) {
			let list = [];

			listTmp.forEach(obj => {
				list.push({
					id_pay_method: obj.id_pay_method,
					name: obj.name,
					abbreviation: obj.abbreviation
				});
			});

			setListPayMethod(list);
		}
	}

	const handleGetWorkOrder = async (idWorkOrder, idCommercialLine) => {

		let listTmp = await handleRequest("GET", `WorkOrder/DetailWorkOrderById/${idWorkOrder}/${idCommercialLine}`);
		//console.log(listTmp);
		if (listTmp !== null) {
			////console.log(listTmp);
			if (listTmp.id_work_order > 0) {
				//SABER SI ESTA PAGADO LA ORDEN
				setcheckpaid(listTmp.paid);
				setid_work_order_descbase64(listTmp.id_work_order);
				let agePatiente = handleCalculateAge(listTmp.birthday);
				let listDoctors = [];

				if (listTmp.listDoctors !== null && listTmp.listDoctors.length > 0) {
					listTmp.listDoctors.forEach(d => {
						listDoctors.push({
							value: d.id_doctor,
							label: d.name_doctor
						});
					});

					setDoctorState([...listDoctors]);
				}
				//TOTAL DE DEVOLUCIONES
				let total_devoluciones = 0;
				if (listTmp.listdevolution !== null && listTmp.listdevolution.length > 0) {
					listTmp.listdevolution.forEach(d => {
						total_devoluciones += (d.amount - d.discount_amount) + d.iva;
						setTotalDevolutionsa(total_devoluciones);
					});
				}

				let listPayM = [];

				let totalIngresado = 0.0;
				if (listTmp.listPayMethods.length > 0) {
					////console.log(listTmp);

					listPayM = listTmp.listPayMethods.map(obj => {

						//TODO: AL UNICO QUE SE LE QUITA EL IVA ES AL EFECTIVO, SEGUN LAS REGLAS DEL BACK

						if (obj.name != "CREDITO") {
							let price = obj.amount === "" ? 0.0 : parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount);

							totalIngresado += !isNaN(price) ? parseFloat(price.toFixed(2)) : 0;
						}
						// else {
						// 	switch (listTmp.config_iva) {
						// 		case true://LOS PRECIOS YA TIENEN IVA

						// 			let price = obj.amount === "" ? 0.0 : parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount);

						// 			//HAY QUE QUITAR EL IVA
						// 			let valueIva = 1 + (listTmp.value_iva / 100);												

						// 			price = (price / valueIva);												

						// 			let with_iva = (price * listTmp.value_iva) / 100;

						// 			obj.amount = !isNaN(price + with_iva) ? parseFloat((price + with_iva).toFixed(2)) : 0;

						// 			totalIngresado += !isNaN(price + with_iva) ? parseFloat((price + with_iva).toFixed(2)) : 0;									

						// 			break;

						// 		case false://LOS PRECIOS NO TIENEN IVA
						// 			let without_price = obj.amount;												

						// 			let iva = (without_price * listTmp.value_iva) / 100;

						// 			obj.amount = !isNaN(without_price + iva) ? parseFloat((without_price + iva).toFixed(2)) : 0;

						// 			totalIngresado += !isNaN(without_price + iva) ? parseFloat((without_price + iva).toFixed(2)) : 0;

						// 			break;
						// 	}	
						// }

						return obj;
					});
				}

				if (listTmp.listTest.length > 0) {

					let id_special_discount = 0;
					let array_common_discounts = [];

					listTmp.listTest.forEach(obj => {
						if (obj.id_special_discount !== null && obj.id_special_discount > 0) {
							id_special_discount = obj.id_special_discount;
						}
						else if (obj.id_common_discount !== null && obj.id_common_discount > 0) {
							array_common_discounts.push(obj.id_common_discount);
						}
					});
					if (id_special_discount > 0) {
						getSpecialDiscount(id_special_discount);
					}
					else if (array_common_discounts.length > 0) {
						setArrayCommonDiscounts(array_common_discounts);
					}
				}
				setcheckDiscount(listTmp.checkDiscount);
				handleUpdateValuesWorkOrder({
					id_work_order: listTmp.id_work_order,
					id_patient: listTmp.id_patient,
					id_branch: listTmp.id_branch,
					id_company: listTmp.id_company === null ? 0 : listTmp.id_company,
					id_income_type: listTmp.id_income_type,
					id_printer_point: {
						"value": 1,
						"label": "Recepción"
					},
					id_special_discount: listTmp.id_special_discount,
					nim: listTmp.nim,
					observations: listTmp.observations === null ? "" : listTmp.observations,
					observations_sample: listTmp.observations_sample === null ? "" : listTmp.observations_sample,
					print_results: listTmp.print_results,
					send_whatsapp: listTmp.send_whatsapp,
					send_doctor: listTmp.send_doctor,
					results_partial: listTmp.results_partial,
					send_email: listTmp.send_email,
					check_invoice: false,
					name_branch: listTmp.name_branch,
					name_company: listTmp.name_company,
					listTest: listTmp.listTest,
					listIndicationsExams: listTmp.listIndicationsExams,
					listIndicationsProfile: listTmp.listIndicationsProfile,
					listPayMethods: listPayM,
					listDoctors: listTmp.listDoctors,
					selectListDoctors: listDoctors,
					observations_general: listTmp.observations_general === null ? "" : listTmp.observations_general,
					checkDiscount: listTmp.checkDiscount,
				});

				setMoneyEntered(totalIngresado - total_devoluciones);
				setstatusTotal(true);

				setInformationPatient({
					name_patient: listTmp.name_patient,
					birthday: listTmp.birthday,
					age: listTmp.age,
					curp: listTmp.curp,
					gender: listTmp.gender,
					phone: listTmp.phone,
					email: listTmp.email,
					membership: listTmp.membership,
					nim: listTmp.nim
				});

				if (listTmp.membership !== "" && listTmp.membership !== null) {
					setIs_membership(true);
				}

				setFirstLoadingWorkOrder(false);
			}
			else {
				setFirstLoadingWorkOrder(false);
			}
		}
		else {
			setFirstLoadingWorkOrder(false);
		}
	}

	//FIND EXAMS AND PROFILES
	const handleSelectExamChange = (e) => {
		let examProfile = e.target.value;

		if (examProfile !== 0 && examProfile !== null) {
			examProfile = examProfile.split('-');

			let idTest = examProfile[0];
			let typeTest = examProfile[1];
			let nameTypeTest = typeTest === "0" ? "Exam" : "Profile";

			if (nameTypeTest === "Profile") {
				handleGetProfile(idTest);
			}
			else {
				handelGetExam(idTest);
			}
		}
	}

	const handleGetProfile = async (id_profile) => {
		let mIdPatient = 0;
		let mIdClient = 0;

		if (formWorkOrder.id_company !== 0 && formWorkOrder.id_company !== null) {
			mIdPatient = 0;
			mIdClient = formWorkOrder.id_company;
		}
		else {
			mIdClient = 0;
			mIdPatient = specialDiscount !== null ? 0 : id_patient;
		}

		let alternative_method = "";
		if (is_membership) {
			alternative_method = "&is_membership=true";
		}

		let listTmp = await handleRequest("GET", `Profiles/FindProfileId/${id_profile},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},0?validation_strict=true${alternative_method}`, "Búscar perfiles");

		let list = listTest;
		let listIndicationsByProfiles = listIndicationsProfile;
		////console.log(list);

		if (listTmp !== null) {
			setstatusTotal(false);
			let descuentoEspecial = null;
			let id_agreement = null;
			let id_agreement_test_range = null;
			let contract_number = null;

			if (validationTest(null, id_profile, listTmp.listExams)) {
				sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
				return;
			}

			let array_specimens = [];

			listTmp.exams_specimens.forEach(obj => {
				if (!array_specimens.find(x => x.id_specimen === obj.id_specimen_origin)) {
					let valorCheck = true;
					let valorCheckForwarded = true;
					list.forEach(item => {

						if (item.id_exam !== null) {
							if (item.specimenExam.id_specimen === obj.id_specimen_origin) {
								valorCheck = item.specimenExam.check_specimen;
								valorCheckForwarded = false;
							}
						}
						else if (item.id_profile !== null) {
							item.listSpecimen.forEach(s => {
								if (s.id_specimen === obj.id_specimen_origin) {
									valorCheck = item.check_specimen;
									valorCheckForwarded = item.check_forwarded;

								}
								////console.log(item.listSpecimen);
							})
						}
					});

					array_specimens.push({
						specimen_name: obj.name_specimen_origin,
						id_specimen: obj.id_specimen_origin,
						check_specimen: valorCheck,
						check_forwarded: valorCheckForwarded,
					});
				}
			});

			let porcentaje_descuento = 0;

			if (specialDiscount !== null) {
				if (specialDiscount.examProfileInclude.length > 0) {
					specialDiscount.examProfileInclude.forEach(obj => {
						if (obj.id_profile === listTmp.id_profile) {
							if (obj.percentege > 0) {
								porcentaje_descuento = obj.percentege;
								descuentoEspecial = specialDiscount.id_special_discount;
							}
							else {
								porcentaje_descuento = specialDiscount.percentege;
								descuentoEspecial = specialDiscount.id_special_discount;
							}
						}
					})
				}
			}
			else if (listTmp.percentage_discount > 0) {
				if (checkDiscount == undefined) {
					setcheckDiscount(listTmp.auto_redeem);
				}
				porcentaje_descuento = listTmp.percentage_discount;
			}
			else if (mIdClient > 0) {
				if (listTmp.agreement !== null) {
					let convenio = listTmp.agreement;

					porcentaje_descuento = convenio.percentage;
					id_agreement = convenio.id_agreement;
					id_agreement_test_range = convenio.id_agreement_test_range;
					contract_number = convenio.contract_number;
				}
			}

			let copyCurves = listTmp.listCurves;

			if (copyCurves !== null && copyCurves.length > 0) {
				copyCurves.forEach(element => {
					element.exist = false
				});
			}
			var hoy = new Date();
			let day = hoy.getDate();
			let month = hoy.getMonth() + 1;
			let year = hoy.getFullYear();
			if (day < 10) {
				day = "0" + day;
			}
			if (month < 10) {
				month = "0" + month;
			}
			var limit = new Date(listTmp.valid_to);
			let day_valid_to = limit.getDate();
			let month_valid_to = limit.getMonth() + 1;
			let year_valid_to = limit.getFullYear();
			let validcommont = true;
			if (year > year_valid_to) {
				validcommont = false;
			} else {
				if (month > month_valid_to) {
					validcommont = false;
				} else {
					if (day > day_valid_to) {
						validcommont = false;

					}
				}
			}
			setvalid_commont(validcommont);
			list.push({
				abbreviation: listTmp.abbreviation,
				amount_discount: null,
				code: listTmp.code,
				contract_number: contract_number,
				exist: false,
				id_agreement: id_agreement,
				id_agreement_test_range: id_agreement_test_range,
				id_common_discount: listTmp.id_common_discount,
				id_exam: null,
				id_price: null,
				id_profile: listTmp.id_profile,
				id_profile_price: listTmp.id_price,
				id_rate: listTmp.id_rate,
				id_special_discount: descuentoEspecial,
				id_work_order_exam: null,
				id_work_order_profile: null,
				is_canceled: false,
				is_curve: listTmp.is_curve,
				is_referenced: false,
				is_urgent: false,
				listSpecimen: array_specimens,
				listTimes: null,
				name: listTmp.name,
				percentage_discount: porcentaje_descuento,
				price: listTmp.price,
				profileCurves: copyCurves,
				specimenExam: null,
				workOrderCurves: null,
				listExams: listTmp.listExams,
				config_iva: listTmp.config_iva,
				value_iva: listTmp.value_iva,
				is_quotation: false,
				price_urgent: 0,
				auto_redeem: listTmp.auto_redeem,
				valid_commont: validcommont
			});

			let indication = [];

			listTmp.listExamIndications.forEach(obj => {
				let indicationsProfile = [];

				obj.listIndications.forEach(i => {
					indicationsProfile.push({
						indication: i.indication
					});
				});

				indication.push({
					code: obj.code,
					name_exam: obj.name_exam,
					list_indications: indicationsProfile
				});
			});

			listIndicationsByProfiles.push({
				code: listTmp.code,
				name: listTmp.name,
				list_indications: indication
			});

			handleUpdateValuesWorkOrder({
				...formWorkOrder,
				listTest: list,
				listIndicationsProfile: listIndicationsByProfiles
			});

			searchExamProfileState({});
		}
	}

	const handelGetExam = async (id_exam) => {
		let mIdPatient = 0;
		let mIdClient = 0;

		if (formWorkOrder.id_company !== 0 && formWorkOrder.id_company !== null) {
			mIdPatient = 0;
			mIdClient = formWorkOrder.id_company;
		}
		else {
			mIdClient = 0;
			mIdPatient = specialDiscount !== null ? 0 : id_patient;
		}

		let alternative_method = "";
		if (is_membership) {
			alternative_method = "?is_membership=true";
		}

		let list = listTest;
		let listIndicationsByExam = listIndicationsExams;

		let listTmp = await handleRequest("GET", `Exam/GetPriceFromExam/${id_exam},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},0,true${alternative_method}`, "Exámenes");

		if (listTmp !== null) {
			setstatusTotal(false);
			let descuentoEspecial = null;
			let id_agreement = null;
			let id_agreement_test_range = null;
			let contract_number = null;

			if (validationTest(id_exam)) {
				sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
				return;
			}

			let valorCheck = true;
			let valorCheckForwarded = true;
			list.forEach(item => {
				if (item.specimenExam !== null) {
					if (item.specimenExam.id_specimen === listTmp.id_specimen) {
						valorCheck = item.specimenExam.check_specimen;
						valorCheckForwarded = item.specimenExam.check_forwarded;
					}

					if (item.listSpecimen !== null) {
						item.listSpecimen.forEach(s => {
							if (s.id_specimen === listTmp.id_specimen) {
								valorCheck = item.check_specimen;
								valorCheckForwarded = item.check_forwarded;
							}
						})
					}
				}
			});

			let porcentaje_descuento = 0;
			if (specialDiscount !== null) {
				if (specialDiscount.examProfileInclude.length > 0) {
					specialDiscount.examProfileInclude.forEach(obj => {
						if (obj.id_exam === listTmp.id_exam) {
							if (obj.percentage > 0) {
								porcentaje_descuento = obj.percentage;
								descuentoEspecial = specialDiscount.id_special_discount;
							}
							else {
								porcentaje_descuento = specialDiscount.percentage;
								descuentoEspecial = specialDiscount.id_special_discount;
							}
						}
					});
				}
				else {
					if (!listTmp.is_referenced) {
						porcentaje_descuento = specialDiscount.percentage;
						descuentoEspecial = specialDiscount.id_special_discount;
					}
				}
			}
			else if (listTmp.percentage_discount > 0) {
				if (checkDiscount == undefined) {
					setcheckDiscount(listTmp.auto_redeem);
				}
				porcentaje_descuento = listTmp.percentage_discount;
			}
			else if (mIdClient > 0) {
				if (listTmp.agreement !== null) {
					let convenio = listTmp.agreement;

					porcentaje_descuento = convenio.percentage;
					id_agreement = convenio.id_agreement;
					id_agreement_test_range = convenio.id_agreement_test_range;
					contract_number = convenio.contract_number;
				}
			}
			var hoy = new Date();
			let day = hoy.getDate();
			let month = hoy.getMonth() + 1;
			let year = hoy.getFullYear();
			if (day < 10) {
				day = "0" + day;
			}
			if (month < 10) {
				month = "0" + month;
			}
			var fecha = day + "/" + month + '/' + hoy.getFullYear();
			var limit = new Date(listTmp.valid_to);
			let day_valid_to = limit.getDate();
			let month_valid_to = limit.getMonth() + 1;
			let year_valid_to = limit.getFullYear();
			let validcommont = true;
			if (year > year_valid_to) {
				validcommont = false;
			} else {
				if (month > month_valid_to) {
					validcommont = false;
				} else {
					if (day > day_valid_to) {
						validcommont = false;

					}
				}
			}

			setvalid_commont(validcommont);
			list.push({
				abbreviation: listTmp.abbreviation,
				code: listTmp.code,
				contract_number: contract_number,
				curves: listTmp.listCurves,
				exist: false,
				id_agreement: id_agreement,
				id_agreement_test_range: id_agreement_test_range,
				id_common_discount: listTmp.id_common_discount,
				id_exam: listTmp.id_exam,
				id_price: listTmp.id_price,
				id_profile: null,
				id_profile_price: null,
				id_rate: listTmp.id_rate,
				id_special_discount: descuentoEspecial,
				id_work_order_exam: null,
				id_work_order_profile: null,
				is_curve: false,
				is_referenced: listTmp.is_referenced,
				is_urgent: false,
				listCurves: null,
				listSpecimen: null,
				listTimes: null,
				name: listTmp.name,
				percentage_discount: porcentaje_descuento,
				price: listTmp.price,
				specimenExam: {
					check_specimen: valorCheck,
					check_forwarded: valorCheckForwarded,
					id_specimen: listTmp.id_specimen,
					specimen_name: listTmp.name_specimen,
				},
				config_iva: listTmp.config_iva,
				value_iva: listTmp.value_iva,
				listExams: null,
				price_urgent: 0,
				auto_redeem: listTmp.auto_redeem,
				valid_commont: validcommont
			});


			let indication = [];

			listTmp.listIndications.forEach(obj => {
				indication.push({
					indication: obj.indication
				});
			})

			listIndicationsByExam.push({
				code: listTmp.code,
				name_exam: listTmp.name,
				list_indications: indication
			});

			handleUpdateValuesWorkOrder({
				...formWorkOrder,
				listTest: list,
				listIndicationsExams: listIndicationsByExam
			});
			searchExamProfileState({});
		}
	}


	const removeExamProfile = (id_exam = null, id_profile = null) => {
		if (listTest.length == 1) {
			handleModalCancelWorkOrder();
		} else {
			handleCreateDevolution();
			let findTest = listTest.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));
			let tmpDevolutions = devolutionsState;

			tmpDevolutions.id_work_order = formWorkOrder.id_work_order;

			if (findTest !== null) {
				setstatusTotal(false);
				let tmpTest = listTest.filter(item => {
					if (!item.exist) {
						if (id_exam !== null) {
							if (item.id_exam === parseInt(id_exam)) {
								return false;
							}
							else {
								return true;
							}
						}
						else {
							if (item.id_profile === parseInt(id_profile)) {
								return false;
							}
							else {
								return true;
							}
						}
					}
					else if (item.exist) {
						if (id_exam !== null) {
							if (item.id_exam === parseInt(id_exam)) {
								item.is_canceled = true;

								tmpDevolutions.listTestDevolution.push({
									id_work_order_profile: null,
									id_work_order_exam: item.id_work_order_exam,
									code: item.code,
									name: item.name,
									amount: item.price,
									iva: 0,
									extra: item.price_urgent
								});
							}

							return true;
						}
						else {
							if (item.id_profile === parseInt(id_profile)) {
								item.is_canceled = true;

								tmpDevolutions.listTestDevolution.push({
									id_work_order_profile: item.id_work_order_profile,
									id_work_order_exam: null,
									code: item.code,
									name: item.name,
									//amount: item.price,
									amount: (item.is_curve ? item.workOrderCurves.price : item.price),
									iva: 0,
									extra: item.price_urgent
								})
							}
							return true;
						}
					}
				});

				handleUpdateValuesWorkOrder({
					...formWorkOrder,
					listTest: tmpTest
				});

				setDevolutionsState(tmpDevolutions);
			}
		}

	}

	const handleCheckSpecimen = (id_exam, id_profile, id_specimen) => {
		let copyTest = listTest;

		let valor = 0;
		let sub = null;

		if (id_exam !== null) {
			let findExam = copyTest.find(t => t.id_exam === parseInt(id_exam));

			if (id_specimen !== null) {
				sub = findExam.specimenExam;
				valor = !sub.check_specimen;
			}
		}
		else if (id_profile !== null) {
			let findProfile = copyTest.find(x => x.id_profile === id_profile);

			if (id_specimen !== null) {
				sub = findProfile.listSpecimen.find(p => p.id_specimen === id_specimen);
				valor = !sub.check_specimen;
			}
		}

		copyTest.map(item => {
			if (item.specimenExam !== null) {
				if (item.id_exam != null) {
					if (item.specimenExam.id_specimen === id_specimen && item.id_exam == id_exam) {
						item.specimenExam.check_specimen = valor;
					}
				} else if (item.id_profile != null) {
					if (item.specimenExam.id_specimen === id_specimen && item.id_profile == id_profile) {
						item.specimenExam.check_specimen = valor;
					}
				}

			}

			if (item.listSpecimen !== null) {
				item.listSpecimen.map(pSpecimen => {
					if (item.id_exam != null) {
						if (pSpecimen.id_specimen === parseInt(id_specimen) && item.id_exam == id_exam) {
							pSpecimen.check_specimen = valor;
						}
					} else if (item.id_profile != null) {
						if (pSpecimen.id_specimen === parseInt(id_specimen) && item.id_profile == id_profile) {
							pSpecimen.check_specimen = valor;
						}
					}
				})
			}
		});

		handleUpdateValuesWorkOrder({
			...formWorkOrder,
			listTest: copyTest
		});
	}

	// const handleCheckForwarded = (id_exam, id_profile, id_specimen) => {
	// 	let copyTest = listTest;

	// 	let valor = 0;
	// 	let sub = null;

	// 	if (id_exam !== null) {
	// 		let findExam = copyTest.find(t => t.id_exam === parseInt(id_exam));

	// 		if (id_specimen !== null) {
	// 			sub = findExam.specimenExam;
	// 			valor = !sub.check_forwarded;
	// 		}
	// 	}
	// 	else if (id_profile !== null) {
	// 		let findProfile = copyTest.find(x => x.id_profile === id_profile);

	// 		if (id_specimen !== null) {
	// 			sub = findProfile.listSpecimen.find(p => p.id_specimen === id_specimen);
	// 			valor = !sub.check_forwarded;
	// 		}
	// 	}

	// 	copyTest.map(item => {
	// 		if (item.specimenExam !== null) {
	// 			if (item.specimenExam.id_specimen === id_specimen) {
	// 				item.specimenExam.check_forwarded = valor;
	// 			}
	// 		}

	// 		if (item.listSpecimen !== null) {
	// 			item.listSpecimen.map(pSpecimen => {
	// 				if (pSpecimen.id_specimen === parseInt(id_specimen)) {
	// 					pSpecimen.check_forwarded = valor;
	// 				}
	// 			})
	// 		}
	// 	});

	// 	handleUpdateValuesWorkOrder({
	// 		...formWorkOrder,
	// 		listTest: copyTest
	// 	});
	// }
	const handleCheckForwarded = (id_exam, id_profile, id_specimen) => {
		let copyTest = listTest;

		let valor = 0;
		let sub = null;

		if (id_exam !== null) {
			let findExam = copyTest.find(t => t.id_exam === parseInt(id_exam));

			if (id_specimen !== null) {
				sub = findExam.specimenExam;
				valor = !sub.check_forwarded;
			}
		}
		else if (id_profile !== null) {
			let findProfile = copyTest.find(x => x.id_profile === id_profile);

			if (id_specimen !== null) {
				sub = findProfile.listSpecimen.find(p => p.id_specimen === id_specimen);
				valor = !sub.check_forwarded;
			}
		}

		copyTest.map(item => {
			if (item.specimenExam !== null) {
				if (item.id_exam != null) {
					if (item.specimenExam.id_specimen === id_specimen && item.id_exam == id_exam) {
						item.specimenExam.check_forwarded = valor;
					}
				} else if (item.id_profile != null) {
					if (item.specimenExam.id_specimen === id_specimen && item.id_profile == id_profile) {
						item.specimenExam.check_forwarded = valor;
					}
				}

			}

			if (item.listSpecimen !== null) {
				item.listSpecimen.map(pSpecimen => {
					if (item.id_exam != null) {
						if (pSpecimen.id_specimen === parseInt(id_specimen) && item.id_exam == id_exam) {
							pSpecimen.check_forwarded = valor;
						}
					} else if (item.id_profile != null) {
						if (pSpecimen.id_specimen === parseInt(id_specimen) && item.id_profile == id_profile) {
							pSpecimen.check_forwarded = valor;
						}
					}
				})
			}
		});

		handleUpdateValuesWorkOrder({
			...formWorkOrder,
			listTest: copyTest
		});
	}

	const handleCheckUrgent = (e, id_exam, id_profile) => {
		let copyTest = listTest;

		copyTest.map(item => {
			if (id_exam !== null) {
				item.is_urgent = item.id_exam === id_exam ? e.target.checked : item.is_urgent;
			}
			else if (id_profile !== null) {
				item.is_urgent = item.id_profile === id_profile ? e.target.checked : item.is_urgent;
			}
		});

		handleUpdateValuesWorkOrder({
			...formWorkOrder,
			listTest: copyTest
		});
	}

	const changeSelectSamplings = (e, id_profile) => {
		let tmpTest = listTest;

		let id_profile_curve_price = parseInt(e.target.value);

		let findProfile = tmpTest.find(x => x.id_profile === parseInt(id_profile));

		if (findProfile) {
			let newTest = [];

			if (findProfile.exist) {
				let workOrderCurves_canceled = null;

				if (findProfile.workOrderCurves.exist) {
					workOrderCurves_canceled = findProfile.workOrderCurves;
				}
				else {
					if (findProfile.workOrderCurves_canceled !== undefined && findProfile.workOrderCurves_canceled !== null) {
						workOrderCurves_canceled = findProfile.workOrderCurves_canceled;
					}
				}

				newTest = tmpTest.map(t => {
					if (t.id_profile === parseInt(id_profile)) {
						if (workOrderCurves_canceled !== null) {
							if (workOrderCurves_canceled.id_profile_curve_price === parseInt(id_profile_curve_price)) {
								t.workOrderCurves = t.workOrderCurves_canceled;
								t.workOrderCurves_canceled = null;
							}
							else {
								let selected_curve = findProfile.profileCurves.find(x => x.id_profile_curve_price === id_profile_curve_price);
								let newWorkOrderCurves = [];

								if (selected_curve) {

									let curves_aux = [];

									for (let index = 1; index <= selected_curve.number_sampling; index++) {

										curves_aux.push({
											value: 0,
											label: ''
										});
									}

									newWorkOrderCurves = {
										exist: false,
										id_price: selected_curve.id_price,
										id_profile_curve_price: selected_curve.id_profile_curve_price,
										id_work_order_exam: workOrderCurves_canceled.id_work_order_exam,
										id_profile_price: selected_curve.id_profile_price,
										number_sampling: selected_curve.number_sampling,
										price: selected_curve.price,
										listTimeCurves: curves_aux
									};
								}

								t.workOrderCurves = newWorkOrderCurves;
								t.workOrderCurves_canceled = workOrderCurves_canceled;
							}
						}
					}

					return t;
				});
			}
			else {
				let selected_curve = findProfile.profileCurves.find(x => x.id_profile_curve_price === id_profile_curve_price);
				let newWorkOrderCurves = [];

				if (selected_curve) {

					let curves_aux = [];

					for (let index = 1; index <= selected_curve.number_sampling; index++) {

						curves_aux.push({
							value: 0,
							label: ''
						});
					}

					newWorkOrderCurves = {
						exist: false,
						id_price: selected_curve.id_profile_curve_price,
						id_profile_curve_price: selected_curve.id_profile_curve_price,
						id_work_order_exam: null,
						id_profile_price: selected_curve.id_profile_price,
						number_sampling: selected_curve.number_sampling,
						price: selected_curve.price,
						listTimeCurves: curves_aux
					};
				}

				newTest = tmpTest.map(t => {
					if (t.id_profile === parseInt(id_profile)) {
						t.workOrderCurves = newWorkOrderCurves;
						t.workOrderCurves_canceled = null;
					}

					return t;
				});
			}

			handleUpdateValuesWorkOrder({
				...formWorkOrder,
				listTest: newTest
			});
		}
	}

	const changeValueTimeSampling = (e, id_profile, index_time_curve) => {
		let tmpListTest = listTest;

		let time = e;

		let newTmpListTest = tmpListTest.map(t => {
			if (t.id_profile === parseInt(id_profile)) {
				let newListTimes = t.workOrderCurves.listTimeCurves.map((c, cIndex) => {
					if (cIndex === parseInt(index_time_curve)) {
						c = time;
					}

					return c;
				});

				t.workOrderCurves.listTimeCurves = newListTimes;
			}

			return t;
		});

		handleUpdateValuesWorkOrder({
			...formWorkOrder,
			listTest: newTmpListTest
		});
	}

	const handleViewExamsProfile = (id_profile) => {
		let findExams = listTest.find(x => x.id_profile === parseInt(id_profile));
		if (findExams) {
			setViewExamsProfile(findExams.listExams);
			toggleModalViewExams();
		}
	}

	const handleAddPayMethodInputs = () => {
		let tmpPayMethods = listPayMethods;

		tmpPayMethods.push({
			amount: 0,
			id_pay_method: 0,
			id_work_order: 0,
			id_work_order_pay: null
		});

		handleUpdateValuesWorkOrder({
			...formWorkOrder,
			listPayMethods: tmpPayMethods
		});
		setstatusTotal(false);
	}

	const handleChangePayMethod = (e, payMethodI) => {
		let tmpListPayMethods = listPayMethods;

		tmpListPayMethods[payMethodI].id_pay_method = e.target.value;

		handleUpdateValuesWorkOrder({
			...formWorkOrder,
			listPayMethods: tmpListPayMethods
		});
	}

	const handleGiveChange = (e, nameInput, nameArraysInput) => {
		handleDinamicInputWorkOrder(e, nameInput, nameArraysInput);

		// let tmpListPayMethods = [];

		// tmpListPayMethods = listPayMethods.map(obj => {

		// 	let id_pay_method = parseInt(obj.id_pay_method);
		// 	let amount = obj.amount === '' ? 0.0 : (parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount));

		// 	if (id_pay_method === 0) {
		// 		obj.alert = "Selecciona un metodo de pago";
		// 	}
		// 	else if (id_pay_method !== 1 && id_pay_method !== 0) {
		// 		if (amount > totalW) {
		// 			obj.alert = "El monto ingresado, no debe ser mayor al total";
		// 			setEnableBtnSaveWorkorder(true);
		// 		}
		// 	}
		// 	else {
		// 		obj.alert = "";
		// 	}

		// 	return obj;
		// })



		// if (e.target.value !== '') {

		// 	let typePayMethod = listPayMethods[e.target.dataset.id].id_pay_method;
		// 	typePayMethod = parseInt(typePayMethod);

		// 	if (typePayMethod === 0) {

		// 	}
		// }				


		let totalIngresado = 0.0;
		//console.log(listPayMethods);
		listPayMethods.forEach(obj => {
			if (checkpaid == false) {
				if (obj.name != "CREDITO") {
					totalIngresado += (obj.amount === "") ? 0.00 : (parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount));
				}
			} else {
				totalIngresado += (obj.amount === "") ? 0.00 : (parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount));


			}

		});

		totalIngresado = !isNaN(totalIngresado) ? totalIngresado : 0;
		//console.log(totalDevolutionsW);
		totalIngresado = totalIngresado - totalDevolutionsa;
		setMoneyEntered(totalIngresado);
	}

	const handleDeletePayMethod = (index) => {
		let copyListPayMethods = listPayMethods;
		let money = 0;

		if (listPayMethods.length > 1) {
			copyListPayMethods.splice(index, 1);
		}

		copyListPayMethods.forEach(obj => {
			if (checkpaid == false) {
				if (obj.name != "CREDITO") {
					money += parseFloat(obj.amount);
				}
			} else {
				money += parseFloat(obj.amount);
			}


		});

		handleUpdateValuesWorkOrder({
			...formWorkOrder,
			listPayMethods: [...copyListPayMethods]
		});

		setMoneyEntered(money.toFixed(2));
	}

	//METHODS BY DEVOLUTIONS
	const handleCreateDevolution = () => {
		setLoading(false);
		setModalTitle("Crear nueva devolución");
		toggleDevolutions();
		handleGetReturnReasons();
	}

	const handleGetReturnReasons = async () => {
		let listTmp = await handleRequest("GET", 'ReturnReason/List/false', "Devoluciones");

		if (listTmp != null && listTmp.length > 0) {
			let tmpReasons = [];

			listTmp.forEach(r => {
				tmpReasons.push({
					value: r.id_return_reason,
					label: r.reason
				});
			});

			setListReturnReason(tmpReasons);
		}
	}

	const handleChangeReturnReason = (e) => {
		let devolution = devolutionsState;

		setDevolutionsState({
			...devolution,
			id_return_reason: e
		});
	}

	// const saveReturnReason = () => {
	// 	if (validDevolution()) {
	// 		return;
	// 	}

	// 	setModalTitle("");
	// 	toggleDevolutions();
	// }
	const canceldevolution = () => {
		setstatusTotal(true);
		setLoading(false);
		let tmpTest = listTest.filter(item => {
			if (item.exist) {
				item.is_canceled = false;

			}
		});
		setDevolutionsState({
			id_work_order: 0,
			id_return_reason: 0,
			listTestDevolution: []
		});
		setModalDevolutions(!modalDevolutions);


		let newValidations = {};
		newValidations = {
			...newValidations,
			devolutionValid: false
		};
		setValidateDevolutions(newValidations);

	}
	const saveReturnReason = async () => {
		setLoading(true);
		if (validDevolution()) {
			setLoading(false);
			return;
		}
		let mData = {
			listDevolutions: {
				id_return_reason: devolutionsState.id_return_reason.value === undefined ? null : devolutionsState.id_return_reason.value,
				id_work_order: formWorkOrder.id_work_order,
				listTestDevolution: devolutionsState.listTestDevolution,
			},

		};
		let mValues = JSON.stringify(mData);

		let requestOptions = {
			method: 'PUT',
			body: mValues
		};

		const respuesta = await sendRequest(requestOptions, "WorkOrder/SaveDevolution");

		if (respuesta.code === 200) {

			createSweet("create", "success", "Exito!", "Devolución realizada correctamente");
			if (id_work_order !== undefined) {

				let tmp_id_work_order = Buffer.from(id_work_order, 'base64');

				handleGetWorkOrder(tmp_id_work_order, id_commercial_line);
				setDevolutionsState({
					id_work_order: 0,
					id_return_reason: 0,
					listTestDevolution: []
				});
				setModalDevolutions(!modalDevolutions);


				let newValidations = {};
				newValidations = {
					...newValidations,
					devolutionValid: false
				};
				setLoading(false);
				setValidateDevolutions(newValidations);
			}

		}
		else if (respuesta.code === 401) {
			validarSesion(history, respuesta.code, saveAutorized);

		}
		else if (respuesta.code === 500) {
			createSweet("error", "error", "Devolución", "Ocurrio un problema en el servidor");

		}
		else {
			createSweet("error", "warning", "Devolución", respuesta.data.msg);

		}
	}

	const saveWorkOrder = async (validPercentagePrice = -1) => {
		setLoading(true);

		if (validWorkOrder()) {
			setLoading(false);
			return;
		}

		if (moneyEntered > 0) {
			let percentage = (moneyEntered * (50 / 100));
			percentage = parseFloat(percentage.toFixed(2));

			let handleChange = (parseFloat(moneyEntered) - totalW);
			handleChange = !isNaN(handleChange) ? parseFloat(handleChange.toFixed(2)) : 0;

			if (handleChange > percentage && validPercentagePrice === -1) {
				sweetConfirmation("El cambio supera el 50% del total", "Deseas cambiar la cantidad ingresada", "warning", true, saveWorkOrder);
				return;
			}
		}

		if (!validPercentagePrice) {
			return;
		}
		//console.log(valid_commont);
		let body = {

			...{ ...formWorkOrder },
			id_printer_point: formWorkOrder.id_printer_point.value,
			listDevolutions: {
				id_return_reason: devolutionsState.id_return_reason.value === undefined ? null : devolutionsState.id_return_reason.value,
				id_work_order: formWorkOrder.id_work_order,
				listTestDevolution: devolutionsState.listTestDevolution,
			},
			valid_commont: valid_commont,
			checkDiscount: checkDiscount,
		};
		//console.log(body);

		let listTmp = await handleRequest("PUT", "WorkOrder/UpdateWorkOrder", "Actualizar ingreso", body);

		if (listTmp !== null) {
			setLoading(false);
			let tmp_id_work_order = Buffer.from(id_work_order, 'base64');

			handleGetWorkOrder(tmp_id_work_order, id_commercial_line);
			setDevolutionsState({
				id_work_order: 0,
				id_return_reason: 0,
				listTestDevolution: []
			});


			let newValidations = {};
			newValidations = {
				...newValidations,
				devolutionValid: false
			};
			setValidateDevolutions(newValidations);
			createSweet("create", "success", "Exito!");

		}
	}
	const handleBack = async () => {
		window.history.back();
	}
	const handleModalCancelWorkOrder = async () => {
		let listTmp = await handleRequest("GET", 'ReturnReason/List/false', "Devoluciones");

		if (listTmp != null && listTmp.length > 0) {
			let tmpReasons = [];

			listTmp.forEach(r => {
				tmpReasons.push({
					value: r.id_return_reason,
					label: r.reason
				});
			});

			setListReturnReason(tmpReasons);
		}
		toggleAutorizeWO();


	}

	//#endregion
	function validate_mont(event) {
		let percentage_input = event.target.value;
		setpercentagereturn(percentage_input);
		let total = 0.0;
		let subtotal = 0.0;
		let descuento = 0.0;
		let total_iva = 0.0;
		let total_cancellations = 0.0;
		let cantidad_porcentaje = 0.0;
		let total_final = 0.0;
		let mensaje = "";
		if (formWorkOrder.listTest.length > 0) {
			formWorkOrder.listTest.forEach(data => {
				data.is_canceled = true;
			});
		}

		if (formWorkOrder.listTest.length > 0) {
			formWorkOrder.listTest.forEach(data => {


				switch (data.config_iva) {
					case true://LOS PRECIOS YA TIENEN IVA

						let with_discount = 0.0;
						let price = 0.0;

						let value_urgent = parseInt(percentage_urgent_work_order);

						//HAY QUE QUITAR EL IVA
						let valueIva = 1 + (data.value_iva / 100);

						if (data.is_canceled) {
							if (data.id_exam !== null) {
								price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										price = data.workOrderCurves.price;
									}
								}
								else {
									price = data.price;
								}
							}
						}
						else {
							if (data.id_exam !== null) {
								price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										price = data.workOrderCurves.price;
									}
								}
								else {
									price = data.price;
								}
							}
						}

						price = (price / valueIva);

						if (data.is_urgent) {
							if (data.price_urgent > 0) {
								price = price + data.price_urgent;
							}
							else {
								let with_urgent = (price * value_urgent) / 100;
								data.price_urgent = !isNaN(with_urgent) ? Math.round(parseFloat(with_urgent.toFixed(2))) : 0;
								price = Math.round(price + with_urgent);
							}
						}
						else {
							data.price_urgent = 0;
						}
						if (data.valid_commont == true) {
							if (data.auto_redeem == true && checkDiscount == true) {
								if (data.percentage_discount !== undefined && data.percentage_discount) {
									with_discount = price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}

							} else if (data.auto_redeem == false && checkDiscount == true) {

								if (data.percentage_discount !== undefined && data.percentage_discount) {
									with_discount = price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
							} else if (data.auto_redeem == true && checkDiscount == false) {

								if (data.amount_discount != undefined) {
									if (data.percentage_discount !== undefined && data.percentage_discount) {
										with_discount = price * (data.percentage_discount / 100);

										arrayCommonDiscounts.push(data.id_common_discount);
									}
								}
							} else {

								if (data.percentage_discount !== undefined && data.percentage_discount) {
									with_discount = price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
							}
						} else {
							arrayCommonDiscounts.push(data.id_common_discount);
						}

						// if (data.percentage_discount !== undefined && data.percentage_discount) {
						// 	with_discount = price * (data.percentage_discount / 100);

						// 	arrayCommonDiscounts.push(data.id_common_discount);								
						// }

						let with_iva = Math.round(((price - with_discount) * data.value_iva) / 100);
						////console.log(with_iva);

						if (data.is_canceled) {
							total_cancellations += ((price - with_discount) + with_iva);
						}
						else {
							subtotal += price;
							descuento += Math.round(with_discount);
							total_iva += with_iva;
						}

						break;

					case false://LOS PRECIOS NO TIENEN IVA
						let desc = 0.0;
						let without_price = 0;
						let value_urgent_2 = parseInt(percentage_urgent_work_order);

						if (data.is_canceled) {
							if (data.id_exam !== null) {
								without_price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										without_price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										without_price = data.workOrderCurves.price;
									}
								}
								else {
									without_price = data.price;
								}
							}
						}
						else {
							if (data.id_exam !== null) {
								without_price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										without_price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										without_price = data.workOrderCurves.price;
									}
								}
								else {
									without_price = data.price;
								}
							}
						}

						if (data.is_urgent) {
							if (data.price_urgent > 0) {
								without_price = Math.round(without_price + data.price_urgent);
							}
							else {
								let urgent = ((without_price * value_urgent_2) / 100);
								without_price = Math.round(without_price + urgent);

								data.price_urgent = !isNaN(urgent) ? Math.round(parseFloat(urgent.toFixed(2))) : 0;

							}
						}
						else {
							data.price_urgent = 0;
						}
						if (data.valid_commont == true) {
							if (data.auto_redeem == true && checkDiscount == true) {

								if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
									desc = without_price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
								////console.log("1");

							} else if (data.auto_redeem == false && checkDiscount == true) {

								if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
									desc = without_price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
								////console.log("2");
							} else if (data.auto_redeem == true && checkDiscount == false) {
								if (data.amount_discount != undefined) {
									if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
										desc = without_price * (data.percentage_discount / 100);

										arrayCommonDiscounts.push(data.id_common_discount);
									}
								}


							} else {

								if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
									desc = without_price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
								////console.log("4");
							}
						} else {
							arrayCommonDiscounts.push(data.id_common_discount);
						}
						// if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
						// 	desc = without_price * (data.percentage_discount / 100);

						// 	arrayCommonDiscounts.push(data.id_common_discount);
						// }

						let iva = Math.round(((without_price - desc) * data.value_iva) / 100);
						////console.log(without_price);
						if (data.is_canceled) {
							total_cancellations += ((without_price - desc) + iva);
						}
						else if (data.id_profile !== null && (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null)) {
							total_cancellations += ((without_price - desc) + iva);
						}
						else {
							subtotal += without_price;
							descuento += Math.round(desc);
							total_iva += iva;
						}

						break;
				}
			});
		}

		total = (subtotal - descuento) + total_iva;

		descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
		subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
		total_iva = !isNaN(total_iva) ? Math.round(parseFloat(total_iva.toFixed(2))) : 0;
		total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
		total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;
		formAutorizeWO.percentage_return = percentage_input;
		if (formAutorizeWO.percentage_return > 0) {
			cantidad_porcentaje = (percentage_input * total_cancellations) / 100;
			total_final = cantidad_porcentaje.toFixed(2);
		} else {
			cantidad_porcentaje = 0;
			total_final = cantidad_porcentaje.toFixed(2);
		}

		setTotalDevolutionsWOC(total_final);
		mensaje = "La cantidad a devolver es:" + total_final;
		setShowMessageWOC({
			labeldevoluction: "La cantidad a devolver es: ",
			classDevolution: "primary text-center"
		});
	}

	const saveAutorized = async (e) => {
		setLoading(true);
		if (validatesaveAutorize()) {
			setLoading(false);
			return;
		}

		let mData = {
			...{
				...formAutorizeWO,
				id_work_order: id_work_order_descbase64,
				percentage_return: percentagereturn,
			},

		};
		let mValues = JSON.stringify(mData);
		////console.log(mValues);

		let requestOptions = {
			method: 'PUT',
			body: mValues
		};

		const respuesta = await sendRequest(requestOptions, "WorkOrder/CancelWorkOrder");

		if (respuesta.code === 200) {

			createSweet("create", "success", "Exito!", "Admisión eliminada correctamente");
			setTimeout(() => {
				// props.history.replace(`${process.env.PUBLIC_URL}/dashboard/default`);
				window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
			}, 1000);

		}
		else if (respuesta.code === 401) {
			validarSesion(history, respuesta.code, saveAutorized);

		}
		else if (respuesta.code === 500) {
			createSweet("error", "error", "Admisión", "Ocurrio un problema en el servidor");

		}
		else {
			createSweet("error", "warning", "Admisión", respuesta.data.msg);

		}
	}
	const validatesaveAutorize = () => {
		let statusValidation = false;
		let newValidations = {};

		if (formAutorizeWO.user_autorize == "") {
			newValidations = {
				...newValidations,
				user_autorize_valid: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				user_autorize_valid: false
			};
		}

		if (formAutorizeWO.password_autorize == "") {
			newValidations = {
				...newValidations,
				password_autorize_valid: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				password_autorize_valid: false
			}
		}
		if (formAutorizeWO.id_return_reason_woc == 0) {
			newValidations = {
				...newValidations,
				id_return_reason_woc_valid: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				id_return_reason_woc_valid: false
			}
		}
		if (percentagereturn < 1 || percentagereturn > 100 || percentagereturn == '' || percentagereturn == undefined) {
			////console.log(percentagereturn);
			newValidations = {
				...newValidations,
				percentagereturn_valid: true
			};
			statusValidation = true;
		} else {
			newValidations = {
				...newValidations,
				percentagereturn_valid: false
			}
		}

		//id_return_reason
		////console.log(newValidations);
		setvalidationsAutorize(newValidations);
		return statusValidation;
	}

	//END SAVE AUTORIZED

	//CALCULATE AMOUNT OF WORK ORDER
	const format_trunc_number = (_quantity, _decimals) => {
		let _format_number = 0;
		const _base = 10;

		let _number_decimals = Math.pow(_base, _decimals);

		_format_number = Math.round(_quantity * _number_decimals) / _number_decimals;

		return _format_number;
	}

	//CALCULATE AMOUNT OF WORK ORDER

	useMemo(() => {
		let total = 0.0;
		let subtotal = 0.0;
		let descuento = 0.0;
		let total_iva = 0.0;
		let total_cancellations = 0.0;
		let _take_number_decimals = 2;

		if (formWorkOrder.listTest.length > 0) {

			formWorkOrder.listTest.forEach(data => {

				switch (data.config_iva) {
					case true://LOS PRECIOS YA TIENEN IVA

						let with_discount = 0.0;
						let price = 0.0;

						let value_urgent = parseInt(percentage_urgent_work_order);

						//HAY QUE QUITAR EL IVA
						let valueIva = 1 + (data.value_iva / 100);

						if (data.is_canceled) {
							if (data.id_exam !== null) {
								price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										price = data.workOrderCurves.price;
									}
								}
								else {
									price = data.price;
								}
							}
						}
						else {
							if (data.id_exam !== null) {
								price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										price = data.workOrderCurves.price;
									}
								}
								else {
									price = data.price;
								}
							}
						}

						price = (price / valueIva);
						price = format_trunc_number(price, _take_number_decimals);


						if (data.is_urgent) {
							if (data.price_urgent > 0) {
								price = (price + data.price_urgent);
								price = format_trunc_number(price, _take_number_decimals);

							}
							else {
								let with_urgent = (price * value_urgent) / 100;
								data.price_urgent = !isNaN(with_urgent) ? Math.round(parseFloat(with_urgent.toFixed(2))) : 0;
								price = (price + with_urgent);
								price = format_trunc_number(price, _take_number_decimals);

							}
						}
						else {
							data.price_urgent = 0;
						}
						if (data.valid_commont == true) {
							if (data.auto_redeem == true && checkDiscount == true) {
								if (data.percentage_discount !== undefined && data.percentage_discount) {
									with_discount = price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}

							} else if (data.auto_redeem == false && checkDiscount == true) {

								if (data.percentage_discount !== undefined && data.percentage_discount) {
									with_discount = price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
							} else if (data.auto_redeem == true && checkDiscount == false) {

								if (data.amount_discount != undefined) {
									if (data.percentage_discount !== undefined && data.percentage_discount) {
										with_discount = price * (data.percentage_discount / 100);

										arrayCommonDiscounts.push(data.id_common_discount);
									}
								}
							} else {

								if (data.percentage_discount !== undefined && data.percentage_discount) {
									with_discount = price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
							}
						} else {
							arrayCommonDiscounts.push(data.id_common_discount);
						}

						let with_iva = (((price - with_discount) * data.value_iva) / 100);
						////console.log(with_iva);

						if (data.is_canceled) {
							total_cancellations += ((price - with_discount) + with_iva);
						}
						else {
							subtotal += price;
							descuento += (with_discount);
							total_iva = format_trunc_number(with_iva, _take_number_decimals);

							//total_iva += with_iva;
						}

						break;

					case false://LOS PRECIOS NO TIENEN IVA
						let desc = 0.0;
						let without_price = 0;
						let value_urgent_2 = parseInt(percentage_urgent_work_order);

						if (data.is_canceled) {
							if (data.id_exam !== null) {
								without_price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										without_price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										without_price = data.workOrderCurves.price;
									}
								}
								else {
									without_price = data.price;
								}
							}
						}
						else {
							if (data.id_exam !== null) {
								without_price = data.price;
							}
							else {
								if (data.is_curve) {
									if (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null) {
										without_price = data.workOrderCurves_canceled.price;
									}
									else if (data.workOrderCurves !== undefined && data.workOrderCurves !== null) {
										without_price = data.workOrderCurves.price;
									}
								}
								else {
									without_price = data.price;
								}
							}
						}

						if (data.is_urgent) {
							if (data.price_urgent > 0) {
								without_price = (without_price + data.price_urgent);
							}
							else {
								let urgent = ((without_price * value_urgent_2) / 100);
								without_price = (without_price + urgent);

								data.price_urgent = !isNaN(urgent) ? (parseFloat(urgent.toFixed(2))) : 0;

							}
						}
						else {
							data.price_urgent = 0;
						}
						if (data.valid_commont == true) {
							if (data.auto_redeem == true && checkDiscount == true) {

								if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
									desc = without_price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
								////console.log("1");

							} else if (data.auto_redeem == false && checkDiscount == true) {

								if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
									desc = without_price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
								////console.log("2");
							} else if (data.auto_redeem == true && checkDiscount == false) {
								if (data.amount_discount != undefined) {
									if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
										desc = without_price * (data.percentage_discount / 100);
										arrayCommonDiscounts.push(data.id_common_discount);
									}
								}


							} else {

								if (data.percentage_discount !== undefined && data.percentage_discount !== 0) {
									desc = without_price * (data.percentage_discount / 100);

									arrayCommonDiscounts.push(data.id_common_discount);
								}
							}
						} else {
							arrayCommonDiscounts.push(data.id_common_discount);
						}


						let iva = Math.round(((without_price - desc) * data.value_iva) / 100);
						if (data.is_canceled) {
							total_cancellations += ((without_price - desc) + iva);
						}
						else if (data.id_profile !== null && (data.workOrderCurves_canceled !== undefined && data.workOrderCurves_canceled !== null)) {
							total_cancellations += ((without_price - desc) + iva);
						}
						else {
							subtotal += without_price;
							descuento += desc;
							total_iva = format_trunc_number(iva, _take_number_decimals);

							//total_iva += iva;
						}
						break;
				}
			});
		}

		total = Math.round((subtotal - descuento) + total_iva);

		descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
		subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
		total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
		total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
		total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

		setTotalW(total);
		setSubTotalW(subtotal);
		setTotalDiscountW(descuento);
		setTotalTaxesW(total_iva);
		setTotalDevolutionsW(total_cancellations);
		setArrayCommonDiscounts(arrayCommonDiscounts);


	}, [formWorkOrder, devolutionsState, checkDiscount]);


	useMemo(() => {

		if (totalW === 0) {
			setMoneyEntered(0);
			setShowMessageW({
				labelMoney: "",
				amount: 0
			});

			setEnableBtnSaveWorkorder(false);
		}
		else {
			if (moneyEntered >= totalW) {

				let change = (parseFloat(moneyEntered) - totalW);

				setShowMessageW({
					labelMoney: "Cambio",
					amount: !isNaN(change) ? change.toFixed(2) : 0
				});

				setEnableBtnSaveWorkorder(false);


			}
			else {

				let change = (totalW - parseFloat(moneyEntered));
				//console.log(totalW + "asdasd");

				setShowMessageW({
					labelMoney: "Faltan",
					amount: !isNaN(change) ? change.toFixed(2) : 0
				});
				if (change.toFixed(2) > 0) {
					////console.log("lle");
					setEnableBtnSaveWorkorder(true);
				} else {
					////console.log("ll2e");
					setEnableBtnSaveWorkorder(false);
				}
				// setEnableBtnSaveWorkorder(true);


			}
		}
	}, [totalW, moneyEntered]);
	//#endregion WORK ORDER

	//#region PATIENT
	//#region PATIENT MODEL
	const [informationPatient, setInformationPatient] = useState({
		name_patient: "",
		birthday: null,
		phone: null,
		gender: null,
		age: null,
		phone: null,
		email: null,
		curp: null,
		membership: null,
		nim: null
	})
	//#endregion PATIENT MODEL
	//#endregion PATIENT
	//regin descuento
	const clearDiscount = async () => {

		if (checkDiscount == true) {
			setcheckDiscount(false);
			//console.log(false);
		} else {

			setcheckDiscount(true);
			//console.log(true);
		}
	}
	//endregion
	//#region DOCTOR
	//#region DOCTOR MODEL
	const [doctorState, setDoctorState] = useState([]);
	//#endregion DOCTOR MODEL

	//#region DOCTOR METHODS
	const handleChangeDoctor = (e) => {  
		let doctor = e.target.value;
		//console.log('Doctor -> ',doctor)
		let tmpDoctor = formWorkOrder.listDoctors;
		//console.log(tmpDoctor);

		if (tmpDoctor.length > doctor.length) {
			tmpDoctor.map(d => {
				if (!doctor.find(x => x.value === d.id_doctor)) {
					return d.id_doctor;
				}
			});

			handleUpdateValuesWorkOrder({
				...formWorkOrder,
				listDoctors: tmpDoctor
			});
		}

		if (doctor.length > 0) {
			doctor.forEach(d => {
				let idDoctor = d.value;

				handleGetInformationDoctor(idDoctor);
			});
		}
		else {
			handleUpdateValuesWorkOrder({
				...formWorkOrder,
				listDoctors: []
			});
		}
	}


	const handleGetInformationDoctor = async (doctor) => {
		const listTmp = await handleRequest("GET", `Doctors/FindDoctorId/${doctor}`, 'Doctores');
		//console.log('Doctor: 1 ->')
		//console.log(listTmp)
		if (listTmp !== null) {

			let tmpDataDoctor = formWorkOrder.listDoctors;
			let listTmpDataDoctor = [];

			if (tmpDataDoctor.find(x => x.id_doctor === doctor)) {
				//console.log('Ando acá, en el find: ')

				listTmpDataDoctor = tmpDataDoctor.map(d => {
					if (d.id_doctor === doctor) {
						d.name_doctor = listTmp.name;
						d.specialty = listTmp.name_specialty;
						d.email = listTmp.email;
					}

					return d;

				})
				//console.log(listTmpDataDoctor);
			}
			else {
				//console.log('Estoy set los valores: ')
				listTmpDataDoctor = [...tmpDataDoctor];
				// listTmpDataDoctor = tmpDataDoctor;

				// listTmpDataDoctor.push({
				// 	id_work_order_doctor: 0,
				// 	id_doctor: listTmp.id_doctor,
				// 	name_doctor: listTmp.name,
				// 	specialty: listTmp.name_specialty,
				// 	email: listTmp.email
				// });
			}

			handleUpdateValuesWorkOrder({
				...formWorkOrder,
				listDoctors: listTmpDataDoctor
			})
			//console.log('listDoctors: ',listDoctors); 
		}
	}


	//#endregion
	//#endregion

	//#region SPECIAL DISCOUNTS
	//#region SPECIAL DISCOUNTS MODEL
	const [specialDiscount, setSpecialDiscount] = useState(null);
	//#endregion

	//#region SPECIAL DISCOUNTS METHODS
	const getSpecialDiscount = async (id_special_discount) => {
		let listTmp = await handleRequest("GET", `SpecialDiscount/GetInformationById/${id_special_discount}`);

		if (listTmp !== null) {
			setSpecialDiscount(listTmp);
		}
	}
	//#endregion SPECIAL DISCOUNTS METHODS
	//#endregion SPECIAL DISCOUNTS

	//#region COMMON DISCOUNTS        
	//#region COMMON DISCOUNTS METHODS
	const handleModalListCommonDiscounts = async () => {
		if (specialDiscount !== null) {
			setArrayNameDiscount([`Descuento especial: COD-00${specialDiscount.id_special_discount}`]);
			toggleCommonDiscount();
		}
		else if (id_company !== 0 && id_company !== null) {
			let convenios = [];

			listTest.forEach(o => {
				if (o.id_agreement !== null && o.id_agreement > 0) {
					convenios.push(`Convenio: Contrato - ${o.contract_number}`);
				}
			});

			setArrayNameDiscount(convenios);
			toggleCommonDiscount();
		}
		else {
			let data = arrayCommonDiscounts;
			const data_send = data.filter(discount => discount != null);
			if (data.length > 0) {
				let listTmp = await handleRequest('POST', 'CommonDiscount/GetNameList', 'Descuentos', data_send);
				//console.log(listTmp + "res");
				if (listTmp !== null) {
					let array = [];

					// Object.values(listTmp).map(obj => {
					// 	array.push(obj);
					// });

					// setArrayNameDiscount(array);
					listTmp.forEach(obj => {
						array.push(obj);
					});

					setArrayNameDiscount(array);
				}

				toggleCommonDiscount();
			}
		}
	}
	// const handleModalListCommonDiscounts = async () => {
	// 	if (specialDiscount !== null) {
	// 		setArrayNameDiscount([`Descuento especial: COD-00${specialDiscount.id_special_discount}`]);
	// 		toggleCommonDiscount();
	// 	}
	// 	else if (checkTypeClient === 3) {
	// 		let convenios = [];

	// 		listTest.forEach(element => {
	// 			if (element.id_agreement !== null && element.id_agreement > 0) {
	// 				convenios.push(`Convenio: Contrato - ${element.contract_number}`);
	// 			}
	// 		});

	// 		setArrayNameDiscount(convenios);
	// 		toggleCommonDiscount();
	// 	}
	// 	else {
	// 		let data = arrayCommonDiscounts;


	// 		let listTmp = await handleRequest('POST', 'CommonDiscount/GetNameList', 'Descuentos', data);


	// 		if (listTmp !== null) {
	// 			let array = [];

	// 			// Object.values(listTmp).map(obj => {
	// 			//     array.push(obj);
	// 			// })
	// 			listTmp.forEach(obj => {
	// 				array.push(obj);
	// 			});

	// 			setArrayNameDiscount(array);

	// 		}

	// 		toggleCommonDiscount();
	// 	}
	// }
	//#endregion
	//#endregion
	//LIQUIDAR WORK ORDER

	const handleModalLiquided = (index, amount_liquided) => {

		formLiquidedWO.id_method_liquided = index;
		formLiquidedWO.amount_liq = amount_liquided;
		toggleLiquidedWO();

	}
	const validatesaveLiquided = () => {
		let statusValidation = false;
		let newValidations = {};

		if (formLiquidedWO.id_method_pay == "" || formLiquidedWO.id_method_pay == 0) {
			newValidations = {
				...newValidations,
				id_method_pay_valid: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				id_method_pay_valid: false
			};
		}
		if (formLiquidedWO.amount_liquided < 1 || percentagereturn == undefined) {

			newValidations = {
				...newValidations,
				amount_liquided_valid: true
			};
			statusValidation = true;
		} else {
			newValidations = {
				...newValidations,
				amount_liquided_valid: false
			}
		}
		setvalidationsliquided(newValidations);
		return statusValidation;
	}
	const saveLiquided = async (e) => {
		setLoading(true);
		if (validatesaveLiquided()) {
			setLoading(false);
			return;
		}

		let mData = {
			...{
				...formLiquidedWO,
				id_method_pay: id_method_pay,
				amount_liquided: amount_liquided,
				id_method_liquided: id_method_liquided
			},

		};
		let mValues = JSON.stringify(mData);
		//(mValues);

		let requestOptions = {
			method: 'PUT',
			body: mValues
		};

		const respuesta = await sendRequest(requestOptions, "WorkOrder/LiquidedWorkOrder");

		if (respuesta.code === 200) {

			createSweet("create", "success", "Exito!", "Liquidación realizada correctamente");
			setLoading(false);
			//window.location.reload(true);
			if (id_work_order !== undefined) {

				setFirstLoadingWorkOrder(true);

				let tmp_id_work_order = Buffer.from(id_work_order, 'base64');

				handleGetWorkOrder(tmp_id_work_order, id_commercial_line);
				setModalLiquidedWO(false);
			}


		}
		else if (respuesta.code === 401) {
			validarSesion(history, respuesta.code, saveLiquided);
			formLiquidedWO.id_method_pay = 1;
			formLiquidedWO.amount_liquided = 0;

		}
		else if (respuesta.code === 500) {
			createSweet("error", "error", "Liquidación", "Ocurrio un problema en el servidor");

		}
		else {
			createSweet("error", "warning", "Liquidación", respuesta.data.msg);

		}
	}
	//END
	//ENABLE COMmENTS
	const enableComments = () => {
		if (checkComments == false) {
			setcheckComments(true);
			SetShowClassNameC("");
		} else {
			setcheckComments(false);
			SetShowClassNameC("d-none mb-3");
		}

	}

	//GLOBAl REQUEST
	const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
		let requestOptions = {
			method: method
		};

		if (body !== null) {
			requestOptions = {
				...requestOptions,
				body: JSON.stringify(body)
			};
		}

		const response = await sendRequest(requestOptions, methodUrl);

		if (response.code === 200) {
			return response.data;
		}
		else if (response.code === 401) {
			validarSesion(history, response.code, handleRequest);
		}
		else if (response.code === 500) {
			createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
		}
		else {
			createSweet("warning", "warning", nameMethod, response.data.msg);
		}

		return null;
	}

	const handleCalculateAge = (mBirthday) => {
		var date = new Date();
		let birthdayD = new Date(mBirthday);

		let age = date.getFullYear() - birthdayD.getFullYear();

		let m = date.getMonth() - birthdayD.getMonth();

		if (age === 0 && m > 0) {
			return m + `${m === 1 ? " mes" : " meses"}`;
		}
		else if (m < 0 || (m === 0 && date.getDate() < birthdayD.getDate())) {
			age--;
		}

		return age + " año(s)";
	}

	const number_format = (cantidad, decimals) => {
		cantidad += '';
		cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

		decimals = decimals || 0;

		if (isNaN(cantidad) || cantidad === 0) {
			return parseFloat(0).toFixed(decimals);
		}

		cantidad = '' + cantidad.toFixed(decimals);

		let cantidad_parts = cantidad.split('.'),
			regexp = /(\d+)(\d{3})/;

		while (regexp.test(cantidad_parts[0]))
			cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

		return cantidad_parts.join('.');
	}

	//VALIDATIONS
	const validationTest = (id_exam, id_profile = null, listExams = []) => {
		let statusValidation = false;
		let newValidations = {};
		let tmpDevolutions = devolutionsState;
		let listNewDevolutions = [];

		if (formWorkOrder.listTest.length > 0) {
			let findTest = formWorkOrder.listTest.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

			if (findTest) {
				if (findTest.exist) {
					if (findTest.is_canceled) {
						findTest.is_canceled = false;

						listNewDevolutions = tmpDevolutions.listTestDevolution.filter(d => {
							if ((id_exam !== null && d.id_work_order_exam === findTest.id_work_order_exam) || (id_profile !== null && d.id_work_order_profile === findTest.id_work_order_profile)) {
								return false;
							}
							else {
								return true;
							}
						})

						setDevolutionsState({
							...devolutionsState,
							listTestDevolution: listNewDevolutions
						});

						newValidations = {
							...newValidations,
							valid_repeat_test: true,
							message_validation: `${id_exam !== null ? 'Se reingreso un examen: ' + findTest.name : 'Se reingreso un perfil: ' + findTest.name}`
						};

						statusValidation = true;

					}
					else {
						newValidations = {
							...newValidations,
							valid_repeat_test: true,
							message_validation: `${id_exam !== null ? 'El examen: ' + findTest.name + ', ya existe' : 'El perfil: ' + findTest.name + ', ya existe'}`
						};

						statusValidation = true;
					}
				}
				else {
					newValidations = {
						...newValidations,
						valid_repeat_test: true,
						message_validation: `${id_exam !== null ? 'El examen: ' + findTest.name + ', ya existe' : 'El perfil: ' + findTest.name + ', ya existe'}`
					};

					statusValidation = true;
				}
			}
			else {
				if (id_exam !== null) {
					let listProfiles = formWorkOrder.listTest.filter(x => x.id_profile !== null && !x.is_canceled);

					listProfiles.forEach(p => {
						if (p.listExams.find(x => x.id_exam === parseInt(id_exam))) {
							newValidations = {
								valid_repeat_test: true,
								message_validation: "El examen seleccionado ya se encuentra en el siguiente perfil: " + p.name
							};

							statusValidation = true;

							return true;
						}
					})
				}
				else if (id_exam === null && id_profile !== null) {
					let listExamsW = formWorkOrder.listTest.filter(x => x.id_exam !== null && !x.is_canceled);

					listExamsW.forEach(e => {
						if (listExams.find(x => x.id_exam === parseInt(e.id_exam))) {
							newValidations = {
								valid_repeat_test: true,
								message_validation: "Tienes exámenes repetidos: " + e.name + " al seleccionar el perfil"
							};
							statusValidation = true;

							return true;
						}
					});
				}
			}
			setValidationsTest({ ...newValidations });
		}

		return statusValidation;
	}

	const validDevolution = () => {
		let statusValidation = false;
		let newValidations = {};

		if (devolutionsState.id_return_reason <= 0 || devolutionsState.id_return_reason === null) {
			newValidations = {
				...newValidations,
				devolutionValid: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				devolutionValid: false
			};
		}

		if (devolutionsState.listTestDevolution.length === 0) {
			newValidations = {
				...newValidations,
				testDevolution: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				testDevolution: false
			};
		}

		setValidateDevolutions(newValidations);
		return statusValidation;
	}

	const validWorkOrder = () => {
		let statusValidation = false;
		let newValidations = {};

		if (formWorkOrder.id_printer_point === 0 && formWorkOrder.id_printer_point.value === undefined) {
			newValidations = {
				...newValidations,
				id_printer_point_valid: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				id_printer_point_valid: false
			};
		}

		if (formWorkOrder.listTest.length === 0) {
			newValidations = {
				...newValidations,
				listTest_valid: true
			};

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				listTest_valid: false
			};
		}

		if (formWorkOrder.listPayMethods.length > 0) {
			formWorkOrder.listPayMethods.forEach(obj => {

				let id_pay_method = obj.id_pay_method !== '' ? parseInt(obj.id_pay_method) : obj.id_pay_method;
				let amount = obj.amount !== '' ? parseFloat(obj.amount) : obj.amount;

				if ((id_pay_method === '' || id_pay_method === 0) || (amount === '' || amount === 0.0)) {
					newValidations = {
						...newValidations,
						listPayMethods_valid: true
					};

					statusValidation = true;

					return;
				}
			})
		}
		else {
			newValidations = {
				...newValidations,
				listPayMethods_valid: true
			};

			statusValidation = true;
		}

		if (devolutionsState.listTestDevolution.length > 0 && devolutionsState.id_return_reason === 0) {
			newValidations = {
				...newValidations,
				validDevolutions: true
			};

			createSweet("warning", "warning", "Devoluciones", "Te falta ingresar un tipo de devolución");

			statusValidation = true;
		}
		else {
			newValidations = {
				...newValidations,
				validDevolution: false
			};
		}

		setValidationsWorkOrder(newValidations);
		return statusValidation;
	}

	return {
		//WORK ORDER 
		// WORK ORDER MODEL
		id_company,
		id_printer_point, observations, observations_sample,
		print_results, send_whatsapp, send_doctor, results_partial,
		send_email, check_invoice, name_company, listTest,
		listIndicationsExams, listIndicationsProfile, listPayMethods,
		firstLoadingWorkOrder, searchExamProfileState, devolutionsState,
		totalW, subTotalW, totalDiscountW, totalTaxesW, totalDevolutionsW,
		showMessageW, modalViewExamsP, viewExamsProfile, enableBtnSaveWorkorder, enableBtnCancelWorkorder,
		validationsWorkOrder, loading,
		//WORK ORDER AUTORIZE
		user_autorize, password_autorize, modalAutorizeWO, validationsAutorize,
		//LIQUIDAR
		modalLiquidedWO, toggleLiquidedWO, handleModalLiquided, id_method_liquided, validatesaveLiquided, validationsliquided,
		amount_liquided, handleInputChangeLiquided, saveLiquided, id_method_pay,
		id_return_reason_woc, percentage_return, totalDevolutionsWOC, showMessageWOC, percentagereturn, amount_liq,
		//METHODS WORK ORDER AUTORIZE
		toggleAutorizeWO, handleModalCancelWorkOrder, saveAutorized, validate_mont, handleCheckForwarded, handleBack,
		//LIST PRELOADED VALUES
		listPrinterPoint, listPayMethod, listReturnReason,
		times,

		//WORK ORDER METHODS
		handleInputChangeWorkOrder, handleSelectValuesWorkOrder, handleInputChangeAuthorize,
		handleSelectExamChange, removeExamProfile, handleCheckSpecimen,
		handleCheckUrgent, changeSelectSamplings, changeValueTimeSampling,
		handleAddPayMethodInputs, handleChangePayMethod, handleGiveChange,
		handleDeletePayMethod, handleViewExamsProfile, toggleModalViewExams,
		saveWorkOrder,

		//PATIENT
		//PATIENT MODEL
		informationPatient,

		//DOCTOR
		//DOCTOR MODEL
		doctorState, selectListDoctors,
		listDoctors,

		//DOCTOR METHODS
		handleChangeDoctor,

		//RETURN REASON

		//RETURN REASON METHODS
		handleCreateDevolution, handleChangeReturnReason,
		saveReturnReason, toggleDevolutions, modalDevolutions,
		validateDevolutions, modalTitle,

		//COMMON DISCOUNTS
		//VALUES 
		arrayNameDiscount, arrayCommonDiscounts, valid_commont,

		//METHODS
		handleModalListCommonDiscounts, modalCommonDiscount, toggleCommonDiscount,
		//comentario
		observations_general, ShowClassNameC, enableComments,
		//COMMON DISCOUNTS
		checkDiscount, clearDiscount,
		//VALIDA SI ESTA TOTALMENTE PAGA LA ORDEN
		checkpaid,

		//ALTERNATIVE METHODS
		number_format, canceldevolution
	}
}
