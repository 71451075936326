import { Select } from '@mui/material';
import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Form, Row, Label, Input, FormFeedback, FormGroup, FormText, InputGroup, InputGroupAddon, InputGroupText, Fragment, PlaceHolderOption } from 'reactstrap';

export const ModalLiquidacion = (props) => {
    const {
        modal, toggle, handleInputChangeLiquided, saveLiquided, listPayMethod, loading,
        validatesaveLiquided, id_method_liquided, amount_liquided, id_method_pay, amount_liq,
    } = props;

    const {
        id_method_pay_valid,
        amount_liquided_valid,

    } = validatesaveLiquided;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                Liquidar Saldo
            </ModalHeader>
            <Form className="form theme-form" role="form" onSubmit="">
                <ModalBody>
                    <Row>
                        <Col md="6" className='d-none'>
                            <FormGroup>
                                <Input
                                    type="text" name='id_method_liquided'
                                    value={id_method_liquided}
                                    required={false}
                                    hidden={true}
                                    onChange={handleInputChangeLiquided}
                                />
                                <FormFeedback>Elija un metodo de pago</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Método de pago: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type='select'
                                    name="id_method_pay"
                                    required={true}
                                    invalid={id_method_pay_valid}
                                    onChange={handleInputChangeLiquided}
                                    className="form-control form-control-sm input-air-primary p-1"
                                    defaultValue={id_method_pay}
                                >
                                    <option value={0}>Selecciona una opción</option>

                                    {
                                        listPayMethod.length > 0 && listPayMethod.map(pm => {
                                            if (pm.name != "CREDITO") {
                                                return (
                                                    <option key={'method-' + pm.id_pay_method} value={pm.id_pay_method}>{`${pm.abbreviation} - ${pm.name}`}</option>
                                                )
                                            }


                                        })
                                    }
                                </Input>
                                <FormFeedback>Elija un metodo de pago</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Monto: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="number" name='amount_liquided'
                                    value={amount_liquided}
                                    required={true}
                                    invalid={amount_liquided_valid}
                                    placeholder="$ 0.0" step="any" className='form-control input-air-primary form-control-sm'
                                    onChange={handleInputChangeLiquided}
                                />
                                <FormFeedback>Escribe el monto a liquidar</FormFeedback>
                            </FormGroup>

                        </Col>
                        {
                            
                        }
                        <Col md="12">
                            <h6 className={amount_liq < amount_liquided ? "text-center" : "d-none"}><b>Cambio ${amount_liq < amount_liquided ? (amount_liquided - amount_liq) : ""}</b></h6>
                            <h6 className={amount_liq > amount_liquided ? "text-center" : "d-none"}><b>Faltan ${amount_liq > amount_liquided ? (amount_liq - amount_liquided) : ""}</b></h6>
                        </Col>
                    </Row>

                </ModalBody>
            </Form>
            <ModalFooter>
                <Button
                    size="sm"
                    outline color="primary"
                    type="submit"
                    onClick={saveLiquided}
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Aceptar
                </Button>
                <Button outline color="primary" type="button" onClick={toggle}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}
