import React from 'react';
import { Modal, Col, Row, Label, Button, Input, Media, ModalBody, Table, ModalFooter,Card } from 'reactstrap';
import 'animate.css';
//import {ComponentMic } from '../sending/ComponentMic'



export const ModalMicro = (props) => {

    const {
        typeMicro = "Ismicro", modalMicro, closeModalMicro, micDetail,
        temperature_send, temperature_corrected_send, comply_temperature_send, identification_send, container_send, quantity_send,
        handleInputChangeMic, list_mic_sample, handleChangeMic, saveMicDetailSend, expiration_send, mark_and_lot_send
    } = props;


    return (
        <Modal isOpen={modalMicro} toggle={closeModalMicro} backdrop='static' keyboard={false} centered={true} className="modal-xl" >
            {
                typeMicro === "Ismicro" ?
                    <>
                        <Col sm="12" style={{ textAlign: "center" }} className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >

                                <Row >
                                    {
                                        list_mic_sample !== undefined && list_mic_sample.map((_mic, _key) => {
                                            return (
                                                <Col sm="6" key={`curveTr-${_key}`}>
                                                    <Col sm="12" className='bg-primary mb-2' style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}>
                                                        <label className='f-14'>{_mic.nameMic}</label> <br />
                                                        <label className='m-1'>{_mic.folio_mic} / {_mic.id_mic_type_sample}</label>
                                                    </Col>
                                                    <Table className='table table-striped table-sm'  >

                                                        <tr>
                                                            <th>Temperatura de recepcion</th>
                                                            <th>
                                                                <Input type='text' name="temperature_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    autoComplete='off' className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Temperatura corregida</th>
                                                            <th>
                                                                <Input type='text' name="temperature_corrected_send" autoComplete='off'
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Cumple temperatura:</th>
                                                            <th>
                                                                <Input type='text' name="comply_temperature_send" autoComplete='off'
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Identificación:</th>
                                                            <th>
                                                                <Input type='text' name="identification_send" autoComplete='off'
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Marca y Lote:</th>
                                                            <th>
                                                                <Input type='text' name="mark_and_lot_send" autoComplete='off'
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Caducidad: </th>
                                                            <th>
                                                                <Input type='text' name="expiration_send" autoComplete='off'
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Contenedor</th>
                                                            <th>
                                                                <Input type='text' name="container_send" autoComplete='off'
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Cantidad</th>
                                                            <th>
                                                                <Input type='text' name="quantity_send" autoComplete='off'
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className='form-control form-control-sm input-air-primary' />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Observaciones</th>
                                                            <th>
                                                                <textarea
                                                                    className='form-control form-control-sm input-air-primary'
                                                                    name="comments"
                                                                    rows="3"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                >
                                                                </textarea>
                                                            </th>
                                                        </tr>

                                                    </Table>

                                                   

                                                </Col>
                                            )
                                        })
                                    }
                                </Row>

                            </ModalBody>
                            <ModalFooter>
                                <Button size='sm' outline color='primary' type="button" onClick={saveMicDetailSend} >
                                    {"Aceptar"}
                                </Button>
                                {/* <Button size='sm' className='btn-air-light' outline color='danger' type='button' >Cancelar</Button> */}
                            </ModalFooter>
                        </Col>
                    </>
                    :
                    ""
            }
        </Modal >
    )
}