import { useEffect, useState } from 'react';
import { sendRequest } from '../../hooks/requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from 'react-router';
import { useForm } from '../../hooks/forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { usePagination } from '../pagination/usePagination';



export const usePrecodedText = ({ activeTab }) => {
    const history = useHistory();

    useEffect(() => {
        getTypePrecodedTexts();
        getPrecodedTexts();
    }, [history.location]);

    useEffect(() => {
        if (activeTab === 2) {
            getTypePrecodedTexts();
        }
    }, [activeTab]);

    const [typePrecodedTexts, setTypePrecodedTexts] = useState([]);
    const [precodedTexts, setPrecodedTexts] = useState([]);
    const [precodedTextId, setPrecodedTextId] = useState(0);
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const toggle = () => {
        setModal(!modal);
    }

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        id_type_precoded_text: 0,
        text: ""
    });

    const [validaciones, setValidaciones] = useState({
        id_type_precoded_text: false,
        text: false
    });

    const { id_type_precoded_text, text } = formValues;

 

    const getTypePrecodedTexts = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "TypePrecodedTexts");
        if (respuesta.code === 200) {
            setTypePrecodedTexts(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getTypePrecodedTexts);
        }
    }

    const getPrecodedTexts = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "PrecodedTexts");
        if (respuesta.code === 200) {

            let listprecod = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((element,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listprecod.push({
                        id_precoded_text: element.id_precoded_text,
                        id_type_precoded_text: element.id_type_precoded_text,
                        type_name: element.type_name,
                        text: element.text,
                        visibleTable:visibleTable
                    }) 

                    
                });
                
            }
            setPrecodedTexts(listprecod);
            setTypePrecod(listprecod);


          //  setPrecodedTexts(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getPrecodedTexts);
        }
    }




    const handleCreate = () => {
        handleUpdateValues({
            id_type_precoded_text: 0,
            text: ""
        });
        setPrecodedTextId(0);
        setMethod("create");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) return;

        setLoading(true);

        let raw = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "PrecodedTexts");

        if (respuesta.code === 200) {
            let newPrecodedTexts = precodedTexts;
            let typePrecodedText = typePrecodedTexts.find(t => t.id_type_precoded_text === parseInt(id_type_precoded_text));
            newPrecodedTexts.push({
                id_precoded_text: respuesta.data.id_precoded_text,
                id_type_precoded_text: id_type_precoded_text,
                type_name: typePrecodedText.name,
                text: text,
            });
            setPrecodedTexts(newPrecodedTexts);
            reset();
            setLoading(false);
            createSweet("create", "success");
            toggle();
        } else {
            //console.log("Error al crear el texto precodificado...");
            validarSesion(history, respuesta.code, saveCreate);
        }
    }

    const handleUpdate = (id_precoded_text) => {
        let precodedText = precodedTexts.find(p => p.id_precoded_text === parseInt(id_precoded_text));
        handleUpdateValues({
            id_type_precoded_text: precodedText.id_type_precoded_text,
            text: precodedText.text
        });
        setPrecodedTextId(id_precoded_text);
        setMethod("update");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        let body = {
            id_precoded_text: precodedTextId,
            ...{ ...formValues }
        }
        setLoading(true);
        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'PUT',
            body: raw,
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "PrecodedTexts");
        if (respuesta.code === 200) {
            let typePrecodedText = typePrecodedTexts.find(t => t.id_type_precoded_text === parseInt(id_type_precoded_text));
            let newPrecodedTexts = precodedTexts.map(function (obj) {
                if (obj.id_precoded_text === precodedTextId) {
                    obj.id_type_precoded_text = id_type_precoded_text;
                    obj.text = text;
                    obj.type_name = typePrecodedText.name
                }
                return obj;
            });
            setPrecodedTexts(newPrecodedTexts);
            setLoading(false);
            createSweet("update", "success");
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveUpdate);
            //console.log("Error");
        }
    }

    const handleDelete = (id_precoded_text) => {
        let precodedText = precodedTexts.find(t => t.id_precoded_text === id_precoded_text);
        handleUpdateValues({
            text: precodedText.text
        });
        setPrecodedTextId(id_precoded_text);
        setMethod("delete");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        let requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, `PrecodedTexts/${precodedTextId}`);
        if (respuesta.code === 200) {
            let newPrecodedTexts = precodedTexts.filter(t => t.id_precoded_text !== precodedTextId);
            setPrecodedTexts(newPrecodedTexts);
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveDelete);
            //console.log("Error");
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        if (formValues.id_type_precoded_text === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_type_precoded_text: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                id_type_precoded_text: false
            }
        }
        if (formValues.text.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                text: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                text: false
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }


    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(precodedTexts.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listPrecodedTexts = [];
        precodedTexts.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listPrecodedTexts.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setPrecodedTexts(listPrecodedTexts);

    }, [totalPageCount, currentPage]);


    const [typePrecod, setTypePrecod] = useState([])


    const handleSearchTypePrecod = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        typePrecod.forEach(element => {            

            let cadena = element.type_name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setPrecodedTexts(newMethod);
    }

    





    return {
        precodedTexts, typePrecodedTexts, method,
        id_type_precoded_text, text, handleInputChange, validaciones,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
         nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchTypePrecod

    };
}
