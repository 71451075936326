import { useState, useEffect, useRef } from "react"
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { ConfigServer } from "../../data/config";
import moment from 'moment';
import { usePagination } from '../pagination/usePagination';
import { useModal } from "react-morphing-modal";
import { usePaginationCash } from "../pagination/usePaginationCash";
import * as XLSX from 'xlsx';



export const useCash = () => {
    let id_branch = localStorage.getItem('branchId');


    const [viewTab, setViewTab] = useState(1);
    const [data, setData] = useState([]);
    const [dataAmount, setDataAmount] = useState([])
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    //indicadores
    const [indicador1, setIndicador1] = useState([]);
    const [countPatient, setCountPatient] = useState(0);
    const [cancell, setCancell] = useState(0);
    const [discountT, setDiscountT] = useState(0);
    const [AmountTotal, setAmountTotal] = useState(0);
    const [orderFirst, setOrderFirst] = useState(0);
    const [orderLast, setOrderLast] = useState(0);
    const [idCashFlow, setIdCashFlow] = useState(0);
    const [totalCancell, setTotalCancell] = useState(0);
    const [realCash, setRealCash] = useState([]);
    const [creditTotal, setCreditTotal] = useState(0);
    //historic cash flow
    const [dataHistoric, setDataHistoric] = useState([]);
    const [dateInit, setDateInit] = useState(new moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(new moment().format('YYYY-MM-DD'));
    const [typeModal, setTypeModal] = useState("ViewCas");
    const [modalC, setModalC] = useState(false);
    const [typeModalCredit, setTypeModalCredit] = useState("ViewCredit");
    const [modalCredit, setModalCredit] = useState(false);
    const [dataCredit, setDataCredit] = useState([]);
    const numberItems = 8;
    const [typeAmoun, setTypeAmoun] = useState([]);
    const [amountfin, setAmountfin] = useState([]);
    const [totalPaymets, setTotalPaymets] = useState(0);
    const numberCash = 8;
    const [branches, setBranches] = useState([]);


    useEffect(() => {
        getAmount();
        getCreditPending();
        handleHistoric();
        getDetailCreditCash();
        getBranches();
    }, []);

    useEffect(() => {
        if (idCashFlow > 0) {
            printTicket(idCashFlow);
        }
    }, [idCashFlow]);

    const toggleCash = () => {
        setModalC(!modalC);
    }

    const toggleCredit = () => {
        setModalCredit(!modalCredit);
    }


    const [formCash, handleInputChangeCash, resetCash, handleUpdateValuesCash, handleSelectValuesCash] = useForm({
        capture_amount: "",
        input2: "",
        input3: "",
        input4: "",
        comments: "",
        mDateRange1: "",
        mDateRange2: "",
        mSelectBranch: 0,
        ids_users: 0,
        ids_branches: 0,
        type_company: null

    });


    const {
        capture_amount,
        input2,
        input3,
        input4,
        comments,
        mDateRange1,
        mDateRange2,
        mSelectBranch,
        type_company
    } = formCash;



    //#region validations
    const [validationsSG, setvalidationsSG] = useState({
        capture_amount: false,
    });
    //#endregion validations

    useEffect(() => {
        if (branches.length > 0) {
            let _find = branches.find(x => x.value == parseInt(id_branch))
            let _findName = branches.find(x => x.label == _find)
            handleUpdateValuesCash({
                ...formCash,
                mSelectBranch: _find
            });

        }
    }, [branches]);

    const getBranches = async () => {
        let _listTemp = await handleRequest("GET", "Branch/List", "Sucursal");
        if (_listTemp !== null && _listTemp.length > 0) {
            let _newBranches = [];
            let _namesBranches = [];
            _listTemp.forEach((_obj) => {
                let _validB = _obj.code + "-" + _obj.name
                _newBranches.push({
                    value: _obj.id_branch,
                    label: _validB
                });
                _namesBranches.push({
                    label: _validB
                })
            });
            setBranches(_newBranches);
            setDataBranch(_namesBranches)
        }
    }


    //#region modal
    const toggle = () => {
        setModal(!modal);
    }

    const handleFinish = () => {
        setMethod("create");
        setModalTitle("Corte de caja");
        toggle();
    }
    //#endregion modal

    //#region navigate
    const navigatePrev = () => {
        let tab = viewTab;
        tab = tab - 1;
        setViewTab(tab);
        handleUpdateValuesCash({
            capture_amount: 0,
            input2: "",
            input3: "",
            input4: "",
            comments: ""
        })
    };

    const navigateNext = () => {

        let tab = viewTab;
        tab = tab + 1;
        setViewTab(tab);
    };
    //#endregion navigate

    //#region detail User
    const [datafinally, setDatafinally] = useState([]);

    const [dataDevolution, setDataDevolution] = useState(0);
    const [listC, setListC] = useState(0)


    const getAmount = async () => {

        let _listTmp = await handleRequest("GET", `WorkOrder/DetailAmount/${id_branch}`, 'detail');
        //console.log(_listTmp);

        if (_listTmp !== null) {

            let _rest = 0;
            let _arrayNew = _listTmp.workOrderCash;
            let arrayNew2 = _listTmp.workOrderCash;
            let _arrayFinal = [];
            let _arrayFinal2 = [];
            let sumTotal = 0
            let _restTotal = 0

            if (_listTmp.workOrderCash.length >= 1) {
                _listTmp.workOrderCash.forEach(_a => {
                    setAmountTotal(_a.amout);
                });
            }

            _arrayNew.forEach(_b => {
                if (_b.id_pay_method === 1) {
                    let b = _b.amout
                    _rest = Math.round(b - _listTmp.cancellAndIva);
                }

                _arrayFinal.push({
                    id_pay_method: _b.id_pay_method,
                    pay_method: _b.pay_method,
                    total: _b.id_pay_method === 1 ? _restTotal : Math.round(_b.amout)
                })
            });

            arrayNew2.forEach(_c => {

                if (_c.id_pay_method === 10 && _c.fully_paid === true) {
                    _restTotal += _c.amout
                }
                if (_c.indicador === 1 && _c.id_pay_method === 10 && _c.fully_paid === true) {
                    let _sum2 = _c.amout
                    sumTotal = (_c.amout += _sum2)
                }

                _arrayFinal2.push({
                    id_pay_method: _c.id_pay_method,
                    pay_method: _c.pay_method,
                    total: (_c.id_pay_method === 10) ? Math.round(_c.amout) : Math.round(_c.amout)
                })
            });
            setDataAmount(_listTmp);
            setIndicador1(_listTmp.workOrderCash);
            setCountPatient(_listTmp.total_work_orders);
            setCancell(_listTmp.total_work_order_cancels);
            setDiscountT(_listTmp.total_discounts);
            setOrderFirst(_listTmp.id_work_order_first);
            setOrderLast(_listTmp.id_work_order_last);
            setTotalCancell(_listTmp.cancell)
            setRealCash(_arrayFinal)
            setDatafinally(_arrayFinal2)
            setDataDevolution(_listTmp.cancellAndIva)
            setListC(_restTotal)
        }
    }


    useEffect(() => {
        if (datafinally.length >= 1) {

            let _array = datafinally;
            let _nuevoObjeto = [];
            let _indexObject = [];

            _array.forEach(x => {
                if (!_nuevoObjeto.hasOwnProperty(x.pay_method)) {
                    _nuevoObjeto[x.pay_method] = {
                        Amount: 0,
                        AmountCapture: 0,
                    }
                    _indexObject.push(x.pay_method);
                }
                _nuevoObjeto[x.pay_method].Amount = _nuevoObjeto[x.pay_method].Amount + Math.round(x.total);
            })

            if (_nuevoObjeto.hasOwnProperty("CREDITO")) {
                _nuevoObjeto["CREDITO"].Amount -= Math.round(listC);
            }
            setAmountfin([_nuevoObjeto])
            setTypeAmoun([..._indexObject])
        }
        else {
            setTypeAmoun([]);
            setAmountfin([]);
        }
    }, [datafinally, dataDevolution, listC])
    //#endregion detail User


    //#region Create Cash Flow
    const saveCreate = async () => {


        let _arrayNew = amountfin[0]
        let _e = "EFECTIVO"
        let _notAmount = _arrayNew[_e]

        let _body = {
            comments: formCash.comments,
            amount: _notAmount === undefined ? 0 : _arrayNew[_e].Amount,
            capture_amount: formCash.capture_amount,
            id_work_order_first: orderFirst,
            id_work_order_last: orderLast,
            id_branch: id_branch,
        }

        const _response = await handleRequest("POST", "CashFlow/Create", "Corte de Caja", _body);
        if (_response !== null) {
            setIdCashFlow(_response);
            createSweet("success", "success", "Corte de Caja realizado");
            updateCash();
        }
    }
    //#endregion Create Global Cash

    //#region Update
    const updateCash = () => {
        handleUpdateValuesCash({
            capture_amount: 0,
            input2: "",
            input3: "",
            input4: "",
            comments: ""
        })
        setViewTab(1);
        getAmount();
        setIndicador1([]);
        setTypeAmoun([]);
        setAmountfin([]);
    }
    //#endregion Update


    //#region PintTicket
    const printTicket = async (id_cash_flow) => {

        if (id_cash_flow > 0) {

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl + "/api/CashFlow/PrintTicket/" + id_cash_flow, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }

                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();

                    setIdCashFlow(0);
                })
        }
    }
    //#endregion PintTicket

    //#region formatter price $ 
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })
    //#endregion formatter price $

    //#region Historic cashFlow

    const previewCash = async (nim) => {
        setTypeModal("ViewCas");
        toggleCash();
    }

    const previewCredit = async () => {
        setTypeModalCredit("ViewCredit");
        toggleCredit();
    }



    const handleHistoric = async (filters = false) => {

        let _body = {};
        if (filters === false) {
            _body = {
                "date_init": "",
                "date_end": "",
                "type": "all"
            }
        } else {
            _body = {
                "date_init": dateInit,
                "date_end": dateEnd,
                "type": "filter"
            }
        }
        let _listTemp = await handleRequest("POST", "WorkOrder/DetailCash", "cash", _body);

        if (_listTemp !== null) {
            setDataHistoric([..._listTemp]);
        } else {
            setDataHistoric([]);
        }
    }

    const changeDate = (data, type) => {
        let _fecha = new moment(data._d).format('YYYY-MM-DD');
        if (type === "init") {
            setDateInit(_fecha)
        } else {
            setDateEnd(_fecha)
        }
    }
    //#endregion Historic cashFlow

    //#region credit
    const getCreditPending = async () => {
        let _listTmp = await handleRequest("GET", `WorkOrder/DetailCredit/${id_branch}`, 'Clientes');

        if (_listTmp !== null) {
            let _array = [];
            let _amounTotal = 0;
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            _listTmp.forEach((_amount, _key) => {
                let posicion = _key + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                _array.push({
                    amount_deposit: Math.round(_amount.amount_deposit).toFixed(2),
                    fech: _amount.fech,
                    name_patient: _amount.name_patient,
                    gender: _amount.patient,
                    nim: _amount.nim,
                    visibleTable: visibleTable
                })
                _amounTotal += Math.round(parseFloat(_amount.amount_deposit));
            });
            setCreditTotal(_amounTotal);
            setDataCredit(_array);
        }
    }

    //#endregion credit


    //#region registro cash
    const [dataReport, setDataReport] = useState([]);
    const [key, setKey] = useState(0);
    const [dataPaymets, setDataPaymets] = useState([]);
    const [dataCancell, setDataCancell] = useState(0);
    const [dataDiscount, setDataDiscount] = useState(0);
    const [dataPatient, setDataPatient] = useState(0);
    const [dataBranch, setDataBranch] = useState(0);
    const [dataMDiscount, setDataMDiscount] = useState(0);

    const [state, setState] = useState(prevState => ({
        ...prevState,
        text: "",
        number: 0
    }));

    const modalFullRef = useRef(null);

    const { close, modalProps, open } = useModal({
        onClose: () => setKey(key => key + 1),
        background: "#fff"

    });

    const handleReport = () => {
        open(modalFullRef);
    }

    const handleTypeCompany = (_typeClient) => {

        handleUpdateValuesCash({
            ...formCash,
            type_company: _typeClient
        });
    }

 



    const getReport = (e, search = true) => {
        e.preventDefault();

        if (search) {

            let query = "";

            let _mfrom = (mDateRange1) ? moment(mDateRange1).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            let _mto = (mDateRange2) ? moment(mDateRange2).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            let _idBranch = mSelectBranch.value;
            let _typeUser = type_company;
            console.log(_mfrom);
            console.log(_mto);
            console.log(_idBranch);
            console.log(_typeUser);
         
         


        
            if (_mfrom !== "") {
                query += `${_idBranch}?date_from=${_mfrom}`;
            }

            if (_mto !== "") {
                query += `&date_to=${_mto}`;
            }

            if (_idBranch !== "" && _mfrom === "") {
                query += `${_idBranch}`;
                console.log("aqui pasa?");

            }

            if (type_company !== null && _mfrom.length === 0  && _mto.length === 0 ) {
               query += `?type_company=${_typeUser}`;   
               console.log("aqui pasa?2");
            }

            if (type_company == null || _mfrom.length !== 0  || _mto.length !== 0 ) {
                //console.log("aqui ya debe de entrar");
                console.log("aqui pasa?3");
             }

             if (type_company !== null || _mfrom.length === 0  || _mto.length === 0 ) {
                query += `&type_company=${_typeUser}`;  
             }

        
            getDetailCreditCash(true, query)
        }
    }



    const getDetailCreditCash = async (_search = false, _filter) => {

        //console.log(_filter);

        setLoading(true);

        var _listTmp = {};

        let _alterUrl = "";

        if (_search === false) {
            _listTmp = await handleRequest("GET", `WorkOrder/DetailCreditCash/${id_branch}` + _alterUrl, 'Report');
        }
        else {
            _listTmp = await handleRequest("GET", `WorkOrder/DetailCreditCash/` + _filter, 'Report');

        }
        

        if (_listTmp !== null) {
            console.log("---------------");

            console.log(_listTmp);
            console.log("---------------");
          
            setLoading(false);

            let _arrayNew = [];
            let startItems = 0;
            let endItems = numberCash;
            let aux = numberCash * currentPage1;

            endItems = aux;
            startItems = endItems - numberCash;

            let _amountGlobal = 0;
            let decimals = 2;


            _listTmp.workOrderDetailCash.forEach((_find, _key) => {
                console.log(_find);

                let posicion = _key + 1;
                let visibleTable = posicion > startItems && posicion <= endItems;


                let { nameMethod, amount, nim, fech, name, company, status, user, is_fullPaid, abreviationName, abreviationPaternal, abreviationMaternal, id_user } = _find;

                let payment = {
                    credit: nameMethod === "CREDITO" ? amount : 0,
                    creditCard: nameMethod === "TARJETA DE CRÉDITO" ? amount : 0,
                    cash: nameMethod === "EFECTIVO" ? amount : 0,
                    debitCard: nameMethod === "TARJETA DE DÉBITO" ? amount : 0,
                    electronicTransfer: nameMethod === "TRANSFERENCIA ELECTRÓNICA DE FONDOS" ? amount : 0
                }
                let existingOrderIndex = _arrayNew.findIndex(objeto => objeto.nim === _find.nim);

             

                if (existingOrderIndex !== -1) {

                    if (!_find.ajuste) {
                        _arrayNew[existingOrderIndex].amount += amount;
                    }    

                    Object.keys(payment).forEach(method => {
                        _arrayNew[existingOrderIndex][method] += payment[method];
                    });
                    
            } else {

                    _arrayNew.push({
                        ...payment,
                        nim,
                        hour: fech,
                        namePatiente: name,
                        type: company ? "Empresas" : "Público General",
                        status: status === 0 ? "Cancelado" : "Vigente",
                        method: nameMethod,
                        amount: (!_find.ajuste ? amount : 0),
                        user,
                        id_user,
                        is_fullPaid: is_fullPaid === false ? "PENDIENTE" : "LIQUIDADO",
                        visibleTable,
                        is_adjustP: _find.is_adjustP,
                        abreviationName,
                        abreviationPaternal,
                        abreviationMaternal,
                        gatos: _find.status === 0 ? 0 : 1,
                        nameUserAdjust: _find.nameUserAdjust,
                        nameCommont: _find.nameCommont,
                        amountDiscount: company ? "" : _find.amountDiscount,
                        amountPercentag1: _find.amountPercentag1,
                        codeD: _find.codeD,
                        amountPercentag: _find.amountPercentag




                    })
                }
            });

            let _totalCancell = 0;
    
            _arrayNew.forEach(_movement => {
                if (_movement.status =="Cancelado") {
                    _totalCancell += _movement.amount  
                }
            });

            let _totalDiscount = _arrayNew.reduce((total, _f) => {
                let _finA = _f.amountDiscount;
                
                if (_finA !== 0 && _finA !== "" && _finA !== null) {
                    return total + _f.amountDiscount;
                } else {
                    return total;
                }
            }, 0);

               
            _listTmp.listMovement.forEach(_find => {
                _amountGlobal += _find.amount;              
            });

            let _subtotal_ = parseFloat(_amountGlobal.toFixed(decimals));
 
            setDataReport(_arrayNew);
            setDataPaymets(_listTmp.listMovement);
            setDataCancell(_totalCancell);
            setDataMDiscount(_totalDiscount)
            setDataDiscount(_listTmp.total_discount);
            setDataPatient(_listTmp.total_patien);
            setTotalPaymets(_subtotal_)

        }
    }


    const handleClean =() => {

        handleUpdateValuesCash({
            mDateRange1: "",
            mDateRange2: "",
            type_company: null,
            mSelectBranch: 1


        });
        getDetailCreditCash(false)

    }



    //#endregion registro cash

    const { totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1 } = usePaginationCash(dataReport.length, numberCash);

    useEffect(() => {
        //  debugger;
        let startItems = 0;
        let endItems = numberCash;

        let aux = numberCash * currentPage1;

        endItems = aux;

        startItems = endItems - numberCash;

        let listTmp = [];

        dataReport.forEach((data, key) => {
            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listTmp.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setDataReport(listTmp);

    }, [totalPageCount1, currentPage1]);


    //#region PAGINATION      
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(dataCredit.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listTmp = [];

        dataCredit.forEach((data, key) => {
            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listTmp.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setDataCredit(listTmp);

    }, [totalPageCount, currentPage]);

    //#endregion PAGINATION

    //#region  view Bottons
    const [viewFormFilters, setViewFormFilters] = useState(true);


    const handleViewFilters = () => {
        setViewFormFilters(!viewFormFilters);
    }



    const printCashClosing = async () => {

        let valuesBranch = formCash.mSelectBranch
        let nameBranch = valuesBranch.label
        let amounGlobal1 = totalPaymets
        //console.log(amounGlobal1);
        //console.log(mDateRange1);



        let data = {
            workOrderDetailCash: dataReport,
            listMovements: dataPaymets,
            total_devolution: dataCancell,
            total_discount: dataDiscount,
            total_patien: dataPatient,
            name_branch: nameBranch,
            date_cash:mDateRange1,
            amount_global:totalPaymets,
            amount_discount: (dataMDiscount).toFixed(2)
        }

        //console.log(data);

        data.workOrderDetailCash.forEach(workOrderCash => {

            if (workOrderCash.status == "Cancelado") {
                workOrderCash.status = 0;
            } else {
                workOrderCash.status = 1;
            }

            if (workOrderCash.is_fullPaid == "PENDIENTE") {
                workOrderCash.is_fullPaid = false;
            } else {
                workOrderCash.is_fullPaid = true;
            }
        })

        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/CashFlow/PrintCashClosing", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {

                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Corte de Caja.pdf")
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }

    //#region excel 


    const [titleCash, setTitleCash] = useState("Titulo de Mi corte")

 


  

    function exportToExcel(nimDataExcel, title) {

        let _Report = [];
        let _array = nimDataExcel;
      
        _array.forEach(_find => {
            console.log(_find);
            _Report.push({
                "Nim": _find.nim,
                "Nombre": _find.namePatiente,
                "Comapñia": _find.type,
                "Efectivo": _find.cash,
                "Credito": _find.credit,
                "Tarjeta de Crédito": _find.creditCard,
                "Tarjeta de Debito": _find.debitCard,
                Transferencia: _find.electronicTransfer,
                "Total de Descuento": _find.amountDiscount,
                "Porcentaje del Descuento": _find.nameCommont === "" ? "" : _find.nameCommont === "Descuento Especial" ? _find.amountPercentag1 : _find.amountPercentag,
                "Código de descuento": _find.codeD,
                "Usuario que realizo admisión": _find.user,
                "Usuario que realizo recuperación": _find.nameUserAdjust,
                "Estatus": _find.status
               
            })
        });


    
        const worksheet = XLSX.utils.json_to_sheet(_Report);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'Repote_Corte_de_caja.xlsx';

        if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }

    //#endregion excel




    //#region view Bottons





    //#region validations
    const validationCashGlobal = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formCash.capture_amount === "") {
            newValidations = {
                ...newValidations,
                capture_amount: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                capture_amount: false,
            };
        }
        setvalidationsSG(newValidations);
        return statusValidation;
    }
    //#endregion validations

    //#region request
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }

    return {

        navigatePrev, navigateNext, viewTab, formCash,
        //input
        capture_amount, input2, input3, input4, handleInputChangeCash, data,
        //modal
        method, modal, modalTitle, loading, toggle, handleFinish,
        //indicadores
        dataAmount, indicador1, countPatient, cancell, discountT, comments, saveCreate, formatter, validationsSG, totalCancell,
        realCash, creditTotal
        , dataHistoric,
        dateInit, dateEnd, changeDate, handleHistoric, dataHistoric,
        previewCash, toggleCash, typeModal, modalC,
        previewCredit, toggleCredit, modalCredit,
        typeModalCredit,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        dataCredit, typeAmoun, amountfin,
        //Report cash
        dataReport, modalFullRef, handleReport, close, modalProps,
        //pagination cassh
        totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1,
        dataPaymets, dataCancell, dataDiscount, dataPatient, dataDevolution,
        //filter
        mDateRange1, mDateRange2, getReport, mSelectBranch, handleSelectValuesCash,
        //button
        handleViewFilters, viewFormFilters, printCashClosing,branches, totalPaymets,
        exportToExcel,titleCash,
        //#region type clienet
        handleTypeCompany,type_company,handleClean,dataMDiscount



    }
}
