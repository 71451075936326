import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, CardBody } from 'reactstrap'
import { sendRequest } from '../../hooks/requests/useRequest';
import { useForm } from '../../hooks/forms/useForm';
import { classes } from '../../data/layouts';
import '../../index.scss';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import jwt_decode from "jwt-decode";
import './styleLogin.css'
import { useParams } from 'react-router-dom';



const LoginRemoto = (props) => {
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    const jwt_token = localStorage.getItem('token');
    const [createSweet, sweetConfirmation] = useSweetAlert();

    const [statusBranches, setStatusBranches] = useState(false);
    const [statusCommercialLines, setStatusCommercialLines] = useState(true);

    const { token } = useParams();

    const iniciarSesionRemote = () => {
        if (token !== undefined) {
            let { Name, Username, Job, PaternalSurname, Branch, BranchId, CommercialLineId, CommercialLine, CommercialLineShort, Sections, EnableWhatsapp, PercentageUrgentWorkOrder, txtSection, UrlHelen, is_enable_interface_imagenology, enable_tracking_toma, listbranch, ids_release_status, unprintable_preview_status,credit_payment, interfaced } = jwt_decode(token);
            localStorage.setItem("filter_sections", '[]');
            localStorage.setItem("filter_sections_sheet", '[]');
            localStorage.setItem("token", token);
            localStorage.setItem("nameUser", Name + " " + PaternalSurname);
            localStorage.setItem("username", Username);
            localStorage.setItem("roleUser", Job);
            localStorage.setItem("branchId", BranchId);
            localStorage.setItem("branchName", Branch);
            localStorage.setItem("commercialLineId", CommercialLineId);
            localStorage.setItem("commercialLineName", CommercialLine);
            localStorage.setItem("CommercialLineShort", CommercialLineShort);
            //localStorage.setItem("sections", `[${Sections}]`);
            localStorage.setItem("enable_whatsapp", EnableWhatsapp);
            localStorage.setItem("percentage_urgent_work_order", PercentageUrgentWorkOrder);
            localStorage.setItem("url_helen", UrlHelen);
            // localStorage.setItem("TextAS", "Sección/Área");
            localStorage.setItem("TextAS", txtSection);

            localStorage.setItem("enable_interface_imagenology", is_enable_interface_imagenology);
            localStorage.setItem("enable_tracking_toma", enable_tracking_toma);
            localStorage.setItem("listbranch", listbranch);
            localStorage.setItem("list_branch_storage", '[]');
            localStorage.setItem("branchIdPrincipal", BranchId);
            localStorage.setItem("ids_release_status", ids_release_status);
            localStorage.setItem("unprintable_preview_status", unprintable_preview_status);
            localStorage.setItem("credit_payment", credit_payment);
            localStorage.setItem("interfaced", interfaced);

            //  localStorage.setItem("Toma", Toma);

            getAccessUser(token);
        }
    }

    const getAccessUser = async (tokenAux) => {
        // let raw = JSON.stringify({ "token": tokenAux });

        let requestOptions = {
            method: 'POST',
            // body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "users/loginremote");
        if (respuesta.code === 200) {
            localStorage.setItem("access", JSON.stringify(respuesta.data.access));
            localStorage.setItem("ids_sections", JSON.stringify(respuesta.data.sections));
            localStorage.setItem("sections", JSON.stringify(respuesta.data.sections));

            try{
                var id_raw = parseInt(localStorage.getItem("branchId"));
                if(isNaN(id_raw) || !isFinite(id_raw)) id_raw = 0;
                var branches = respuesta.data.branches;
                const branch = branches.find(b => {
                    return b.id_helen_branch == id_raw;
                });

                if(branch != undefined && branch != null){
                    localStorage.setItem("branchId", branch.id_branch);
                }

            }
            catch(e){
                console.log(e);
            }

            setTimeout(() => {
                // props.history.replace(`${process.env.PUBLIC_URL}/dashboard/default`);
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
            }, 2000);
        }
    }

    useEffect(() => {
        iniciarSesionRemote();
    }, [])

    return (
        <Container className="login-main-container" fluid={true} style={{ backgroundColor: "#F8F9FA", boxSizing: "border-box" }}>
            <Row>
                <Col xl="4" style={{ paddingBlock: "15px", fontFamily: 'Comfortaa' }}>
                    <div className="left-container" style={{ backgroundColor: "#46AFE5", color: "white" }}>
                        <p className="title" style={{ fontWeight: "lighter" }}> <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} alt="helenLogo" />HELEN LABS</p>
                        <p className="subtitle" style={{ fontWeight: "bold", letterSpacing: "0.2px", width: "70%", lineHeight: "1.3" }}>Bienvenido al sistema de información</p>
                        <div className="image-left-container">
                            <img src={require("../../assets/images/login/loginImg.svg")} alt="loginImg" style={{ width: "70%" }} />
                        </div>
                    </div>
                </Col>
                <Col className="right-container" xl="8" style={{ padding: "0px" }}>
                    <div className="login-card">
                        <div className="login-main card">
                            <CardBody className='text-center'>
                                <h4> <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} alt="helenLogo" style={{ width: "8%" }} /> <label className='LabelLogin'>HELEN</label> <label className='LabelLogin2'>LABS</label> </h4>
                                <hr style={{ height: "0.1px", backgroundColor: "black", marginBlock: "22px" }} />
                                <div className="loader-box">
                                    <div className="loader-22"></div>
                                </div>
                                <br />
                                <h3>ESPERANDO A REDIRECCIONAR</h3>
                            </CardBody>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    );
}

export default LoginRemoto;