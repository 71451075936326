import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, Row, Spinner } from 'reactstrap';
import { useCreateOrUpdateAnalytes } from '../../../hooks/exams/useCreateOrUpdateAnalytes';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalAnalytes } from '../../../components/analytes/ModalAnalytes';
import { FormAnalytes } from '../../../components/analytes/FormAnalytes';
import { ModalTestFormula } from '../../../components/analytes/ModalTestFormula';
import { ModalRange } from '../../../components/analytes/ModalRange';
import { ModalOrderAnalytes } from '../../../components/analytes/ModalOrderAnalytes';
import { Modal } from 'react-morphing-modal';
import { ModalComplex } from '../../../components/analytes/ModalComplex';
import 'react-morphing-modal/dist/ReactMorphingModal.css';
import { ModalOrderPrintAnalytes } from '../../../components/analytes/FormsTypeAnalytes/ModalOrderPrintAnalytes';
import Breadcrumb from '../../../layout//breadcrumb'
import { useDetectedActivity } from '../../../hooks/exams/useDetectedActivity';
import './StyleExam.css';
import { useHistory } from 'react-router-dom';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { GlobalReferenceValue } from './GlobalReferenceValue';


export const CreateOrUpdateAnalytes = () => {
    const {
        analytes, loadingAnalytes, exam, analytesGroup, analytesGroupPrint, groupAnalytes, typeAnalytes, typePrecodedTexts, units, unitsTo,
        isOpen, toggle, loading, modal, toggleModal, nameAnalyte, loadingModal,
        handleInputChange, validaciones, methodActive, validateFormula,
        name, app_code, code, id_analyte_group, name_analyte_group, id_type_analyte, id_info_analyte, print, required, id_type_precoded_text, integers, decimals, id_unit, id_unit_to, compute, check_compute, replace, id_exam, loinc_num,
        addAnalyte, removeAnalyte, saveAnalytes, deleteAnalyte, handleEdit, editAnalyte, backCreate, changeUnit, validarFormula, resetValidarFormula,
        toggleTest, modalTest, loadingTest, testearFormula,
        init_range, end_range, value, increment, handleInputTest, resetTest, results, resetResults, handleModalTest,
        handleFullModal, modalProps, modalFullRef,
        gender, init_value, end_value, years_from, months_from, days_from, years_to, months_to, days_to, text_range, validacionesRange, method, methods,
        handleInputRange, resetRange, precodedTexts, listRanges, addAnalyteNumberRange, removeAnalyteNumberRange, saveTempAnalytesNumberRanges, editAnalyteNumberRange,
        id_analyte_age_gender_range, handleInputRangePrecoded,
        listRangesPrecoded, addRangePrecoded, saveTempRangePrecoded, removeRangePrecoded, editRangePrecoded, saveEditRangePrecoded, methodActiveRangePrecoded, backToCreateRangePrecoded,
        saveEditAnalyteRangeNumber, methodActiveRange, backToCreateRange, loincState,
        handleModalOrder, modalOrder, loadingOrder, saveOrderAnalytes,
        handleModalOrderPrint, modalOrderPrint, loadingOrderPrint, saveOrderPrintAnalytes,
        activeModal, analytesAvailables, refreshFormComplex, responseComplexText, refreshResultComplex, preSavedFormComplex, analytesComplex, exams,
        saveTempAnalytesNumberAlerts, listReferenceValues,
        handleValueBold, handleValueItalics, handleValueUnderline, handleValueFormatTransform, valueBold, valueItalics, valueUnderline, valueFormatTransform,
        selectedInfoAnalytes, infoAnalytes, handleValueInfoAnalyte,
        selectedInfoCharacteristics, infoCharacteristics, handleValueInfoCharacteristics,
        selectedExamSimple, simpleExams, handleValueExamSimple,
        selectedAnalyteImport, analytesFromExam,
        saveTempAnalytesTextAlerts, listReferenceTexts,
        tabTypeAnalyte, toggleTypeAnalyte,
        options, onChange, data, handleChangeTextP, prueba, selected, savePrecodText,
        // global reference        
        viewGlobalReferenceValue, handleViewGlobalReference
    } = useCreateOrUpdateAnalytes(false, true);
    // //console.log(infoAnalytes, "---");
    const { innerHeight: height } = window;

    const txtsection = localStorage.getItem('TextAS');
    const history = useHistory();

    useDetectedActivity(60000, 10000);

    // //console.log("ANALITOS: ", analytes);

    return (
        <div>
            {/* <Breadcrumb parent="Diseño de examen" title={exam.name} /> */}

            <Container className="mt--7" fluid> <br />

                <Row className="pt-1 position-relative">
                    <Col sm="4" className='scrollAnalytes ' >
                        {/* style={{ height: height - 300, overflow: "scroll", top: 150  datos mostrados de la misma linea de trabajo en la conexion  }} */}
                        <Card className="shadow position-sticky"  >
                            <CardHeader className="p-3 bg-secondary">
                                {
                                    methodActive === "create" ?
                                        <label className='labelIntegracionAnalyte'>Integración de analitos </label> :
                                        (
                                            <>
                                                <Col sm="12"><Row>
                                                    <Col sm="5" className='p-l-0 p-r-0'> <br />
                                                        <i className="fa fa-long-arrow-left"></i><h7 style={{ cursor: "pointer" }} onClick={backCreate}>&nbsp;  Regresar a creación</h7> <br />

                                                    </Col>
                                                    <Col sm="7"> <br />
                                                        <h6>Edición de analito</h6>

                                                    </Col>
                                                </Row>
                                                </Col>
                                                <br />
                                            </>
                                        )
                                }
                            </CardHeader>
                            <FormAnalytes
                                {...{
                                    methodActive, addAnalyte, editAnalyte, changeUnit, validarFormula, resetValidarFormula,
                                    name, app_code, code, id_analyte_group, name_analyte_group, id_type_analyte, id_info_analyte, print, required, id_type_precoded_text, integers, decimals, id_unit, id_unit_to, compute, replace, check_compute, id_exam, loinc_num,
                                    groupAnalytes, typeAnalytes, typePrecodedTexts, units, unitsTo,
                                    handleInputChange, validaciones, validateFormula, handleModalTest,
                                    handleFullModal, modalFullRef, loincState,
                                    handleValueBold, handleValueItalics, handleValueUnderline, handleValueFormatTransform, valueBold, valueItalics, valueUnderline, valueFormatTransform,
                                    selectedInfoAnalytes, infoAnalytes, handleValueInfoAnalyte,
                                    selectedInfoCharacteristics, infoCharacteristics, handleValueInfoCharacteristics,
                                    selectedExamSimple, simpleExams, handleValueExamSimple,
                                    selectedAnalyteImport, analytesFromExam,
                                    tabTypeAnalyte, toggleTypeAnalyte, handleChangeTextP,
                                }}
                            />
                        </Card>
                    </Col>

                    <Col sm="8">
                        <Card>
                            {
                                (id_type_analyte === "1" && id_type_precoded_text >= 1) ?
                                    <div>
                                        <CardHeader className='p-1 bg-secondary'>
                                            <Col sm="12">
                                                <Row>
                                                    <Col sm="8" className='p-l-0'>
                                                        {/* anteriormente f-18 */}
                                                        <label className='labelTypeAnalyte'>Exámen: <label className='f-20 txt-primary'>{exam.name}</label>   </label>
                                                        <p>{txtsection} : {exam.name_section}</p>
                                                    </Col>
                                                </Row>

                                            </Col>

                                        </CardHeader>
                                        <Card>


                                            <div>
                                                <Col sm="12"> <br /> <br />
                                                    <Row>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Col sm="10">
                                                            <DualListBox
                                                                selected={selected}//el que va a la derecha
                                                                simpleValue={false}
                                                                onChange={onChange}
                                                                filterPlaceholder={"Buscar..."}
                                                                showNoOptionsText={true}
                                                                canFilter options={data}//izquierda
                                                                lang={{
                                                                    moveAllLeft: 'Remover todas las opciones',
                                                                    moveAllRight: 'Agregar todas las opciones',
                                                                    moveLeft: 'Remover la opción seleccionada',
                                                                    moveRight: 'Agregar la opcion seleccionada',
                                                                    moveBottom: 'Move to bottom',
                                                                    noAvailableOptions: 'No hay más textos precodificados disponibles',
                                                                    noSelectedOptions: 'No se ha seleccionado ningún texto precodificado',
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="6">
                                                            {/* <Button color='primary'>Guardar</Button> */}
                                                        </Col>
                                                        <Col sm="6">
                                                            <Col sm="12">
                                                                <Row style={{ textAlign: "-webkit-right" }}>
                                                                    <Col sm="6">
                                                                    </Col>
                                                                    <Col sm="6">
                                                                    </Col>

                                                                </Row>
                                                            </Col>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </Card>
                                    </div>
                                    :
                                    <div>

                                        <Card className="shadow">
                                            <CardHeader className="p-1 ">
                                                <Row>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="4" className='P-L-0'>
                                                        {/* f-18 */}
                                                        <label className='labelTypeAnalyte'>Exámen: <label className='labelTypeAnalyte txt-primary'>{exam.name}</label>   </label>
                                                        <p className='labelTypeAnalyte'>{txtsection} : {exam.name_section}</p>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="8" className='p-l-0'>
                                                        <div> <br />
                                                            <Button color="light" className="float-right mr-1 ml-1" size='xs' onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>

                                                            <Button onClick={handleModalOrderPrint} outline color="primary" className="float-right mr-1 ml-1" size="xs" type="button">
                                                                <i className="fa fa-print"></i>
                                                                <label className='labelConfigurationPrint'>Ordenar impresión</label>
                                                            </Button>
                                                            {/* <Button onClick={handleModalOrder} className="float-right mr-1 ml-1" color="primary" size="sm" type="button"> */}
                                                            <Button onClick={handleModalOrder} outline color="primary" className="float-right mr-1 ml-1" size="xs" type="button">

                                                                <i className="fa fa-sort"></i>
                                                                <label className='labelConfigurationPrint'>Ordenar analitos</label>
                                                            </Button>
                                                            <Button onClick={handleViewGlobalReference} outline={viewGlobalReferenceValue ? false : true} color="primary" className="float-right mr-1 ml-1" size="xs" type="button">
                                                                <label className='labelConfigurationPrint'>
                                                                    {viewGlobalReferenceValue ? 'Listado de analitos' : 'Valor de referencia global'}
                                                                </label>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    viewGlobalReferenceValue ?
                                                        <GlobalReferenceValue examId={id_exam}
                                                        />
                                                        :

                                                        loadingAnalytes ?
                                                            <>
                                                                Cargando... &nbsp;<Spinner size="sm" />
                                                            </>
                                                            :
                                                            analytes.length > 0 ?
                                                                <Row>
                                                                    <label className='labelAnalytes'>Listado de analitos</label>
                                                                    <div className='table-responsive'>
                                                                        <TableStriped
                                                                            notMaped={["id_analyte_group", "id_type_precoded_text", "id_type_analyte", "id_exam", "id_unit", "id_unit_to",
                                                                                "name_analyte_group", "name_type_precoded_text", "name_unit", "name_unit_to", "compute", "replace", "auto_select",
                                                                                "decimals", "check_compute", "print", "isTemp", "analyte_number_ranges", "analyte_age_gender_ranges", "analyte_text_alerts", "loinc_num", "component", "position", "required", "integers", "complex_value_analytes", "app_code",
                                                                                "analyte_number_alerts", "position_print",
                                                                                "print_bold_font", "print_format_transform", "print_italics_font", "print_underlined_font",
                                                                                "id_info_analyte", "id_analyte_references", "list_availables", "is_bacterium",
                                                                                "id_mic_bacterium"
                                                                            ]}
                                                                            methodsActions={true}
                                                                            cabeceras={["Analito", "Código", "Tipo", "¿Es Analito?"]}
                                                                            items={analytes}
                                                                            handleDelete={removeAnalyte}
                                                                            handleUpdate={handleEdit}
                                                                            dataCollapsed={true}
                                                                            dataCollapsedType="analyte"
                                                                            size="table-xs"
                                                                        />
                                                                    </div>

                                                                    <Button
                                                                        type="button"
                                                                        color="secondary"
                                                                        className={"btn-block mt-2" + (loading ? "disabled progress-bar-animated progress-bar-striped" : "")}
                                                                        onClick={saveAnalytes}
                                                                    >
                                                                        Guardar analitos
                                                                    </Button>
                                                                </Row>
                                                                :
                                                                <>
                                                                    <div style={{ textAlign: "-webkit-center" }}>
                                                                        <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "35%" }} alt="" />   <br />
                                                                        <br /> <p className="f-18">{"0 Análitos agregados."}</p>
                                                                    </div>
                                                                </>
                                                }
                                            </CardBody>
                                        </Card>
                                    </div>
                            }
                        </Card>
                    </Col>
                </Row>
                <ModalAnalytes
                    {...{ modal, toggleModal, nameAnalyte, loadingModal, deleteAnalyte }}
                />
                <ModalTestFormula
                    {...{
                        toggleTest, modalTest, loadingTest, testearFormula, compute,
                        init_range, end_range, value, increment, handleInputTest, resetTest, results, resetResults
                    }}
                />
                <Modal {...modalProps} padding={false} className="containerZ"  >
                    {
                        // TODO:estoy armando el modal para los puros rangos, estoy pasando la variable de "method" y debo continuar con es Daniel del futuro, no te estreses.
                        activeModal === "modalRangos" ?
                            (<ModalRange
                                {...{
                                    name, gender, init_value, end_value, years_from, months_from, days_from, years_to, months_to, days_to, text_range,
                                    handleInputRange, resetRange, validacionesRange, method, methods, id_type_analyte,
                                    id_type_precoded_text, precodedTexts, listRanges, addAnalyteNumberRange, removeAnalyteNumberRange, saveTempAnalytesNumberRanges, editAnalyteNumberRange,
                                    saveEditAnalyteRangeNumber, methodActiveRange, backToCreateRange,
                                    id_analyte_age_gender_range, handleInputRangePrecoded,
                                    listRangesPrecoded, addRangePrecoded, saveTempRangePrecoded, removeRangePrecoded, editRangePrecoded, saveEditRangePrecoded, methodActiveRangePrecoded, backToCreateRangePrecoded,
                                    saveTempAnalytesNumberAlerts, listReferenceValues,
                                    saveTempAnalytesTextAlerts, listReferenceTexts,
                                    tabTypeAnalyte, toggleTypeAnalyte
                                }}
                            />)
                            :
                            <ModalComplex
                                {...{
                                    analytesAvailables, refreshFormComplex, responseComplexText, refreshResultComplex, preSavedFormComplex, analytesComplex, exams, exam, methodActive, name
                                }}
                            />
                    }
                </Modal>
                {
                    modalOrder &&

                    <ModalOrderAnalytes
                        {...{
                            analytesGroup, modalOrder, loadingOrder, handleModalOrder, saveOrderAnalytes
                        }}
                    />
                }
                {
                    modalOrderPrint &&

                    <ModalOrderPrintAnalytes
                        {...{
                            analytesGroupPrint, modalOrderPrint, loadingOrderPrint, handleModalOrderPrint, saveOrderPrintAnalytes
                        }}
                    />
                }
            </Container>
        </div >
    )
}