import React from 'react'
import { Modal, Row, Col} from 'reactstrap';
import { FormCash } from './FormCash';

export const ModalCash = (props) => {

    const {

        method,modal,modalTitle,loading,toggle
       
    } = props;

    return (                                
        <Modal isOpen={modal} toggle={toggle} transparent={true} keyboard={false} centered={true}  className='modal-lg'  >
               {
                method === "create"
                 ?
                <FormCash
                { ...{  method,modal,modalTitle,loading,toggle
                }}/>
                :
                ""
               }
         </Modal>   
    )
}
