import React,{ useState } from 'react'
import {  Form,  Input, Col,Card,Button,Row} from 'reactstrap';



export const FormCash = (props) => {


    const {
        method,modal,modalTitle,loading,toggle


       }=props;


    return (
        <Form role="form" >
            <Col  xl="12" className="box-col-6 pr-0 file-spacing  p-0 m-0 t-0 ">
                             <div className=" p-2">
                                 <Row>
                                    <Col sm="12" style={{ textAlign: "center" }} > 
                                    <div>
                                    <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Cash/Success.svg")} style={{ width: "22%" }} alt="" />

                                    </div>
                                    <b className='f-14'>Se ha cerrado la caja con exito</b>
                                    </Col>
                                 </Row>
                                 <br/>
                                  <Col className='col-md-12'>
                                    <Row>
                                        <Col sm="6">
                                        <Button outline color='secondary' onClick={toggle}>Aceptar</Button>
                                        </Col>
                                        <Col sm="6" style={{ textAlign: "-webkit-right"}}>
                                        <Button outline color='primary'>Descargar recibo</Button>
                                        </Col>
                                    </Row>
                                  </Col>
                     
                 </div>
             </Col> 
    </Form >
         
    )
}
