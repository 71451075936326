import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Button, CardBody, Table, Badge, Progress, Label, Form, FormGroup, Input, TabContent, TabPane, Alert, ButtonGroup } from 'reactstrap';
import { useDashboardCard } from '../../../hooks/dashboard/useDashboardCard';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { ModalCheckSpecimen2 } from './ModalCheckSpecimen2';
import { ModalPreViewMedicalOrder } from './ModalPreViewMedicalOrder.jsx';
import { ModalPrintI } from './ModalPrintI.jsx';
import { ModalViewInvoice } from './ModalViewInvoice.jsx';
import './styleDashboard.css';
import { TableStriped } from '../../../components/tables/TableStriped';
import { IndexDashboardExam } from './IndexDashboardExam';
import { IndexDashboardInicadores } from './IndexDashboardInicadores'
import { ModalHistoricSample } from './ModalHistoricSample';
import { ModalTrazabilidadSampleDashboard } from './ModalTrazabilidadSampleDashboard';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import Select from 'react-select';
import 'animate.css';
import { ModalCheckDelivery } from './ModalCheckDelivery';
import { ModalSendEmail } from './ModalSendEmail';
import { ModalExamsEmail } from './ModalExamsEmail';
import "../../../assets/css/pantalla1.css";


const Sample = (props) => {

	const {
		//checkDelivery  
		toggleCheckDelivery, handleCheckDelivery, modalCheckDelivery, idWorkOrder,
		//pagination
		totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearchNim, getdashboard, nim, handleInputChange, loading,
		//dashboard general and indications
		nimData, patientCompany, patientTotal, countTotal, examsAndProfile, orderTotal,
		//methods absolutes
		printTicket, printSticker, navigateConfigEtiqueta,
		//modal Medical
		typeModall, modalOrdenMedical, toggleMedical, handleMedical, workMedicalOrderPDF, loadingPdf, workMedicalOrderPDFDownload,
		//print result
		printWorkOrder, changeEstatus, commentPatient,
		//muestra
		handleModalCheckSpecimen, toggleCheckSpecimen, modalCheckSpecimen,
		specimensUncheck, checkSpecimens,
		loadingSpecimen, classT, chek,
		//nuevo
		treeviewRef, itemsTree, onRenderItem, changeTree, savePendingMuestra, enable_tracking_toma,

		//update work order
		navigateUpdate,
		//pruebas
		type_document,
		handleViewTest, handleCloseViewTest, enableViewTest,
		//Print PDF Individual
		togglePrintI, typeModalPrintI, modalPrintI, handleListPrint, dataExams, handleCheckProfile, handleCheckAll, handleCheckAllProfile,
		Impresionbyexam, id_workorder_print, letter,
		//INVOICE
		handleInvoice, toggleInvoice, typeModalInvoice, modalInvoice, InvoicePDF, sendResultForEmail, exportToExcel, nimDataExcel,
		//historic Sample
		typePending, modalPending, handleHistoric, toggleHistoric,
		dataSample, loadingSample, dataSendProcess2, dataSendProcess3, dataTrackingRecepetion,
		handleTrazabilidad, close, modalProps, namePatient, idGender,
		dataSection, dataID,
		mDateRange1, mDateRange2, handlePickerValues, mSelectStatus, handleSelectValues, statusOrder, handleCleanFilter,
		viewFormFilters, handleViewFilters, navigateInvoice, mSelectCompany, company,
		//validation
		validationsSG,
		ids_users, user, handleSendExam, toggleExamEmail, modalCheckEmail, sendResultEmail, orderCancell, modalExamsEmail, handleListExamEmail,
		typeModalExamEmail, toggleListExamEmail, id_workorder_send, sendTo, handleFindExam,
		//sendEmailMedical
		respuestaAPI, credit_payment, dataCompany, id_company,
		//button of dashboard
		handleChangeTypeFilter, typeNumber, pending, is_pending,validinvoice
		//MICROSANITARIA
		
	} = useDashboardCard();

	console.log(validinvoice);

	const [activeTab, setActiveTab] = useState("1");

	const toggleTab = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	}

	let prueba = countTotal

	const AlertItem = props => {
		const { item } = props;
		const [Open, setOpen] = useState(true);

		return (
			<Alert className="alert-dismissible"
				color={item.alertcolor}
				isOpen={Open}
				target={"Alert-" + item.id}
			>
				{item.icon}
				<p>{item.alerttxt}</p>
			</Alert>
		);
	}




	return (
		<Fragment>
			<Container fluid={true} className="" >
				<Row>
					<Col sm="12" md="12" lg="12">

						<TabContent activeTab={activeTab}>
							<TabPane className="fade show" tabId="1">
								<Row>
									<Col sm="12">
										<Card className='shadow cardDashboard' style={{ height: "160px" }} >
											<Row>
												<Col sm="4">
													<div className='ecommerce-widgets media'>

														<Col sm="2"> <br />
															<div className='ecommerce-box light-bg-primary' >
																<img src={require("../../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "78%" }} alt="" />
															</div>
														</Col>
														<Col sm="8" style={{ textAlign: "-webkit-center" }} > <br />
															<label className='labelIndications f-16'>Ordenes de trabajo / Día</label> <br />
															<label className='f-20'>{orderTotal}</label> <br />
														</Col>
													</div>
													<Col sm="12">
														{
															(orderTotal === 0) ?
																<Row className='mt-2'>
																	<Col sm="12" md="12" lg="12" className='text-center'>
																		<label htmlFor=""></label> <br />


																	</Col>

																	<Col sm="2" md="2" lg="2">
																	</Col>

																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'></Col>
																</Row>
																:
																<div>
																	<br />
																	<Progress className="sm-progress-bar" color="secondary" value={100} style={{ height: "8px" }} />
																</div>

														}
													</Col>
												</Col>
												<Col sm="4">
													<div className='ecommerce-widgets media'>

														<Col sm="2"> <br />
															<div className='ecommerce-box light-bg-primary'>
																<img src={require("../../../assets/images/loginHelenLabs/dashboard/ExamAndProfile.svg")} style={{ width: "95%" }} alt="" />
															</div>
														</Col>
														<Col sm="8" style={{ textAlign: "-webkit-center" }} > <br />
															<label className='labelIndications f-16'>Pruebas realizadas / Día</label> <br />
															<label className='f-20'>{examsAndProfile}</label> <br />
														</Col>
													</div>
													<Col sm="12">
														{
															(examsAndProfile === 0) ?
																<Row className='mt-2'>
																	<Col sm="12" md="12" lg="12" className='text-center'></Col>

																	<Col sm="2" md="2" lg="2">
																	</Col>

																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'></Col>
																</Row>
																:
																<div>
																	<br />
																	<Progress className="sm-progress-bara" color='secondary' value={100} style={{ height: "8px" }} />

																</div>
														}
													</Col>
												</Col>
												<Col sm="4" className='p-l-0' >
													<div className='ecommerce-widgets media'>

														<Col sm="2"><br />
															<div className='ecommerce-box light-bg-primary'>
																<img src={require("../../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "75%" }} alt="" />
															</div>
														</Col>
														<Col sm="8" style={{ textAlign: "-webkit-center" }} className="p-l-0 " > <br />
															<label className='labelIndications f-16' >Ordenes de trabajo / Mes</label> <br />
															<label className='f-20'>{prueba}</label> <br />
														</Col>
													</div>
													<Col sm="12">
														{
															(prueba === 0) ?
																<Row className='mt-2'>
																	<Col sm="12" md="12" lg="12" className='text-center'></Col>

																	<Col sm="2" md="2" lg="2">
																	</Col>

																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'>
																		<br />
																		<p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
																	</Col>
																	<Col sm="2" md="2" lg="2" className='text-center'></Col>
																</Row>
																:
																<div>
																	<br />
																	<Progress className="sm-progress-bar" color="secondary" value={100} style={{ height: "8px" }} />

																</div>
														}
													</Col>
												</Col>
											</Row>
											
										</Card>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<Row>
											<Col xs="12" sm="6" md="6" lg="6" xl="4">
												<Card className='cardDashboard shadow  ' style={{ height: "160px" }}>
													<Row>
														<Col sm="1">
														</Col>
														<Col sm="8"> <br /> <br />
															<label className='labelIndications f-14'>Número de pacientes</label> <br />
															<label className='labelIndications f-22' >Particulares</label> <br /> <br />
															{
																patientTotal === 0 ?
																	<div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
																		<div className="progress-gradient-danger" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-danger">{patientTotal}</span><span className="animate-circle f-26"></span></div>
																	</div>
																	:
																	<div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
																		<div className="progress-gradient-primary" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-primary">{patientTotal}</span><span className="animate-circle f-26"></span></div>
																	</div>
															}
														</Col>
														<Col sm="3"> <br /> <br />
															<img className="imgU animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/dashboard/User.svg")} style={{ width: "50%", filter: "sepia(1)" }} alt="" />
														</Col>
													</Row>
												</Card>
											</Col>
											<Col xs="12" sm="6" md="6" lg="6" xl="4">
												<Card className='cardDashboard shadow ' style={{ height: "160px" }}>
													<Row>
														<Col sm="1">
														</Col>
														<Col sm="8"> <br /> <br />
															<label className='labelIndications f-14'>Número de pacientes</label> <br />
															<label className='labelIndications f-22' >Empresas</label> <br /> <br />
															{
																patientCompany === 0 ?
																	<div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
																		<div className="progress-gradient-danger" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-danger">{"0"}</span><span className="animate-circle"></span></div>
																	</div>
																	:
																	<div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
																		<div className="progress-gradient-primary" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-secondary">{patientCompany}</span><span className="animate-circle"></span></div>
																	</div>
															}
														</Col>
														<Col sm="3"> <br /> <br />
															<img className="imgU animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/dashboard/Company.svg")} style={{ width: "50%", filter: "grayscale(1)" }} alt="" />
														</Col>
													</Row>
												</Card>
											</Col>
											<Col xs="12" sm="6" md="6" lg="6" xl="4">
												<Card className='cardDashboard shadow ' style={{ height: "160px" }}>
													<Row>
														<Col sm="1">
														</Col>
														<Col sm="8"> <br /> <br />
															<label className='labelIndications f-14'>Número de ordenes de trabajo</label> <br />
															<label className='labelIndications f-22' >Canceladas</label> <br /> <br />
															{
																orderCancell === 0 ?
																	<div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
																		<div className="progress-gradient-danger" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-danger">{"0"}</span><span className="animate-circle"></span></div>
																	</div>
																	:
																	<div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
																		<div className="progress-gradient-primary" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-secondary">{orderCancell}</span><span className="animate-circle"></span></div>
																	</div>
															}
														</Col>
														<Col sm="3"> <br /> <br />
															<img className="imgU animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/dashboard/Cancellations.svg")} style={{ width: "50%" }} alt="" />
														</Col>
													</Row>
												</Card>
											</Col>

									

										</Row>
									</Col>




								</Row>

								<Row>

									<Col sm="12" className={!viewFormFilters ? "" : "  d-none"} >
										<br /> <br />
										<Form onSubmit={(e) => getdashboard(e, true)}>
											<Row>

												<Col sm="2" md="2" lg="2" className='p-r-0 p-1' >
													<Row>
														<Label className="col-12">Ingresa el NIM  o Nombre</Label>
														<FormGroup className="col-md-12">
															<Input className='form-control form-control-sm input-air-primary b-r-9' name="nim" value={nim} onChange={handleInputChange} />
														</FormGroup>
													</Row>
												</Col>
												<Col sm="2" className='p-1' >
													<FormGroup>
														<Label type="text" className='form-col-label'>Fecha de inicio</Label>
														<Input type='date' value={mDateRange1} name='mDateRange1' onChange={handleInputChange} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
													</FormGroup>
												</Col>
												<Col sm="2" className='p-1' >
													<FormGroup>
														<Label className='form-col-label'>Fecha de cierre</Label>
														<Input type='date' value={mDateRange2} name="mDateRange2" onChange={handleInputChange} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
													</FormGroup>
												</Col>
												<Col sm="2" md="2" lg="2" >
													<Label className=" ">Estatus</Label>
													<Select
														name="mSelectStatus"
														isClearable={true}
														placeholder="Selecciona un estatus"
														noOptionsMessage="Sin resultados"
														options={statusOrder}
														onChange={(e) => handleSelectValues(e, 'mSelectStatus')}
														value={mSelectStatus}
														className="b-r-9"
													/>
												</Col>
												<Col sm="2" md="2" lg="2" className={dataCompany.length === 0 ? "d-none" : ""} >

													<Label className=" ">Empresas</Label>

													<Select
														name="id_company"
														isClearable={true}
														placeholder="Selecciona una empresa"
														noOptionsMessage="Sin resultados"
														options={dataCompany}
														onChange={(e) => handleSelectValues(e, 'id_company')}
														value={id_company}
														className="b-r-9"
													/>
												</Col>


												<Col sm="1" md="1" lg="1" className="d-flex align-items-center">
													<Button type="submit" outline color="primary" size='sm' className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} style={{ width: '100%', padding: '0.5rem 0.75rem', marginRight: '0.5rem' }}>
														Buscar
													</Button>
													<Button outline color='danger' size='sm' onClick={handleCleanFilter} style={{ width: '100%', padding: '0.5rem 0.75rem' }}>
														Limpiar
													</Button>
												</Col>

											</Row>
										</Form>
									</Col>
									<br />
									<Col sm="12">
										{
											(validationsSG.mDateRange1 === true && validationsSG.mDateRange2 === false) &&
											<AlertItem
												item={
													{
														alerttxt: "Debes de ingresar dos rangos de fechas para realizar una búsqueda.",
														alertcolor: 'danger inverse',
														btnclose: 'default',
														icon: <i className="icon-alert"></i>
													}
												}
											/>
										}
									</Col>
								</Row>
								<Col sm="12" md="12" lg="!2">
									<Form onSubmit={(e) => getdashboard(e, true, 1)}>
										<ButtonGroup md="12" className='btn-group-pill btn-block'>

											<Button color='primary' type='button' onClick={handleCleanFilter} outline={typeNumber === 0 ? true : false}><i className="icofont icofont-test-tube-alt"></i> &nbsp;&nbsp; Trabajo del día</Button>
											<Button color='danger' type="submit" outline={typeNumber === 1 ? false : true}><i className="icofont icofont-prescription"></i> &nbsp;&nbsp; Pendientes</Button>

										</ButtonGroup>
									</Form>
								</Col>
								<Row className='m-b-20'>
									<Col sm="12">
										<Row>
											<Col sm="12"> <br /> <br />
												<Card>
													<CardBody className='p-b-0' >
														<div className="ribbon ribbon-clip ribbon-primary">
															{"Ordenes de Trabajo Ingresadas"}
														</div>
														<br />
														<Row className={typeNumber === 0 ? 'mt-3' : 'd-none'}>
															<Row className={`${!enableViewTest ? 'm-t-10 animate__animated animate__fadeIn' : 'animate__animated animate__fadeIn'}`} hidden={enableViewTest}>
																<Col sm="12">
																	<Row>
																		<Col xs="8" className="p-0">
																			{/* Aquí está un espacio para que los demás elementos aparezcan a la derecha en versión de escritorio */}
																		</Col>
																		<Col xs="4" className="p-0 d-flex justify-content-end align-items-center">
																			<Button type="button" size="sm" outline color="primary" className="btn-pill" onClick={handleViewTest}>
																				<i className="fa fa-flask" /> Exámenes
																			</Button>
																			<Button type="button" size="sm" outline color="success" className="btn-pill ms-2" onClick={() => exportToExcel(nimDataExcel)}>
																				<i className="icofont icofont-file-excel" /> Excel
																			</Button>
																			<Button type="button" size="sm" outline color="secondary" className="btn-pill ms-2" onClick={handleViewFilters}>
																				{!viewFormFilters ? (
																					<>
																						<i className="icofont icofont-eye-blocked" /> Ocultar
																					</>
																				) : (
																					<>
																						<i className="icofont icofont-filter" /> Filtros
																					</>
																				)}
																			</Button>
																		</Col>
																	</Row>
																</Col>
																<br />
																<br />
																<br />
																<br />
																<div className='table-responsive'>
																	{
																		loading === true ?
																			<Col sm="12" className='text-center'>


																				<img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className=' ' style={{ width: '10px' }} />


																				{/* <Row className='mt-2'>
																					<Col sm="3" md="3" lg="3" className='text-center'></Col>
																					<Col sm="2" md="2" lg="2" className='text-left'>
																						<p className='f-16 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p>
																					</Col>
																					<Col sm="1" md="1" lg="1" className='text-center'>
																						<br />
																						<p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
																					</Col>
																					<Col sm="1" md="1" lg="1" className='text-center'>
																						<br />
																						<p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-14"></i></p>
																					</Col>
																					<Col sm="1" md="1" lg="1" className='text-center'>
																						<br />
																						<p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
																					</Col>
																					<Col sm="2" md="2" lg="2" className='text-center'></Col>
																				</Row> */}
																			</Col>
																			:
																			<div>
																				{
																					nimData.length === 0 ?
																						<div style={{ textAlign: "-webkit-center" }}>
																							<p className='f-w-600 f-14 badge badge-light-primary'>No existen resultados para su busqueda.</p>

																							<img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "32%" }} alt="" />
																						</div>
																						:
																						<TableStriped
																							notMaped={["visibleTable", "listProfileWorkOrders", "listExam", "id_estatus_order", "id_patient", "is_parcial", "income_type", "id_estatus_order", "cont_check_specimen", "validar_faltantes", "indices", "paid", "valid_invoice", "UUID", "is_send_email", "id_company", "nim2", "fechAndHour_deliver", "deliver", "valid_user_deliveri", "send", "valid_sendAndDelivery", "send2", "valid_sendAndDelivery2","is_invoiced","send_email","send_doctor", "is_micro","invoice_is_general_public"]}
																							methodsModal={true}
																							methodsInvoice={true}
																							methodsActions={false}
																							methodActiveDropdown={false}
																							cabeceras={["NIM", "Fecha y Hora", "Paciente", "Sucursal"]}
																							items={nimData}
																							nextPage={nextPage}
																							previousPage={previousPage}
																							totalPageCount={totalPageCount}
																							currentPage={currentPage}
																							goToPage={goToPage}
																							dataBadge={true}
																							statusOrder={true}
																							company={true}
																							checkSpecimen={true}
																							changeTable={classT}
																							viewTotalCheckSpecimens={true}
																							methodCheck={[
																								{
																									type: "check",
																									method: handleModalCheckSpecimen,
																									icon: "icofont icofont-warning f-12",
																									name: "handleModalCheckSpecimen",
																									color: 'secondary',
																									tooltip: "Muestra",
																								},
																							]}
																							methodActiveInvoice={[
																								{
																									type: "ModInvoice",
																									method: navigateInvoice,
																									icon: "icofont icofont-file-pdf f-12",
																									name: "navigateInvoice",
																									color: 'secondary',
																									tooltip: "Facturar",
																									tooltipInvoice: "Paciente Facturado"
																								},
																							]}
																							methodActiveModal={[
																								{
																									type: "ModIdM",
																									method: handleTrazabilidad,
																									icon: "fa fa-truck f-16",
																									name: "handleTrazabilidad",
																									color: 'secondary',
																									tooltip: "Trazabilidad de Muestra",
																									disable: enable_tracking_toma
																								},
																							]}
																							dataBadgeType={[
																								{
																									type: "idAction",
																									method: navigateUpdate,
																									icon: "fa fa-edit f-12",
																									name: "navigateUpdate",
																									color: 'primary',
																									tooltip: "Modificar",
																								},
																								{
																									type: "idAction",
																									method: printTicket,
																									icon: "fa fa-ticket f-14",
																									backgroundColor: "#5e4803",
																									color: "dark",
																									name: "printTicket",
																									tooltip: "Reimprimir ticket"
																								},
																								{
																									type: "idAction",
																									method: printSticker,
																									icon: "fa fa-barcode f-14",
																									backgroundColor: "#6c6c6c",
																									color: "secondary",
																									name: "printSticker",
																									tooltip: "Etiquetas"
																								},
																								{
																									type: "idAction",
																									method: navigateConfigEtiqueta,
																									icon: "fa fa-clipboard f-14",
																									backgroundColor: "#822854",
																									color: "warning",
																									name: "navigateConfigEtiqueta",
																									tooltip: "Detalle de Impresión"
																								},
																								{
																									type: "idAction",
																									method: handleMedical,
																									icon: "fa fa-eye f-12",
																									name: "handleMedical",
																									color: 'primary',
																									tooltip: "Previsualizar",
																									disable: credit_payment
																								},
																								{
																									type: "idAction",
																									method: handleListPrint,
																									icon: "icofont icofont-printer f-12",
																									name: "printWorkOrder",
																									color: 'dark',
																									tooltip: "Imprimir Resultado",
																									disable: credit_payment

																								},
																								{
																									type: "idAction",
																									method: handleCheckDelivery,
																									icon: "fa fa-check-square-o f-12",
																									name: "changeEstatus",
																									color: 'success',
																									tooltip: "Entregar",
																									disable: credit_payment

																								}, {
																									type: "idAction",
																									method: handleInvoice,
																									icon: "fa fa-file-pdf-o f-12",
																									name: "viewinvoice",
																									color: 'success',
																									tooltip: "Factura",
																								}
																								, {
																									type: "idAction",
																									method: sendResultForEmail,
																									icon: "fa fa-mail-forward f-12",
																									name: "viewinvoicemail",
																									color: 'success',
																									tooltip: "Enviar Factura",
																								},
																								{
																									type: "idAction",
																									method: handleListExamEmail,
																									icon: "icofont icofont-envelope f-14",
																									name: "handleSendExam",
																									color: 'primary',
																									tooltip: "Enviar a correo",
																									disable: credit_payment

																								},
																							]}
																						/>
																				}
																			</div>
																	}
																</div>
																<br /> <br />
															</Row>
														</Row>
														<Row>
			
														</Row>

														{/* <Row className={typeNumber === 1 ? 'mt-3' : 'd-none'}>
															<p>Pendientes del día: </p>
															<IndexDashboardPending
															type = {typeNumber}
															
															/>
														</Row> */}
														<Row className={typeNumber === 2 ? 'mt-3' : 'd-none'}>
															<p>MICROSANITARIA</p>
														</Row>

														<Row className={`${enableViewTest ? 'animate__animated animate__fadeIn m-t-10' : 'd-none'} `}>
															<br />
															<br /> <br />
															<Col sm="12">
																<Row>
																	<Col sm="6">
																	</Col>
																	<Col sm="6" style={{ textAlign: 'right' }} >
																		<Button type="button" color="danger" size="sm" onClick={() => handleCloseViewTest()}>
																			<i className="fa fa-times"></i> &nbsp;Cerrar
																		</Button>
																	</Col>
																</Row>
															</Col>
															<IndexDashboardExam
															/>
														</Row>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</Col> <br /> <br /> <br /> <br />
								</Row>
								<Row>
									{
										validinvoice === false ?  ""
										:
										<Col sm="6">
										<Row>
											<Col sm="1">
											<div id='btn-tooltip-filter-public' className='tap-top-invoice-general zoom' style={{ display: "block" }} >
												<i className="icofont icofont-file-pdf f-24 zoom ">
												<ToolTips
                                                    placement="top"
                                                    dataTarget='btn-tooltip-filter-public'
                                                    dataText='Facturas generadas a público general'
                                                />
												</i>
										    </div>
											</Col>
											<Col sm="1">
											<div id='btn-tooltip-filter-patient' className='tap-top-invoice-patient-company zoom' style={{ display: "block" }} >
												<i className="icofont icofont-file-pdf f-24 zoom ">
												<ToolTips
                                                    placement="top"
                                                    dataTarget='btn-tooltip-filter-patient'
                                                    dataText='Facturas generadas a pacientes y empresas'
                                                />
											    </i>		
                                            </div>
											</Col>
										</Row>
									</Col>
									}
								
								</Row>
							</TabPane>

							<TabPane className="fade show" tabId="2">
								<IndexDashboardExam
								/>
							</TabPane>

							<TabPane className="fade show" tabId="3">
								<IndexDashboardInicadores
								/>
							</TabPane>

						</TabContent>

					</Col >
				</Row >


			</Container >
			<ModalCheckSpecimen2
				{
				...{
					modalCheckSpecimen, toggleCheckSpecimen,
					specimensUncheck, checkSpecimens, loadingSpecimen, chek,
					treeviewRef, itemsTree, onRenderItem, changeTree, savePendingMuestra, enable_tracking_toma

				}
				}

			/>
			<ModalPreViewMedicalOrder
				{
				...{

					typeModall, workMedicalOrderPDF, workMedicalOrderPDFDownload, toggleMedical, typeModall, modalOrdenMedical, loadingPdf
				}
				}
			/>
			<ModalPrintI
				{
				...{
					togglePrintI, typeModalPrintI, modalPrintI, dataExams, handleCheckProfile, handleCheckAll, Impresionbyexam, id_workorder_print, handleCheckAllProfile, letter, handleInputChange
				}
				}
			/>
			<ModalViewInvoice
				{
				...{
					toggleInvoice, typeModalInvoice, modalInvoice, InvoicePDF, loadingPdf
				}
				}
			/>
			<ModalHistoricSample
				{
				...{
					typePending, modalPending, toggleHistoric, dataSample, loadingSample, dataSendProcess2, dataSendProcess3,
					dataTrackingRecepetion
				}
				}

			/>
			<ModalTrazabilidadSampleDashboard
				{...modalProps}
				close={close}
				dataSample={dataSample}
				dataSendProcess2={dataSendProcess2}
				dataSendProcess3={dataSendProcess3}
				dataTrackingRecepetion={dataTrackingRecepetion}
				namePatient={namePatient}
				treeviewRef={treeviewRef}
				itemsTree={itemsTree}
				idGender={idGender}
				dataSection={dataSection}
				dataID={dataID}
			>
			</ModalTrazabilidadSampleDashboard>
			<ModalCheckDelivery
				{
				...{
					toggleCheckDelivery,
					modalCheckDelivery,
					changeEstatus, idWorkOrder, commentPatient, handleInputChange, nimData
				}
				}
			/>
			<ModalSendEmail
				{
				...{ toggleExamEmail, modalCheckEmail, sendResultEmail, respuestaAPI, sendResultForEmail, sendTo,handleCheckProfile,handleCheckAll }
				}


			/>
			<ModalExamsEmail
				{
				...{
					toggleExamEmail, toggleListExamEmail, typeModalExamEmail, modalExamsEmail, dataExams, handleCheckProfile, handleCheckAll,
					Impresionbyexam, handleCheckAllProfile, handleSendExam, id_workorder_send, handleFindExam
				}
				}
			/>

		</Fragment >
	);
}
export default Sample;

