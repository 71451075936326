import React from 'react'
import { Modal, ModalHeader, Col, Form, FormGroup, FormText, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';

export const ModalTracking = (props) => {

    const {
        modal, toggle, loading, handleInputChange,
        nim, getExamsFromNim, typeModal = "nim", cerrarModalNim,
        exams, handleInputCheckboxChange, initTracking
    } = props;

    //mañana modificar el diseño de los modales y asi como verivicar que esten ñas secciones

    return (
        <Modal isOpen={modal} toggle={cerrarModalNim} backdrop='static' keyboard={false} centered={true}>
            {
                typeModal === "nim" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Código de Barras"}</Label>
                                    </Col>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-3'>
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={cerrarModalNim} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Form className="theme-form" role="form" onSubmit={(e) => getExamsFromNim(e, "manual")}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ border: "#0079C7 3px solid" }}>
                                <Row>
                                    <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                                    <Col xs="10" sm="10" md="10" lg="10" xl="10"> <br /><br />
                                        <div>
                                            <input
                                                className="tamañoinputID text-center input-air-primary "
                                                placeholder="Ingresa el número de la muestra"
                                                type='text'
                                                name='nim'
                                                value={nim}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            />
                                        </div>  <br /><br />
                                        <div style={{ textAlign: "-webkit-center" }}>
                                            <button className={loading ? "modalButton" : "modalButton"} type="submit">Ingresar</button>
                                        </div> <br />
                                    </Col>
                                    <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                                </Row>
                            </Col>
                        </Form>
                    </>
                    :
                    typeModal === "exams" ?
                        <>
                            <div className="colorModalIDmuestra" style={{ textAlignLast: "center" }}>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Row>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                        <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                            <Label className="labelModal p-1"> &nbsp;{nim}</Label>
                                        </Col>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-3'>
                                            <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={cerrarModalNim} />
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ border: "#46AFE5 3px solid" }}>
                                <Row>
                                    <Col xs="1" sm="1" md="1" lg="1" xl="1"></Col>
                                    <Col xs="10" sm="10" md="10" lg="10" xl="10">
                                        <div style={{ textAlignLast: "center" }}>  <br />
                                            <Label className='Label-Modal-Checked-Txt'>Seleccionar Estudios</Label> <br />
                                        </div>
                                        <div> <br />
                                            {
                                                exams.map((x, key) => {
                                                    //console.log(x);
                                                    return <FormGroup key={key}>
                                                        <Input id={`checkbox-${x.id_exam}`} value={x.id_exam} checked={x.check}
                                                            onChange={(e) => handleInputCheckboxChange(e)}
                                                            disabled={x.code_error === 409 ? true : false}
                                                            type="checkbox"
                                                            className='checkbox_animated '
                                                        />
                                                        <Label className='Label-Modal-Checked label-input' for={`checkbox-${x.id_exam}`}>{x.name}</Label>
                                                        <br />
                                                        <label className='f-w-600 f-12 badge badge-light-secondary'>{x.status}</label>
                                                        {/* <span className='text-danger small'><i>{x.status}</i></span> E0005   */}
                                                    </FormGroup>
                                                })
                                            }
                                        </div> <br /> <br />
                                        <div style={{ textAlign: "-webkit-center" }}>

                                            <Button
                                                disabled={exams.find(x => x.check === true) !== undefined ? false : true}
                                                className={loading ? " primary" : "primary"}
                                                // className={loading ? "Modal-Button-Checked pointer " : "Modal-Button-Checked pointer"}
                                                type="submit"
                                                onClick={(e) => initTracking(e, "", false)}
                                            >Aceptar</Button>



                                        </div>

                                        <br />

                                    </Col>
                                    <Col sm="1"></Col>
                                </Row>
                            </Col>
                        </>
                        :
                        typeModal === "pending" ?
                            <>
                                <div className="colorModalIDmuestraTracking" style={{ textAlignLast: "center" }}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <Row>
                                            <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                            <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                                <Label className="labelModindexal p-1"> &nbsp;Examenes pendientes</Label>
                                            </Col>
                                            <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-3'>
                                                <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={cerrarModalNim} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ border: "#0079C7 3px solid" }}>
                                    <Row>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-left" }}>
                                            <br /><br /><br /><br />
                                            <label className='f-w-600 f-12 badge badge-light-secondary'>Estas a punto de cerrar los examanes que actualmente  <br /> se encuentran en proceso.</label>
                                            <label className='f-w-600 f-12 badge badge-light-secondary'>Quedan pendientes examenes por procesar, <br />    deberas de pasar la muestra nuevamente</label>
                                        </Col>

                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <br /> <br />
                                            <div style={{ textAlign: "-webkit-center" }}>
                                                <button
                                                    className={loading ? "modalButton" : "modalButton"}
                                                    type="button"
                                                    onClick={() => initTracking("", "", true, true)}
                                                >Aceptar</button>
                                            </div> <br />
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                            : ""
            }
        </Modal>
    )
}
