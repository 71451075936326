import React, { Fragment } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Table } from 'reactstrap'
import { Riskfactorchart, Riskfactorchart1 } from '../../constant/billing'
import ReactApexChart from 'react-apexcharts'
import { useReportWorkOrderInvoice } from '../../hooks/reports/useReportWorkOrderInvoice'
import CountUp from 'react-countup';

export const IndexInvoiceReport = () => {

    const {
        loading,

        start_date, end_date, id_branch,

        viewCompany,

        handleInputChange,

        reportCompany, reportPatient, reportGeneralPublic, 

        GetReportInvoice, handleChangeView,

        RiskBarCompany, RiskBarPatient
    } = useReportWorkOrderInvoice();

    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0 mb-5'>
                <Row className='size-column'>
                    <Col sm={"12"} md="12" lg="12">
                        <Card>
                            <CardBody className='p-3'>
                                <Row>
                                    <Col sm="4" md="4" lg="4">
                                        <h6 className='pt-3'>Panel de administración de facturas</h6>
                                    </Col>
                                    <Col sm="8" md="8" lg="8">
                                        <Row>
                                            <Col sm="3" className='p-2'>
                                                <Input type='select' size={"sm"} className='round b-r-5 border-0'>
                                                    <option value="">Selecciona una sucursal</option>
                                                </Input>
                                            </Col>
                                            <Col sm="3" className='p-2'>
                                                <Input type='date' size={"sm"} className='round b-r-5 border-0' name='start_date' value={start_date} onChange={(e) => handleInputChange(e)}>
                                                    <option value="">Selecciona una sucursal</option>
                                                </Input>
                                            </Col>
                                            <Col sm="3" className='p-2'>
                                                <Input type='date' size={"sm"} className='round b-r-5 border-0' name='end_date' value={end_date} onChange={(e) => handleInputChange(e)}>
                                                    <option value="">Selecciona una sucursal</option>
                                                </Input>
                                            </Col>
                                            <Col sm="3" className='p-2 text-center'>
                                                <Button color='info' size='sm' onClick={GetReportInvoice}>
                                                    <i className='fa fa-search'></i> Filtrar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="size-column">
                    <Col xl={viewCompany === true ? "7" : "5"} className="risk-col box-col-12">
                        <Card className={viewCompany === true ? "total-users" : " disabled isableLabel  total-users "}>
                            <CardHeader className="card-no-border p-3 mb-2">
                                <div className='ribbon ribbon-clip ribbon-primary'>
                                    {"Empresas"}
                                </div>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="fa fa-spin fa-spinner"></i></li>  
                                    </ul>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row>
                                    <Col sm="12" md="7" lg="7" className='text-center'>
                                        <center>
                                            <div className="goal-chart">
                                                <ReactApexChart id="riskfactorchart" options={RiskBarCompany?.options} series={RiskBarCompany?.series} type='radialBar' height={350} />
                                            </div>
                                            <div className="goal-end-point text-center mb-2">
                                                <h6 className="typeCompany">{"Empresas"}</h6>                                                
                                            </div>
                                            <hr />
                                            <Row className='mt-2'>
                                                <Col sm="4" md="4" lg="4">
                                                    <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Admisiones</Label>
                                                    <h5 className="mb-0">{reportCompany?.total_work_orders}</h5>
                                                </Col>
                                                <Col sm="4" md="4" lg="4">
                                                    <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Solicitadas</Label>
                                                    <h5 className="mb-0">{reportCompany?.total_requiered_invoice}</h5>
                                                </Col>
                                                <Col sm="4" md="4" lg="4">
                                                    <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Pendientes</Label>
                                                    <h5 className="mb-0">{reportCompany?.total_not_requiered_invoice}</h5>
                                                </Col>
                                            </Row>
                                        </center>
                                    </Col>
                                    <Col sm="12" md="5" lg="5">
                                        <div className="apex-chart-container goal-status text-center">
                                            <ul className="col-xl-12">
                                                <li>
                                                    <div className="goal-detail">
                                                        <h6> <span className="font-primary">{"Facturado"} : </span>{"$91,000.000"}</h6>
                                                        <div className="progress sm-progress-bar progress-animate">
                                                            <div className="progress-gradient-info" role="progressbar" style={{ width: "60%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div className="goal-detail mb-0">
                                                        <h6><span className="font-primary">{"Sin facturar"}: </span>{"9 Month"}</h6>
                                                        <div className="progress sm-progress-bar progress-animate">
                                                            <div className="progress-gradient-info" role="progressbar" style={{ width: "50%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="btn-download btn btn-gradient btn-sm f-w-500" onClick={handleChangeView}>{"Ver detalles"}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>                                
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={viewCompany === true ? "5" : "7"} className="risk-col box-col-12">
                    
                        <Card    className={viewCompany === true ? " disabled isableLabel  total-users " : " total-users"} >
                            <CardHeader className="card-no-border p-3 mb-2">
                                <div className='ribbon ribbon-clip ribbon-secondary-Particular'>
                                    {"Particular"}
                                </div>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="fa fa-spin fa-spinner"></i></li>  
                                    </ul>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row>
                                    <Col sm="12" md="6" lg="6" className='text-center'>
                                        <center>
                                            <div className="goal-chart">
                                                <ReactApexChart id="riskfactorchart" options={RiskBarPatient.options} series={RiskBarPatient.series} type='radialBar' height={350} />
                                            </div>
                                            <div className="goal-end-point text-center">
                                                <h6 className="typeParticular">{"Particular"}</h6>                                                
                                            </div>
                                            <hr />
                                            <Row className='mt-2'>
                                                <Col sm="4" md="4" lg="4">
                                                    <Label type="text" className='f-w-600 f-12 badge type-Badge-Particular'>Admisiones</Label>
                                                    <h5 className="mb-0">{reportPatient?.total_work_orders}</h5>
                                                </Col>
                                                <Col sm="4" md="4" lg="4">
                                                    <Label type="text" className='f-w-600 f-12 badge type-Badge-Particular'>Solicitadas</Label>
                                                    <h5 className="mb-0">{reportPatient?.total_requiered_invoice}</h5>
                                                </Col>
                                                <Col sm="4" md="4" lg="4">
                                                    <Label type="text" className='f-w-600 f-12 badge type-Badge-Particular'>Pendientes</Label>
                                                    <h5 className="mb-0">{reportPatient?.total_not_requiered_invoice}</h5>
                                                </Col>
                                            </Row>
                                        </center>
                                    </Col>
                                    <Col sm="12" md="6" lg="6">
                                        <div className="apex-chart-container goal-status text-center">
                                            <ul className="col-xl-12">
                                                <li>
                                                    <div className="goal-detail">
                                                        <h6> <span className="font-info">{"Facturado"} : </span>{"$91,000.000"}</h6>
                                                        <div className="progress sm-progress-bar progress-animate">
                                                            <div className="progress-gradient-info-Patient" role="progressbar" style={{ width: "60%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div className="goal-detail mb-0">
                                                        <h6><span className="font-info">{"Sin facturar"}: </span>{"9 Month"}</h6>
                                                        <div className="progress sm-progress-bar progress-animate">
                                                            <div className="progress-gradient-info-Patient" role="progressbar" style={{ width: "50%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="btn-download btn btn-gradient-Particular btn-sm f-w-500" onClick={handleChangeView}>{"Ver detalles"}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='m-b-20'>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className='table-responsive'>
                                    <Table className='table-bordered m-b-5'>
                                        <thead className={(viewCompany === true ? "thead-Company" : "thead-Particular")}> 
                                            <tr >
                                                <th className='labeltheadInvoice'>NIM</th>
                                                <th className='labeltheadInvoice'>Paciente</th>
                                                <th className={(viewCompany === true ? "labeltheadInvoice" : "d-none")}>Empresa</th>
                                                <th className='labeltheadInvoice'>Sucursal</th>
                                                <th className='labeltheadInvoice'>Recepción</th>
                                                <th className='labeltheadInvoice'>¿Pagada?</th>
                                                <th className='labeltheadInvoice'>¿Facturada?</th>
                                                <th className={(viewCompany === true ? "labeltheadInvoice" : "d-none")}>Factura a empresa</th>
                                                <th className={(viewCompany === true ? "labeltheadInvoice" : "d-none")}>Factura a cliente</th>
                                                <th className='labeltheadInvoice'>Total</th>
                                                <th className='labeltheadInvoice'>Debe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (viewCompany ? reportCompany : reportPatient)?.reportDetail.map(_order => {
                                                    return (
                                                        <tr key={_order?.id_work_order}>
                                                            <td>{_order?.nim}</td>
                                                            <td>{_order?.name_patient}</td>
                                                            <td className={(viewCompany === true ? "" : "d-none")} >{_order?.name_company}</td>
                                                            <td>{_order?.name_branch}</td>
                                                            <td>{_order?.reception_date}</td>
                                                            <td>{_order?.is_full_paid ? "Si" : "No"}</td>
                                                            <td>{_order?.is_invoiced ? "Si" : "Pendiente"}</td>
                                                            <td className={(viewCompany === true ? "" : "d-none")} >{_order?.require_invoice_company ? "Si" : "No"}</td>
                                                            <td className={(viewCompany === true ? "" : "d-none")} >{_order?.requiere_invoice_patient ? "Si" : "No"}</td>
                                                            <td>{_order?.total}</td>
                                                            <td>{_order?.credit}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
