import React, { useState } from 'react'
import { Fragment } from 'react'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { sendRequest } from '../../hooks/requests/useRequest'

export const IndexUpdatePrices = () => {
    const [loading, setLoading] = useState(false);
    const [dateIni, setDateIni] = useState("");
    const [dateEnd, setDateEnd] = useState("");

    const handleUpdate = async () => {
        setLoading(true);
        const response = await _handleRequest("GET", `Invoice/UpdateXmlInvoice/${dateIni}/${dateEnd}`, "Actualizacion de precios urgente");

        if (response != null) {
            //console.log(response);
            setLoading(false);
        }
        else {
            alert("algo fallo");
            setLoading(false);
        }

    }

    const _handleRequest = async (method, methodUrl, nameMethod, body = null ) => {
        let requestOptions = {
            method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else {
            return null;
        }        
    }

    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0'>
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label>Fecha inicio</Label>
                            <Input type='date' name='dateIni' value={dateIni} onChange={(e) => {
                                setDateIni(e.target.value)
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fecha inicio</Label>
                            <Input type='date' name='dateEnd' value={dateEnd} onChange={(e) => {
                                setDateEnd(e.target.value)
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Button onClick={handleUpdate}>Actualizar</Button>
                        </FormGroup>
                        <FormGroup>
                            {
                                loading ? <p>Cargando...</p> : ""
                            }
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
