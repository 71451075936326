import React from 'react';
import { Modal, Col, Row, Label, Button, ModalBody } from 'reactstrap';
import Select from 'react-select';
import 'animate.css';
import DeniReactTreeView from "deni-react-treeview"



export const ModalSendRefused = (props) => {

    const {
        typeModalRefused = "refused", modalRefused, closeModalCancell, reason, handleSelectValuesR, id_return_reason, loadinginfo,
        flebo, namepatien, datanim, treeviewRef, itemsTree, onRenderItem, refusedPatient, idWorkOrder, saveSendSample


    } = props;

    return (
        <Modal isOpen={modalRefused} toggle={closeModalCancell} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeModalRefused === "refused" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Rechazar Muestra"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "25%" }} alt="" onClick={closeModalCancell} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >
                                {
                                    loadinginfo === false ?
                                        <>
                                            <Row className='mt-2' style={{ textAlign: "center" }} >
                                                <Col sm="12" md="12" lg="12" className='text-center'>
                                                    <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                <Col sm="3" md="3" lg="3" className='text-left'>
                                                    <p className='f-24 text-primary'><b>Cargando</b></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            </Row>
                                        </>
                                        :
                                        <div className='m-t-10 animate__animated animate__fadeIn'>
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="6" className='p-l-0 p-1' >
                                                        <label className='txt-secondary f-14'>Estudios:</label>
                                                        <DeniReactTreeView
                                                            ref={treeviewRef}
                                                            style={{ marginRight: '10px', marginBottom: '10px', height: "130px", textAlign: "center" }}
                                                            showCheckbox={true}
                                                            showIcon={false}
                                                            items={itemsTree}
                                                            selectRow={false}
                                                            onRenderItem={onRenderItem}
                                                            className="deni-react-treeview-container.green .deni-react-treeview-item-container.green .icon-and-text.selected"
                                                        />
                                                    </Col>
                                                    <Col sm="6" className='p-r-0' > <br /> <br /> <br />
                                                        <label className='txt-secondary f-14'>Paciente:</label>&nbsp;&nbsp;<b className='f-12'>{namepatien}</b> <br />
                                                        <label className='txt-secondary f-14'>NIM:</label>&nbsp;&nbsp;<b className='f-12'>{datanim}</b> <br />
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <b className='txt-danger f-14'>Selecciona un motivo por el cual estas rechazando la muestra:</b>
                                                <br /><br />
                                                <div>

                                                    <Select
                                                        classNamePrefix="select"
                                                        name="id_return_reason"
                                                        value={id_return_reason}
                                                        options={reason}
                                                        onChange={(e) => handleSelectValuesR(e, "id_return_reason")}
                                                        isClearable={true}
                                                        placeholder={"Selecciona un motivo..."}
                                                    />
                                                </div>
                                                <br />
                                                <Col sm="12" className='p-l-0 p-r-0' >
                                                    <b className='txt-danger f-14'>Responsable:</b>&nbsp;&nbsp;{flebo}
                                                </Col>
                                                <br />
                                                <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: 'right' }} >
                                                    <Button outline color='primary' size='sm' onClick={() => saveSendSample()} >Aceptar</Button>
                                                    {/* <Button type="button" outline color="danger" size="sm" onClick={() => refusedPatient(idWorkOrder)}>Rechazar</Button> */}
                                                </Col>
                                            </Col>
                                            <br />
                                        </div>

                                }
                            </ModalBody>
                        </Col>
                    </>
                    : ""
            }





        </Modal >
    )
}



