import React from 'react'
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
import { ListCardsRoles } from '../../../components/cards/ListCardsRoles';
import { ModalRol } from '../../../components/roles/ModalRol';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useRoles } from '../../../hooks/usersRols/useRoles';

export const ComponentRoles = () => {

	const {
		modalTitle, modalRoles, methodRoles, toggleRol,
        //ROLES MODEL
        listRoles, listViews, loading, 
        handleInputChange, handleSelectValues,
        name, ids_views,    validationsRole,     
        listRolesData,
        
        //ROLES METHODS
        handleCreateRoles, saveCreateRol,
        handleUpdate, saveUpdateRole,
        handleDelete, saveDeleteRol,
        handleViewViews,
		totalPageCount, currentPage, nextPage, previousPage, goToPage,
		handleSearchRol
	} = useRoles();


    return (
		<>
			<Card>
				<CardBody className='p-1'>
					<div className='text-right m-1 p-0'>
						<Button className='btn btn-pill btn-air-success' color='secondary' size='sm' onClick={handleCreateRoles}>
							<span className='fa fa-plus-circle'></span>
						</Button>
					</div>
					<Row>
						<div className='offset-8 col-4 pt-1 pb-2'>
							<Input placeholder='Búsqueda...' className='form-control' size='sm' onChange={handleSearchRol} />
						</div>
					</Row>
					<Row className='m-t-2'>
						<Col sm="12" md="12" lg="12" xl="12">
							{
								listRoles.length === 0
								?
									<div style={{ textAlign: "-webkit-center" }}>
										<img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "35%" }} alt="" />   <br />                                         
										<br /> <p className="f-18">{"No hay registrado ningún rol aún."}</p>
									</div>
								:
								<ListCardsRoles
									methodsActions={true}
									items={listRoles}
									{...{ handleUpdate, handleDelete }}
									methodsAbsolutes ={
										[
											{
												type: "linkId",
												method: handleViewViews,
												icon: "icofont icofont-eye-alt",
												backgroundColor: "#01839d",
												color: "#fff",
												name: "navigateViewView",
												tooltip: "Accesos"
											}
										]
									}
									nextPage={nextPage}
									previousPage={previousPage}
									totalPageCount={totalPageCount}
									currentPage={currentPage}
									goToPage={goToPage}
								/>
							}							
						</Col>						
					</Row>
				</CardBody>
			</Card>
			<ModalRol
				{
					...{
						modalRoles, toggleRol, modalTitle, methodRoles, loading,
						handleInputChange, handleSelectValues, validationsRole,
						listViews, listRolesData,
						name, ids_views, 
						saveCreateRol, saveUpdateRole, saveDeleteRol
					}
				}
			/>
		</>
    )
}
