import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';

export const useInfoCharacteristics = () => {

    const history = useHistory();

    const [characteristics, setCharacteristics] = useState([]);
    const [characteristicId, setCharacteristicId] = useState(0);
    const [characteristicData, setCharacteristicData] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet, sweetConfirmation,sweetDelete, sweetError, createSweett, sweetConfirmationUpdate] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        code: "",
        app_code: "",
        is_interfaced: false
    });

    const { name, code, app_code, is_interfaced } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        code_valid: false,
    });

    const toggle = () => {
        setModal(!modal);
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(characteristics.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listCharacteristics = [];
        characteristics.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listCharacteristics.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setCharacteristics(listCharacteristics);

    }, [totalPageCount, currentPage])

    const getInfoCharacteristics = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "InfoAnalyte/ListCharacteristics");

        if (respuesta.code === 200) {

            let listCharacteristics = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((obj, index) => {


                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listCharacteristics.push({
                        id_info_analyte: obj.id_info_analyte,
                        name: obj.name,
                        code: obj.code,
                        // app_code: obj.app_code,
                        // is_interfaced: obj.is_interfaced,
                        visibleTable: visibleTable
                    });
                });
                setCharacteristics(listCharacteristics);
                setCharacteristicData(listCharacteristics)

                //console.log(listCharacteristics);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getInfoCharacteristics);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Caracteristicas", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Caracteristicas", respuesta.data.msg);
            setCharacteristics([]);

        }

    }, [history]);


    useEffect(() => {
        getInfoCharacteristics();
    }, [getInfoCharacteristics]);


    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            code: "",
            app_code: "",
            is_interfaced: false
        });

        setValidaciones({
            name_valid: false,
            code_valid: false,
            app_code_valid: false,
        });

        setCharacteristicId(0);
        setMethod("create");
        setModalTitle("Nueva caracteristica");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "InfoAnalyte/CreateCharacteristic");

        if (respuesta.code === 200) {
            reset();
            createSweet("create", "success", "Exito!", "Caracteristica creada con exito");
            setLoading(false);
            toggle();
            getInfoCharacteristics();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Caracteristicas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Caracteristicas", respuesta.data.msg);
            setLoading(false)
        }
    }

    const handleUpdate = (id_info_analyte) => {
        let characteristic = characteristics.find(x => x.id_info_analyte === parseInt(id_info_analyte));

        handleUpdateValues({
            name: characteristic.name,
            code: characteristic.code,
        });

        setValidaciones({
            name_valid: false,
            code_valid: false,
        });

        setCharacteristicId(id_info_analyte);
        setMethod("update");
        setModalTitle("Actualizar caracteristica");
        toggle();
    }

    const saveUpdate = async (e, validation = -1) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        if (validation === 0) {
            setLoading(false);
            return;
        }

        let body = {
            id_info_analyte: characteristicId,
            ...{...formValues}
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: "PUT",
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, `InfoAnalyte/UpdateCharacteristic?valid_updated=${validation === -1 ? false : validation}`);

        if (respuesta.code === 200) {
            
            let validation = respuesta.data.valid_action;
            let message = respuesta.data.message;

            if (validation && message !== '') {
                
                sweetConfirmationUpdate("Advertencia", message, "warning", saveUpdate, e, true);
                return;
            }
            else if (validation === false && message === "") {
                sweetConfirmationUpdate("Información", "Deseas actualizar la siguiente caracteristica", "warning",saveUpdate, e, true)
                return;
            }
            else
            {
                getInfoCharacteristics();
                setLoading(false);                
                createSweet("success", "success", "Info", message);
                toggle();
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading();
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Analitos", "Ocurrio un error en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Analitos", respuesta.data.msg);
            setLoading(false);
        }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 2) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.code === undefined || formValues.code.length < 2) {
            newValidations = {
                ...newValidations,
                code_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }


    const handleSearch = (e) => {
        let busqueda = e.target.value;
        let newMethod = [];

        characteristicData.forEach(element => {
            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setCharacteristics(newMethod);
    }


    return {
        characteristics, method, validaciones,
        name, code, app_code, is_interfaced,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearch,
        handleUpdate, saveUpdate
    }
}
