import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Card, CardBody, Table, Button } from 'reactstrap';
export const ModalPreviewGlobalCash = (props) => {

    const {
        typeModal = "ViewGlobal", modal, toggle, loadingData, dataGlobal, formatter

    } = props;



    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} size={loadingData === false ? '' : 'xl'} scrollable={true} >

            <ModalBody className="border-3 b-primary "  >
                {
                    typeModal === "ViewGlobal" ?
                        <div>
                            {
                                loadingData === false ?
                                    <>
                                        <Row className='mt-2'>
                                            <Col sm="12" md="12" lg="12" className='text-center'>
                                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            <Col sm="3" md="3" lg="3" className='text-left'>
                                                <p className='f-24 text-primary'><b>Cargando</b></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                        </Row>
                                        <Col sm="12" style={{ textAlign: "end" }} >
                                            <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggle}>Cerrar</Button>

                                        </Col>
                                    </>
                                    :
                                    <div>
                                        <Card className='shadow' >
                                            <label className='f-16'>Concepto Global</label>
                                            <hr />
                                            <div className="table-responsive">
                                                <Table className="table-border-vertical">
                                                    <thead className="theadA">
                                                        <tr>
                                                            <th className='labelThead' scope="col">#</th>
                                                            <th className='labelThead' scope="col">Sucursal</th>
                                                            <th className='labelThead' scope="col">Encargado</th>
                                                            <th className='labelThead' scope="col">Tipo</th>
                                                            <th className='labelThead' scope="col">Monto Real</th>
                                                            <th className='labelThead' scope="col">Monto Capturado</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            dataGlobal.length >= 1 && dataGlobal.map((_data, _key) => {
                                                                return (
                                                                    <tr key={_key} >
                                                                        <td>{_key + 1}</td>
                                                                        <td>{_data.branch}</td>
                                                                        <td>{_data.userCapture}</td>
                                                                        <td>{_data.pay_method}</td>
                                                                        <td>{_data.amount}</td>
                                                                        <td>{_data.captureAmount}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        <tr>
                                                        </tr>
                                                    </tbody>

                                                </Table>
                                            </div>
                                        </Card>


                                    </div>
                            }

                        </div>

                        :
                        ""

                }



            </ModalBody>
            {
                loadingData === false ? "" : <ModalFooter>
                    <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggle}>Cerrar</Button>
                </ModalFooter>


            }



        </Modal>
    )
}