import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { LostConnection } from '../../constant';
import { validarSesion } from '../tokens/useToken';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useDebounce } from '../debounce/useDebounce';




export const usePrices = () => {

    const history = useHistory();
    const { rateId } = useParams();

    const [prices, setPrices] = useState([]);
    const [pricesId, setPricesId] = useState(0);

    const [rate, setRate] = useState({});

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [examProfileState, setExamProfileState] = useState({});

    const [isCurve, setIsCurve] = useState(false);
    const [curves, setCurves] = useState([]);

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        id_rate: rateId, price: 0, from: "", to: "", id_examProfile: 0
    });

    const {
        price, from, to, id_examProfile
    } = formValues;

    const [validacionesPrice, setValidacionesPrice] = useState({
        id_examProfile_valid: false,
        price_valid: false,
        from_valid: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    const getRate = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Rate/GetRateId/" + rateId);

        if (respuesta.code === 200) {
            setRate(respuesta.data);
        }
        else {
            validarSesion(history, respuesta.code, getRate);
        }
    }, [history]);

    useEffect(() => {

        getRate();

    }, [getRate]);

    const [searchQuery, setSearchQuery] = useState("");
    const debounceValue = useDebounce(searchQuery, 150);

    const handleSearch = (e) => {
        let busqueda = e.target.value;
        setSearchQuery(busqueda);
    }

    useEffect(() => {
        searchAsync();
    }, [debounceValue])

    const [metaData, setMetaData] = useState([]);
    const [examsData, setExamsData] = useState([]);


    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        "Price/List/" + rateId,
        history,
        searchQuery
    );

    useEffect(() => {

        let listPrice = [];

        results.forEach((dataa, key) => {

            dataa.price = formatter.format(dataa.price)

            let visibleTable = true;

            listPrice.push({
                name_profile: dataa.name_profile + dataa.name_exam,
                name_exam: dataa.name_exam.trim() !== "" ? dataa.name_exam : dataa.name_profile,
                price: dataa.price,
                from: dataa.from,
                to: dataa.to,
                curvePrices: dataa.curvePrices,
                visibleTable: visibleTable
            });
        });
        setExamsData(results);
        setMetaData(listPrice);


    }, [results])


    useEffect(() => {
        // //console.log(id_examProfile, "Esto es lo que hay en eta varioable");
        if (id_examProfile !== undefined && id_examProfile !== "" && id_examProfile !== null && id_examProfile.length > 0) {
            let id_profile_search = 0;

            let exProfile = id_examProfile;
            exProfile = exProfile.split("-");

            if (parseInt(exProfile[1]) === 1) {//PERFIL
                id_profile_search = parseInt(exProfile[0]);
                getProfile(id_profile_search);

            } else {
                setIsCurve(false);

            }
        }

        return () => {

        }
    }, [id_examProfile])

    const getExam = async (id_exam) => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Exam/GetExam/" + id_exam);

        if (respuesta.code === 200) {
            setLoading(false);
            setIsCurve(false);
        }
        else {
            setLoading(false);
            createSweet("error", "error", "Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, getExam);
        }
    }

    const getProfile = async (id_profile) => {

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Profiles/ListDetailProfile/" + id_profile);

        if (respuesta.code === 200) {
            setLoading(false);
            setIsCurve(respuesta.data.is_curve);
        }
        else {
            setLoading(false);
            createSweet("error", "error", "Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, getProfile);
        }
    }

    const addCurve = () => {
        let curvas = curves;
        let nuevaCurva = {
            "exist": false,
            "id_exam_curve_price": Date.now(),
            "number_sampling": 2,
            "price": 0.0
        };
        curvas.push(nuevaCurva);
        setCurves([...curvas]);
    }

    const editCurve = (e, id, type) => {
        // //console.log(e, id, type);
        let value = e.target.value;
        let curvas = curves;
        let newCurvas = curvas.map(x => {
            if (x.id_exam_curve_price === id) {
                if (type === "number") {
                    x.number_sampling = value;
                } else {
                    x.price = value;
                }
            }
            return x;
        });

        setCurves([...newCurvas]);

    }

    const validInputDate = (current) => {
        let yesterday = moment().subtract(1, "day");

        return current.isBefore(yesterday);
    }

    const validToInputDate = (current) => {
        let mArray = getFechasBeforeAfter(from, moment().format("YYYY-MM-DD"));

        return mArray.includes(current.format("YYYY-MM-DD"));

        //return current.isAfter(from);
    }

    const getFechasBeforeAfter = (desde, hasta) => {

        let fechas = [];

        fechas.push(moment(desde).format("YYYY-MM-DD"));

        while (moment(hasta).format("YYYY-MM-DD") >= moment(desde).format("YYYY-MM-DD")) {
            //desde.setDate(desde.getDate() + 1);

            desde = moment(desde).add(1, 'days');

            fechas.push(moment(desde).format("YYYY-MM-DD"));
        }

        return fechas;
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_rate: rateId,
            price: 0,
            from: new Date(),
            to: "",
            id_examProfile: ""
        });

        setValidacionesPrice({
            id_examProfile_valid: false,
            price_valid: false,
            from_valid: false
        });

        setExamProfileState({});

        setPricesId(0);
        setMethod("create");
        setModalTitle("Nuevo precio");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }
        // let exProfile = formValues.id_examProfile;
        let exProfile = formValues.id_examProfile;

        let timeExam = formValues.id_examstatus

        let from = moment(formValues.from._d).format("YYYY-MM-DD HH:mm:ss");
        let to = (formValues.to === "") ? null : moment(formValues.to._d).format("YYYY-MM-DD HH:mm:ss");

        exProfile = exProfile.split("-");
        let data = {};

        if (exProfile[1] === "0") {
            data = {
                id_exam: exProfile[0],
                id_profile: 0,
                ...{ ...formValues },
                from: from,
                to: to,
                curves: curves
            };
        }
        else {
            data = {
                id_exam: 0,
                id_profile: exProfile[0],
                ...{ ...formValues },
                from: from,
                to: to,
                curves: curves
            };
        }

        let body = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: body

        };

        const respuesta = await sendRequest(requestOptions, "Price/Create");

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "success", "Exito!", "Se agrego correctamente");
            // getPrices();

            reset();
            toggle();
        }
        else if (respuesta.code === 409) {
            setLoading(false);
            createSweet("error", "error", "Hubo un error!", respuesta.data.msg);
        }
        else {
            setLoading(false);
            createSweet("error", "error", "Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, saveCreate);
        }
    }

    const handleUpdate = (id_price) => {
        let foundPrices = prices.find(p => p.id_price === parseInt(id_price));

        if (foundPrices !== null) {
            handleUpdateValues({
                id_rate: rateId,
                id_examProfile: foundPrices.id_examProfile,
                price: parseFloat(foundPrices.price.replace(/[$,]/g, '')),
                from: foundPrices.from,
                to: foundPrices.to

            });

            setExamProfileState({
                value: foundPrices.id_examProfile,
                label: foundPrices.name
            });

            setValidacionesPrice({
                id_examProfile_valid: false,
                price_valid: false,
                from_valid: false
            });

            setPricesId(id_price);
            setMethod("update");
            setModalTitle("Modificar precio");
            toggle();
        }
    }

    const saveUpdate = async (e) => {

        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = {};

        let foundPrices = prices.find(p => p.id_price === parseInt(pricesId));

        if (foundPrices !== null) {

            let idExam = 0;
            let idProfile = 0;

            if (foundPrices.id_examProfile === formValues.id_examProfile) {

                if (foundPrices.type === "Exam") {
                    idExam = foundPrices.id_examProfile;
                    idProfile = 0;
                }
                else if (foundPrices.type === "Profile") {
                    idExam = 0;
                    idProfile = foundPrices.id_examProfile;
                }

                mValues = {
                    id_profile: idProfile,
                    id_exam: idExam,
                    price: formValues.price,
                    from: moment(formValues.from).format("YYYY-MM-DD HH:mm:ss"),
                    to: (formValues.to === "") ? null : moment(formValues.to).format("YYYY-MM-DD HH:mm:ss")
                };
            }
            else {
                let exProfile = formValues.id_examProfile;
                exProfile = exProfile.split("-");

                if (parseInt(exProfile[1]) === 0) {//EXAMEN
                    idExam = parseInt(exProfile[0]);
                    idProfile = 0;
                }
                else if (parseInt(exProfile[1]) === 1) {//PERFIL
                    idExam = 0;
                    idProfile = parseInt(exProfile[0]);
                }

                mValues = {
                    id_profile: idProfile,
                    id_exam: idExam,
                    price: formValues.price,
                    from: moment(formValues.from).format("YYYY-MM-DD HH:mm:ss"),
                    to: (formValues.to === "") ? null : moment(formValues.to).format("YYYY-MM-DD HH:mm:ss")
                };
            }
        }

        let data = {
            id_price: pricesId,
            ...{ ...mValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(data)
        };

        const respuesta = await sendRequest(requestOptions, "Price/Update");

        if (respuesta.code === 200) {
            //getPrices();
            reset();
            createSweet("update", "info", "Exito!", "Precio modificado con exito!");
            setLoading(false);
            toggle();
        }
        else {
            setLoading(false);
            createSweet("error", "error", "Hubo un error", LostConnection);
        }
    }

    const handleDelete = (id_price) => {
        let foundPrices = prices.find(p => p.id_price === parseInt(id_price));

        if (foundPrices !== null) {
            handleUpdateValues({
                id_rate: rateId,
                price: foundPrices.price
            })
            setPricesId(id_price);
            setMethod("delete");
            setModalTitle("Eliminar precio de " + foundPrices.name);
            toggle();
        }
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        let respuesta = await sendRequest(requestOptions, `Price/${pricesId}`);

        if (respuesta.code === 200) {
            //getPrices();
            setLoading(false);
            createSweet("delete", "info", "Exito!", "El precio se elimino correctamente");
            toggle();
        }
        else {
            validarSesion(history, respuesta.code, saveDelete);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        let actualDay = moment().format("YYYY-MM-DD");

        if (formValues.id_examProfile === "") {
            newValidaciones = {
                ...newValidaciones,
                id_examProfile_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_examProfile_valid: false
            }
        }

        // if (formValues.price <= 0) {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         price_valid: true
        //     };
        //     statusValidacion = true;
        // }
        // else {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         price_valid: false
        //     };
        // }

        if (formValues.from === '') {
            newValidaciones = {
                ...newValidaciones,
                from_valid: true
            };
            statusValidacion = true;
        }
        else if (moment(formValues.from).format("YYYY-MM-DD") < actualDay) {
            newValidaciones = {
                ...newValidaciones,
                from_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                from_valid: false
            };
        }

        setValidacionesPrice(newValidaciones);

        return statusValidacion;
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    // const [priceteData, setPriceData] = useState([])

    // const handleSearch = (e) => {


    //     let busqueda = e.target.value;
    //     let newMethod = [];

    //     priceteData.forEach(element => {

    //         let cadena = element.name.toUpperCase();
    //         if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
    //             element.visibleTable = true;
    //             newMethod.push(element);
    //         }
    //     });
    //     setPrices(newMethod);
    // }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })







    return {
        rate, prices, method, handleInputChange, validacionesPrice,
        price, from, to, id_examProfile,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        examProfileState,
        handlePickerValues, validInputDate, validToInputDate,
        // totalPageCount, currentPage, nextPage, previousPage, goToPage,
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,

        handleSearch, metaData, examsData,
        isCurve, curves, addCurve, editCurve
    }
}
